import type { SchemedRegionCondition } from './type';
import { LEVEL_ONE } from './data';

type TriggerAreaOption = Campaign.Scheme.Trigger.Trigger['areas'][number];
export const schemeRegionCondition = (trigger: TriggerAreaOption): SchemedRegionCondition => {
  const areaOption: SchemedRegionCondition = {
    tab: '기본',
    condition: {
      lv1: '',
      lv2: { district: [], commercial: [] },
      category: [],
      brand: [],
      store: [],
      complex: []
    },
    geofence: { single: [], group: [], count: 0 }
  };

  if (trigger.store.some((item) => typeof item === 'number' && item === 1)) {
    areaOption.tab = '주거지';
  }

  trigger.area.forEach((sector) => {
    let isSavedLevelOne = false;

    if (sector.type !== 'geofence') {
      if (!isSavedLevelOne) {
        areaOption.condition.lv1 = LEVEL_ONE[sector.lv1];
        isSavedLevelOne = true;
      }
      if (sector?.lv2) {
        areaOption.condition.lv2[sector.type].push(sector.lv2);
      }
      return;
    }

    if (sector.type === 'geofence') {
      if (sector.gfgids && sector.gfgids.length) {
        sector.gfgids.forEach((group) => {
          const geoGroup = group as Campaign.Scheme.Trigger.GeofenceScheme.Group;
          const { name, active, gfgid } = geoGroup;
          let count: number;
          if ('count' in geoGroup) {
            count = geoGroup.count;
            areaOption.geofence.count += geoGroup.count;
          } else {
            count = geoGroup.circle_num + geoGroup.polygon_num;
            areaOption.geofence.count += geoGroup.circle_num + geoGroup.polygon_num;
          }
          areaOption.geofence.group.push({ name, active, count, id: gfgid });
        });
      }

      if (sector.gfids && sector.gfids.length) {
        sector.gfids.forEach((single) => {
          const { name, active, shape_type, gfid } = single as Campaign.Scheme.Trigger.GeofenceScheme.Single;
          areaOption.geofence.single.push({ name, active, shape: shape_type, id: gfid });
          areaOption.geofence.count += 1;
        });
      }
    }
  });

  if ('brand' in trigger) {
    trigger.brand.forEach(({ name }) => areaOption.condition.brand.push(name));
  }

  if ('category' in trigger) {
    trigger.category.forEach((item) => {
      areaOption.condition.category.push((item as Campaign.Scheme.Trigger.Category).name);
    });
  }
  if ('complex' in trigger) {
    trigger.complex.forEach((item) => {
      areaOption.condition.complex.push(stringifyStoreName(item as Campaign.Scheme.Trigger.Complex));
    });
  }
  if ('store' in trigger) {
    trigger.store.forEach((item) => {
      if (item === 1) return;
      areaOption.condition.store.push(stringifyStoreName(item as Campaign.Scheme.Trigger.Store));
    });
  }

  return areaOption;
};

function stringifyStoreName(store: Campaign.Scheme.Trigger.Store | Campaign.Scheme.Trigger.Complex): string {
  const { name, branch_name } = store;
  if (!branch_name) return name;
  return `${name} ${branch_name}`;
}
