// https://stackoverflow.com/questions/60269936/typescript-convert-generic-object-from-snake-to-camel-case

import { CampaignSummary } from 'loplat-x';

export const camelToSnake = (str: string): string => str.replace(/[A-Z]/g, (upper) => `_${upper.toLowerCase()}`);
export const snakeToCamel = (str: string): string => str.replace(/((_+)\w)/g, (n_w) => n_w.slice(-1).toUpperCase());

export const snakeObjToCamelObj = <T extends Record<string, unknown> | CampaignSummary>(
  obj: T
): Record<string | number | symbol, unknown> =>
  Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [snakeToCamel(key)]: value }), {} as SnakeToCamelObj<T>);

export const camelObjToSnakeObj = <T extends Record<string, unknown>>(obj: T): Record<string, unknown> =>
  Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [camelToSnake(key)]: value }), {} as CamelToSnakeObj<T>);
