import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '@F/Loading';
import MaxCustomError from '@FUNC/AxiosCustomError';
import CompanyInput from '../campaigns/search/Filter/CompanyInput';
import { selectOperationCompany } from './redux/operationCompany/selector';
import { actions } from './redux/operationCompany/slice';
import { selectError } from '../campaigns/redux/campaigns/selector';

export default function OperationPage(): JSX.Element {
  const [company, setCompany] = useState('');
  const operationCompany = useSelector(selectOperationCompany);
  const dispatch = useDispatch();
  const { operationCompany: status } = useSelector(selectOperationCompany);
  const apiError = useSelector(selectError);

  const setOperationCompany = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(actions.setOperationCompany(company));
  };

  useEffect(() => {
    dispatch(actions.fetchOperationCompany());
  }, []);

  if (status === 'idle' || status === 'loading') return <Loading />;
  if (status === 'failure' && apiError) throw new MaxCustomError(apiError);

  return (
    <form onSubmit={setOperationCompany}>
      <CurrentOperationCompany>
        현재 연결된 Operation 계정 : {operationCompany.operationCompany}
      </CurrentOperationCompany>
      <Box>
        <CompanyInput company={company} setCompany={setCompany} />
        <Button
          color="primary"
          variant="contained"
          size="large"
          disabled={company === '' || company === operationCompany.operationCompany}
          type="submit"
        >
          변경
        </Button>
      </Box>
    </form>
  );
}

const Box = styled.div`
  display: flex;
  gap: 12px;

  padding: 20px;
  box-shadow: 0 0 0 1px #eee;
  background-color: #fff;
  border-radius: 4px;

  Button {
    max-height: 40px;
  }
`;

const CurrentOperationCompany = styled.div`
  padding: 20px;
`;
