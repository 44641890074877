import { useState, useCallback, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import { StreetViewIcon } from 'loplat-ui';

type Props = {
  naverMap: naver.maps.Map;
  exceptionSnapShotEvent: (e: KeyboardEvent, keyList: string[]) => boolean;
};

const MapTypeButton = ({ naverMap, exceptionSnapShotEvent }: Props): JSX.Element => {
  const [viewType, setViewType] = useState<string>(naverMap.getOptions('mapTypeId'));
  const isNormalType = viewType === naver.maps.MapTypeId.NORMAL;

  const setViewTypeFunc = useCallback(
    () =>
      setViewType((prev) => {
        if (prev === naver.maps.MapTypeId.NORMAL) {
          naverMap.setMapTypeId(naver.maps.MapTypeId.HYBRID);
          return naver.maps.MapTypeId.HYBRID;
        }
        naverMap.setMapTypeId(naver.maps.MapTypeId.NORMAL);
        return naver.maps.MapTypeId.NORMAL;
      }),
    [naverMap]
  );

  const snapShotEvent = useCallback(
    (e) => {
      if (!exceptionSnapShotEvent(e, ['f', 'ㄹ'])) {
        return;
      }
      setViewTypeFunc();
    },
    [exceptionSnapShotEvent, setViewTypeFunc]
  );

  useEffect(() => {
    window.addEventListener('keydown', snapShotEvent);
    return () => {
      window.removeEventListener('keydown', snapShotEvent);
    };
  }, [snapShotEvent]);

  return (
    <Tooltip title={isNormalType ? '위성도 변환 (단축키 : f)' : '지형도 (단축키 : f)'}>
      <span
        role="presentation"
        className={isNormalType ? 'drawingManager' : 'drawingManager active'}
        onClick={setViewTypeFunc}
      >
        <StreetViewIcon fillColor={isNormalType ? '#9DAAB7' : '#fff'} />
      </span>
    </Tooltip>
  );
};

export default MapTypeButton;
