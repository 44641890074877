import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TableBody as TB } from '@material-ui/core';
import { toastErrorMessage } from '@FUNC/toast';

import TableRow from './TableRow';
import usePolygonTable from './usePolygonTable';
import RowSkeleton from '@/Templates/RowSkeleton';
import usePolygonTableFilter from '../PolygonTableFilter/usePolygonTableFilter';

import type RootStateType from '@/redux/common/store.type';

const TableBody = (): JSX.Element => {
  const { getParams } = usePolygonTableFilter();
  const { heads } = usePolygonTable();
  const { result } = useSelector((state) => (state as RootStateType).polygonListReducer);

  useEffect(() => {
    if (result.isError) {
      toastErrorMessage(result.message);
    }
  }, [result.isError, result.message]);

  const showTable = result.isLoading === 'fetched' && result.data.length > 0;

  return (
    <TB>
      {result.isLoading === 'loading' &&
        Array(Number(getParams().per_page))
          .fill(null)
          .map((_, idx) => <RowSkeleton key={idx} length={heads.length} />)}
      {showTable && result.data.map((row) => <TableRow key={row.job_id} row={row} />)}
    </TB>
  );
};

export default TableBody;
