export const format = (stringifiedNumber: number): string =>
  stringifiedNumber.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });

export function getSafeNumber(percent: number): number {
  return Number.isNaN(percent) ? 0 : percent;
}

export function getPercentWithTwoFraction(partial: number, total: number): string {
  return getSafeNumber(partial / total).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'percent'
  });
}
