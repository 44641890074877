import dayjs from 'dayjs';
import { TableCell, TableRow } from '@material-ui/core';
import SegmentDetail from './SegmentDetail';

function SegmentListItem({
  onClickEvent,
  isExpanded,
  ...props
}: Segments.DMP.Segment & { onClickEvent: () => void; isExpanded: boolean }) {
  const format = 'YYYY-MM-DD';
  const formatTime = 'YYYY-MM-DD HH:mm:ss';
  const { segment_id, company, name, state, target_count, duration, creator, created_at } = props;

  const stateObj: Record<Segments.DMP.Segment['state'], string> = {
    '0': '준비중',
    '1': '활성',
    '-1': '에러'
  };

  return (
    <>
      <TableRow hover onClick={onClickEvent}>
        <TableCell align="center">{segment_id}</TableCell>
        <TableCell align="center">{company}</TableCell>
        <TableCell align="center">{name}</TableCell>
        <TableCell align="center">{stateObj[state]}</TableCell>
        <TableCell align="center">{target_count.toLocaleString()}</TableCell>
        <TableCell align="center">
          {duration ? `${dayjs(duration.start).format(format)} ~ ${dayjs(duration.end).format(format)}` : null}
        </TableCell>
        <TableCell align="center">{creator}</TableCell>
        <TableCell align="center">{dayjs(created_at).format(formatTime)}</TableCell>
      </TableRow>
      {isExpanded && <SegmentDetail {...props} />}
    </>
  );
}

export default SegmentListItem;
