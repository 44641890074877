import { SchemedRegionCondition } from '@P/max/campaigns/utils/type';
import RegionModule from './RegionModule';
import { RegionList } from './styles';
import { Section } from '../section';

const RegionSection = ({ regions }: { regions: SchemedRegionCondition[] }): JSX.Element => (
  <Section title="위치 조건">
    <RegionList>
      {regions.map((region, idx) => (
        <li key={idx}>
          <RegionModule region={region} idx={idx + 1} />
        </li>
      ))}
    </RegionList>
  </Section>
);

export default RegionSection;
