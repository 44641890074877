import { Caption, grayscale800, Body, grayscale100, primary, highlight, styled } from 'loplat-ui';
import type { SchemedCampaignReport } from '@P/max/campaigns/utils/type';
import { Section } from '../section';
import { OptionIndex, RegionSection } from '../RegionSection/styles';

const Report = ({ report }: { report: SchemedCampaignReport }): JSX.Element => {
  const isAbTest = report.length === 2;

  return (
    <Section title="캠페인 성과">
      {isAbTest
        ? report.map(({ type, service, hitCnt, click, vendor }, idx) => (
            <RegionSection key={idx}>
              <OptionIndex>{REPORT_TITLE[vendor][vendor === 'kakao' ? service : type]}</OptionIndex>
              <ReportWrapper>
                <ReportBlock title="도달" content={hitCnt.toLocaleString()} />
                <ReportBlock title="응답" content={click.count.toLocaleString()} caption={click.percent} />
              </ReportWrapper>
            </RegionSection>
          ))
        : report.map(({ hitCnt, click, type }) => (
            <ReportWrapper key={type}>
              <ReportBlock title="도달" content={hitCnt.toLocaleString()} />
              <ReportBlock title="응답" content={click.count.toLocaleString()} caption={click.percent} />
            </ReportWrapper>
          ))}
    </Section>
  );
};

export default Report;

const REPORT_TITLE = {
  loplat: { 0: '메시지 A', 1: '메시지 B' },
  kakao: { 0: '캠페인 도달', 1: '친구톡 도달' }
};

type ReportModuleType = {
  title: string;
  content: string;
  caption?: string;
};
const ReportBlock = ({ title, content, caption }: ReportModuleType): JSX.Element => (
  <ModuleWrapper>
    <Body tag="span" color={grayscale800} size="base" weight="bold" mb={2}>
      {title}
    </Body>
    <Body tag="span" weight="extraBold" size="4xl" color={primary}>
      {content}
    </Body>
    {caption && (
      <ReportCaption color={grayscale800} mt={2} size="base">
        응답률: {caption}
      </ReportCaption>
    )}
  </ModuleWrapper>
);

const ReportWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const ModuleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
    max-width: 500px;
  }
  & + & {
    border-left: 1px solid ${grayscale100};
  }
`;

export const ReportCaption = styled(Caption)`
  ${highlight()};
`;
