import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

export const StyledTableRow = styled(TableRow)`
  ${({ isExpanded }) =>
    isExpanded ? `visibility: visible;` : `visibility:hidden; height: 0px !important; width: 0px; display:block;`}
`;

export const StyledDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${({ isExpanded }) => (isExpanded ? 'height: 600px;' : 'height: 0;')}
`;
