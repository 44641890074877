import { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { isQaOptions } from '@P/store/ErrorReport/Filter/options';
import moment from 'moment';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loading from '@F/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/store/storeErrorReport/state';
import * as S from './styles';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';

function Filter() {
  const classes = S.useStyles();

  // 필터 states
  const [storeId, setStoreId] = useInput(null);
  const StoreIdInput = (
    <TextInputForm name="StoreIdInput" label="Store ID (PID)" onChange={setStoreId} value={storeId} />
  );

  const [reporter, setReporter] = useInput(null);
  const ReporterInput = <TextInputForm name="ReporterInput" label="Reporter" onChange={setReporter} value={reporter} />;

  const [isQa, setIsQa] = useInput('전체');
  const isQaSelect = (
    <SelectForm label="QA 여부" options={Object.keys(isQaOptions)} value={isQa} onSelect={setIsQa} name="isQaSelect" />
  );

  const [storeSection, setStoreSection] = useInput('store');
  const StoreSectionSelect = (
    <SelectForm
      label="장소 종류"
      options={['all', 'store', 'section']}
      value={storeSection}
      onSelect={setStoreSection}
      name="StoreSectionSelect"
    />
  );

  const [perPage, setPerPage] = useInput('15');
  const CountSelect = (
    <SelectForm label="개수" options={PER_PAGE_OPTIONS} value={perPage} onSelect={setPerPage} name="CountSelect" />
  );

  const [period, setPeriod] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [isSearchPeriod, setIsSearchPeriod] = useState(true);
  const confirmPeriod = useCallback((dates) => {
    setPeriod(() => ({
      startDate: dates[0],
      endDate: dates[1]
    }));
  }, []);
  const TimeStampInput = <DatePickers onEmitDate={confirmPeriod} initialEndDate={moment().format('YYYY-MM-DD')} />;
  const SearchPeriodCheckbox = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isSearchPeriod} onChange={() => setIsSearchPeriod(!isSearchPeriod)} />
      }
      label="기간 검색"
    />
  );

  const [isReportOnLngLat, setIsReportOnLngLat] = useState(false);
  const ReportOnLngLatCheckbox = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isReportOnLngLat} onChange={() => setIsReportOnLngLat(!isReportOnLngLat)} />
      }
      label="위경도"
    />
  );
  const [isReportOnName, setIsReportOnName] = useState(false);
  const ReportOnNameCheckbox = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isReportOnName} onChange={() => setIsReportOnName(!isReportOnName)} />
      }
      label="매장명"
    />
  );
  const [isReportOnSubName, setIsReportOnSubName] = useState(false);
  const ReportOnSubNameCheckbox = (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={isReportOnSubName}
          onChange={() => setIsReportOnSubName(!isReportOnSubName)}
        />
      }
      label="지점명"
    />
  );
  const [isReportOnCategory, setIsReportOnCategory] = useState(false);
  const ReportOnCategoryCheckbox = (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={isReportOnCategory}
          onChange={() => setIsReportOnCategory(!isReportOnCategory)}
        />
      }
      label="카테고리"
    />
  );

  // fetch data
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.storeErrorReport.isLoading);

  const fetchReports = () => {
    dispatch(
      actions.setValue('params', {
        isQa: isQaOptions[isQa],
        reporter: reporter || null,
        startDate: isSearchPeriod ? period.startDate : null,
        endDate: isSearchPeriod ? period.endDate : null,
        pid: storeId || null,
        storeType: storeSection,
        orFilterLoc: isReportOnLngLat,
        orFilterName: isReportOnName,
        orFilterBranch: isReportOnSubName,
        orFilterCategory: isReportOnCategory
      })
    );
    dispatch(actions.setValue('perPage', Number(perPage)));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchReports());
  };
  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <S.StyledFilter
      onSubmit={(e) => {
        e.preventDefault();
        fetchReports();
      }}
    >
      {isLoading && <Loading />}
      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs>
          {StoreIdInput}
        </Grid>
        <Grid item xs>
          {ReporterInput}
        </Grid>
        <Grid item xs>
          {isQaSelect}
        </Grid>
        <Grid item xs>
          {StoreSectionSelect}
        </Grid>
      </Grid>

      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs={3}>
          {CountSelect}
        </Grid>
        <Grid item xs={4}>
          {TimeStampInput}
          {SearchPeriodCheckbox}
        </Grid>
        <Grid item xs={4}>
          {ReportOnLngLatCheckbox}
          {ReportOnNameCheckbox}
          {ReportOnSubNameCheckbox}
          {ReportOnCategoryCheckbox}
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <S.ButtonRow>
        <Button variant="contained" color="primary" type="submit">
          불러오기
        </Button>
      </S.ButtonRow>
    </S.StyledFilter>
  );
}
export default Filter;

Filter.propTypes = {};
