import { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Dispatch, SetStateAction } from 'react';

import TextInputForm from '@F/materialUI/TextInputForm';
import { StyledUL } from '@P/polygon/styles';
import { toastErrorMessage, toastWarningMessage } from '@FUNC/toast';
import { fetchDapiByQuery } from '@P/polygon/redux/dapiSlice';
import type RootStateType from '@/redux/common/store.type';
import { callReverseGeocode } from './naverMapFunction';

type Props = {
  setLatitude: Dispatch<SetStateAction<string>>;
  setLongitude: Dispatch<SetStateAction<string>>;
  setAddr: Dispatch<SetStateAction<string>>;
  setRoad: Dispatch<SetStateAction<string>>;
  moveMap: (x: number, y: number) => void;
};

const AddressInput = ({ setLatitude, setLongitude, setAddr, setRoad, moveMap }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [isQuerySelect, setIsQuerySelect] = useState(false);
  const [isPointLoading, setIsPointLoading] = useState(false);
  const queryInput = useRef<HTMLInputElement>(null);
  const dapiSearchResult = useSelector((state) => (state as RootStateType).dapiSearchReducer[query]);

  const {
    isLoading: isQueryLoading,
    options,
    isError,
    errorMsg
  } = useMemo(() => {
    if (!query || !dapiSearchResult) {
      return {
        isLoading: 'idle',
        isError: false,
        options: [],
        errorMsg: ''
      };
    }

    const { isLoading, isError } = dapiSearchResult;
    if (isError || !dapiSearchResult.data) {
      return {
        isLoading: `${isLoading}`,
        isError,
        options: [],
        errorMsg: dapiSearchResult?.errorMsg ?? ''
      };
    }

    const { addressResult, keywordResult } = dapiSearchResult.data;
    const { documents: address } = addressResult;
    const { documents: keyword } = keywordResult;

    const options = [
      ...address.map((addressItem) => ({
        address_name: addressItem.address ? addressItem.address.address_name : addressItem.address_name,
        place_name: addressItem.address_name,
        road_address: addressItem.road_address ? addressItem.road_address.address_name : '',
        x: addressItem.x,
        y: addressItem.y,
        isAddress: true
      })),
      ...keyword.map((keywordItem) => ({
        address_name: keywordItem.address_name,
        place_name: keywordItem.place_name,
        road_address: keywordItem.road_address_name,
        x: keywordItem.x,
        y: keywordItem.y,
        isAddress: false
      }))
    ];

    return {
      isLoading: `${isLoading}`,
      options,
      isError,
      errorMsg: ''
    };
  }, [dapiSearchResult, query]);

  useEffect(() => {
    if (isError) {
      toastErrorMessage(errorMsg);
    }
  }, [isError, errorMsg]);

  const selectQueryResult = ({
    address_name,
    road_address,
    x,
    y
  }: {
    address_name: string;
    road_address: string | null;
    x: string;
    y: string;
  }) => {
    setLongitude(x);
    setLatitude(y);
    setAddr(address_name);
    setRoad(road_address ?? '');
    setIsQuerySelect(true);
    moveMap(Number(x), Number(y));
  };

  const fetchByPoint = async () => {
    const [lat, lng] = query.split(',');
    const numLng = Number(lng.trim());
    const numLat = Number(lat.trim());
    if (numLat < -90 || numLat > 90) {
      toastWarningMessage('올바르지 않은 위도 값입니다.');
      return;
    }

    if (numLat < -180 || numLat > 180) {
      toastWarningMessage('올바르지 않은 경도 값입니다.');
      return;
    }
    const coords = { x: numLng, y: numLat };

    try {
      setIsPointLoading(true);
      const { jibun, road } = await callReverseGeocode({ coords });
      if (jibun.address === '' && road.address === '') {
        toastWarningMessage('검색 결과가 없습니다.');
        return;
      }

      setAddr(jibun.address);
      setRoad(road.address);
      moveMap(numLng, numLat);
    } catch (err) {
      toastWarningMessage('일일 API 사용량을 초과하였습니다.');
    } finally {
      setIsPointLoading(false);
    }
  };

  const fetchByQueryOrPoint = async () => {
    const pointInputCheckRegex = /^\s*\d+(\.\d+)?,\s*\d+(\.\d+)?$/;
    if (pointInputCheckRegex.test(query)) {
      fetchByPoint();
    } else {
      dispatch(fetchDapiByQuery({ query }));
    }
  };

  return (
    <>
      <div className="addressInput" ref={queryInput}>
        <TextInputForm
          label="검색"
          name="query"
          value={query}
          onChange={(e) => {
            if (isQuerySelect) {
              setIsQuerySelect(false);
            }
            setQuery(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && isQueryLoading !== 'true' && !isPointLoading) {
              fetchByQueryOrPoint();
            }
          }}
          size="small"
        />
      </div>
      {queryInput.current && !isQuerySelect && (
        <StyledUL inputHeight={queryInput.current.clientHeight}>
          {isQueryLoading === 'true' ? (
            <li>로딩중입니다.</li>
          ) : (
            <>
              {options.length > 0 ? (
                options.map((option, idx) => (
                  <li
                    role="presentation"
                    key={`${option.address_name}_${idx}`}
                    onClick={() => selectQueryResult({ ...option })}
                  >
                    {option.isAddress ? option.address_name : `${option.place_name} (${option.address_name})`}
                  </li>
                ))
              ) : (
                <>{isQueryLoading !== 'idle' && <li>검색 결과가 없습니다.</li>}</>
              )}
            </>
          )}
        </StyledUL>
      )}
    </>
  );
};

export default AddressInput;
