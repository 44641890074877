import { useState, useCallback } from 'react';
import useInputs from '@HOOK/useInputs';
import TextInputForm from '@F/materialUI/TextInputForm';
import { complexesApi } from '@API/manager/index.js';
import { Caption, danger, grayscale800 } from 'loplat-ui';
import type { Complex } from '@TS/store/complex';
import * as S from './FilterStyle.js';

const shortenString = (text: string): string => text.trim();

type Props = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setComplexes: React.Dispatch<React.SetStateAction<Complex[]>>;
  setSelectedComplex: React.Dispatch<React.SetStateAction<any | null>>;
  isTree?: boolean;
};
const CIDFilter = ({ setIsLoading, setComplexes, setSelectedComplex, isTree = false }: Props): JSX.Element => {
  const { value, onChange, clearByKey } = useInputs({ cid: '', name: '', branchName: '', parent: '' });
  const [isError, setIsError] = useState(false);

  // NOTE: '복합몰 CID 추가' 검색 (트리 검색)
  const searchComplexesTree = useCallback(async (parameters) => {
    const { data } = await complexesApi.getComplexes({ ...parameters, isTree: true });
    return data;
  }, []);

  const searchComplexForFilterTab = useCallback(async (parameters): Promise<Complex[]> => {
    const { cid } = parameters;
    if (cid) {
      const response = await complexesApi.getComplex({ cid });
      if (!response.data) throw new Error('데이터 없음');
      return [response.data];
    }
    const { data } = await complexesApi.getComplexes(parameters);
    return data.items;
  }, []);

  const searchComplex = async () => {
    const parameters = Object.entries(value).reduce(
      (acc, [key, value]) => {
        if (!shortenString(value)) return acc;
        return { ...acc, [key]: shortenString(value) };
      },
      {} as any // TODO: complexesApi 가 TS 마이그레이션되면 any 때고 적절하게 할당해주기
    );

    try {
      setIsError(false);
      setIsLoading(true);
      let result: Complex[] = [];
      if (isTree) result = await searchComplexesTree(parameters);
      else result = await searchComplexForFilterTab(parameters);
      setComplexes(result);
      setSelectedComplex(null);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <S.SearchForm
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          searchComplex();
        }}
      >
        {inputParamsList.map(({ name, ...props }) => (
          <TextInputForm
            key={name}
            name={name}
            value={value[name]}
            onClear={() => clearByKey(name)}
            onChange={onChange}
            {...props}
          />
        ))}
        <S.HiddenInput type="submit" />
      </S.SearchForm>
      <Caption size="sm" color={grayscale800}>
        입력 후 엔터키를 누르면 검색됩니다.
      </Caption>
      {isError && (
        <Caption size="sm" color={danger}>
          에러가 생겼습니다. 다시 시도해주세요.
        </Caption>
      )}
    </>
  );
};

export default CIDFilter;

const inputParamsList: Params<'cid' | 'name' | 'branchName' | 'parent'>[] = [
  { label: 'cid', name: 'cid', helperText: '일치' },
  { label: '이름', name: 'name', helperText: '포함' },
  { label: '지점명', name: 'branchName', helperText: '포함' },
  { label: 'parent', name: 'parent', helperText: '일치' }
];

type Params<T> = { label: string; name: T; helperText: string };
