/* eslint-disable max-classes-per-file */
import dayjs from 'dayjs';
import { sortByName } from '@FUNC/sort';
import type { CampaignDetail } from '../redux/campaigns/slice';
import type {
  SegmentOptionType,
  SegmentAllUserScheme,
  SegmentBase,
  SegmentSchemesType,
  SegmentCustomScheme,
  SegmentFileScheme,
  SegmentCommercialScheme,
  SegmentCondition
} from './type';

const classifySegmentType = (campaign: CampaignDetail): SegmentSchemesType => {
  const segment = campaign.segments[0];
  if (isSegmentAllType(segment)) {
    if (segment.name === '모든 사용자') return 'allUser';
    return 'commercial';
  }
  if (segment.name === '전체 타겟') return 'allUser'; // 레거시

  if ('segment_type' in segment) {
    if (segment.segment_type === 'file') return 'file';
  }
  return 'custom';
};

class SegmentTemplate {
  campaign: CampaignDetail;

  constructor(campaign: CampaignDetail) {
    this.campaign = campaign;
  }

  /** - all user 타입은 period 필드가 없음.
   * - default 값 = 90 */
  private defaultPeriod = 90;

  protected getDuration(period = this.defaultPeriod, createdAt?: string): string {
    return serializeSegmentDuration(period, createdAt);
  }

  protected createBase(): SegmentBase {
    const { estimatedUsers } = this.campaign;
    const segment = this.campaign.segments[0] as Campaign.Scheme.Segment.SegmentLogBase;
    const { name, description, is_live } = segment;
    const hasEstimatedUsersInSegment = 'target_count' in segment;

    const segmentForAllCase = ['모든 사용자', '테스트용 광고 ID'];
    const formattedValue = {
      name: name === '전체 타겟' ? '모든 사용자' : name,
      get description() {
        return segmentForAllCase.includes(this.name) ? '' : description;
      }
    };

    return {
      estimatedUsers: hasEstimatedUsersInSegment ? segment.target_count : estimatedUsers ?? 0,
      ...formattedValue,
      period: this.defaultPeriod,
      is_live
    };
  }
}

export class AllUserSegmentScheme extends SegmentTemplate {
  print(): SegmentAllUserScheme {
    return {
      ...this.createBase(),
      type: 'allUser',
      duration: this.getDuration()
    };
  }
}

export class FileSegmentScheme extends SegmentTemplate {
  print(): SegmentFileScheme {
    return {
      ...this.createBase(),
      type: 'file'
    };
  }
}

export class CustomSegmentScheme extends SegmentTemplate {
  segment: Campaign.Scheme.Segment.SegmentLogBase & { period: Segments.SegmentScheme.Period };

  constructor(campaign: CampaignDetail) {
    super(campaign);
    this.segment = campaign.segments[0] as Campaign.Scheme.Segment.SegmentLogBase & {
      period: Segments.SegmentScheme.Period;
    };
  }

  print(): SegmentCustomScheme {
    const { comment, period } = this.segment as Campaign.Scheme.Segment.SegmentLogBase & {
      period: Segments.SegmentScheme.Period;
    };
    const parsedComment: Segments.SegmentScheme.Comment[] | null = comment !== null ? JSON.parse(comment) : null;
    const options = parsedComment === null ? [] : generateSegmentOptions(parsedComment);
    const duration = this.getDuration(this.segment.period as number, this.segment.created_at);

    return {
      ...this.createBase(),
      type: 'custom',
      period,
      options,
      duration,
      end: this.segment.duration?.end ?? ''
    };
  }
}

export class CommercialSegmentScheme extends SegmentTemplate {
  print(): SegmentCommercialScheme {
    const { adids } = this.campaign;
    return {
      ...this.createBase(),
      type: 'commercial',
      name: '광고 ID',
      // NOTE: 광고ID는 무조건 adids가 있어야 함
      adids: adids as string[]
    };
  }
}

const generateSegmentOptions = (segments: Segments.SegmentScheme.Comment[]): SegmentOptionType[] =>
  segments.map(({ lv1, selects, areas }) => {
    const selection: Partial<Segments.SegmentScheme.Select> = (
      Object.entries(selects[0]) as Entries<Segments.SegmentScheme.Select>
    )
      .filter(([, value]) => value.length > 0)
      .sort(([a], [b]) => sortByName(a, b))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    const address: SegmentOptionType['lv2'] = areas.reduce(
      (acc, curr) => {
        if (curr.slice(-2) === '상권') {
          acc.commercial.push(curr);
        } else {
          acc.district.push(curr);
        }
        return acc;
      },
      { district: [], commercial: [] } as SegmentOptionType['lv2']
    );

    return {
      lv1,
      lv2: address,
      selection
    };
  });

const serializeSegmentDuration = (period: Segments.SegmentScheme.Period | number, createdAt?: string): string => {
  let endDate = dayjs().subtract(1, 'day');
  if (createdAt && period) {
    endDate = dayjs(createdAt).subtract(1, 'day');
  }
  const [end, start] = [endDate.subtract(period - 1, 'day'), endDate].map((item) => item.format('YYYY.MM.DD(dd)'));
  return `${end} ~ ${start} (총 ${period}일)`;
};

function isSegmentAllType(
  segment: Campaign.CampaignDetail['segments'][number]
): segment is Campaign.Scheme.Segment.SegmentForAllUser {
  return !('period' in segment);
}

export const schemeSegment = (campaign: CampaignDetail): SegmentCondition => {
  const type = classifySegmentType(campaign);

  if (type === 'allUser') return new AllUserSegmentScheme(campaign).print();
  if (type === 'commercial') return new CommercialSegmentScheme(campaign).print();
  if (type === 'file') return new FileSegmentScheme(campaign).print();
  return new CustomSegmentScheme(campaign).print();
};
