import { CircleIcon, grayscale100, grayscale800, grayscale900, PolygonIcon, primary } from 'loplat-ui';
import styled from 'styled-components';
import { MAX_DISPLAY } from './data';
import { isGeofenceCircle, isGeofenceGroup } from './type';
import type { Geofence } from './index';

type GeofenceTiAreaProps = {
  areaSize: number | null;
  geofence: Geofence;
};
export const GeofenceTipArea = ({ areaSize, geofence }: GeofenceTiAreaProps): JSX.Element => {
  if (isGeofenceGroup(geofence))
    return (
      <>
        <GeofenceCountLabel type="circle" count={geofence.circle_num} />
        <GeofenceCountLabel type="polygon" count={geofence.polygon_num} />
        <Tip>
          <p>각각 최대 {MAX_DISPLAY}개만 그립니다.</p>
        </Tip>
      </>
    );
  return (
    <Tip>
      <b>총 면적</b>
      <span>
        <strong>{typeof areaSize === 'number' ? areaSize.toFixed(2) : '-'}</strong>
        <span>
          km<sup>2</sup>
        </span>
      </span>
    </Tip>
  );
};
export const GeofenceTipRadius = ({ geofence }: { geofence: Geofence }): JSX.Element | null => {
  if (!isGeofenceCircle(geofence)) return null;

  return (
    <Tip>
      <b>반지름</b>
      <span>
        <strong>{geofence.dist}</strong>m
      </span>
    </Tip>
  );
};

export const GeofenceCountLabel = ({ type, count }: { type: 'circle' | 'polygon'; count: number }): JSX.Element => (
  <Tip>
    <Label>
      {type === 'circle' ? <CircleIcon /> : <PolygonIcon />}
      <b>{type}</b>
    </Label>
    <span>
      <strong>{count.toLocaleString()}</strong>개
    </span>
  </Tip>
);

const Label = styled.span`
  display: flex;
  align-items: center;
  b {
    margin-left: 4px;
  }
`;

export const TipLayer = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.8);
  &:hover {
    border-color: white;
    background-color: rgba(255, 255, 255);
  }
  backdrop-filter: blur(5px);
  border-radius: 4px;
  width: 200px;
  height: fit-content;
  padding: 0.725rem 1.25rem;

  font-size: 0.875rem;
  font-weight: 400;
  color: ${grayscale900};

  display: flex;
  flex-direction: column;
`;

const Tip = styled.li`
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  word-break: keep-all;
  b {
    font-weight: 600;
  }
  strong {
    color: ${primary};
    margin-right: 0.125rem;
  }
  p {
    color: ${grayscale800};
  }

  &:not(:first-of-type) {
    border-top: 1px solid ${grayscale100};
  }
`;
