import { Outlet, useLocation, Link } from 'react-router-dom';
import { Headline } from 'loplat-ui';
import styled from '@emotion/styled';
import { findRouteNodeByPathname } from '@/routes';

export default function Main({
  pageScrollViewRef
}: {
  pageScrollViewRef: React.RefObject<HTMLDivElement>;
}): JSX.Element {
  const { pathname } = useLocation();

  const routeNode = findRouteNodeByPathname(pathname);
  const parentRouteNode = findRouteNodeByPathname(`/${pathname.split('/')[1]}`);

  return (
    <main ref={pageScrollViewRef}>
      <StyledTitle>
        <Headline tag="h2" size="2xl">
          {routeNode !== parentRouteNode
            ? `${parentRouteNode?.label} - ${routeNode?.label ?? ''}`
            : routeNode?.label ?? ''}
        </Headline>
        <StyledLink to={pathname} reloadDocument>
          <Headline tag="h3" size="xs">
            {pathname}
          </Headline>
        </StyledLink>
      </StyledTitle>
      <div className="page">
        <Outlet />
      </div>
    </main>
  );
}

const StyledTitle = styled.nav`
  background-color: #fafafa;
  width: 100%;
  padding: 20px;

  display: flex;
  align-items: center;

  h2 {
    font-size: 20px;
    margin-right: 10px;
  }

  a {
    text-decoration: none;
    color: #555;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  :hover {
    color: black;
  }
`;
