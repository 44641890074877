import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createHeadCells, refineRow } from '@F/table/Table/functions';
import moment from 'moment';
import EnhancedTable from '@F/table/Table';
import Detail from '@P/store/StoreCombine/tableSection/Detail';
import ManualCombine from '@P/store/StoreCombine/combineModal/ManualCombine';
import CustomizingColumns from '@P/store/StoreCombine/tableSection/CustomizingColumns';
import PaginationRow from '@F/table/PaginationRow';
import { actions } from '@/redux/store/storeCombine/state';
import * as S from './styles';

function TableSection() {
  const { page, perPage, count, stores } = useSelector((state) => state.storeCombine, shallowEqual);

  // rows
  const [headCells, setHeadCells] = useState(() => columns.map((column) => createHeadCells(column.name, column.label)));
  const rows = useMemo(
    () =>
      (stores ?? []).map((store) => ({
        isCombined: {
          value: store.qa_combined,
          component: <S.RedText>{store.qa_combined ? '병합됨' : ''}</S.RedText>
        },
        id: store.pid,
        name: store.name,
        branchName: store.branch_name,
        lat: parseFloat(store.lat).toFixed(6),
        lng: parseFloat(store.lng).toFixed(6),
        floor: `${store.floor}F`,
        addr: store.addr_road || store.addr,
        bid: store.brands.map((brand) => brand.bid).join(', '),
        cid: store.complexes.map((complex) => complex.cid).join(', '),
        createdAt: moment(store.created_at).format('YYYY-MM-DD HH:mm:ss')
      })),
    [stores]
  );

  // column modal
  const [selectedColumns, setSelectedColumns] = useState(() =>
    columns.reduce((obj, column) => ({ ...obj, [column.name]: true }), {})
  );

  // pagination
  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchStores());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchStores());
  }, [dispatch, page]);

  const refinedRows = useMemo(() => refineRow(rows, selectedColumns), [rows, selectedColumns]);
  useEffect(() => {
    const updatedHeadCells = headCells.map((cell) => ({
      ...cell,
      hide: !selectedColumns[cell.id]
    }));
    setHeadCells(updatedHeadCells);
  }, [selectedColumns]);

  // expand
  const openedStoreIds = useSelector((state) => state.storeCombine.openedStoreIds);
  const setOpenedStoreIds = useCallback(
    (newOpenedStoreIds) => {
      dispatch(actions.setValue('openedStoreIds', newOpenedStoreIds));
    },
    [dispatch]
  );

  const closeDetailList = useCallback(
    (id) => {
      const newOpened = openedStoreIds.map((item) => (item.id === id ? { id, opened: false } : item));
      setOpenedStoreIds(newOpened);
    },

    [setOpenedStoreIds, openedStoreIds]
  );

  return (
    <S.StyledTableSection>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />

      <CustomizingColumns columns={columns} selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />
      <ManualCombine />

      <EnhancedTable
        headCells={headCells.filter((cell) => cell.hide === false)}
        rows={refinedRows}
        isCheckable={false}
        isCollapsible
        opened={openedStoreIds}
        setOpened={setOpenedStoreIds}
      >
        <Detail data={stores} onCloseDetailList={closeDetailList} />
      </EnhancedTable>

      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

TableSection.propTypes = {};

const columns = [
  { name: 'isCombined', label: '병합여부' },
  { name: 'id', label: 'STORE ID', isImportant: true },
  { name: 'cid', label: 'CID' },
  { name: 'bid', label: 'BID' },
  { name: 'name', label: '장소명' },
  { name: 'branchName', label: '지점명' },
  { name: 'floor', label: '층수' },
  { name: 'addr', label: '주소' },
  { name: 'lat', label: '위도' },
  { name: 'lng', label: '경도' },
  { name: 'createdAt', label: '생성 시간' }
];
