import { useState, useEffect, useCallback } from 'react';

function useDrawingTool({ naverMap, drawingManager, isDrawingMode, setIsDrawingMode }) {
  const [clickedPoints, setClickedPoints] = useState([]);
  const [dummyPolyline, setDummyPolyline] = useState(null);
  const [dummyPolygon, setDummyPolygon] = useState(null);

  const resetState = useCallback(() => {
    if (dummyPolyline) {
      dummyPolyline.setMap(null);
      setDummyPolyline(null);
    }

    if (dummyPolygon) {
      dummyPolygon.setMap(null);
      setDummyPolygon(null);
    }

    setClickedPoints([]);

    if (isDrawingMode) {
      setIsDrawingMode(false);
    }
  }, [dummyPolygon, dummyPolyline, isDrawingMode, setIsDrawingMode]);

  useEffect(() => {
    const clickEvent = naverMap.addListener('click', (e) => {
      const { coord } = e;
      const point = new window.naver.maps.Point(coord.x, coord.y);
      setClickedPoints((prev) => [...prev.filter((v) => v.type === 'click'), { type: 'click', point }]);
    });

    if (!isDrawingMode) {
      naverMap.removeListener(clickEvent);
    }

    return () => {
      naverMap.removeListener(clickEvent);
    };
  }, [drawingManager, isDrawingMode, naverMap]);

  useEffect(() => {
    const moveEvent = naverMap.addListener('mousemove', (e) => {
      const { coord } = e;
      const point = new window.naver.maps.Point(coord.x, coord.y);
      setClickedPoints((prev) => [...prev.filter((v) => v.type === 'click'), { type: 'move', point }]);
    });

    if (!isDrawingMode) {
      naverMap.removeListener(moveEvent);
    }

    return () => {
      naverMap.removeListener(moveEvent);
    };
  }, [isDrawingMode, naverMap]);

  useEffect(() => {
    const rightClickEvent = naverMap.addListener('rightclick', (e) => {
      const onlyClickedPath = clickedPoints.filter((v) => v.type === 'click').map((v) => v.point);

      if (!dummyPolygon || onlyClickedPath.length < 3) {
        setIsDrawingMode(false);
        resetState();
        return;
      }

      const drawingPolygon = new window.naver.maps.Polygon({
        map: naverMap,
        paths: [...onlyClickedPath, onlyClickedPath[0]],
        fillColor: 'red',
        fillOpacity: 0.5,
        strokeColor: 'red'
      });
      drawingManager.addDrawing(drawingPolygon, window.naver.maps.drawing.DrawingMode.POLYGON);
      resetState();
    });

    if (!isDrawingMode) {
      naverMap.removeListener(rightClickEvent);
    }

    return () => {
      naverMap.removeListener(rightClickEvent);
    };
  }, [clickedPoints, drawingManager, dummyPolygon, isDrawingMode, naverMap, resetState, setIsDrawingMode]);

  useEffect(() => {
    if (clickedPoints.length < 2) {
      if (dummyPolyline) {
        dummyPolyline.setMap(null);
        setDummyPolyline(null);
      }
    }

    if (clickedPoints.length === 2) {
      if (dummyPolygon) {
        dummyPolygon.setMap(null);
        setDummyPolygon(null);
      }
      if (dummyPolyline) {
        dummyPolyline.setPath([...clickedPoints.map((v) => v.point)]);
      } else {
        const newPolyLine = new window.naver.maps.Polyline({
          map: naverMap,
          path: [...clickedPoints.map((v) => v.point)],
          strokeColor: 'red',
          strokeWeight: 1
        });
        setDummyPolyline(newPolyLine);
      }
    }

    if (clickedPoints.length > 2) {
      if (dummyPolyline) {
        dummyPolyline.setMap(null);
        setDummyPolyline(null);
      }

      if (dummyPolygon) {
        dummyPolygon.setPath([...clickedPoints.map((v) => v.point), clickedPoints[0].point]);
      } else {
        const newPolygon = new window.naver.maps.Polygon({
          map: naverMap,
          path: [...clickedPoints.map((v) => v.point), clickedPoints[0].point],
          fillColor: 'red',
          fillOpacity: 0.5,
          strokeColor: 'red',
          strokeWeight: 1
        });
        setDummyPolygon(newPolygon);
      }
    }
  }, [clickedPoints, dummyPolygon, dummyPolyline, naverMap]);

  useEffect(() => {
    const keyDownListener = (e) => {
      const isZKey = e.key.toLowerCase() === 'z' || e.key === 'ㅋ';
      if ((e.ctrlKey || e.metaKey) && isZKey) {
        setClickedPoints((prev) => {
          if (prev.length < 1) {
            return [];
          }
          const filtered = prev.filter((position) => Boolean(position));
          if (filtered.lenth <= 1) {
            return [];
          }
          const clicked = filtered.filter((v) => v.type === 'click');
          const removed = clicked.slice(0, -1);
          return [...removed, prev[prev.length - 1]];
        });
      }
    };
    window.addEventListener('keydown', keyDownListener);
    return () => {
      window.removeEventListener('keydown', keyDownListener);
    };
  }, []);

  useEffect(() => {
    const drawings = drawingManager.getDrawings();
    const overlays = Object.values(drawings);
    overlays.forEach((polygon) => {
      polygon.setClickable(!isDrawingMode);
    });
    if (!isDrawingMode) {
      resetState();
    }
  }, [drawingManager, isDrawingMode, resetState]);

  return null;
}

export default useDrawingTool;
