import { useCallback, useEffect, useState, useMemo } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import { Button, Grid } from '@material-ui/core';
import CountBox from '@F/CountBox';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import moment from 'moment';
import Loading from '@F/Loading';
import { useFetch } from '@HOOK/';
import { cashplaceUsersApi } from '@API/manager/cashplace';
import Chart from './Chart';
import * as S from './styles';

/* eslint camelcase : 0 */

function Dashboard(): JSX.Element {
  const [period, setPeriod] = useState<{ startDate: string; endDate: string }>({
    startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const confirmPeriod = useCallback((dates: string[]): void => {
    setPeriod(() => ({
      startDate: dates[0],
      endDate: dates[1]
    }));
  }, []);

  const [totalData, setTotalData] = useState({
    total_users: 0,
    total_credit: 0,
    total_pending: 0,
    total_accumulated: 0,
    total_withdraw_complete: 0,
    total_withdraw_pending: 0
  });

  const { callApi, isLoading } = useFetch();
  const fetchAllData = async () => {
    const response = await callApi(cashplaceUsersApi.getStatsAll);
    return response.data;
  };

  const [dataByPeriod, setDataByPeriod] = useState<
    {
      accumulated: number;
      complete_withdraw: number;
      day: string;
      pending_withdraw: number;
      ss_id: number;
      total_credit: number;
      total_pending: number;
      total_users: number;
    }[]
  >([]);
  const dates: string[] = useMemo(() => dataByPeriod.map((item) => item.day), [dataByPeriod]);
  const counts: number[] = useMemo(() => dataByPeriod.map((item) => item.total_users), [dataByPeriod]);
  const fetchDataByPeriod = async () => {
    const response = await callApi(cashplaceUsersApi.getStatsByPeriod, {
      startDate: period.startDate,
      endDate: period.endDate
    });
    return response.data.result;
  };

  useEffect(() => {
    fetchAllData().then((data) => setTotalData(data));
    fetchDataByPeriod().then((data) => setDataByPeriod(data));
  }, []);

  const generateCsv = () => {
    let content = 'data:text/csv;charset=utf-8,';
    content += `${[
      'day',
      '전체 사용자 수',
      '총 Credit',
      '누적 Credit',
      '검수 대기 중인 Credit',
      '인출 처리된 Credit'
    ].join(',')}\n`;

    Object.values(dataByPeriod).forEach((data, index) => {
      content += [
        dates[index],
        data.total_users,
        data.total_credit,
        data.accumulated,
        data.pending_withdraw,
        data.complete_withdraw
      ].join(',');
      content += index < dates.length ? '\n' : '';
    });

    window.open(encodeURI(content));
  };

  return (
    <S.StyledDashboard>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="전체 사용자 수"
              description="v2이후 전체 등록 사용자 수"
              currentData={totalData.total_users}
              labelText="total"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="총 Credit"
              description="실제 사용 가능 총 금액"
              currentData={totalData.total_credit}
              labelText="total"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="Pending Credit"
              description="검수 중 금액"
              currentData={totalData.total_pending}
              labelText="total"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="누적 금액"
              description="총 누적금액"
              currentData={totalData.total_accumulated}
              labelText="Total"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
      </Grid>
      <br />
      <Wrapper>
        <S.ChartContainer>
          <Chart dates={dates} counts={counts} />
          <S.DatePickerWrapper>
            <DatePickers
              initialStartDate={period.startDate}
              initialEndDate={period.endDate}
              onEmitDate={confirmPeriod}
            />
            <Button
              style={{ marginLeft: '10px' }}
              variant="contained"
              onClick={() => fetchDataByPeriod().then((data) => setDataByPeriod(data))}
            >
              LOAD
            </Button>
            <Button style={{ marginLeft: '10px' }} variant="contained" onClick={generateCsv}>
              DOWNLOAD CSV
            </Button>
          </S.DatePickerWrapper>
        </S.ChartContainer>
      </Wrapper>
      <br />
      <Grid container spacing={2}>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="인출 처리한 총 금액"
              description="인출 처리 완료한 총 금액"
              currentData={totalData.total_withdraw_complete}
              labelText="total"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="인출 대기 금액"
              description="인출 처리 완료하지 않은 금액"
              currentData={totalData.total_withdraw_pending}
              labelText="Total"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
      </Grid>
    </S.StyledDashboard>
  );
}
export default Dashboard;
