import { TableRow, TableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const RowSkeleton = ({ length }: { length: number }): JSX.Element => (
  <TableRow>
    {new Array(length).fill(null).map((_, idx) => (
      <TableCell width={100} key={idx}>
        <Skeleton variant="text" height={25} animation="wave" />
      </TableCell>
    ))}
  </TableRow>
);

export default RowSkeleton;
