import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Button, Checkbox, Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextInputForm from '@F/materialUI/TextInputForm';
import CategoryInput from '@F/modal/CategoryInput';
import SelectForm from '@F/materialUI/SelectForm';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import { priorityOptions, combinedOptions } from '@P/store/StoreCombine/options';
import Loading from '@F/Loading';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/store/storeCombine/state';
import * as S from './styles';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';

function Filter() {
  const classes = S.useStyles();

  const [storeId, setStoreId, clearSID] = useInput('');
  const [storeName, setStoreName, clearStoreName] = useInput('');
  const [storeBranchName, setStoreBranchName, clearBranchName] = useInput('');
  const [collector, setCollector, clearCollector] = useInput('');

  const [categoryCode, setCategoryCode] = useState({ korName: '', code: '' });
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  const [perPage, setPerPage] = useInput('15');
  const CountSelect = (
    <SelectForm label="개수" options={PER_PAGE_OPTIONS} value={perPage} onSelect={setPerPage} name="CountSelect" />
  );
  const [priority, setPriority] = useInput('전체');
  const PrioritySelect = (
    <SelectForm
      label="우선도"
      options={Object.keys(priorityOptions)}
      value={priority}
      onSelect={setPriority}
      name="PrioritySelect"
    />
  );
  const [combined, setCombined] = useInput('전체');
  const CombinedSelect = (
    <SelectForm
      label="합쳐짐 여부"
      options={Object.keys(combinedOptions)}
      value={combined}
      onSelect={setCombined}
      name="CombinedSelect"
    />
  );

  const [period, setPeriod] = useState({
    startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [isSearchPeriod, setIsSearchPeriod] = useState(true);
  const confirmPeriod = useCallback((dates) => {
    setPeriod(() => ({
      startDate: dates[0],
      endDate: dates[1]
    }));
  }, []);
  const TimeStampInput = (
    <DatePickers onEmitDate={confirmPeriod} initialStartDate={moment().subtract(1, 'months').format('YYYY-MM-DD')} />
  );
  const SearchPeriodCheckbox = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isSearchPeriod} onChange={() => setIsSearchPeriod(!isSearchPeriod)} />
      }
      label="기간 검색"
    />
  );

  const [isWithoutCategory, setIsWithoutCategory] = useState(false);
  const withoutCategoryOption = (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={isWithoutCategory}
          onChange={() => setIsWithoutCategory(!isWithoutCategory)}
        />
      }
      label="카테고리 제외"
    />
  );
  const [isAbsoluteValue, setIsAbsoluteValue] = useState(false);
  const AbsoluteValueOption = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isAbsoluteValue} onChange={() => setIsAbsoluteValue(!isAbsoluteValue)} />
      }
      label="절대값"
    />
  );

  // fetch data
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.storeCombine.isLoading);
  const fetchStores = () => {
    dispatch(
      actions.setValue('params', {
        startDate: isSearchPeriod ? period.startDate : null,
        endDate: isSearchPeriod ? period.endDate : null,
        pid: storeId || null,
        name: storeName || null,
        branchName: storeBranchName || null,
        collector: collector || null,
        categoryCode: categoryCode.code || null,
        categoryExclude: isWithoutCategory,
        equalSearch: isAbsoluteValue,
        qaPriDedup: priorityOptions[priority],
        combined: combinedOptions[combined]
      })
    );
    dispatch(actions.setValue('perPage', Number(perPage)));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchStores());
  };

  useEffect(() => {
    fetchStores();
  }, []);

  return (
    <S.StyledForm
      onSubmit={(event) => {
        event.preventDefault();
        fetchStores();
      }}
    >
      {isLoading && <Loading />}
      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs>
          <TextInputForm
            name="StoreIdInput"
            label="Store ID"
            onChange={setStoreId}
            value={storeId}
            onClear={clearSID}
          />
        </Grid>
        <Grid item xs>
          <TextInputForm
            name="StoreNameInput"
            label="장소명"
            onChange={setStoreName}
            value={storeName}
            onClear={clearStoreName}
          />
        </Grid>
        <Grid item xs>
          <TextInputForm
            name="StoreBranchNameInput"
            label="지점명"
            onChange={setStoreBranchName}
            value={storeBranchName}
            onClear={clearBranchName}
          />
        </Grid>
        <Grid item xs>
          <CategoryInput
            categoryCode={categoryCode}
            setCategoryCode={setCategoryCode}
            isCategoryModalOpen={isCategoryModalOpen}
            setIsCategoryModalOpen={setIsCategoryModalOpen}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs>
          <TextInputForm
            name="CollectorInput"
            label="COLLECTOR"
            onChange={setCollector}
            value={collector}
            onClear={clearCollector}
          />
        </Grid>
        <Grid item xs>
          {CountSelect}
        </Grid>
        <Grid item xs>
          {PrioritySelect}
        </Grid>
        <Grid item xs={3}>
          {CombinedSelect}
        </Grid>
      </Grid>
      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs={4}>
          {TimeStampInput}
          {SearchPeriodCheckbox}
        </Grid>
        <Grid item xs={3}>
          {withoutCategoryOption}
          {AbsoluteValueOption}
        </Grid>
      </Grid>

      <S.ButtonRow>
        <Button variant="contained" color="primary" type="submit">
          불러오기
        </Button>
      </S.ButtonRow>
    </S.StyledForm>
  );
}
export default Filter;
