export type CustomSegmentType = {
  lv1: string;
  lv2: {
    district: string[]; // 행정구역
    commercial: string[]; // 상권
  };
  selection: Partial<Segments.SegmentScheme.Select>;
};

export function getSegmentOptions({ lv1, selects, areas }: Segments.SegmentScheme.Comment): CustomSegmentType {
  const selection = { ...selects[0] };
  for (const key in selection) {
    if (selection[key as keyof typeof selection].length === 0) delete selection[key as keyof typeof selection];
  }

  const address: CustomSegmentType['lv2'] = areas.reduce(
    (acc, curr) => {
      if (curr.slice(-2) === '상권') acc.commercial.push(curr);
      else acc.district.push(curr);
      return acc;
    },
    { district: [], commercial: [] } as CustomSegmentType['lv2']
  );

  return {
    lv1,
    lv2: address,
    selection
  };
}
