import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import Wrapper from '@F/materialUI/Wrapper';

import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { SdkConfigStatus } from '@API/manager/sdk/types';
import { useManageSdkConfigs } from '../useManageCompanies';
import { SDK_CONFIG_STATUSES } from '../constant';
import { valuesStore } from '@/mobX/values';

// filter
export default function CompaniesFilter(): JSX.Element {
  const [filterValues, setFilterValues] = useState<{ clientId: string; status: SdkConfigStatus }>({
    clientId: 'ALL',
    status: 'ALL'
  });
  const { getApiStatus, fetchSdkConfigSummaries } = useManageSdkConfigs();

  const handleChangeStatus = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setFilterValues((prev) => ({ ...prev, status: e.target.value as SdkConfigStatus }));
  };
  const handleChangeCompany = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setFilterValues((prev) => ({ ...prev, clientId: e.target.value as string }));
  };

  const submit = () => {
    const { clientId, status } = filterValues;
    if (clientId === 'ALL') {
      fetchSdkConfigSummaries({ status });
    } else {
      fetchSdkConfigSummaries({ client_id: clientId, status });
    }
  };

  useEffect(() => {
    fetchSdkConfigSummaries({ status: 'ALL' });
  }, [fetchSdkConfigSummaries]);

  const sdkClientIds = toJS(valuesStore.sdkClientIds);

  return (
    <Wrapper>
      <StyledManageCompaniesFilter container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="sdkConfig-client_id">회사</InputLabel>
            <Select name="status" label="회사" value={filterValues.clientId} onChange={handleChangeCompany}>
              <MenuItem value="ALL">전체</MenuItem>
              {sdkClientIds.map((clientId) => (
                <MenuItem key={clientId} value={clientId}>
                  {clientId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="sdkConfig-status">상태</InputLabel>
            <Select name="status" label="상태" value={filterValues.status} onChange={handleChangeStatus}>
              {SDK_CONFIG_STATUSES.map((status) => (
                <MenuItem key={status.name} value={status.name}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            onClick={submit}
            color="primary"
            variant="contained"
            disabled={getApiStatus === 'loading'}
            size="large"
            style={{ height: '55px' }}
            fullWidth
          >
            조회
          </Button>
        </Grid>
      </StyledManageCompaniesFilter>
    </Wrapper>
  );
}

const StyledManageCompaniesFilter = styled(Grid)`
  padding: 10px 20px;
  border-radius: 10px;
`;
