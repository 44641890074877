import { useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import TextInputForm from '@F/materialUI/TextInputForm';
import { Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import SelectForm from '@F/materialUI/SelectForm';
import { actions } from '@/redux/cashplace/version/state';
import * as S from './styles';

const numberReg = /^[0-9]+$/;

function AddVersion(): JSX.Element {
  const classes = S.useStyles();

  const [osType, setOsType] = useState<string>('');
  const osTypeInput = (
    <SelectForm
      name="osType"
      label="OS 타입"
      value={osType}
      onSelect={(e): void => setOsType(e.target.value as string)}
      options={['ios', 'android']}
    />
  );

  const [versionNumberParsedInteger, setVersionNumberParsedInteger] = useState<string>('');
  const VersionNumberParsedIntegerInput = (
    <TextInputForm
      name="VersionNumberParsedIntegerInput"
      label="앱 버전(정수형)"
      value={versionNumberParsedInteger}
      onChange={(e): void => setVersionNumberParsedInteger(e.target.value)}
      error={!numberReg.test(versionNumberParsedInteger)}
      required
    />
  );

  const [availableMinimumVersionNumberParsedInteger, setAvailableMinimumVersionNumberParsedInteger] =
    useState<string>('');
  const AvailableMinimumVersionNumberParsedIntegerInput = (
    <TextInputForm
      name="AvailableMinimumVersionNumberParsedInteger"
      label="동작가능 최소버전"
      value={availableMinimumVersionNumberParsedInteger}
      onChange={(e): void => setAvailableMinimumVersionNumberParsedInteger(e.target.value)}
      required
    />
  );

  const [remarks, setRemarks] = useState<string>('');
  const RemarksInput = (
    <TextInputForm
      name="RemarksInput"
      label="비고"
      value={remarks}
      onChange={(e): void => setRemarks(e.target.value)}
    />
  );
  const dispatch = useDispatch();
  const createVersion = () => {
    dispatch(
      actions.createVersion({
        version: versionNumberParsedInteger,
        minimumVersion: availableMinimumVersionNumberParsedInteger,
        comment: remarks,
        type: osType
      })
    );
  };

  return (
    <S.StyledAddVersion>
      <Wrapper>
        <S.Title>버전 등록</S.Title>
        <S.Row container className={classes.margin} spacing={1}>
          <Grid item xs>
            {osTypeInput}
          </Grid>
          <Grid item xs>
            {VersionNumberParsedIntegerInput}
          </Grid>
          <Grid item xs>
            {AvailableMinimumVersionNumberParsedIntegerInput}
          </Grid>
          <Grid item xs>
            {RemarksInput}
          </Grid>
        </S.Row>

        <S.ButtonRow>
          <Button variant="contained" color="primary" onClick={createVersion}>
            등록
          </Button>
        </S.ButtonRow>
      </Wrapper>
    </S.StyledAddVersion>
  );
}
export default AddVersion;
