import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { complexesApi } from '@API/manager';

export const fetchPolygonByCid = createAsyncThunk('fetchPolygonByCid', async ({ cid }: { cid: number }) => {
  const response = await complexesApi.getPolygon({ cid });
  return response.data;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: Record<number, Record<string, any>> = {};

const slice = createSlice({
  name: 'polygonReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPolygonByCid.pending, (state, action) => {
      const { cid } = action.meta.arg;
      state[cid] = {
        isLoading: true
      };
    });
    builder.addCase(fetchPolygonByCid.fulfilled, (state, action) => {
      const { cid } = action.meta.arg;
      const data = action.payload;
      state[cid] = {
        isLoading: false,
        hasData: Boolean(data),
        data
      };
    });
    builder.addCase(fetchPolygonByCid.rejected, (state, action) => {
      const { cid } = action.meta.arg;
      // TODO. 사용자에게 알려줄 필요가 있는지.
      state[cid] = {
        isLoading: false,
        hasData: false
      };
    });
  }
});

export const { actions } = slice;
export default slice.reducer;

export const polygonConfig = {
  key: 'polygonReducer',
  storage,
  whitelist: ['polygonReducer']
};
