import React, { MouseEventHandler, useState } from 'react';
import styled from 'styled-components';
import { TableCell as TC, TableSortLabel } from '@material-ui/core';
import { ArrowCircleDown } from '@mui/icons-material';

interface TableHeadCellWithSortingApi {
  name: string;
  label: string;
  onClick: (value: string) => void;
  isSortAble: boolean;
  currentSort: string;
}

const TableCell = styled(TC)`
  .active {
    color: blue !important;
  }
`;

export default function TableHeadCellWithSortingApi({
  name,
  label,
  onClick,
  isSortAble,
  currentSort
}: TableHeadCellWithSortingApi): JSX.Element {
  const handleClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    if (currentSort.endsWith(name)) {
      onClick(currentSort.startsWith('^') ? name : `^${name}`);
    } else {
      onClick(name);
    }
  };

  return (
    <TableCell align="center">
      {isSortAble ? (
        <TableSortLabel
          active={true}
          direction={currentSort.startsWith('^') ? 'asc' : 'desc'}
          onClick={handleClick}
          classes={{
            icon: currentSort.endsWith(name) ? 'active' : ''
          }}
        >
          <b>{label}</b>
        </TableSortLabel>
      ) : (
        <div role="button" tabIndex={-1}>
          <b>{label}</b>
        </div>
      )}
    </TableCell>
  );
}
