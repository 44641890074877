import { TableHead as TH, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import { CustomArrowDownwardOutlined } from '@P/polygon/styles';
import Tooltip from '@F/Tooltip';
import usePolygonTable from './usePolygonTable';
import usePolygonTableFilter from '../PolygonTableFilter/usePolygonTableFilter';

const TableHead = (): JSX.Element => {
  const { getParams, setParams } = usePolygonTableFilter();
  const { heads } = usePolygonTable();

  const isAscSort = getParams().order.startsWith('^');
  const isActiveSort = (value: string) => getParams().order.endsWith(value);
  const getDirection = (value: string) => (isActiveSort(value) ? (isAscSort ? 'asc' : 'desc') : 'desc');

  const sortingTip = (label: string, isActive: boolean): string => {
    if (isActive) return `클릭하면 ${label}을/를 기준으로 오름차순으로 정렬합니다.`;
    return `클릭하면 ${label}을/를 기준으로 내림차순으로 정렬합니다.`;
  };

  const onClickHandler = (value: string) => {
    const direction = getDirection(value);
    const newOrder = direction === 'desc' ? `^${value}` : value;
    setParams({
      ...getParams,
      order: newOrder
    });
  };

  return (
    <TH>
      <TableRow>
        {heads.map((v) => (
          <TableCell key={v.key} width={v.width} align={v.align}>
            {v.sortAble ? (
              <Tooltip title={sortingTip(v.label, getDirection(v.value) === 'desc')}>
                <TableSortLabel
                  active
                  direction={getDirection(v.value)}
                  className={isActiveSort(v.value) ? 'active' : ''}
                  onClick={() => onClickHandler(v.value)}
                  IconComponent={(props) => <CustomArrowDownwardOutlined {...props} />}
                >
                  <b>{v.label}</b>
                </TableSortLabel>
              </Tooltip>
            ) : (
              v.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TH>
  );
};

export default TableHead;
