import { useCallback, useEffect, useMemo, useState } from 'react';
import { toJS } from 'mobx';
import { Button, Checkbox, Grid, TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';
import SelectForm from '@F/materialUI/SelectForm';
import TextInputForm from '@F/materialUI/TextInputForm';
import CategoryModal from '@F/modal/CategoryModal';
import BrandsInput from '@F/input/BrandsInput';
import ComplexInput from '@F/input/ComplexInput';
import moment from 'moment';
import { prioritySelectOptions } from '@P/store/StoreManager/filter/Filter/Options';
import Loading from '@F/Loading';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '@HOOK/';
import DialogModal from '@F/DialogModal';
import CustomError from '@FUNC/CustomError';
import useAuth from '@HOOK/useAuth';
import { actions } from '@/redux/store/storeManager/state';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';
import * as S from './styles';
import { valuesStore } from '@/mobX/values';

function Filter() {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const isCustomerUser = user.user_claims.role === 'customer';

  const classes = S.useStyles();
  const [perPage, setPerPage] = useInput('15');

  // State: Text Input value
  const [storeIDState, setStoreIDState] = useInput(null);
  const [fingerprintIDState, setFingerprintIDState] = useInput(null);
  const [nameState, setNameState] = useInput(null);
  const [branchNameState, setBranchNameState] = useInput(null);
  const [collector, setCollector] = useInput(null);
  const [qaState, setQaState] = useInput(null);

  // State: from Modal
  const [categoryCodeState, setCategoryCodeState] = useState({ korName: '', code: '' });
  const [complex, setComplex] = useState(null);
  const [brands, setBrands] = useState([]);

  // State: Select
  const [storeSectionState, setStoreSectionState] = useInput('store');
  const [priorityState, setPriorityState] = useInput('All');
  const [inspectionState, setInspectionState] = useInput('검수');

  // 모달이 떠야 하는 input들의 clicked 를 나타내는 값
  const [isCategoryCodeModalVisible, setIsCategoryCodeModalVisible] = useState(false);

  // 옵션 값
  const [isSearchPeriod, setIsSearchPeriod] = useState(true);
  const [isWithoutCategory, setIsWithoutCategory] = useState(false);
  const [isAbsoluteValue, setIsAbsoluteValue] = useState(false);
  const [isCategoryNull, setIsCategoryNull] = useState(false);
  const [isAllComplexes, setIsAllComplexes] = useState(false);
  const [isExcludingCertainQA, setIsExcludingCertainQA] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ title: '', message: '' });

  // State: Date
  const [period, setPeriod] = useState({
    startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  const confirmPeriod = useCallback((dates) => {
    setPeriod(() => ({
      startDate: dates[0],
      endDate: dates[1]
    }));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      fetchStores();
    } catch (error) {
      const { title, message } = error;
      setIsError(true);
      setErrorMessage({ title, message });
    }
  };

  const isLoading = useSelector((state) => state.storeManager.isLoading);
  const fetchStores = () => {
    dispatch(actions.setValue('perPage', Number(perPage)));
    dispatch(actions.setPage(1));

    // NOTE: 우선순위 : FPID > storeID > etc
    if (fingerprintIDState.length > 0) {
      dispatch(
        actions.setValue('params', {
          fpid: fingerprintIDState.trim() || undefined
        })
      );
      dispatch(actions.fetchStores('FPID'));
    } else if (storeIDState.length > 0) {
      dispatch(
        actions.setValue('params', {
          pid: storeIDState || undefined
        })
      );
      dispatch(actions.fetchStores('PID'));
    } else {
      if (isExcludingCertainQA && !qaState.trim()) {
        throw new CustomError(
          '검수자를 지정해주세요.',
          '지정 검수자 제외에 체크하시면 검수자(qa_by)항목을 입력하셔야 합니다.'
        );
      }

      dispatch(
        actions.setValue('params', {
          fpid: fingerprintIDState.trim() || undefined,
          name: nameState || undefined,
          branchName: branchNameState || undefined,
          startDate: isSearchPeriod ? period.startDate : undefined,
          endDate: isSearchPeriod ? period.endDate : undefined,
          categoryCode: categoryCodeState.code || undefined,
          categoryIsNull: isCategoryNull || undefined,
          categoryExclude: isWithoutCategory || undefined,
          collector: collector || undefined,
          equalSearch: isAbsoluteValue || undefined,
          qaBy: qaState || undefined,
          searchPriFilter: prioritySelectOptions.find((item) => item.type === priorityState)?.id,
          cid: complex ? complex.id : undefined,
          bid: brands.length ? brands[0].id : undefined,
          qualified: inspectionState === '검수',
          storeType: storeSectionState || undefined,
          hasComplex: isAllComplexes || undefined,
          qaByExcluded: isExcludingCertainQA || undefined
        })
      );
      dispatch(actions.fetchStores('default'));
    }
  };

  useEffect(() => {
    fetchStores();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const StoreIDInput = (
    <TextInputForm name="StoreIDInput" label="Store ID (PID)" onChange={setStoreIDState} value={storeIDState} />
  );
  const FingerprintIDInput = (
    <TextInputForm
      name="FingerprintIDInput"
      label="Fingerprint ID (FPID)"
      onChange={setFingerprintIDState}
      value={fingerprintIDState}
    />
  );
  const NameInput = <TextInputForm name="NameInput" label="장소명 (name)" onChange={setNameState} value={nameState} />;
  const BranchNameInput = (
    <TextInputForm
      name="BranchNameInput"
      label="지점명 (branch name)"
      onChange={setBranchNameState}
      value={branchNameState}
    />
  );

  const collectorOptions = useMemo(
    () => toJS(valuesStore.collectors).map((user) => user.username),
    [valuesStore.collectors]
  );

  const CollectorInput = (
    <Autocomplete
      id="ChargeInput"
      options={collectorOptions}
      noOptionsText="수집자를 입력해주세요"
      onInputChange={(e, value) => setCollector({ target: { value } })}
      renderInput={(params) => <TextField {...params} label="수집자" variant="outlined" />}
    />
  );

  const QAInput = <TextInputForm name="QAInput" label="검수자 (qa_by)" onChange={setQaState} value={qaState} />;
  const CategoryInput = (
    <TextInputForm
      name="CategoryInput"
      label="카테고리 (category)"
      value={categoryCodeState.korName}
      onClick={() => setIsCategoryCodeModalVisible(!isCategoryCodeModalVisible)}
      readOnly
      onClear={() =>
        setCategoryCodeState({
          korName: '',
          code: ''
        })
      }
    />
  );

  const complexInput = <ComplexInput onChange={setComplex} />;
  const brandsInput = <BrandsInput onChange={setBrands} />;

  const CountSelect = (
    <SelectForm
      label="개수"
      options={PER_PAGE_OPTIONS}
      value={perPage}
      onSelect={setPerPage}
      disabled={false}
      name="CountSelect"
    />
  );
  const StoreSectionSelect = (
    <SelectForm
      label="장소종류"
      options={['all', 'store', 'section']}
      value={storeSectionState}
      onSelect={setStoreSectionState}
      disabled={false}
      name="StoreSectionSelect"
    />
  );
  const PrioritySelect = (
    <SelectForm
      label="검수우선도"
      options={prioritySelectOptions.map((item) => item.type)}
      value={priorityState}
      onSelect={setPriorityState}
      name="PrioritySelect"
    />
  );
  const InspectionSelect = (
    <SelectForm
      label="검수 / 미검수"
      options={['검수', '미검수']}
      value={inspectionState}
      onSelect={setInspectionState}
      name="InspectionSelect"
    />
  );
  const TimeStampInput = (
    <DatePickers
      initialStartDate={
        isCustomerUser ? moment().subtract(1, 'months').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }
      onEmitDate={confirmPeriod}
    />
  );
  const SearchPeriodOption = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isSearchPeriod} onChange={() => setIsSearchPeriod(!isSearchPeriod)} />
      }
      label="기간 검색"
    />
  );
  const withoutCategoryOption = (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={isWithoutCategory}
          onChange={() => setIsWithoutCategory(!isWithoutCategory)}
        />
      }
      label="카테고리 제외"
    />
  );
  const AbsoluteValueOption = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isAbsoluteValue} onChange={() => setIsAbsoluteValue(!isAbsoluteValue)} />
      }
      label="절대값"
    />
  );
  const CategoryNullOption = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isCategoryNull} onChange={() => setIsCategoryNull(!isCategoryNull)} />
      }
      label="카테고리 Null"
    />
  );

  return (
    <S.FilterForm onSubmit={handleSubmit}>
      {isLoading && <Loading />}

      <DialogModal
        title={errorMessage.title}
        description={errorMessage.message}
        open={isError}
        setOpen={setIsError}
        confirmButtonDidClicked={() => setIsError(false)}
        hasCancelButton={false}
      />

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {StoreIDInput}
        </Grid>
        <Grid item xs>
          {FingerprintIDInput}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs>
          {NameInput}
        </Grid>
        <Grid item xs>
          {BranchNameInput}
        </Grid>
        <Grid item>
          {CategoryInput}
          {isCategoryCodeModalVisible && (
            <CategoryModal
              open={isCategoryCodeModalVisible}
              setOpen={setIsCategoryCodeModalVisible}
              setValue={setCategoryCodeState}
            />
          )}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {complexInput}
        </Grid>
        <Grid item xs>
          {brandsInput}
        </Grid>
        <Grid item xs={1} />
        {isCustomerUser ? null : (
          <>
            <Grid item xs>
              {CollectorInput}
            </Grid>
            <Grid item xs>
              {QAInput}

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isExcludingCertainQA}
                    onChange={() => setIsExcludingCertainQA((prev) => !prev)}
                  />
                }
                label="지정 검수자 제외"
                className="smallCheck"
              />
            </Grid>
          </>
        )}
        <Grid item xs>
          {CountSelect}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs={2}>
          {StoreSectionSelect}
        </Grid>
        {isCustomerUser ? null : (
          <>
            <Grid item xs={1}>
              {PrioritySelect}
            </Grid>
            <Grid item xs={1}>
              {InspectionSelect}
            </Grid>
          </>
        )}
        <Grid item xs={4}>
          {TimeStampInput}
          {SearchPeriodOption}
        </Grid>
        <Grid item xs={4}>
          {withoutCategoryOption}
          {AbsoluteValueOption}
          {CategoryNullOption}
        </Grid>
      </S.Row>
      <S.ButtonRow>
        <Button variant="contained" color="primary" type="submit">
          불러오기
        </Button>
      </S.ButtonRow>
    </S.FilterForm>
  );
}
export default Filter;
