import Badge from '@F/Badge';
import { bluescale300, grayscale800, green300 } from 'loplat-ui';

const CampaignState = ({
  status,
  full = false
}: {
  status: Campaign.Scheme.Status.ParameterKorean;
  full?: boolean;
}): JSX.Element => <Badge full={full} text={status} color={CampaignStateColor[status]} />;

export default CampaignState;

export const CampaignStateColor: Record<Campaign.Scheme.Status.ParameterKorean, string> = {
  대기: grayscale800,
  진행: green300,
  종료: bluescale300,
  테스트: bluescale300,
  '라이브 캠페인 생성중': grayscale800
};
