export const ERROR_MESSAGES = {
  axios: {
    homepage: '요청 실패 : 관리자(윤준호)에게 문의바랍니다.',
    max: '요청 실패 : 관리자(윤준호)에게 문의바랍니다.',
    manager: '요청 실패 : 관리자(윤준호)에게 문의바랍니다.'
  },
  errorBoundary: {
    EntityNotFound: '자료를 찾을 수 없습니다. 찾으시는 자료의 id를 확인해주세요.',
    AccessDeny: '다시 로그인 해주세요.'
  }
};
