import { useEffect, useMemo, useState } from 'react';
import { toJS } from 'mobx';
import { Button, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import moment from 'moment';
import Loading from '@F/Loading';
import { useDispatch, useSelector } from 'react-redux';
import CategoryInput from '@F/modal/CategoryInput';
import { useTogglers } from '@HOOK/useTogglers';
import { useInput } from '@HOOK/';
import Duration from '@F/Duration';
import useInputs from '@HOOK/useInputs';
import { actions } from '@/redux/fingerprint/fpManager/state';
import { qaStatusOptions, imageUploadStatusOptions, fpStateOptions } from './options';
import * as S from './styles';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';
import { valuesStore } from '@/mobX/values';

function Filter() {
  const classes = S.useStyles();

  // 필터 states
  const [fingerprintId, setFingerprintId] = useInput(null);
  const [name, setName] = useInput(null);
  const [branchName, setBranchName] = useInput(null);
  const [collector, setCollector] = useInput(null);
  const params = useSelector((state) => state.fpManager.params);
  const collectorOptions = useMemo(
    () => toJS(valuesStore.collectors).map((user) => user.username),
    [valuesStore.collectors]
  );

  const [qaStatus, setQaStatus] = useInput('전체');
  const [imageUploadStatus, setImageUploadStatus] = useInput('전체');
  const [fpState, setFpState] = useInput('유효');
  const [perPage, setPerPage] = useInput('15');

  const [isSearchPeriod, setIsSearchPeriod] = useState(true);
  const { value: period, onChange: confirmPeriod } = useInputs({
    start: moment().format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD')
  });

  const [categoryCode, setCategoryCode] = useState({ korName: '', code: '' });
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  // fetch data
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.fpManager.isLoading);

  const togglers = useTogglers();

  const fetchFingerprints = () => {
    dispatch(
      actions.setValue('params', {
        ...params,
        startDate: isSearchPeriod ? period.start : null,
        endDate: isSearchPeriod ? period.end : null,
        fpid: fingerprintId || null,
        name: name || null,
        branchName: branchName || null,
        collector: collector || null,
        categoryCode: categoryCode?.code || null,
        imageInspected: qaStatusOptions[qaStatus],
        imageUploadStatus: imageUploadStatusOptions[imageUploadStatus],
        fpState: fpStateOptions[fpState]
      })
    );
    dispatch(actions.setValue('perPage', Number(perPage)));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchFingerprints());
  };

  useEffect(() => {
    fetchFingerprints();
    togglers.setOffAll();
  }, []);

  return (
    <S.StyledFilter
      onSubmit={(e) => {
        e.preventDefault();
        fetchFingerprints();
        togglers.setOffAll();
      }}
    >
      {isLoading && <Loading />}
      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs>
          <TextInputForm
            name="FingerprintInput"
            label="Fingerprint ID"
            onChange={setFingerprintId}
            value={fingerprintId}
            size="small"
          />
        </Grid>
        <Grid item xs>
          <TextInputForm name="NameInput" label="장소명" onChange={setName} value={name} size="small" />
        </Grid>
        <Grid item xs>
          <TextInputForm
            name="BranchNameInput"
            label="지점명"
            onChange={setBranchName}
            value={branchName}
            size="small"
          />
        </Grid>
        <Grid item xs>
          <Autocomplete
            id="ChargeInput"
            options={collectorOptions}
            noOptionsText="수집자를 입력해주세요"
            onInputChange={(_, value) => setCollector({ target: { value } })}
            renderInput={(params) => <TextField {...params} label="수집자" variant="outlined" />}
            freeSolo
            size="small"
          />
        </Grid>
        <Grid item xs>
          <CategoryInput
            size="small"
            categoryCode={categoryCode}
            setCategoryCode={setCategoryCode}
            isCategoryModalOpen={isCategoryModalOpen}
            setIsCategoryModalOpen={setIsCategoryModalOpen}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs>
          <SelectForm
            size="small"
            label="검수 상태"
            options={Object.keys(qaStatusOptions)}
            value={qaStatus}
            onSelect={setQaStatus}
            name="qaStatusSelect"
          />
        </Grid>
        <Grid item xs>
          <SelectForm
            size="small"
            label="이미지 업로드 상태"
            options={Object.keys(imageUploadStatusOptions)}
            value={imageUploadStatus}
            onSelect={setImageUploadStatus}
            name="imageUploadSelect"
          />
        </Grid>
        <Grid item xs>
          <SelectForm
            size="small"
            label="상태"
            options={Object.keys(fpStateOptions)}
            value={fpState}
            onSelect={setFpState}
            name="fpStateSelect"
          />
        </Grid>
        <Grid item xs={3}>
          <Duration
            size="small"
            onChange={confirmPeriod}
            names={['start', 'end']}
            labels={['', '']}
            values={[period.start, period.end]}
            checked={isSearchPeriod}
            checkOnChange={() => setIsSearchPeriod((prev) => !prev)}
            checkName="searchByCreation"
            checkLabel="기간 검색"
          />
        </Grid>
        <Grid item xs={1}>
          <SelectForm
            size="small"
            label="개수"
            options={PER_PAGE_OPTIONS}
            value={perPage}
            onSelect={setPerPage}
            name="CountSelect"
          />
        </Grid>
        <Grid item xs>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            불러오기
          </Button>
        </Grid>
      </Grid>
    </S.StyledFilter>
  );
}
export default Filter;

Filter.propTypes = {};
