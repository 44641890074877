import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '@F/materialUI/Wrapper';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import Duration, { isValidDurationValue } from '@F/Duration';
import useInputs from '@HOOK/useInputs';
import useInput from '@HOOK/useInput';
import type { SearchInputs } from '@TS/max/campaigns/search';
import type { PerPageOption } from '@TS/perPageOption';
import type { GetCampaignsParameters } from '@TS/max/campaigns/api';
import { toastErrorMessage } from '@FUNC/toast';
import { nonNullable } from '@FUNC/nonNullable';
import { actions } from '@P/max/campaigns/redux/campaigns/slice';
import { selectParams, selectStatus } from '@P/max/campaigns/redux/campaigns/selector';
import type { TextValue } from './type';
import { INITIAL_SEARCH_VALUE, searchFields, ONE_MONTH, statusInputProps, sizeInputProps } from './data';
import CompanyInput from './CompanyInput';
import { CAMPAIGN_STATUS, CAMPAIGN_STATUS_KOREAN } from '@/constants/max/status';
import * as S from './styles';

const Filter = (): JSX.Element => {
  const dispatch = useDispatch();
  const savedParams = useSelector(selectParams);
  const { campaigns: searchStatus } = useSelector(selectStatus);

  const { value, onChange, clearByKey, manuallySetValue: setValue } = useInputs<TextValue>(INITIAL_SEARCH_VALUE);
  const {
    value: operation,
    onChange: changeOperation,
    setValue: setOperation
  } = useInputs<NonNullable<SearchInputs['operation']>>(ONE_MONTH);
  const {
    value: creation,
    onChange: changeCreation,
    setValue: setCreation
  } = useInputs<NonNullable<SearchInputs['creation']>>(ONE_MONTH);
  const [size, onChangeSize, , setSize] = useInput<PerPageOption>('15');
  const [status, onChangeStatus, , setStatus] = useInput<Campaign.Scheme.Status.NamedStatusKorean>('전체');
  const [company, setCompany] = useState('');
  const [willSearchByDuration, setWillSearchByDuration] = useState(true);
  const [willSearchByCreation, setWillSearchByCreation] = useState(true);

  const generateParams = (): GetCampaignsParameters | void => {
    const isValidPeriodValues: boolean[] = [
      willSearchByDuration ? operation : null,
      willSearchByCreation ? creation : null
    ]
      .filter(nonNullable)
      .map(isValidDurationValue);

    if (isValidPeriodValues.some((item) => !item)) {
      return toastErrorMessage('기간 입력이 잘못 되었습니다.');
    }

    return {
      ...value,
      company,
      size,
      status: CAMPAIGN_STATUS[status],
      ...(willSearchByDuration && { operation }),
      ...(willSearchByCreation && { creation }),
      page: 1
    };
  };

  const searchCampaigns = () => {
    const parameters = generateParams();
    if (!parameters) return;
    // NOTE: saga debounce때문에 로딩이 느리게 보여서, 바로 스켈레톤으로 렌더링되게 여기서 선언함
    dispatch(actions.fetchStart({ type: 'campaigns' }));
    dispatch(actions.setParams(parameters));
    dispatch(actions.setSortOrder(['campaignId', 'DESC']));
    dispatch(actions.fetchCampaigns());
  };

  useEffect(() => {
    // MC-1387 최초진입시에 default 설정으로 검색하기
    if (searchStatus === 'idle') {
      searchCampaigns();
    } else if (searchStatus === 'success') {
      setWillSearchByDuration('operation' in savedParams);
      setWillSearchByCreation('creation' in savedParams);
      setCompany(savedParams?.company ?? '');
      setStatus(CAMPAIGN_STATUS_KOREAN[savedParams.status]);
      setSize(savedParams.size);
      if (savedParams.creation) setCreation(savedParams.creation);
      if (savedParams.operation) setOperation(savedParams.operation);
      if (savedParams.campaignId) setValue('campaignId', savedParams.campaignId);
      if (savedParams.campaignName) setValue('campaignName', savedParams.campaignName);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <S.Form
        onSubmit={(e) => {
          e.preventDefault();
          searchCampaigns();
        }}
      >
        <S.Row container spacing={1}>
          <CompanyInput company={company} setCompany={setCompany} />
          {searchFields.map(({ name, ...field }) => (
            <S.GridItem key={name}>
              <TextInputForm
                {...field}
                size="small"
                name={name}
                value={String(value[name])}
                onChange={onChange}
                onClear={() => clearByKey(`${name}`)}
              />
            </S.GridItem>
          ))}
          <S.GridItem xs={3}>
            <SelectForm size="small" {...statusInputProps} value={status} onSelect={onChangeStatus} />
          </S.GridItem>
        </S.Row>

        <S.Row container spacing={1}>
          <S.GridItem xs={4}>
            <Duration
              size="small"
              onChange={changeCreation}
              names={['start', 'end']}
              labels={['', '']}
              values={[creation.start, creation.end]}
              helperTexts={['시작일은 종료일보다 늦을 수 없습니다.', '종료일은 시작일을 앞설 수 없습니다.']}
              checked={willSearchByCreation}
              checkOnChange={() => setWillSearchByCreation((prev) => !prev)}
              checkName="searchByCreation"
              checkLabel="캠페인 생성 기간"
            />
          </S.GridItem>
          <S.GridItem xs={4}>
            <Duration
              size="small"
              onChange={changeOperation}
              names={['start', 'end']}
              labels={['캠페인 시작일', '캠페인 종료일']}
              values={[operation.start, operation.end]}
              helperTexts={['시작일은 종료일보다 늦을 수 없습니다.', '종료일은 시작일을 앞설 수 없습니다.']}
              checked={willSearchByDuration}
              checkOnChange={() => setWillSearchByDuration((prev) => !prev)}
              checkName="searchByOperation"
              checkLabel="캠페인 기간"
            />
          </S.GridItem>

          <S.GridItem xs={2}>
            <SelectForm {...sizeInputProps} value={size} onSelect={onChangeSize} size="small" />
          </S.GridItem>

          <S.GridItem xs={2}>
            <S.SearchButton variant="contained" color="primary" type="submit">
              검색
            </S.SearchButton>
          </S.GridItem>
        </S.Row>
      </S.Form>
    </Wrapper>
  );
};

export default Filter;
