import { getSegmentOptions } from '@FUNC/getSegmentOptions';
import { areaType } from './const';

import { BoxParagraph } from './style';

type Props = {
  visit_type: Segments.DMP.Segment['visit_type'];
  comment: Segments.DMP.Segment['comment'];
};

function LogBaseSegment({ comment, visit_type }: Props) {
  const segmentComment: Segments.SegmentScheme.Comment[] = JSON.parse(comment ?? '[]');
  const { lv1, lv2, selection } = getSegmentOptions(segmentComment[0]);
  const { brand, category, store, complex } = selection;
  const calculateSelects = {
    brand: [...(brand ?? [])],
    category: [...(category ?? [])],
    store: [...(complex ?? []), ...(store ?? [])]
  };

  return (
    <div>
      <BoxParagraph>
        {areaType[visit_type ?? 'all']} 세그먼트 / <span className="bold area">{lv1}</span>
      </BoxParagraph>
      {lv2.district.length > 0 && <BoxParagraph className="area">{lv2.district.join(', ')}</BoxParagraph>}
      {lv2.commercial.length > 0 && <BoxParagraph className="commercial">{lv2.commercial.join(', ')}</BoxParagraph>}
      {Object.keys(selection).length > 0 && (
        <>
          {Object.entries(calculateSelects).flatMap(([key, value]) => {
            const validValue = Array.isArray(value) && value.length > 0;
            if (!validValue) {
              return [];
            }

            return (
              <BoxParagraph key={key}>
                <span className="bold">{key.toUpperCase()} : </span>
                <span className={key}>{value.join(', ')}</span>
              </BoxParagraph>
            );
          })}
        </>
      )}
    </div>
  );
}

export default LogBaseSegment;
