import { useState } from 'react';
import PropTypes from 'prop-types';
import DialogModal from '@F/DialogModal';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

function CustomizingColumnsDialog(props) {
  const { open, setOpen, selectedColumns, setSelectedColumns } = props;
  const [willBeSelectedColumns, setWillBeSelectedColumns] = useState({
    ...selectedColumns
  });
  const handleChange = (event) => {
    setWillBeSelectedColumns({
      ...willBeSelectedColumns,
      [event.target.name]: event.target.checked
    });
  };
  const confirmButtonDidClicked = () => {
    setSelectedColumns(() => willBeSelectedColumns);
    setOpen(false);
  };

  const LIST = [
    { label: 'STORE ID (PID)', checked: willBeSelectedColumns.id, name: 'storeID', disabled: true },
    { label: 'Complex ID (CID)', checked: willBeSelectedColumns.complexID, name: 'complexID', disabled: false },
    { label: 'Brand ID (BID)', checked: willBeSelectedColumns.brandID, name: 'brandID', disabled: false },
    { label: '장소명 (name)', checked: willBeSelectedColumns.name, name: 'name', disabled: false },
    { label: '지점명 (branch_name)', checked: willBeSelectedColumns.branchName, name: 'branchName', disabled: false },
    { label: '층수 (floor)', checked: willBeSelectedColumns.floor, name: 'floor', disabled: false },
    { label: '카데고리 (category)', checked: willBeSelectedColumns.category, name: 'category', disabled: false },
    {
      label: '카테고리 (상세)',
      checked: willBeSelectedColumns.categoryDetail,
      name: 'categoryDetail',
      disabled: false
    },
    {
      label: '카테고리 코드 (category_code)',
      checked: willBeSelectedColumns.categoryCode,
      name: 'categoryCode',
      disabled: false
    },
    { label: '수집자 (collector)', checked: willBeSelectedColumns.collector, name: 'collector', disabled: false },
    { label: '생성 시간(timestamp)', checked: willBeSelectedColumns.timestamp, name: 'timestamp', disabled: false },
    { label: 'Client Code', checked: willBeSelectedColumns.clientCode, name: 'clientCode', disabled: false },
    { label: '검수자 (inspector)', checked: willBeSelectedColumns.inspector, name: 'inspector', disabled: false },
    { label: 'PHONE', checked: willBeSelectedColumns.phone, name: 'phone', disabled: false },
    { label: '주소 (addr)', checked: willBeSelectedColumns.addr, name: 'addr', disabled: false },
    { label: '도로명주소 (addr_road)', checked: willBeSelectedColumns.addrRoad, name: 'addrRoad', disabled: false },
    { label: '세부주소 (addr_sub)', checked: willBeSelectedColumns.addrSub, name: 'addrSub', disabled: false },
    { label: '검수시간 (qa_at)', checked: willBeSelectedColumns.qaAt, name: 'qaAt', disabled: false },
    { label: '수정시간 (updated_at)', checked: willBeSelectedColumns.updatedAt, name: 'updatedAt', disabled: false }
  ];

  return (
    <DialogModal
      title="Columns 보이기 / 숨기기"
      description="보고자 하는 Column을 선택해주세요. 확인을 누르셔야 적용됩니다."
      open={open}
      setOpen={setOpen}
      confirmButtonDidClicked={confirmButtonDidClicked}
    >
      <FormGroup>
        {LIST.map(({ label, checked, name, disabled }) => (
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} name={name} value disabled={disabled} />}
            label={label}
          />
        ))}
      </FormGroup>
    </DialogModal>
  );
}

export default CustomizingColumnsDialog;

CustomizingColumnsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedColumns: PropTypes.objectOf(PropTypes.bool).isRequired,
  setSelectedColumns: PropTypes.func.isRequired
};
