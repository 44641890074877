import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import jwtDecode from 'jwt-decode';
import { actions } from '@/redux/store/storeManager/state';
import { managerLocalStorage } from '@/utils/localStorageParser';

import * as S from './styles';

export default function EnhancedTableToolbar(props) {
  const dispatch = useDispatch();
  const accessToken = managerLocalStorage.get(managerLocalStorage.keys.access_token);
  const user = jwtDecode(accessToken);
  const { company } = user.user_claims;

  const classes = S.useToolbarStyles();
  const { title, numSelected, selectedActions = [] } = props;

  // csv download
  const downloadCsv = () => dispatch(actions.fetchStoresCsv());

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
          {company === 'jinair' && (
            <Button variant="outlined" color="primary" onClick={downloadCsv} width="200px">
              CSV 다운로드
            </Button>
          )}
        </Typography>
      )}

      {selectedActions}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    PropTypes.string
  ]).isRequired,
  numSelected: PropTypes.number.isRequired
};
