import { AddIcon, DeleteIcon, grayscale900, IconButton, TargetIcon } from 'loplat-ui';
import React, { useEffect, useRef } from 'react';
import type { Geofence } from './index';
import useGeofenceMap from './useGeofenceMap';
import { isGeofenceGroup } from './type';
import { moveToCenter } from './utils';
import { TipLayer as GeofenceCountLayer, GeofenceTipArea, GeofenceTipRadius } from './NaverMapLayer';
import { MapWrapper } from './styles';

type Props = {
  geofence: Geofence;
};
const NaverMap = ({ geofence }: Props): JSX.Element => {
  const { mapId, areaSize, setZoom, centerBoundsRef, initGeofenceCircle, initGeofencePolygon, initGeofenceGroup } =
    useGeofenceMap('geofence');
  const mapRef = useRef<naver.maps.Map | null>(null);

  useEffect(() => {
    let map: null | naver.maps.Map = null;
    if (isGeofenceGroup(geofence)) {
      map = initGeofenceGroup(geofence);
    } else if (geofence.shape_type === 1) {
      map = initGeofenceCircle(geofence);
    } else if (geofence.shape_type === 0) {
      map = initGeofencePolygon(geofence);
    }
    mapRef.current = map;
    moveToCenterOfGeofence();

    return () => {
      if (map) map.destroy();
    };
  }, [geofence]); // eslint-disable-line react-hooks/exhaustive-deps

  const moveToCenterOfGeofence = () => {
    if (!mapRef.current) return;
    if (!centerBoundsRef.current) return;
    moveToCenter(mapRef.current, centerBoundsRef.current);
  };

  const handleZoom = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    switch (value) {
      case '+':
        setZoom((prev) => {
          const newZoom = prev + 1;
          mapRef.current?.setZoom(newZoom);
          return newZoom;
        });
        break;
      case '-':
        setZoom((prev) => {
          const newZoom = prev - 1;
          mapRef.current?.setZoom(newZoom);
          return newZoom;
        });
        break;
      default:
        break;
    }
  };

  return (
    <MapWrapper>
      <div id={mapId} className="map" />

      <GeofenceCountLayer>
        <ul>
          <GeofenceTipArea areaSize={areaSize} geofence={geofence} />
          <GeofenceTipRadius geofence={geofence} />
        </ul>
      </GeofenceCountLayer>

      <section>
        <IconButton value="+" onClick={handleZoom} color="primary2">
          <AddIcon fillColor={grayscale900} />
        </IconButton>
        <IconButton value="-" onClick={handleZoom} color="primary2">
          <DeleteIcon fillColor={grayscale900} />
        </IconButton>
        <IconButton value="x" onClick={moveToCenterOfGeofence} color="primary2">
          <TargetIcon fillColor={grayscale900} />
        </IconButton>
      </section>
    </MapWrapper>
  );
};

export default NaverMap;
