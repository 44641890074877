import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { PolygonApi } from '@API/manager';
import { GetDapiAddressData, GetDapiKeywordData } from '@API/manager/polygon';

export const fetchDapiByQuery = createAsyncThunk('fetchDapiByQuery', async ({ query }: { query: string }) => {
  const address = await PolygonApi.getDapiAddress({ query });
  const keyword = await PolygonApi.getDapiKeyword({ query });

  return {
    addressResult: address.data,
    keywordResult: keyword.data
  };
});

interface DapiSearch {
  isLoading: boolean;
  isError: boolean;
  errorMsg?: string;
  data?: {
    addressResult: GetDapiAddressData;
    keywordResult: GetDapiKeywordData;
  };
}

const initialState: Record<string, DapiSearch> = {};

const slice = createSlice({
  name: 'dapiSearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDapiByQuery.pending, (state, action) => {
      const { query } = action.meta.arg;
      return {
        ...state,
        [query]: {
          isLoading: true,
          isError: false
        }
      };
    });
    builder.addCase(fetchDapiByQuery.fulfilled, (state, action) => {
      const { query } = action.meta.arg;
      const data = action.payload;
      return {
        ...state,
        [query]: {
          isLoading: false,
          isError: false,
          data
        }
      };
    });
    builder.addCase(fetchDapiByQuery.rejected, (state, action) => {
      const { query } = action.meta.arg;
      const { message } = action.error;
      return {
        ...state,
        [query]: {
          isLoading: false,
          isError: true,
          errorMsg: message
        }
      };
    });
  }
});

export const { actions } = slice;
export default slice.reducer;

export const dapiSearchConfig = {
  key: 'dapiSearch',
  storage,
  whitelist: ['dapiSearch']
};
