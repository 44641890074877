import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Dialog, DialogTitle } from '@material-ui/core';
import Loading from '@F/Loading';
import SdkConfigForm from './SdkConfigForm';

export default function SdkConfigFormLoading({ open }: { open: boolean }): JSX.Element {
  const navigate = useNavigate();

  return (
    <SdkConfigForm
      readOnly
      renderToolbar={() => (
        <Grid container justifyContent="flex-end" direction="row" spacing={1}>
          <Grid item>
            <Button onClick={() => navigate('/x/companies')} color="primary" variant="contained">
              목록으로
            </Button>
          </Grid>
          <Dialog open={open}>
            <StyledBox>
              <DialogTitle>고객사 상세 정보를 불러오는 중입니다...</DialogTitle>
              <Loading />
            </StyledBox>
          </Dialog>
        </Grid>
      )}
    />
  );
}

const StyledBox = styled.div`
  padding-bottom: 80px;
`;
