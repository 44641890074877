import { GetContactsParams, PutContactParams } from '@TS/cashplace/contact';
import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class CashplaceContactsApi {
  getContacts({ userId, state, content, page, perPage }: GetContactsParams) {
    return axiosInstancesManager.manager.get('/cashplace/contacts', {
      params: {
        user_id: userId,
        state,
        page,
        per_page: perPage,
        content
      }
    });
  }

  putContact(contactId: number, { reply }: PutContactParams) {
    return axiosInstancesManager.manager.put(`/cashplace/contacts/${contactId}`, { reply });
  }
}

export default new CashplaceContactsApi();
