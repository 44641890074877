import { Body, green300, primary } from 'loplat-ui';
import { SchemedZone } from '../utils/type';

export const LevelTwoModule = ({ lv2 }: Pick<SchemedZone, 'lv2'>): JSX.Element => (
  <>
    {(Object.entries(lv2) as Entries<typeof lv2>)
      .filter(([, value]) => !!value.length)
      .map(([key, value]) => (
        <Body key={key} color={LEVEL_TWO_COLOR[key]}>
          {value.join(', ')}
        </Body>
      ))}
  </>
);

export const LEVEL_TWO_COLOR: Record<keyof SchemedZone['lv2'], string> = {
  district: primary,
  commercial: green300
};

export default LevelTwoModule;
