import { SdkLog, FetchSdkLogParams } from '@P/max/sdkLog/useSdkLogBloc';
import dayjs from 'dayjs';

export type SdkLogFilterValues = {
  client_id: string;
  log: {
    type: Extract<keyof FetchSdkLogParams, 'adid' | 'echo_code'>;
    value: string;
  };
  dates: [string, string];
};

export type OrderBy = {
  name: Extract<keyof SdkLog, 'KST'>;
  orderType: OrderType;
};

export const Sdk_LOG_TABLE_HEAD_CELLS: {
  name: Extract<
    keyof SdkLog,
    'KST' | 'log_kind' | 'sdk_state' | 'adid' | 'echo_code' | 'os_version' | 'device_name' | 'userAgent'
  >;
  label: string;
  defaultOrderType: OrderType;
  initialIsActive?: boolean;
}[] = [
  {
    name: 'KST',
    label: '시간',
    defaultOrderType: 'DESC',
    initialIsActive: true
  },
  {
    name: 'log_kind',
    label: '상태',
    defaultOrderType: 'DESC'
  },
  {
    name: 'sdk_state',
    label: 'SDK 상태',
    defaultOrderType: 'DESC'
  },
  {
    name: 'adid',
    label: 'adid',
    defaultOrderType: 'DESC'
  },
  {
    name: 'echo_code',
    label: 'echo 코드',
    defaultOrderType: 'DESC'
  },
  {
    name: 'os_version',
    label: 'OS 버전',
    defaultOrderType: 'DESC'
  },
  {
    name: 'device_name',
    label: '기기명',
    defaultOrderType: 'DESC'
  },
  {
    name: 'userAgent',
    label: 'User Agent',
    defaultOrderType: 'DESC'
  }
];

export const SDK_LOG_TABLE_INITIAL_ORDER_BY: OrderBy = {
  name: 'KST',
  orderType: 'DESC'
};

export const INITIAL_Sdk_LOG_FILTER_VALUES: SdkLogFilterValues = {
  client_id: '',
  log: {
    type: 'adid',
    value: ''
  },
  dates: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
};

export const LOG_TYPES: {
  name: Extract<keyof FetchSdkLogParams, 'adid' | 'echo_code'>;
  label: string;
}[] = [
  {
    name: 'adid',
    label: 'ADID'
  },
  {
    name: 'echo_code',
    label: 'Echo Code'
  }
];
