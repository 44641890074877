import { useState, useCallback, useEffect, useRef } from 'react';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import SelectToggleForm from '@F/materialUI/SelectToggleForm';

import { Button, Grid, IconButton, Chip } from '@material-ui/core';
import FingerPrintTableSection from '@P/store/StoreManager/tableSection/detail/FingerPrintTableSection';
import BleTableSection from '@P/store/StoreManager/tableSection/detail/BleTableSection';
import {
  storeNameReviewSelectOptions,
  subNameReviewSelectOptions,
  categoryCodeReviewSelectOptions,
  locationReviewSelectOptions,
  sectionOptions,
  floorReviewSelectOptions
} from '@P/store/StoreManager/tableSection/detail/Infos/Options';

import { copyMessageToClipboard } from '@HOOK/useCopy';
import CategoryModal from '@F/modal/CategoryModal';
import DialogModal from '@F/DialogModal';
import Loading from '@F/Loading';
import BrandModal from '@F/modal/BrandModal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toJS } from 'mobx';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { RemoveCircle } from '@material-ui/icons';
import { toastErrorMessage } from '@FUNC/toast';
import ComplexMatchingModal from '@F/modal/ComplexMatchingModal';
import { useInput } from '@HOOK/';

import useAuth from '@HOOK/useAuth';
import { snakeObjToCamelObj } from '@FUNC/word';
import { actions } from '@/redux/store/storeManager/state';
import * as S from './styles';
import { valuesStore } from '@/mobX/values';

function Infos(props) {
  const { user } = useAuth();
  const isCustomerUser = user.user_claims.role === 'customer';
  const { detail, newLocation, onComplete } = props;
  const classes = S.useStyles();

  const { isLoading, status } = useSelector(
    (state) => ({
      isLoading: state.storeManager.isLoading,
      status: state.storeManager.status
    }),
    shallowEqual
  );

  // State: TextInput
  const [storeNameState, setStoreNameState] = useInput(detail.name);
  const [subNameState, setSubNameState] = useInput(detail.branch_name);
  const [categoryState] = useInput(detail.category);
  const [categoryCodeState, setCategoryCodeState] = useState({ korName: '', code: detail.category_code });
  const [floorState, setFloorState] = useInput(detail.floor);
  const [complexIDState, setComplexIDState] = useState(
    detail.cids ? detail.cids.split(',').map((cid) => ({ cid })) : []
  );
  const [brandIDState, setBrandIDState] = useState(() =>
    detail.brands.map((brand) => ({
      id: brand.bid,
      name: brand.name
    }))
  );

  const [nidState, setNIDState] = useInput(detail.nid);
  const [didState, setDIDState] = useInput(detail.did);
  const [siteNameState] = useInput(detail.site_origin_name);
  const [clientCodeState, setClientCodeState] = useState([]);
  const [clientIdState, setClientIdState] = useState([]);
  useEffect(() => {
    if (detail.client_codes?.length > 0) {
      const initialClientCodes = [];
      const initialClientIds = [];
      detail.client_codes.forEach((client) => {
        initialClientCodes.push(client.client_code);
        initialClientIds.push(client.client_id);
      });
      setClientCodeState(initialClientCodes);
      setClientIdState(initialClientIds);
    }
  }, [detail]);

  const [addr, setAddr] = useInput(detail.addr ?? '');
  const [addrRoad, setAddrRoad] = useInput(detail.addr_road ?? '');
  const [addrSub, setAddrSub] = useInput(detail.addr_sub ?? '');

  // State: Modal Visible
  const [isCategoryCodeModalVisible, setIsCategoryCodeModalVisible] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isBrandIDModalVisible, setIsBrandIDModalVisible] = useState(false);
  const [isComplexMatchingModalOpen, setIsComplexMatchingModalOpen] = useState(false);
  const [clientRemoveModalOpen, setClientRemoveModalOpen] = useState(false);
  const clientRemoveIndex = useRef(null); // NOTE: client 목록 중 [index]번째 삭제 버튼을 눌렀을 때 해당 index 를 임시 저장하는 변수

  // State: UI
  const [isStoreInnerTableVisible, setIsStoreInnerTableVisible] = useState(null);

  // State: Select; type만 저장한다
  const sectionSelectFormOption = Object.values(sectionOptions);
  const storeNameReviewSelectFormOptions = Object.values(storeNameReviewSelectOptions);
  const subNameReviewSelectFormOptions = Object.values(subNameReviewSelectOptions)
    .sort((prev, next) => prev.order - next.order)
    .map((option) => option.value);

  const categoryCodeReviewSelectFormOptions = Object.values(categoryCodeReviewSelectOptions);
  const floorReviewSelectFormOptions = Object.values(floorReviewSelectOptions);
  const locationReviewSelectFormOptions = Object.values(locationReviewSelectOptions);

  const [sectionState, setSectionState] = useInput(
    Object.hasOwnProperty.call(sectionOptions, detail.section) ? sectionOptions[Number(detail.section)] : ''
  );
  const [storeNameReviewState, setStoreNameReviewState] = useState(
    Object.hasOwnProperty.call(storeNameReviewSelectOptions, detail.qa_name)
      ? storeNameReviewSelectOptions[detail.qa_name]
      : '미검수'
  );

  const [subNameReviewState, setSubNameReviewState] = useState(
    Object.hasOwnProperty.call(subNameReviewSelectOptions, detail.qa_subname)
      ? subNameReviewSelectOptions[detail.qa_subname].value
      : '미검수'
  );
  const [categoryCodeReviewState, setCategoryCodeReviewState] = useState(
    Object.hasOwnProperty.call(categoryCodeReviewSelectOptions, detail.qa_category)
      ? categoryCodeReviewSelectOptions[detail.qa_category]
      : '미검수'
  );
  const [floorReviewState, setFloorReviewState] = useState(
    Object.hasOwnProperty.call(floorReviewSelectOptions, detail.qa_floor)
      ? floorReviewSelectOptions[detail.qa_floor]
      : '미검수'
  );
  const [locationReviewState, setLocationReviewState] = useState(
    Object.hasOwnProperty.call(locationReviewSelectOptions, detail.qa_location)
      ? locationReviewSelectOptions[detail.qa_location]
      : '미검수'
  );

  useEffect(() => {
    if (status === 'success') onComplete();
  }, [status, onComplete]);

  // update pid
  const dispatch = useDispatch();

  const closeStore = useCallback(() => {
    dispatch(actions.closeStore(detail.pid));
  }, [dispatch, detail]);

  const discardStore = useCallback(async () => {
    dispatch(actions.discardStore(detail.pid));
  }, [dispatch, detail]);

  const deleteStore = useCallback(async () => {
    dispatch(actions.deleteStore(detail.pid));
  }, [dispatch, detail]);

  const completeStore = () => {
    if (clientCodeState.indexOf('') !== -1 || clientIdState.indexOf('') !== -1) {
      toastErrorMessage('클라이언트 코드와 클라이언트 ID는 모두 필요합니다. 매핑을 끊으시려면 행을 삭제해주세요.');
      return;
    }
    const clientCodes = clientIdState
      .map((id, index) => ({
        client_id: id,
        client_code: clientCodeState[index]
      }))
      .filter((item) => item.client_id && item.client_code);

    const storeInfo = {
      cids: String(complexIDState.map(({ cid }) => cid).join(',')) || null,
      pid: detail.pid,
      name: String(storeNameState),
      branchName: String(subNameState),
      floor: Number(floorState),
      lat: Number(newLocation.lat),
      lng: Number(newLocation.lng),
      clientCodes,
      categoryCode: categoryCodeState.code ? String(categoryCodeState.code) : null,
      section: Number(Object.keys(sectionOptions).find((key) => sectionOptions[key] === sectionState)),
      nid: nidState ? Number(nidState) : null,
      did: didState ? Number(didState) : null,
      bids: brandIDState.map((brand) => brand.id),
      qaCategory: Number(
        Object.keys(categoryCodeReviewSelectOptions).find(
          (key) => categoryCodeReviewSelectOptions[key] === categoryCodeReviewState
        )
      ),
      qaFloor: Number(
        Object.keys(floorReviewSelectOptions).find((key) => floorReviewSelectOptions[key] === floorReviewState)
      ),
      qaLocation: Number(
        Object.keys(locationReviewSelectOptions).find((key) => locationReviewSelectOptions[key] === locationReviewState)
      ),
      qaSubName: Number(
        Object.keys(subNameReviewSelectOptions).find(
          (key) => subNameReviewSelectOptions[key].value === subNameReviewState
        )
      ),
      qaName: Number(
        Object.keys(storeNameReviewSelectOptions).find(
          (key) => storeNameReviewSelectOptions[key] === storeNameReviewState
        )
      ),
      addr,
      addrRoad,
      addrSub
    };
    dispatch(actions.completeStore(storeInfo));
  };

  const updateClientCode = useCallback((index, value) => {
    setClientCodeState((prevState) => [...prevState.slice(0, index), value, ...prevState.slice(index + 1)]);
  }, []);
  const updateClientId = useCallback((index, value) => {
    setClientIdState((prevState) => [...prevState.slice(0, index), value, ...prevState.slice(index + 1)]);
  }, []);
  const removeClientByIndex = useCallback((index) => {
    setClientCodeState((prevState) => [...prevState.slice(0, index), ...prevState.slice(index + 1)]);
    setClientIdState((prevState) => [...prevState.slice(0, index), ...prevState.slice(index + 1)]);
  }, []);
  const createClientRow = (index) => (
    <S.Row container className={classes.margin} spacing={1} key={index}>
      <Grid item xs>
        <TextInputForm
          error={clientCodeState[index] === ''}
          required
          name="ClientCodeInput"
          label="클라이언트 코드"
          value={clientCodeState[index] ?? ''}
          onChange={(e) => updateClientCode(index, e.target.value)}
        />
      </Grid>
      {isCustomerUser ? null : (
        <Grid item xs>
          <SelectForm
            error={clientIdState[index] === ''}
            label="SDK 클라이언트 ID"
            value={clientIdState[index] ?? ''}
            options={toJS(valuesStore.sdkClientIds)}
            onSelect={(e) => updateClientId(index, e.target.value)}
            name="ClientIdSelect"
          />
        </Grid>
      )}
      <IconButton
        onClick={() => {
          clientRemoveIndex.current = index;
          setClientRemoveModalOpen(true);
        }}
      >
        <RemoveCircle />
      </IconButton>
    </S.Row>
  );

  const ClientRemoveModal = (
    <DialogModal
      title="정말로 삭제하시겠습니까?"
      description="client 삭제는 poi 삭제 수준의 부작용을 가집니다. 신중히 선택하십시오."
      open={clientRemoveModalOpen}
      setOpen={setClientRemoveModalOpen}
      confirmButtonDidClicked={() => removeClientByIndex(clientRemoveIndex.current)}
    />
  );
  const CloseDialogModal = (
    <DialogModal
      title="정말로 폐점하시겠습니까?"
      description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
      open={closeModalOpen}
      setOpen={setCloseModalOpen}
      confirmButtonDidClicked={closeStore}
    />
  );
  const DeleteDialogModal = (
    <DialogModal
      title="정말로 삭제하시겠습니까?"
      description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
      open={deleteModalOpen}
      setOpen={setDeleteModalOpen}
      confirmButtonDidClicked={deleteStore}
    />
  );
  const DiscardDialogModal = (
    <DialogModal
      title="정말로 휴지통에 넣으시겠습니까?"
      description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
      open={discardModalOpen}
      setOpen={setDiscardModalOpen}
      confirmButtonDidClicked={discardStore}
    />
  );

  return (
    <S.StyledStoreInnerDetail>
      {isLoading && <Loading />}
      {clientRemoveModalOpen && ClientRemoveModal}
      {isCustomerUser ? null : (
        <>
          {closeModalOpen && CloseDialogModal}
          {deleteModalOpen && DeleteDialogModal}
          {discardModalOpen && DiscardDialogModal}
          <S.Row container className={classes.margin} spacing={1}>
            <Grid item xs>
              <TextInputForm name="StoreNameInput" label="장소명" onChange={setStoreNameState} value={storeNameState} />
            </Grid>
            <Grid item xs={2}>
              <SelectToggleForm
                label="장소명 검수 상태"
                options={storeNameReviewSelectFormOptions}
                value={storeNameReviewState}
                onChange={setStoreNameReviewState}
                disabled={false}
                name="StoreNameReviewSelect"
              />
            </Grid>
            <Grid item xs>
              <TextInputForm name="SubNameInput" label="subname" onChange={setSubNameState} value={subNameState} />
            </Grid>
            <Grid item xs={2}>
              <SelectToggleForm
                label="sub name 검수 상태"
                options={subNameReviewSelectFormOptions}
                value={subNameReviewState}
                onChange={setSubNameReviewState}
                disabled={false}
                name="SubNameReviewSelect"
              />
            </Grid>
          </S.Row>

          <S.Row container className={classes.margin} spacing={1}>
            <Grid item xs>
              <TextInputForm name="CategoryInput" label="카테고리" readOnly value={categoryState} variant="filled" />
            </Grid>
            <Grid item xs>
              <TextInputForm
                name="CategoryCodeInput"
                label="카테고리(코드)"
                onClick={() => setIsCategoryCodeModalVisible(!isCategoryCodeModalVisible)}
                value={categoryCodeState.code}
              />
              {isCategoryCodeModalVisible && (
                <CategoryModal
                  open={isCategoryCodeModalVisible}
                  setOpen={setIsCategoryCodeModalVisible}
                  setValue={setCategoryCodeState}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              <SelectToggleForm
                label="카테고리 코드 검수 상태"
                options={categoryCodeReviewSelectFormOptions}
                value={categoryCodeReviewState}
                onChange={setCategoryCodeReviewState}
                disabled={false}
                name="CategoryCodeReviewSelect"
              />
            </Grid>
          </S.Row>

          <S.Row container className={classes.margin} spacing={1}>
            <Grid item xs>
              <TextInputForm name="FloorInput" label="층수" onChange={setFloorState} value={`${floorState}`} />
            </Grid>
            <Grid item xs={2}>
              <SelectToggleForm
                label="층수 검수 상태"
                options={floorReviewSelectFormOptions}
                value={floorReviewState}
                onChange={setFloorReviewState}
                disabled={false}
                name="FloorReviewState"
              />
            </Grid>
            <Grid item xs>
              <TextInputForm
                onClick={() => copyMessageToClipboard(`${newLocation.lat}, ${newLocation.lng}`)}
                name="LocationInput"
                label="location"
                value={`${newLocation.lat}, ${newLocation.lng}`}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectToggleForm
                label="Location 검수 상태"
                options={locationReviewSelectFormOptions}
                value={locationReviewState}
                onChange={setLocationReviewState}
                disabled={false}
                name="LocationReviewSelect"
              />
            </Grid>
          </S.Row>

          <S.Row container className={classes.margin} spacing={1}>
            <Grid item xs>
              <TextInputForm name="storeAddr" label="주소" onChange={setAddr} value={addr} />
            </Grid>
            <Grid item xs>
              <TextInputForm name="storeAddrRoad" label="도로명 주소" onChange={setAddrRoad} value={addrRoad} />
            </Grid>
            <Grid item xs>
              <TextInputForm name="storeAddrSub" label="상세 주소" onChange={setAddrSub} value={addrSub} />
            </Grid>
          </S.Row>

          <S.Row container className={classes.margin} spacing={1}>
            <Grid item xs>
              <SelectForm
                label="Section"
                options={sectionSelectFormOption}
                value={sectionState}
                onSelect={setSectionState}
                disabled={false}
                name="sectionSelect"
              />
            </Grid>
            <Grid item xs>
              <TextInputForm
                name="ComplexIDInput"
                label="Complex ID (CID)"
                onClick={() => setIsComplexMatchingModalOpen(true)}
                value={complexIDState.map(({ cid, name }) => (name ? `${name}(${cid})` : `${cid}`)).join(', ')}
                onClear={() => setComplexIDState([])}
              />
              {isComplexMatchingModalOpen && (
                <ComplexMatchingModal
                  open={isComplexMatchingModalOpen}
                  setOpen={setIsComplexMatchingModalOpen}
                  setValue={setComplexIDState}
                  currentStore={snakeObjToCamelObj(detail)}
                />
              )}
            </Grid>
            <Grid item xs>
              <TextInputForm
                name="BrandIDInput"
                label="Brand ID (BID)"
                onClick={() => setIsBrandIDModalVisible(!isBrandIDModalVisible)}
                value={brandIDState.map(({ id, name }) => `${name}(${id})`).join(', ')}
                readOnly
                onClear={() => setBrandIDState([])}
              />
              {isBrandIDModalVisible && (
                <BrandModal
                  open={isBrandIDModalVisible}
                  setOpen={setIsBrandIDModalVisible}
                  setValue={setBrandIDState}
                  previousBrands={brandIDState}
                />
              )}
            </Grid>
          </S.Row>

          <S.Row container className={classes.margin} spacing={1}>
            <Grid item xs>
              <TextInputForm name="NIDInput" label="NID" onChange={setNIDState} value={`${nidState}`} />
              <Chip
                label="NAVER 확인"
                clickable
                color="primary"
                onClick={() => window.open(`https://map.naver.com/p/entry/place/${nidState}`)}
              />
            </Grid>
            <Grid item xs>
              <TextInputForm name="DIDInput" label="DID" onChange={setDIDState} value={didState} />
            </Grid>
            <Grid item xs>
              <TextInputForm
                readOnly
                name="SiteNameInput"
                label="장소명 (Site)"
                value={siteNameState}
                variant="filled"
              />
            </Grid>
          </S.Row>
        </>
      )}

      <S.Row container className={classes.margin}>
        <Grid item xs>
          <h3>클라이언트 추가하기</h3>
          {!isCustomerUser && <p>클라이언트 코드와 아이디 중 하나라도 비어있으면 안됩니다.</p>}
        </Grid>
        <IconButton
          variant="outlined"
          color="default"
          className={classes.button}
          onClick={() => {
            setClientIdState((prevState) => [...prevState, isCustomerUser ? user.user_claims.company : '']);
            setClientCodeState((prevState) => [...prevState, '']);
          }}
        >
          <ControlPointIcon />
        </IconButton>
      </S.Row>

      <S.Row container spacing={1}>
        {clientIdState.map((_, index) => createClientRow(index))}
      </S.Row>

      {isCustomerUser ? null : (
        <>
          <S.ButtonRow>
            <Button
              variant="outlined"
              color="default"
              onClick={() => setIsStoreInnerTableVisible(isStoreInnerTableVisible === 'ble' ? null : 'ble')}
              className={classes.button}
            >
              BLE 정보보기
            </Button>
            <Button
              variant="outlined"
              color="default"
              onClick={() => setIsStoreInnerTableVisible(isStoreInnerTableVisible === 'fp' ? null : 'fp')}
              className={classes.button}
            >
              FP 정보보기
            </Button>
          </S.ButtonRow>
          {isStoreInnerTableVisible === 'ble' && (
            <S.InnerRowTable>
              <BleTableSection store={detail} pid={detail.pid} bles={detail.ble_fingerprint} />
            </S.InnerRowTable>
          )}
          {isStoreInnerTableVisible === 'fp' && (
            <S.InnerRowTable>
              <FingerPrintTableSection store={detail} pid={detail.pid} fingerprints={detail.fingerprint} />
            </S.InnerRowTable>
          )}
        </>
      )}

      <S.ButtonRow>
        {isCustomerUser ? null : (
          <>
            <Button
              variant="outlined"
              color="default"
              className={classes.button}
              onClick={() => setCloseModalOpen(true)}
            >
              폐점
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => setDeleteModalOpen(true)}
            >
              삭제
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={() => setDiscardModalOpen(true)}
            >
              휴지통
            </Button>
          </>
        )}
        <Button variant="contained" color="primary" className={classes.completeButton} onClick={completeStore}>
          확인
        </Button>
      </S.ButtonRow>
    </S.StyledStoreInnerDetail>
  );
}
export default Infos;

Infos.propTypes = {};
