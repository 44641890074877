import { useState, useCallback, useEffect, useRef } from 'react';
import { toastErrorMessage } from '@FUNC/toast';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '@F/Loading';
import { useFetch } from '@HOOK/index';
import type { Polygon } from '@API/manager/polygon';
import { PolygonApi } from '@API/manager';
import { GetPolygonListItem } from '@API/manager/polygon';

import PolygonNaverMap from './polygonNaverMap';
import InputForm from './InputForm';
import useDrawingManager from './polygonNaverMap/useDrawingManager';
import { PageWrapper } from './styles';

const PolygonEditPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { isLoading, isError, callApi } = useFetch();
  const { drawingManagerState } = useDrawingManager();

  const [drawingManager, setDrawingManager] = drawingManagerState;
  const [fetched, setFetched] = useState(false);
  const [title, setTitle] = useState('');
  const [addr, setAddr] = useState('서울특별시 강남구 역삼동 736-16 삼한빌딩');
  const [road, setRoad] = useState('서울 강남구 테헤란로20길 5');
  const [latitude, setLatitude] = useState('37.5000776');
  const [longitude, setLongitude] = useState('127.0385419');
  const [originGeoJsons, setOriginGeoJsons] = useState<Polygon>([]);
  const marker = useRef<naver.maps.Marker>(
    new naver.maps.Marker({
      position: new naver.maps.Point(Number(longitude), Number(latitude)),
      clickable: true,
      draggable: true
    })
  );

  const getPolygonDataById = useCallback(
    async (id: string) => {
      if (isLoading) {
        return;
      }
      const response = await callApi(PolygonApi.getPolygon, { id });
      const { title, addr, road_addr, p_lng_lat, mpolygon_area } = response.data as GetPolygonListItem;
      setTitle(title);
      setAddr(addr ?? '');
      setRoad(road_addr ?? '');
      setOriginGeoJsons(mpolygon_area ? mpolygon_area.coordinates : []);
      setLongitude(p_lng_lat ? `${p_lng_lat.coordinates[0]}` : '127.0385419');
      setLatitude(p_lng_lat ? `${p_lng_lat.coordinates[1]}` : '37.5000776');
      if (p_lng_lat) {
        marker.current.setPosition({
          x: p_lng_lat.coordinates[0],
          y: p_lng_lat.coordinates[1]
        });
      }
      setFetched(true);
    },
    [callApi, isLoading]
  );

  useEffect(() => {
    if (!id || Number.isNaN(Number(id))) {
      toastErrorMessage('잘못된 경로로 접근하였습니다.');
      navigate('/polygon/list');
    } else if (!fetched && marker) {
      getPolygonDataById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetched, id, marker, navigate]);

  useEffect(() => {
    if (isError) {
      toastErrorMessage('지오펜스를 불러올 수 없습니다.');
      navigate('/polygon/list');
    }
  }, [isError, navigate]);

  return (
    <PageWrapper>
      {isLoading && <Loading />}
      <InputForm
        drawingManager={drawingManager}
        title={title}
        setTitle={setTitle}
        addr={addr}
        road={road}
        latitude={latitude}
        longitude={longitude}
        isEdit
        isLoading={isLoading}
      >
        <PolygonNaverMap
          drawingManager={drawingManager}
          setDrawingManager={setDrawingManager}
          marker={marker.current}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setAddr={setAddr}
          setRoad={setRoad}
          isLoading={!(fetched && !isLoading)}
          originGeoJsons={originGeoJsons}
          isEdit
        />
      </InputForm>
    </PageWrapper>
  );
};

export default PolygonEditPage;
