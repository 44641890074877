import type { Geofence } from '.';

export function isGeofenceCircle(
  geofence: Geofence
): geofence is Geofence.GeofenceApi.Get.Single.Response.GeofenceCircle {
  if (!isGeofenceGroup(geofence)) return geofence.shape_type === 1;
  return false;
}
export function isGeofenceGroup(geofence: Geofence): geofence is Geofence.GeofenceApi.Get.Group.Response.Detail {
  return 'gfgid' in geofence && typeof geofence.gfgid === 'number';
}

export type HandleHoverStyle = (geofence: naver.maps.Circle | naver.maps.Polygon, type: 'circle' | 'polygon') => void;
export type DrawCircle = ({
  dist,
  lat,
  lng,
  map
}: Pick<Geofence.GeofenceApi.Get.Single.Response.GeofenceCircle, 'dist' | 'lat' | 'lng'> & {
  map: naver.maps.Map;
}) => naver.maps.Circle;
export type DrawPolygon = ({ paths, map }: { paths: naver.maps.LatLng[][]; map: naver.maps.Map }) => naver.maps.Polygon;
export type DrawMarker = ({ map, center }: { map: naver.maps.Map; center: naver.maps.LatLng }) => naver.maps.Marker;
export type SetTooltipLayer = (
  geofence: naver.maps.Circle | naver.maps.Polygon,
  map: naver.maps.Map,
  marker: naver.maps.Marker
) => void;

export type UseGeofenceMapOutput = {
  mapId: string;
  zoom: number;
  areaSize: number | null;
  centerBoundsRef: React.MutableRefObject<naver.maps.Bounds | null>;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  initGeofenceCircle: (circle: Geofence.GeofenceApi.Get.Single.Response.GeofenceCircle) => naver.maps.Map;
  initGeofencePolygon: (polygon: Geofence.GeofenceApi.Get.Single.Response.GeofencePolygon) => naver.maps.Map;
  initGeofenceGroup: ({ circles, polygons }: Geofence.GeofenceApi.Get.Group.Response.Detail) => naver.maps.Map;
  tipRef: React.RefObject<HTMLDivElement>;
};
export type UseGeofenceMap = (id: string) => UseGeofenceMapOutput;
