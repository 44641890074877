import { cashplaceReportsApi } from '@API/manager/cashplace';
import { PutReportParams, Report } from '@TS/cashplace/report';

type FetchReportsParams = {
  userId: number;
  state: number | null;
  kind: number | null;
  placeName: string;
};

export function fetchReports(page: number, perPage: number, params: FetchReportsParams): Promise<Report[]> {
  return cashplaceReportsApi.getReports({ page, perPage, ...params }).then((response) => response.data);
}

export function updateReport(reportId: number, params: PutReportParams): Promise<Report> {
  return cashplaceReportsApi.putReport(reportId, params).then((response) => response.data);
}
