import { MenuItem } from '@material-ui/core';
import { useRef, Dispatch, SetStateAction, useState } from 'react';
import { SelectFormProps } from '../SelectForm';
import * as S from './style';

type SelectToggleFormProps = Omit<SelectFormProps, 'onSelect'> & {
  onChange: Dispatch<SetStateAction<string>>;
};

function SelectToggleForm({
  label,
  options = [],
  value,
  disabled = false,
  name,
  onChange,
  error = false,
  size = 'medium'
}: SelectToggleFormProps): JSX.Element {
  const wrapper = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const toggleOption = () => {
    const optionsLength = options.length;
    const currentIndex = options.indexOf(value);
    onChange(options[currentIndex + 1 === optionsLength ? 0 : currentIndex + 1]);
  };

  const toggleMenu = () => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(wrapper.current);
    }
  };

  const selectOption = (option: string) => {
    onChange(option);
    setAnchorEl(null);
  };

  return (
    <S.StyledFormControl ref={wrapper} variant="outlined" fullWidth disabled={disabled} error={error} size={size}>
      <S.StyledTextField role="button" label={label} value={value} variant="outlined" name={name} />
      <S.StyledPaper className="dim" elevation={0} onClick={() => toggleOption()} />
      <S.StyledArrowDorpDown isOpen={Boolean(anchorEl)} onClick={() => toggleMenu()} />
      {wrapper && wrapper.current && (
        <S.StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          width={wrapper.current.clientWidth}
        >
          {options.map((option) => (
            <MenuItem value={option} key={option} onClick={() => selectOption(option)}>
              {option}
            </MenuItem>
          ))}
        </S.StyledMenu>
      )}
    </S.StyledFormControl>
  );
}

export default SelectToggleForm;
