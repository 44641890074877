/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const usePolygonTable = () => {
  const heads: {
    key: number;
    label: string;
    value: string;
    align: 'center' | 'left' | 'right' | 'justify' | 'inherit' | undefined;
    sortAble: boolean;
    width: number;
  }[] = [
    {
      key: 1,
      label: 'ID',
      value: 'id',
      align: 'center',
      sortAble: true,
      width: 80
    },
    {
      key: 2,
      label: '제목',
      value: 'title',
      align: 'left',
      sortAble: false,
      width: 150
    },
    {
      key: 3,
      label: '작업자',
      value: 'worker',
      align: 'left',
      sortAble: false,
      width: 100
    },

    {
      key: 4,
      label: '주소',
      value: 'addr',
      align: 'left',
      sortAble: false,
      width: 350
    },
    {
      key: 6,
      label: '상태',
      value: 'state',
      align: 'center',
      sortAble: false,
      width: 80
    },
    {
      key: 7,
      label: '생성일',
      value: 'created',
      align: 'left',
      sortAble: true,
      width: 100
    },
    {
      key: 8,
      label: '수정일',
      value: 'updated',
      align: 'left',
      sortAble: true,
      width: 100
    },
    {
      key: 0,
      label: '',
      value: '',
      align: 'center',
      sortAble: false,
      width: 80
    }
  ];

  return {
    heads
  };
};

export default usePolygonTable;
