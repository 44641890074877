/* eslint-disable jsx-a11y/control-has-associated-label */
import { Skeleton } from '@material-ui/lab';
import { Table } from './styles';

const SkeletonTable = (): JSX.Element => (
  <Table>
    <thead>
      <tr>
        <SkeletonHeader colspan={4} height={28} />
      </tr>
    </thead>
    <tbody>
      <SkeletonTwoCellsInRow />
      <SkeletonFourCellsInRow />
      <SkeletonFourCellsInRow />
      <tr>
        <td colSpan={4} className="map">
          <Skeleton height={680} variant="rect" />
        </td>
      </tr>
    </tbody>
  </Table>
);

export default SkeletonTable;

const SkeletonHeader = ({ colspan = 1, height = 14 }) => (
  <th colSpan={colspan}>
    <Skeleton height={height} />
  </th>
);
const SkeletonTwoCellsInRow = () => (
  <tr>
    <SkeletonHeader />
    <td colSpan={3}>
      <Skeleton height={14} />
    </td>
  </tr>
);

const SkeletonFourCellsInRow = (): JSX.Element => (
  <tr>
    <SkeletonHeader />
    <td>
      <Skeleton height={14} />
    </td>
    <SkeletonHeader />
    <td>
      <Skeleton height={14} />
    </td>
  </tr>
);
