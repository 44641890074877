import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTogglers } from '@HOOK/useTogglers';
import Pagination from '@F/materialUI/Pagination';
import Wrapper from '@F/materialUI/Wrapper';
import Loading from '@F/Loading';
import SegmentListItem from './SegmentListItem';
import { levelOneCodeList } from './SegmentDetail/const';
import { saveAs } from 'file-saver';

import { fetchSegmentListByParams, SEGMENT_TABLE_HEAD_CELLS } from '@/redux/dmp/segmentList';
import { Button } from 'loplat-ui';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import SearchCompany from './SearchCompany';
import TableHeadCellWithSortingApi from '@F/table/cells/TableHeadCellWithSortingApi';
import type RootStateType from '@/redux/common/store.type';
import dayjs from 'dayjs';
import { getSegmentOptions } from '@FUNC/getSegmentOptions';

type LawDistrict = {
  [key: string]: {
    [subKey: string]: string[];
  };
};

function SegmentListTable() {
  const dispatch = useDispatch();
  const { setOffAll, state, toggle, resetStateWithData } = useTogglers();
  const { isLoading, data } = useSelector((state) => (state as RootStateType).segmentListReducer);

  const [page, setPage] = useState(1);
  const [company, setCompany] = useState('');
  const [size, setSize] = useState(20);
  const [orderBy, setOrderBy] = useState('segment_id');
  const getParams = useMemo(() => {
    if (company) {
      return {
        page,
        size,
        company,
        orderby: orderBy
      };
    } else {
      return {
        page,
        size,
        orderby: orderBy
      };
    }
  }, [company, orderBy, page, size]);

  useEffect(() => {
    setOffAll();
    dispatch(fetchSegmentListByParams(getParams));
  }, [dispatch, getParams, setOffAll]);

  useEffect(() => {
    resetStateWithData(data.result);
  }, [data, resetStateWithData]);

  const downloadSegmentList = () => {
    const format = 'YYYY-MM-DD';
    const formatTime = 'YYYY-MM-DD HH:mm:ss';

    if (isLoading !== 'fetched' || data.total_count < 0) {
      return;
    }
    const csvHeader = ['id, 고객사, 세그먼트 이름, 사용자 수, 방문 기간, 생성자, 생성일, 조건'];
    const csvContent = data.result.map((segment) => {
      const { segment_id, company, name, target_count, duration, creator, created_at, comment, segment_type } = segment;
      const calculateDuration = duration
        ? `${dayjs(duration.start).format(format)} ~ ${dayjs(duration.end).format(format)}`
        : '';
      const calculateCreatedAt = dayjs(created_at).format(formatTime);
      const segmentBasicInfo = [
        `"${segment_id}","${company}","${name}","${
          target_count ?? 0
        }","${calculateDuration}","${creator}","${calculateCreatedAt}"`
      ];

      switch (segment_type) {
        case 'all':
          return [...segmentBasicInfo, `"모든 사용자"`];

        case 'file':
          return [...segmentBasicInfo, `"사용자 업로드 파일"`];

        case 'log_base':
          const version = JSON.parse(comment ?? '[]').version;
          if (version === 2) {
            const { selects, commercial, law_district } = JSON.parse(comment ?? '');
            const { brand, category, store, complex } = selects;
            const calculateSelects = {
              brand,
              category,
              store: [...complex, ...store]
            };
            // 위치 정보
            const districtOption = Object.entries((law_district as LawDistrict) ?? {})
              .flatMap(([key, value]) => (value && typeof value === 'object' ? { key, value } : []))
              .map(({ key, value }) => {
                if (key === '전국') {
                  return '전국';
                }
                const lv2 = Object.keys(value).flat();
                const lv3 = lv2.flatMap((key) => (key in value ? value[key] : [])).flat();
                const lv2String = lv2.length > 0 ? `\n${lv2.join(',')}` : '';
                const lv3String = lv2.length > 0 ? `\n${lv3.join(',')}` : '';
                return `${key}${lv2String}${lv3String}`;
              })
              .join('\n');

            // 상권 정보
            const commercialOption = Object.entries(commercial)
              .flatMap(([key, value]) => {
                const targetLevelOne = levelOneCodeList.find(({ code }) => code === key);
                const validValue = Array.isArray(value) && value.length > 0;
                if (!targetLevelOne || !validValue) {
                  return [];
                }
                return `${targetLevelOne.name} : ${value.join(',')}`;
              })
              .join('\n');

            // 셀렉트 정보
            const selectOption = Object.entries(calculateSelects)
              .flatMap(([key, value]) => {
                const validValue = Array.isArray(value) && value.length > 0;
                if (!validValue) {
                  return [];
                }
                if (key === 'category' || key === 'brand') {
                  return `${key} : ${value.map(({ name }) => name).join(', ')}`;
                } else {
                  return `${key} : ${value.join(', ')}`;
                }
              })
              .join('\n');

            const totalOption = [districtOption, commercialOption, selectOption].filter(Boolean).join('\n');

            return [...segmentBasicInfo, `"${totalOption}"`];
          } else {
            const segmentComment: Segments.SegmentScheme.Comment[] = JSON.parse(comment ?? '[]');
            const { lv1, lv2, selection } = getSegmentOptions(segmentComment[0]);
            const { brand, category, store, complex } = selection;
            const calculateSelects = {
              brand: [...(brand ?? [])],
              category: [...(category ?? [])],
              store: [...(complex ?? []), ...(store ?? [])]
            };
            const selectOption = Object.entries(calculateSelects)
              .flatMap(([key, value]) => (Array.isArray(value) && value.length > 0 ? { key, value } : []))
              .map(({ key, value }) => `${key}:${value.join(', ')}`)
              .join('\n');
            return [
              ...segmentBasicInfo,
              `"${lv1}\n${lv2.district.join(', ')}\n${lv2.commercial.join(', ')}\n${selectOption}"`
            ];
          }

        case 'combination':
          const combineInfo = JSON.parse(comment ?? '');
          const { relation } = combineInfo;
          return [...segmentBasicInfo, `"${relation}"`];

        default:
          return [...segmentBasicInfo, `""`];
      }
    });

    const csvData = [csvHeader, ...csvContent].join('\n');
    const blob = new Blob(['\uFEFF', csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'segment.csv');
  };

  return (
    <div>
      <SearchCompany size={size} setSize={setSize} setCompany={setCompany} />
      <Button mb={2} size="xs" onClick={() => downloadSegmentList()} disabled={isLoading !== 'fetched'}>
        다운로드
      </Button>
      <Wrapper>
        {isLoading === 'loading' && <Loading />}
        <Table>
          <TableHead>
            <TableRow>
              {SEGMENT_TABLE_HEAD_CELLS.map(({ name, label }, i) => {
                const isSortingCell = name === 'segment_id' || name === 'creator' || name === 'company';
                return (
                  <TableHeadCellWithSortingApi
                    key={i}
                    name={name}
                    label={label}
                    onClick={(name) => setOrderBy(name)}
                    isSortAble={isSortingCell}
                    currentSort={orderBy}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.total_count === 0 && (
              <TableCell colSpan={SEGMENT_TABLE_HEAD_CELLS.length} align="center">
                데이터가 없습니다.
              </TableCell>
            )}
            {data.total_count > 0 &&
              data.result.map((row, index) => (
                <SegmentListItem
                  key={row.segment_id}
                  {...row}
                  onClickEvent={() => toggle(index)}
                  isExpanded={state[index]}
                />
              ))}
          </TableBody>
        </Table>
        <Pagination
          page={page}
          perPage={size}
          totalCount={data.total_count > 0 ? data.total_count : 1}
          changePage={(e, page) => setPage(page)}
        />
      </Wrapper>
    </div>
  );
}

export default SegmentListTable;
