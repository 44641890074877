import { AxiosPromise, CancelTokenSource } from 'axios';
import { Recruit, RecruitJob, RecruitsParams, RecruitForm, RecruitStatus } from '@TS/homepage/recruit';
import { axiosInstancesManager } from '@API/axios';

type GetAllResponse = {
  job_count: {
    [key in RecruitJob]: number;
  };
  total_count: number;
  result: Recruit[];
};

class HomepageRecruitsApi {
  cancelTokenSources: CancelTokenSource[] = [];

  // 전체/단일 채용정보 조회
  getAll = (params?: RecruitsParams): AxiosPromise<GetAllResponse> => {
    if (params === undefined) {
      return axiosInstancesManager.manager.get('/recruits');
    }

    return axiosInstancesManager.manager.get('/recruits', { params });
  };

  get(id: number): AxiosPromise<Recruit> {
    return axiosInstancesManager.manager.get(`/recruits/${id}`);
  }

  // 채용정보 등록 (모든 필드 전달)
  post(data: RecruitForm): AxiosPromise {
    return axiosInstancesManager.manager.post('/recruits', data);
  }

  // 채용정보 수정 (일부 필드 수정)
  patch({ id, data }: { id: number; data: Partial<Recruit> }): AxiosPromise {
    return axiosInstancesManager.manager.patch(`/recruits/${id}`, data);
  }

  // 채용정보 수정 (모든 필드 전달)
  put({ id, data }: { id: number; data: RecruitForm & { status: RecruitStatus } }): AxiosPromise {
    return axiosInstancesManager.manager.put(`/recruits/${id}`, {
      ...data
    });
  }

  delete(id: number): AxiosPromise {
    return axiosInstancesManager.manager.delete(`/recruits/${id}`);
  }
}

export default new HomepageRecruitsApi();
