import styled from '@emotion/styled';

type Props = {
  alertMessage?: string;
};

export default function AlertBox({ alertMessage }: Props): JSX.Element {
  return (
    <StyledAlertBox>
      {' '}
      {alertMessage ?? '로그 조회 기간이 7일을 초과할 경우 big query 비용이 크게 발생할 수 있습니다.'}{' '}
    </StyledAlertBox>
  );
}

const StyledAlertBox = styled.div`
  padding: 20px;
  box-shadow: 0 0 0 1px #eee;
  background-color: #fff;
  color: #333;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;
