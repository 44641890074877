import { useState } from 'react';
import { storesApi } from '@API/manager';
import DialogModal from '@F/DialogModal';
import Loading from '@F/Loading';
import ResponseAlert from '@F/ResponseAlert';
import { useFetch } from '@HOOK/';
import { AddToDriveRounded } from '@mui/icons-material';

function RemoveDialog(props) {
  const { open, setOpen, fpid, bfpid, pid, updateRemovedData, setSelected } = props;
  const { callApi, isLoading, isError, error } = useFetch();
  const [responseAlertOpen, setResponseAlertOpen] = useState(false);

  const confirmButtonDidClicked = async () => {
    AddToDriveRounded;
    const response = await callApi(storesApi.patchStoreUnMap, {
      items: [
        {
          bfpid: bfpid?.bfpid ? bfpid.bfpid : 0,
          pid,
          bid: 0,
          cid: 0,
          fpid: fpid?.fpid ? fpid.fpid : 0,
          delete: true
        }
      ]
    });

    setSelected([]);
    updateRemovedData();
    setResponseAlertOpen(true);
    if (response) {
      setOpen(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <ResponseAlert
        error={isError}
        open={responseAlertOpen}
        handleClose={() => setResponseAlertOpen(false)}
        message={isError ? String(error) : '성공하였습니다.'}
      />
      <DialogModal
        title={fpid ? 'fpid 삭제' : 'bfpid 삭제'}
        description={
          fpid ? `정말로 fpid ${fpid.fpid}를 삭제하시겠습니까?` : `정말로 bfpid ${bfpid.bfpid}를 삭제하시겠습니까?`
        }
        open={open}
        setOpen={setOpen}
        confirmButtonDidClicked={confirmButtonDidClicked}
      />
    </>
  );
}

export default RemoveDialog;
