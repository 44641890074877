import { useState, useCallback, useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { FeatureGeoJSON, Polygon } from '@API/manager/polygon';

function useDrawingManager(): {
  drawingManagerState: [
    naver.maps.drawing.DrawingManager | null,
    Dispatch<SetStateAction<naver.maps.drawing.DrawingManager | null>>
  ];
  drawingOptions: naver.maps.drawing.DrawingOptions;
  getGeoJsons: (dm: naver.maps.drawing.DrawingManager | null) => FeatureGeoJSON | null;
  getDrawData: (dm: naver.maps.drawing.DrawingManager | null) => Polygon[];
} {
  const [drawingManager, setDrawingManager] = useState<naver.maps.drawing.DrawingManager | null>(null);
  const [eventListener, setEventListener] = useState<naver.maps.MapEventListener | null>(null);
  const [selectedOverlayId, setSelectedOverlayId] = useState('');

  const drawingOptions: naver.maps.drawing.DrawingOptions = {
    drawingControl: undefined,
    drawingMode: 0,
    polygonOptions: {
      paths: [],
      fillColor: 'red',
      fillOpacity: 0.5,
      strokeColor: 'red'
    }
  };

  const getGeoJsons = useCallback((drawingManager: naver.maps.drawing.DrawingManager | null) => {
    if (!drawingManager) {
      return null;
    }
    const geoJsons = drawingManager.toGeoJson() as FeatureGeoJSON;
    return geoJsons;
  }, []);

  const getDrawData = useCallback(
    (drawingManager: naver.maps.drawing.DrawingManager | null) => {
      const geoJsons = getGeoJsons(drawingManager);
      if (!geoJsons || !geoJsons.features || geoJsons.features.length < 1) {
        return [];
      }
      const filteredGeoJsons = geoJsons.features.map((item) => {
        const { coordinates } = item.geometry;
        return coordinates;
      });

      return filteredGeoJsons;
    },
    [getGeoJsons]
  );

  useEffect(() => {
    if (drawingManager && !eventListener) {
      const addEvent = (overlay: naver.maps.drawing.DrawingOverlay) => {
        const geoJsons = getGeoJsons(drawingManager);
        if (!geoJsons || !geoJsons.features) {
          return;
        }
        const lastDrawing = geoJsons.features[geoJsons.features.length - 1];
        const coordinate = lastDrawing.geometry.coordinates[0] as number[][];
        const getVertex = coordinate.length;
        if (getVertex < 4) {
          (
            drawingManager as naver.maps.drawing.DrawingManager & {
              removeDrawing: (overlay: naver.maps.drawing.DrawingOverlay) => void;
            }
          ).removeDrawing(overlay);
        }
      };
      const listener = drawingManager.addListener(naver.maps.drawing.DrawingEvents.ADD, addEvent);
      setEventListener(listener);
    }

    return () => {
      if (drawingManager && eventListener) {
        drawingManager.removeListener(eventListener);
      }
    };
  }, [drawingManager, eventListener, getGeoJsons]);

  useEffect(() => {
    const clearSelectedOverlayNotPathClick = (e: MouseEvent) => {
      if (!(e.target instanceof SVGPathElement)) {
        setSelectedOverlayId('');
      }
    };

    const setSelectedOverlay = (e: naver.maps.drawing.DrawingOverlay) => {
      setSelectedOverlayId(e.id);
    };

    const removeDrawingsBySelectedOverlay = (e: KeyboardEvent) => {
      if (drawingManager && (e.key === 'Backspace' || e.key === 'Delete') && selectedOverlayId) {
        (
          drawingManager as naver.maps.drawing.DrawingManager & {
            removeDrawing: (idOrOverlay: string | naver.maps.drawing.DrawingOverlay) => void;
          }
        ).removeDrawing(selectedOverlayId);
      }
    };

    window.addEventListener('click', clearSelectedOverlayNotPathClick);
    window.addEventListener('keydown', removeDrawingsBySelectedOverlay);
    const listener = drawingManager?.addListener(naver.maps.drawing.DrawingEvents.SELECT, setSelectedOverlay);
    return () => {
      window.removeEventListener('click', clearSelectedOverlayNotPathClick);
      window.removeEventListener('keydown', removeDrawingsBySelectedOverlay);
      if (listener && drawingManager) {
        drawingManager.removeListener(listener);
      }
    };
  }, [drawingManager, selectedOverlayId, setSelectedOverlayId]);

  return {
    drawingManagerState: [drawingManager, setDrawingManager],
    drawingOptions,
    getGeoJsons,
    getDrawData
  };
}

export default useDrawingManager;
