import { red300, red400, teal } from 'loplat-ui';
import Pin from '@ICON/pin.svg';

export const MAX_DISPLAY = 20;

export const MAP_OPTIONS = Object.freeze({
  // 대한민국 중심 + 제주도 포함
  KOREA: {
    ZOOM: 7,
    COORDS: {
      lat: 36.6232296,
      lng: 127.9735369
    }
  },
  SEOUL: {
    ZOOM: 11,
    COORDS: {
      lat: 37.5256044,
      lng: 126.9822617
    }
  }
});

export const MAP_STYLES = Object.freeze({
  map: {
    tileTransition: false,
    minZoom: 7
  },
  circle: {
    fillColor: '#6ed9e3',
    fillOpacity: 0.3,
    strokeColor: '#55D8E4',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true
  },
  polygon: {
    fillColor: '#d998ff',
    fillOpacity: 0.3,
    strokeColor: '#CA79F9',
    strokeWeight: 1,
    strokeOpacity: 1,
    clickable: true
  },
  // TODO 색상 임의로 정함
  hover: {
    polygon: {
      fillColor: red300,
      strokeColor: red400
    },
    circle: {
      fillColor: teal,
      strokeColor: teal
    }
  },
  marker: {
    icon: Pin,
    zIndex: 99,
    clickable: true
  },
  infoWindow: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    disableAnchor: true,
    pixelOffset: new naver.maps.Point(0, -14)
  }
});
