import { ChangeEvent, useCallback, useState, MouseEvent } from 'react';
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { DeleteIcon } from 'loplat-ui';
import { CampaignStatusBadge, CampaignName } from '@P/max/campaigns/module';
import { HEAD_CELL } from './data';
import { AlertMessage } from './styles';
import type { Props as ModalProps } from './Modal';

type Props = Pick<ModalProps, 'campaigns' | 'setCampaigns'> & {
  selectedQuantity: number;
};

const ModalContent = ({ campaigns, setCampaigns, selectedQuantity }: Props): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, campaigns.length - page * rowsPerPage);

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setPage, setRowsPerPage]
  );

  const toggleAllCampaigns = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCampaigns((prev) => prev.map((campaign) => ({ ...campaign, selected: e.target.checked })));
    },
    [setCampaigns]
  );

  const toggleCampaign = useCallback(
    (e: MouseEvent, campaignId: Campaign.CampaignCommon['campaign_id']) => {
      setCampaigns((prev) =>
        prev.map((campaign) =>
          campaign.campaignId === campaignId ? { ...campaign, selected: !campaign.selected } : campaign
        )
      );
    },
    [setCampaigns]
  );

  const deleteCampaign = useCallback(
    (campaignId: Campaign.CampaignCommon['campaign_id']) => {
      setCampaigns((prev) => prev.filter((campaign) => campaign.campaignId !== campaignId));
    },
    [setCampaigns]
  );

  if (!campaigns.length) return <AlertMessage>선택된 캠페인이 없습니다.</AlertMessage>;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={selectedQuantity > 0 && selectedQuantity < campaigns.length}
              checked={campaigns.length > 0 && selectedQuantity === campaigns.length}
              onChange={toggleAllCampaigns}
            />
          </TableCell>
          {HEAD_CELL.map((cell) => (
            <TableCell key={cell.name} align="left" {...cell}>
              {cell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0 ? campaigns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : campaigns).map(
          ({ name, selected, duration, campaignId, status }) => (
            <TableRow
              hover
              role="checkbox"
              key={campaignId}
              selected={selected}
              onClick={(e) => {
                toggleCampaign(e, campaignId);
              }}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={selected} color="primary" />
              </TableCell>
              <TableCell>
                <CampaignName {...name} />
              </TableCell>
              <TableCell align="center">{campaignId}</TableCell>
              <TableCell align="center">
                <CampaignStatusBadge full status={status} />
              </TableCell>
              <TableCell align="right">{duration}</TableCell>
              <TableCell padding="checkbox" align="center">
                <IconButton aria-label="삭제" size="small" color="primary" onClick={() => deleteCampaign(campaignId)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        )}
        {emptyRows > 0 && (
          <TableRow style={{ height: 33 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, { label: 'All', value: -1 }]}
            colSpan={6}
            count={campaigns.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              native: true
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default ModalContent;
