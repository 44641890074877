import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { SegmentApi } from '@API/max';
import type { SegmentRequestParams, SegmentResponse } from '@API/max/segment';

type SegmentSliceState = {
  isLoading: 'idle' | 'loading' | 'fetched';
  isError: boolean;
  message: string;
  data: SegmentResponse;
};

export const SEGMENT_TABLE_HEAD_CELLS: {
  name: string;
  label: string;
  defaultOrderType?: OrderType;
  initialIsActive?: boolean;
  isImportant?: boolean;
}[] = [
  { name: 'segment_id', label: 'id', defaultOrderType: 'DESC' },
  { name: 'company', label: '고객사' },
  { name: 'name', label: '세그먼트 이름' },
  { name: 'state', label: '상태' },
  { name: 'target_count', label: '사용자 수' },
  { name: 'duration', label: '방문 기간' },
  { name: 'creator', label: '생성자' },
  { name: 'created_at', label: '생성일' }
];

export const fetchSegmentListByParams = createAsyncThunk(
  'fetchSegmentListByParams',
  async (params: SegmentRequestParams) => {
    try {
      const response = await SegmentApi.getSegmentList(params);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

const initialState: SegmentSliceState = {
  isLoading: 'idle',
  isError: false,
  message: '',
  data: {
    total_count: 0,
    result: []
  }
};

const slice = createSlice({
  name: 'segmentList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSegmentListByParams.pending, (state) => ({
        ...state,
        isLoading: 'loading',
        data: {
          total_count: state.data.total_count ?? 0,
          result: []
        }
      }))
      .addCase(fetchSegmentListByParams.fulfilled, (state, action) => {
        const payload = action.payload;

        return {
          isLoading: 'fetched',
          isError: false,
          message: '',
          data: payload
        };
      })
      .addCase(fetchSegmentListByParams.rejected, (state, action) => {
        const { message } = action.error;
        return {
          isLoading: 'fetched',
          isError: true,
          message: message ?? '',
          data: {
            total_count: 0,
            result: []
          }
        };
      });
  }
});

export const { actions } = slice;
export default slice.reducer;

export const segmentListConfig = {
  key: 'segmentList',
  storage,
  whitelist: ['segmentList']
};
