import { AppleIcon, AndroidIcon, primary } from 'loplat-ui';
import { Wrapper } from './index.styles';

export type AppProps = { type: Campaign.Scheme.TargetApp['target_type']; name: Campaign.Scheme.TargetApp['name'] };
export const AppModule = ({ type, name }: AppProps): JSX.Element => (
  <Wrapper>
    {type === 'android' && <AndroidIcon fillColor={primary} />}
    {type === 'ios' && <AppleIcon fillColor={primary} />}
    {name}
  </Wrapper>
);

export default AppModule;
