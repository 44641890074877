import { handleErrorWithToast } from '@FUNC/error';
import { toastStore } from '@/mobX/toast';

export function toastSuccessMessage(message = '정상 처리되었습니다') {
  toastStore.setIsOpen(true);
  toastStore.setMessageInfo({ message, type: 'success' });
}

export function toastErrorMessage(message = '에러가 발생하였습니다. 관리자(윤준호)에게 문의해주세요.') {
  // TODO: 프로젝트 전체의 toastErrorMessage를 handleErrorWithToast 교체한 후, 아래의 if 절은 삭제
  if (typeof message !== 'string') {
    handleErrorWithToast(message);
    return;
  }

  toastStore.setIsOpen(true);
  toastStore.setMessageInfo({
    message,
    type: 'error'
  });
}

export function toastWarningMessage(message = '에러가 발생하였습니다. 관리자(윤준호)에게 문의해주세요.') {
  if (typeof message !== 'string') {
    handleErrorWithToast(message);
    return;
  }

  toastStore.setIsOpen(true);
  toastStore.setMessageInfo({
    message,
    type: 'warning'
  });
}
