/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useDispatch } from 'react-redux';
import { FetchPolygonListParams, fetchPolygonListByParams } from '@P/polygon/redux/polygonList';
import { useCallback, useEffect } from 'react';

export const PolygonKey = 'polygon_filter_key';

const usePolygonTableFilter = () => {
  const dispatch = useDispatch();

  const getSavedParamsAtLocalStorage = useCallback(() => {
    const initalParams = {
      per_page: '15',
      state: '전체',
      order: 'created',
      page: 1,
      q: '',
      kind: '제목'
    };

    const params = window.localStorage.getItem(PolygonKey);
    if (!params || typeof params !== 'string') {
      window.localStorage.setItem(PolygonKey, JSON.stringify(initalParams));
      return initalParams;
    }

    const parsing = JSON.parse(params);
    return {
      ...JSON.parse(params),
      page: parsing?.page || 1,
      q: parsing?.q || '',
      kind: parsing?.kind || '제목'
    };
  }, []);

  const saveParamsToLocalStorage = (params: Partial<FetchPolygonListParams>) => {
    const currentParams = getSavedParamsAtLocalStorage();
    const newParams = {
      ...currentParams,
      ...params
    };
    window.localStorage.setItem(PolygonKey, JSON.stringify(newParams));
    dispatch(fetchPolygonListByParams(newParams));
  };

  useEffect(() => {
    const removeSpecificKey = () => {
      const currentParams = getSavedParamsAtLocalStorage();
      delete currentParams.q;
      delete currentParams.page;
      delete currentParams.kind;
      window.localStorage.setItem(PolygonKey, JSON.stringify(currentParams));
    };
    window.addEventListener('beforeunload', removeSpecificKey);
    return () => {
      window.removeEventListener('beforeunload', removeSpecificKey);
    };
  }, [getSavedParamsAtLocalStorage]);

  return {
    getParams: getSavedParamsAtLocalStorage,
    setParams: saveParamsToLocalStorage
  };
};

export default usePolygonTableFilter;
