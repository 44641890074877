import { Promotion } from '@TS/max/Promotion/api';
import { Button } from '@material-ui/core';
import { Switch } from 'loplat-ui';

type UpdateTableRowProps = {
  promotion: Promotion;
  setUpdatingPromotionItem: React.Dispatch<React.SetStateAction<Promotion | null>>;
  updatingPromotionItem: Promotion;
  imgUploadAndUpdate: (file: File | string, promotion: Promotion) => void;
  updateImg: File | null;
  setUpdateImg: React.Dispatch<React.SetStateAction<File | null>>;
  updateImgURL: (file: File) => string;
};

const UpdateTableRow = ({
  promotion,
  setUpdatingPromotionItem,
  updatingPromotionItem,
  imgUploadAndUpdate,
  updateImg,
  setUpdateImg,
  updateImgURL
}: UpdateTableRowProps): JSX.Element => (
  <>
    <td className="Img">
      <label htmlFor="promotion-Img">
        <input
          id="promotion-Img"
          type="file"
          accept="Img/*"
          onChange={(e) => setUpdateImg(e.target.files?.[0] || null)}
        />
        <img src={updateImg ? updateImgURL(updateImg) : promotion.img} width={68} height={68} alt="promotion" />
      </label>
    </td>
    <td className="td-content" aria-label="content">
      <input
        value={updatingPromotionItem.content}
        onChange={(e) => setUpdatingPromotionItem({ ...updatingPromotionItem, content: e.target.value })}
        type="text"
      />
    </td>
    <td className="td-url" aria-label="url">
      <input
        value={updatingPromotionItem.url}
        onChange={(e) => setUpdatingPromotionItem({ ...updatingPromotionItem, url: e.target.value })}
        type="text"
      />
    </td>
    <td>
      <Button variant="contained" color="secondary" type="button" onClick={() => setUpdatingPromotionItem(null)}>
        취소
      </Button>
    </td>
    <td>
      <Button
        variant="contained"
        color="primary"
        type="button"
        onClick={() => imgUploadAndUpdate(updateImg || updatingPromotionItem.img, updatingPromotionItem)}
      >
        완료
      </Button>
    </td>
    <td>
      <Switch checked={promotion.status === 'PUBLISH'} />
    </td>
  </>
);

export default UpdateTableRow;
