import { useCallback, useState, useEffect } from 'react';
import EnhancedTable from '@F/table/Table';
import Button from '@material-ui/core/Button';

import RemoveDialog from '@P/store/StoreManager/dialogs/RemoveDialog';
import moment from 'moment';
import { storesApi } from '@API/manager';
import * as S from './styles';

function createHeadCells(id, label, numeric = false, disablePadding = false) {
  return {
    id,
    numeric,
    disablePadding,
    label
  };
}

const headCells = [
  createHeadCells('BleID', 'Ble ID'),
  createHeadCells('date', 'date (created_at)'),
  createHeadCells('collector', 'collector'),
  createHeadCells('floor', 'floor', true, true),
  createHeadCells('fpID', 'FP ID'),
  createHeadCells('actions', '')
];

function BleTableSection(props) {
  const { pid, bles } = props;
  const [updatedBles, setUpdatedBles] = useState(bles);

  const classes = S.useStyles();
  const [selected, setSelected] = useState([]);
  const [selectedBle, setSelectedBle] = useState([]);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const loadBlesData = useCallback(async (pid) => {
    const response = await storesApi.getStoreDataByPID({ pid });
    if (response) {
      setUpdatedBles(response.data.ble_fingerprint);
    }
  }, []);

  const updateRemovedData = () => {
    setUpdatedBles((prev) => prev.filter((fp) => fp.bfpid !== selectedBle.bfpid));
  };

  useEffect(() => {
    loadBlesData(pid);
  }, [loadBlesData, pid]);

  function deleteButton(bfpid) {
    return (
      <Button
        id={`${bfpid} ble delete`}
        variant="contained"
        color="secondary"
        disableElevation
        className={classes.button}
        disabled={selected.length > 1}
        onClick={(event) => {
          event.stopPropagation();
          setRemoveDialogOpen(true);
          setSelectedBle({ bfpid });
        }}
      >
        삭제
      </Button>
    );
  }

  function selectAllButton() {
    return (
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        className={classes.unselectAllButton}
        onClick={() => {
          setSelected(updatedBles.map((fp) => ({ id: String(fp.bfpid) })));
        }}
      >
        전체 선택
      </Button>
    );
  }

  function unselectAllButton() {
    return (
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        className={classes.unselectAllButton}
        onClick={() => {
          setSelected([]);
        }}
      >
        선택 취소
      </Button>
    );
  }

  const rows =
    updatedBles.length === 0
      ? [
          {
            id: '',
            date: '',
            collector: '',
            floor: '',
            fpID: '',
            actions: []
          }
        ]
      : updatedBles.map((item) => ({
          id: `${item.bfpid}`,
          date: item.created_at ? moment(item.created_at).format('YYYY-MM-DD HH:mm:ss') : '',
          collector: item.collector,
          floor: item.floor,
          fpID: `${item.fpid}`,
          actions: [deleteButton(item.bfpid)]
        }));

  return (
    <S.StyledStoreInnerFpTable>
      <EnhancedTable
        titleText={['BLE 정보 ']}
        headCells={headCells}
        rows={rows}
        initialOrder="desc"
        initialOrderBy="carbs"
        isCollapsible={false}
        isCheckable={false}
        selected={selected}
        setSelected={setSelected}
        selectedActions={[selected.length ? unselectAllButton() : selectAllButton(selected)]}
        defaultrowsPerPage={5}
        selectMode="drag"
      />

      <RemoveDialog
        pid={pid}
        open={removeDialogOpen}
        setOpen={setRemoveDialogOpen}
        bfpid={selectedBle}
        updateRemovedData={updateRemovedData}
        setSelected={setSelected}
      />
    </S.StyledStoreInnerFpTable>
  );
}

export default BleTableSection;

BleTableSection.propTypes = {};
