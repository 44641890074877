import type { AxiosResponse } from 'axios';

import { axiosInstancesManager } from '@API/axios';
import {
  GetPromotionResponse,
  GetPromotionsResponse,
  CreatePromotionParams,
  CreatePromotionResponse,
  UpdatePromotionParams,
  UpdatePromotionResponse,
  DeletePromotionResponse
} from '@TS/max/Promotion/api';

class PromotionApi {
  getPromotion(id: number): Promise<AxiosResponse<GetPromotionResponse>> {
    return axiosInstancesManager.manager.get(`/promotion_banner/${id}`);
  }

  getPromotions(): Promise<AxiosResponse<GetPromotionsResponse>> {
    return axiosInstancesManager.manager.get('/promotion_banner');
  }

  createPromotion(params: CreatePromotionParams): Promise<AxiosResponse<CreatePromotionResponse>> {
    return axiosInstancesManager.manager.post('/promotion_banner', params);
  }

  updatePromotion(params: UpdatePromotionParams): Promise<AxiosResponse<UpdatePromotionResponse>> {
    return axiosInstancesManager.manager.patch(`/promotion_banner/${params.id}`, params);
  }

  deletePromotion(id: number): Promise<AxiosResponse<DeletePromotionResponse>> {
    return axiosInstancesManager.manager.delete(`/promotion_banner/${id}`);
  }
}

export default new PromotionApi();
