/* eslint-disable arrow-body-style */
import { CreatePromotionParams } from '@TS/max/Promotion/api';
import styled from 'styled-components';
import { usePromotionContext } from '../usePromotionContext';

type Props = {
  imgFile: File | null;
  setImgFile: React.Dispatch<React.SetStateAction<File | null>>;
  setPromotion: React.Dispatch<React.SetStateAction<CreatePromotionParams>>;
  promotion: CreatePromotionParams;
};

const UploadInputs = ({ imgFile, setImgFile, setPromotion, promotion }: Props): JSX.Element => {
  const { createPromotion, uploadBlobImageAndCallback } = usePromotionContext();

  const UploadImgAngCreatePromotion = async (imgFile: File, promotion: CreatePromotionParams) => {
    const fileUrl = await uploadBlobImageAndCallback(imgFile);
    const newPromotion = { ...promotion, img: fileUrl };
    await createPromotion(newPromotion);
  };

  return (
    <Wrapper>
      <div className="promotion-imageAndURL">
        <label htmlFor="promotion">
          <input
            id="promotion"
            type="file"
            accept="image/*"
            onChange={(e) => setImgFile(e.target.files?.[0] || null)}
          />
          <span>68x68 size</span>
        </label>
        <input
          type="url"
          className="promotion-url"
          placeholder="이동할 링크를 입력해주세요."
          onChange={(e) => setPromotion({ ...promotion, url: e.target.value })}
        />
      </div>
      <textarea
        className="promotion-text"
        placeholder="홍보 문구를 입력하세요."
        onChange={(e) => setPromotion({ ...promotion, content: e.target.value })}
      />
      <SubmitButton
        disabled={!imgFile || !promotion.content || !promotion.url}
        onClick={() => UploadImgAngCreatePromotion(imgFile as File, promotion)}
      >
        등록
      </SubmitButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    max-width: 300px;
    cursor: pointer;

    span {
      color: red;
    }

    input {
      width: 180px;
    }
  }

  .promotion-imageAndURL {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .promotion-url {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 300px;
  }

  .promotion-text {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 300px;
    height: 68px;

    resize: none;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ced4da;
    cursor: not-allowed;
  }
`;

export default UploadInputs;
