import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { actions } from '@P/max/campaigns/redux/campaigns/slice';
import { selectOrderType, selectStatus } from '@P/max/campaigns/redux/campaigns/selector';
import { toggleOrder } from '@FUNC/order';
import type { TableHeadName } from '@TS/max/table/campaignTable';
import { TABLE_HEAD } from '../data';
import TableHeadCell from './TableHeadCell';

function isSortableHead(name: TableHeadName): name is SnakeToCamel<CampaignApi.OrderBy> {
  return !TABLE_HEAD.find((head) => head.name === name)?.notSortable;
}
type SortedHeadCell = [SnakeToCamel<CampaignApi.OrderBy>, OrderType];
const TableHeadRow = (): JSX.Element => {
  const dispatch = useDispatch();
  const [sortingName, sortingOrder] = useSelector(selectOrderType);
  const { campaigns: fetchStatus } = useSelector(selectStatus);

  const sortTableData = useCallback(
    (name: TableHeadName, active: boolean) => () => {
      if (fetchStatus !== 'success') return;
      if (!isSortableHead(name)) return;

      const newOrder: SortedHeadCell = [name, active ? toggleOrder(sortingOrder) : 'DESC'];
      dispatch(actions.fetchStart({ type: 'campaigns' }));
      dispatch(actions.setSortOrder(newOrder));
      dispatch(actions.fetchCampaigns());
    },
    [fetchStatus, sortingOrder, dispatch]
  );

  return (
    <TableHead>
      <TableRow>
        {TABLE_HEAD.map(({ name, label, notSortable, width, align }) => (
          <TableCell key={name} width={width} align={align ?? 'center'} variant="head">
            {notSortable ? (
              <b>{label}</b>
            ) : (
              <TableHeadCell
                value={label}
                isActive={sortingName === name}
                direction={sortingName === name ? sortingOrder : 'DESC'}
                onClick={sortTableData(name, sortingName === name)}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadRow;
