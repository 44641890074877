/* eslint-disable vars-on-top */
import React, { useCallback, useEffect, useState, createContext, useContext } from 'react';
import { AxiosError } from 'axios';
import { useTogglers } from '@HOOK/useTogglers';
import { usePagination } from '@HOOK/usePagination';
import { tableSort } from '@FUNC/order';
import { toastErrorMessage } from '@FUNC/toast';
import { logApi } from '@API/manager';
import { downloadCsv } from '@FUNC/download';
import { SDK_LOG_TABLE_INITIAL_ORDER_BY, Sdk_LOG_TABLE_HEAD_CELLS } from './data';

export type FetchSdkLog = (params: FetchSdkLogParams) => Promise<void>;
export type SdkLog = {
  KST: string;
  log_kind: string;
  sdk_state: string;
  adid: string | null;
  echo_code: string | null;
  os_version: string;
  device_name: string;
  userAgent: string;
};

type SortFetchSdkLogs = (name: keyof SdkLog, orderType: OrderType) => void;

type SdkLogBlocContextType = {
  sdkLogs: SdkLog[];
  pagedSdkLogs: SdkLog[];
  fetchSdkLogs: FetchSdkLog;
  sortSdkLogs: SortFetchSdkLogs;
  fetchStatus: ApiStatus;
  downloadPrimitiveLogs: FetchSdkLog;
  downloadStatus: ApiStatus;
};

export type FetchSdkLogParams = {
  client_id: string;
  start_date: string;
  end_date: string;
  adid?: string;
  echo_code?: string;
};

const SdkLogBlocContext = createContext<SdkLogBlocContextType | undefined>(undefined);

export function SdkLogBlocProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [fetchStatus, setFetchStatus] = useState<ApiStatus>('idle');
  const [downloadStatus, setDownloadStatus] = useState<ApiStatus>('idle');
  const [sdkLogs, setSdkLogs] = useState<SdkLog[]>([]);
  const [pagedSdkLogs, setPagedSdkLogs] = useState<SdkLog[]>([]);
  const collapseTogglers = useTogglers();
  const pagination = usePagination();

  const fetchSdkLogs: FetchSdkLog = useCallback(
    async (params) => {
      try {
        setFetchStatus('loading');

        const { data } = await logApi.getSdkLogQuery(params);
        const { name, orderType } = SDK_LOG_TABLE_INITIAL_ORDER_BY;
        setSdkLogs(tableSort<SdkLog>(data, name, orderType));
        pagination.setTotalCount(data.length);
        pagination.setPage(1);
        setFetchStatus('success');
      } catch (e) {
        setFetchStatus('failure');
        toastErrorMessage(
          e instanceof AxiosError
            ? '요청하신 조건에 해당되는 인식 로그 데이터가 없습니다. 다른 조건으로 검색을 시도해주세요.'
            : '인식 로그 정보 조회에 실패했습니다. 관리자(윤준호)에게 문의 바랍니다.'
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.setTotalCount]
  );

  const downloadPrimitiveLogs = async (params: FetchSdkLogParams) => {
    try {
      setDownloadStatus('loading');
      const data = sdkLogs.map((log) => Object.values(log).join(',')).join('\n');
      const filename = params.adid
        ? `SDK_log-${params.client_id}-${params.adid}.csv`
        : `SDK_log-${params.client_id}-${params.echo_code}.csv`;
      const headers = Sdk_LOG_TABLE_HEAD_CELLS.map((cell) => cell.label).join(',');

      if (!data) {
        throw new Error('데이터가 없습니다.');
      }

      const csvData = `\uFEFF${headers}\n${data}`;

      downloadCsv(csvData, filename);
      setDownloadStatus('success');
    } catch (e) {
      setDownloadStatus('idle');
      toastErrorMessage(
        e instanceof AxiosError
          ? '요청하신 조건에 해당되는 인식 로그 데이터가 없습니다. 다른 조건으로 검색을 시도해주세요.'
          : '인식 로그 정보 조회에 실패했습니다. 관리자(윤준호)에게 문의 바랍니다.'
      );
    }
  };

  const sortSdkLogs: SortFetchSdkLogs = useCallback(
    (name, orderType) => setSdkLogs((prev) => tableSort<SdkLog>([...prev], name, orderType)),
    []
  );

  useEffect(() => {
    const pagedSdkLogs = pagination.pageSlice(sdkLogs);
    setPagedSdkLogs(pagedSdkLogs);
    collapseTogglers.resetStateWithData(pagedSdkLogs);
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdkLogs, pagination, collapseTogglers.resetStateWithData]);

  return (
    <SdkLogBlocContext.Provider
      value={{
        sdkLogs,
        sortSdkLogs,
        fetchSdkLogs,
        pagedSdkLogs,
        fetchStatus,
        downloadPrimitiveLogs,
        downloadStatus
      }}
    >
      {children}
    </SdkLogBlocContext.Provider>
  );
}

export function useSdkLogBloc(): SdkLogBlocContextType {
  const context = useContext(SdkLogBlocContext);

  if (!context) {
    throw new Error('useSdkLogBloc must be used in SdkLogBlocProvider');
  }
  return context;
}
