// NOTE
// naver.maps.KVO의 MapEventListener에서 listener의 인자값이 any로 적용되어 있음.
import { useMemo, useCallback, useEffect, useState } from 'react';
import { callReverseGeocode, makeContent } from './naverMapFunction';

function MarkerControl({ naverMap, marker, setLatitude, setLongitude, setAddr, setRoad }) {
  const [dragEvent, setDragevent] = useState(null);
  const [positionChnageEvent, setPositionChnageEvent] = useState(null);
  const [clickEvent, setClickEvent] = useState(null);

  const infoWindow = useMemo(
    () =>
      new window.naver.maps.InfoWindow({
        anchorSkew: true,
        content: ''
      }),
    []
  );

  const initalDragEvent = useCallback(() => {
    if (dragEvent) {
      return;
    }
    const event = marker.addListener('drag', (e) => {
      infoWindow.close();
      const { x, y } = e.coord;
      setLongitude(x);
      setLatitude(y);
    });
    setDragevent(event);
  }, [dragEvent, infoWindow, marker, setLatitude, setLongitude]);

  const initClickEvent = useCallback(() => {
    if (clickEvent) {
      return;
    }

    const event = marker.addListener('click', async () => {
      infoWindow.close();
      const coords = marker.getPosition();

      const { jibun, road } = await callReverseGeocode({ coords });
      const content = makeContent({
        jibun: jibun.address,
        road: road.address,
        cancelCb: () => {
          infoWindow.close();
        },
        confirmCb: () => {
          setAddr(jibun.address);
          setRoad(road.address);
          infoWindow.close();
        }
      });
      infoWindow.setContent(content);
      infoWindow.open(naverMap, marker);
    });
    setClickEvent(event);
  }, [clickEvent, infoWindow, marker, naverMap, setAddr, setRoad]);

  const initPositionChnageEvent = useCallback(() => {
    if (positionChnageEvent) {
      return;
    }
    const event = marker.addListener('position_changed', (e) => {
      infoWindow.close();
      const { x, y } = e;
      setLongitude(x);
      setLatitude(y);
    });
    setPositionChnageEvent(event);
  }, [infoWindow, marker, positionChnageEvent, setLatitude, setLongitude]);

  useEffect(() => {
    initalDragEvent();

    return () => {
      marker.removeListener(dragEvent);
    };
  }, [dragEvent, initalDragEvent, marker]);

  useEffect(() => {
    initClickEvent();

    return () => {
      marker.removeListener(clickEvent);
    };
  }, [clickEvent, initClickEvent, marker]);

  useEffect(() => {
    initPositionChnageEvent();

    return () => {
      marker.removeListener(positionChnageEvent);
    };
  }, [positionChnageEvent, initPositionChnageEvent, marker]);

  useEffect(() => {}, [marker]);

  return null;
}

export default MarkerControl;
