export const areaType = {
  all: '일반',
  home: '주거지',
  office: '근무지'
};

export const levelOneCodeList = [
  {
    code: '11',
    name: '서울특별시'
  },
  {
    code: '26',
    name: '부산광역시'
  },
  {
    code: '27',
    name: '대구광역시'
  },
  {
    code: '28',
    name: '인천광역시'
  },
  {
    code: '29',
    name: '광주광역시'
  },
  {
    code: '30',
    name: '대전광역시'
  },
  {
    code: '31',
    name: '울산광역시'
  },
  {
    code: '36',
    name: '세종특별자치시'
  },
  {
    code: '41',
    name: '경기도'
  },
  {
    code: '43',
    name: '충청북도'
  },
  {
    code: '44',
    name: '충청남도'
  },
  {
    code: '52',
    name: '전라북도'
  },
  {
    code: '46',
    name: '전라남도'
  },
  {
    code: '47',
    name: '경상북도'
  },
  {
    code: '48',
    name: '경상남도'
  },
  {
    code: '50',
    name: '제주특별자치도'
  },
  {
    code: '51',
    name: '강원도'
  }
];
