import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import LinkIcon from '@material-ui/icons/Link';
import Red from '@material-ui/core/colors/red';
import Blue from '@material-ui/core/colors/blue';
import Loading from '@F/Loading';
import StatusLabel from '@F/StatusLabel';
import PaginationRow from '@F/table/PaginationRow';
import EnhancedTable from '@F/table/Table';
import { createHeadCells } from '@F/table/Table/functions';
import { Column } from '@TS/columns';
import { Contact } from '@TS/cashplace/contact';
import { useAppSelector } from '@HOOK/useSelector';
import { actions } from '@/redux/cashplace/contact/state';
import Detail from '../Detail';
import * as S from './styles';

const columns: Column[] = [
  { name: 'state', label: '상태' },
  { name: 'id', label: 'Contact ID', isImportant: true },
  { name: 'userId', label: 'User ID' },
  { name: 'username', label: '유저명' },
  { name: 'content', label: '내용' },
  { name: 'reportId', label: 'report ID' },
  { name: 'studyId', label: 'Study ID' },
  { name: 'device', label: '기종' },
  { name: 'checkedAt', label: '체크일시' },
  { name: 'createdAt', label: '생성일시' }
];

function TableSection(): JSX.Element {
  const { isLoading, page, perPage, count, contacts, openedContactIds } = useAppSelector(
    (state) => ({
      isLoading: state.contact.isLoading,
      page: state.contact.page,
      perPage: state.contact.perPage,
      count: state.contact.count,
      contacts: state.contact.contacts,
      openedContactIds: state.contact.openedContactIds
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchContacts());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchContacts());
  }, [dispatch, page]);

  const setOpenedContactIds = useCallback(
    (newOpenedContactIds) => {
      dispatch(actions.setValue('openedContactIds', newOpenedContactIds));
    },
    [dispatch]
  );

  const onClickUserIdLink = (e: React.MouseEvent<SVGSVGElement>, userId: number) => {
    e.stopPropagation();
    window.open(`/cashplace/inspect?userId=${userId}`);
  };

  const headCells = columns.map((column) => createHeadCells(column.name, column.label));
  const rows = useMemo(
    () =>
      contacts?.map((contact: Contact) => ({
        state: {
          value: contact.state,
          component: formatStateLabel(contact.state)
        },
        id: contact.contact_id,
        userId: {
          value: contact.user.user_id,
          component: (
            <S.UserId>
              <p>{contact.user.user_id}</p>
              <LinkIcon onClick={(e) => onClickUserIdLink(e, contact.user.user_id)} />
            </S.UserId>
          )
        },
        username: contact.user.username,
        content: {
          value: contact.content,
          component: (
            <S.Content>
              <h4>{contact.title}</h4>
              <p>{contact.content}</p>
            </S.Content>
          )
        },
        reportId: contact.report_id,
        studyId: contact.study_id === 0 ? null : contact.study_id,
        device: contact.device,
        checkedAt: contact.checked_at,
        createdAt: contact.created_at
      })) ?? [],
    [contacts]
  );

  return (
    <S.StyledTableSection>
      {isLoading && <Loading />}
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        rows={rows}
        headCells={headCells}
        isCheckable={false}
        isCollapsible
        opened={openedContactIds}
        setOpened={setOpenedContactIds}
      >
        <Detail data={contacts} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

function formatStateLabel(state: number): React.ReactNode {
  switch (state) {
    case 1:
      return (
        <StatusLabel color={Blue[800]} backgroundColor={Blue[200]}>
          확인됨
        </StatusLabel>
      );
    case 0:
    default:
      return (
        <StatusLabel color={Red[800]} backgroundColor={Red[200]}>
          미확인
        </StatusLabel>
      );
  }
}
