import { useCallback, useEffect, useMemo, useState } from 'react';
import PaginationRow from '@F/table/PaginationRow/index';
import EnhancedTable from '@F/table/Table/index';
import { createHeadCells } from '@F/table/Table/functions';
import { shallowEqual, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import ModalWrapper from '@F/modal/ModalWrapper';
import { BulkCreditForm, User } from '@TS/cashplace/users';
import { Column } from '@TS/columns';
import { useAppSelector } from '@HOOK/useSelector';
import { actions } from '@/redux/cashplace/user/state';
import Detail from '../Detail';
import BulkModal from '../BulkModal';
import * as S from './styles';

const columns: Column[] = [
  { name: 'id', label: 'USER ID' },
  { name: 'name', label: '유저명' },
  { name: 'availableCredit', label: '사용 가능 금액' },
  { name: 'pendingCredit', label: '검수 대기중인 금액' },
  { name: 'email', label: '이메일' },
  { name: 'registeredDate', label: '가입일시' },
  { name: 'lastLoginedDate', label: '최근 로그인' }
];

function TableSection(): JSX.Element {
  const { page, perPage, count, users, openedUsersIds, failed } = useAppSelector(
    (state) => ({
      page: state.user.page,
      perPage: state.user.perPage,
      count: state.user.count,
      users: state.user.users,
      openedUsersIds: state.user.openedUsersIds,
      failed: state.user.failed
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchUsers());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchUsers());
  }, [dispatch, page]);

  const headCells = columns.map((column) => createHeadCells(column.name, column.label));
  const rows = useMemo(
    () =>
      users?.map((item: User) => ({
        id: item.user_id,
        name: item.username,
        avaliableCredit: item.credit.toLocaleString(),
        pendingCredit: item.pending.toLocaleString(),
        email: item.email,
        registeredDate: item.created_at,
        lastLoginedDate: item.last_login
      })),
    [users]
  );

  const setOpenedUsersIds = useCallback(
    (newOpenedUsersIds) => {
      dispatch(actions.setValue('openedUsersIds', newOpenedUsersIds));
    },
    [dispatch]
  );

  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const CsvUploadButton = (
    <Button
      key="CsvUploadButton"
      style={{ marginRight: '10px' }}
      variant="outlined"
      color="primary"
      onClick={() => setIsBulkModalOpen((prevState) => !prevState)}
    >
      Bulk 크레딧 추가
    </Button>
  );

  const [bulkParams, setBulkParams] = useState<BulkCreditForm>();
  const updateUsers = useCallback(() => {
    dispatch(actions.updateUsers(bulkParams));
  }, [dispatch, bulkParams]);

  const isLoading = useAppSelector((state) => state.user.isLoading);

  return (
    <S.StyledTableSection>
      <ModalWrapper
        open={isBulkModalOpen}
        setOpen={setIsBulkModalOpen}
        confirm={
          failed === null
            ? updateUsers
            : () => {
                setIsBulkModalOpen(false);
                dispatch(actions.setValue('failed', null));
              }
        }
        isLoading={isLoading}
      >
        <BulkModal onConfirm={setBulkParams} failed={failed} />
      </ModalWrapper>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <S.ActionBar>{CsvUploadButton}</S.ActionBar>
      <EnhancedTable
        rows={rows}
        headCells={headCells}
        initialOrder="desc"
        initialOrderBy="carbs"
        isCollapsible
        isCheckable={false}
        opened={openedUsersIds}
        setOpened={setOpenedUsersIds}
      >
        <Detail />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;
