import type { AxiosResponse } from 'axios';

import { axiosInstancesManager } from '@API/axios';
import { GetCompaniesResponse } from '@TS/max/company/api';

class MaxOperationCompanyApi {
  getOperationCompany(): Promise<AxiosResponse<GetCompaniesResponse>> {
    return axiosInstancesManager.max.get('/manager/companies/operation');
  }

  setOperationCompany(company: string): Promise<AxiosResponse<GetCompaniesResponse>> {
    return axiosInstancesManager.max.post('/manager/companies/operation', {
      company
    });
  }
}

export default new MaxOperationCompanyApi();
