import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { SdkConfiguration } from '@API/manager/sdk/types';

import SdkConfigForm from './components/SdkConfigForm';
import { useManageSdkConfigs } from './useManageCompanies';
import SdkConfigFormLoading from './components/SdkConfigFormLoading';

export default function CompanyDetailPage(): JSX.Element | null {
  const { id } = useParams();
  const navigate = useNavigate();
  const { sdkConfigs, getSdkConfig } = useManageSdkConfigs();
  const [sdkConfig, setSdkConfig] = useState<SdkConfiguration | null>(null);

  useEffect(() => {
    document.querySelector('main')?.scrollTo({
      top: 0
    });

    if (!id) return;

    const cachedSdkConfig = sdkConfigs.find((sdkConfig) => sdkConfig.id === +id);

    if (cachedSdkConfig) {
      setSdkConfig(cachedSdkConfig);
    } else {
      getSdkConfig(+id).then((sdkConfig) => {
        if (sdkConfig) {
          setSdkConfig(sdkConfig);
        } else {
          setSdkConfig(null);
          navigate('/x/companies');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, sdkConfigs]);

  if (sdkConfig === null) {
    return <SdkConfigFormLoading open={!sdkConfig} />;
  }

  return (
    <SdkConfigForm
      initialValues={{ name: sdkConfig.name, ...sdkConfig.configuration }}
      renderToolbar={(): JSX.Element => (
        <Grid container justifyContent="flex-end" direction="row" spacing={1}>
          <Grid item>
            <Button onClick={() => navigate('/x/companies')} color="primary" variant="contained">
              목록으로
            </Button>
          </Grid>
        </Grid>
      )}
      readOnly
    />
  );
}
