import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { Grid, Button, colors } from '@material-ui/core';
import { ConfigurationForm, SdkConfiguration } from '@API/manager/sdk/types';
import { toastErrorMessage, toastSuccessMessage, toastWarningMessage } from '@FUNC/toast';
import ConfirmModal from '@F/modal/ConfirmModal';

import SdkConfigForm from './components/SdkConfigForm';
import { useManageSdkConfigs } from './useManageCompanies';
import SdkConfigFormLoading from './components/SdkConfigFormLoading';
import { validateConfigurationFormValues } from './validation';

let saveController: AbortController | undefined;

export default function CompanyEditPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { updateSdkConfig, getSdkConfig, deleteSdkConfig, deleteApiStatus } = useManageSdkConfigs();
  const { id } = useParams();
  const [sdkConfig, setSdkConfig] = useState<SdkConfiguration | null>(null);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tempSaveApiStatus, setTempSaveApiStatus] = useState<ApiStatus>('idle');
  const [saveApiStatus, setSaveApiStatus] = useState<ApiStatus>('idle');

  useEffect(() => {
    document.querySelector('main')?.scrollTo({
      top: 0
    });

    if (!id) return;

    getSdkConfig(+id).then((sdkConfig) => {
      if (sdkConfig) {
        if (sdkConfig.published_at) {
          setSdkConfig(null);
          toastWarningMessage(`작성완료된 sdk 회사 정보는 수정할 수 없습니다.`);
          navigate('/x/companies');
        } else {
          setSdkConfig(sdkConfig);
        }
      } else {
        setSdkConfig(null);
        navigate('/x/companies');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);

  if (!id) {
    navigate('/x/companies');
    return null;
  }

  // 임시저장
  const handleTempSave = async (formValues: ConfigurationForm) => {
    setTempSaveApiStatus('loading');
    updateSdkConfig(+id, { ...formValues, publish: false }).then((updated) => {
      if (updated) {
        setSdkConfig(updated);
        toastSuccessMessage('성공적으로 임시 저장하였습니다.');
        setTempSaveApiStatus('success');
      } else {
        setTempSaveApiStatus('failure');
      }
    });
  };

  // 작성완료
  const handleSave = async (formValues: ConfigurationForm) => {
    if (saveController) {
      saveController.abort();
    }

    const errorMessage = validateConfigurationFormValues(formValues);

    if (errorMessage) {
      toastErrorMessage(errorMessage);
      return;
    }

    saveController = new AbortController();

    setSaveApiStatus('loading');
    updateSdkConfig(+id, { ...formValues, publish: true }, saveController?.signal).then((updated) => {
      if (updated) {
        toastSuccessMessage('고객사 정보를 성공적으로 등록하였습니다.');
        navigate(`/x/companies/${updated.id}`);
      } else {
        setSaveApiStatus('failure');
        saveController = undefined;
      }
    });
  };

  // 삭제
  const handlePermitDelete = async () => {
    deleteSdkConfig(+id).then((isSuccess) => isSuccess && navigate(`/x/companies`));
  };

  if (sdkConfig === null) return <SdkConfigFormLoading open={!sdkConfig} />;

  return (
    <SdkConfigForm
      initialValues={{ name: sdkConfig.name, ...sdkConfig.configuration }}
      renderToolbar={(formValues, isDirty): JSX.Element => (
        <Grid container justifyContent="flex-end" direction="row" spacing={1}>
          <Grid item>
            <Button onClick={() => navigate('/x/companies')} color="primary" variant="contained">
              목록으로
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleTempSave(formValues)}
              color="primary"
              variant="contained"
              disabled={!isDirty || tempSaveApiStatus === 'loading'}
            >
              {tempSaveApiStatus === 'loading' ? (
                <div style={{ height: '24.5px' }}>
                  <div style={{ marginTop: '-8px' }}>
                    <ReactLoading type="cylon" color={colors.indigo[600]} height={18} width={40} />
                  </div>
                </div>
              ) : (
                '임시저장'
              )}
            </Button>
          </Grid>
          {sdkConfig.published_at ? null : (
            <Grid item>
              <Button
                onClick={() => setOpenDeleteModal(true)}
                color="secondary"
                variant="contained"
                disabled={deleteApiStatus === 'loading'}
              >
                삭제
              </Button>
              <ConfirmModal
                isOpen={openDeleteModal}
                onPermit={() => handlePermitDelete()}
                onCancel={() => setOpenDeleteModal(false)}
                title={
                  deleteApiStatus === 'loading'
                    ? '고객사 정보를 삭제하는 중입니다...'
                    : '고객사 정보를 삭제하시겠습니까?'
                }
                subTitle={
                  deleteApiStatus === 'loading'
                    ? '잠시만 기다려주세요.'
                    : deleteApiStatus === 'failure'
                    ? '요청에 실패했습니다. 다시 시도해주세요.'
                    : ''
                }
                permitText="삭제"
                disabled={deleteApiStatus === 'loading'}
              />
            </Grid>
          )}
          <Grid item>
            <Button onClick={() => setOpenPublishModal(true)} color="primary" variant="contained">
              작성완료
            </Button>
            <ConfirmModal
              isOpen={openPublishModal}
              title={
                saveApiStatus === 'loading' ? '고객사 정보를 등록하는 중입니다...' : '고객사 정보를 등록하시겠습니까?'
              }
              subTitle={
                saveApiStatus === 'loading'
                  ? '잠시만 기다려주세요.'
                  : saveApiStatus === 'failure'
                  ? '요청에 실패했습니다. 다시 시도해주세요.'
                  : '고객사 정보 등록 시 관리 탭을 통해 해당 정보를 삭제/수정할 수 없습니다.'
              }
              permitText="등록"
              onPermit={() => handleSave(formValues)}
              onCancel={() => setOpenPublishModal(false)}
            />
          </Grid>
        </Grid>
      )}
    />
  );
}
