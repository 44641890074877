import dayjs from 'dayjs';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton, TableRow, TableCell } from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import { SdkConfiguration } from '@API/manager/sdk/types';

export default function CompanyTableItem({ sdkConfig }: { sdkConfig: SdkConfiguration }): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { id, name, client_id, created_at, updated_at, published_at } = sdkConfig;

  const bodyCells = {
    id: Number(id),
    name,
    client_id,
    updated_at: dayjs(updated_at).format('YYYY-MM-DD HH:mm:ss'),
    created_at: dayjs(created_at).format('YYYY-MM-DD HH:mm:ss'),
    status: published_at ? (
      '작성 완료'
    ) : (
      <div>
        <span>작성중</span>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();

            navigate(`/x/companies/edit/${id}`);
          }}
        >
          <EditRounded />
        </IconButton>
      </div>
    )
  };

  return (
    <StyledTableRow hover onClick={() => navigate(`${pathname}/${id}`)}>
      {Object.entries(bodyCells).map(([name, value]) => (
        <TableCell key={name} align="center">
          {value}
        </TableCell>
      ))}
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;
