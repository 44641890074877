import { useEffect, useRef } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTogglers } from '@HOOK/useTogglers';
import ToggleHeadCell from '@F/table/cells/ToggleHeadCell';
import TableHeadCell from '@F/table/cells/TableHeadCell';
import Wrapper from '@F/materialUI/Wrapper';
import { actions } from '@/redux/fingerprint/fpManager/state';
import FingerprintTableItem from '../TableItem';
import { FINGERPRINT_TABLE_HEAD_CELLS } from '../../data';

function TableSection({ selectedColumns }) {
  const { fingerprints, params, fingerprintsUpdateStatus } = useSelector((state) => ({
    count: state.fpManager.count,
    fingerprints: state.fpManager.fingerprints,
    fingerprintsUpdateStatus: state.fpManager.fingerprintsUpdateStatus,
    params: state.fpManager.params
  }));

  const dispatch = useDispatch();

  // collapse
  const collapseTogglers = useTogglers();
  const tableScrollViewRef = useRef();

  useEffect(() => {
    if (fingerprints.length && fingerprintsUpdateStatus === 'RESET') {
      collapseTogglers.resetStateWithData(fingerprints);
      collapseTogglers.setOffAll();
      tableScrollViewRef.current?.scroll(0, 0);
    }
  }, [fingerprints]);

  // sort
  const handleClickHeadCell = (_, name, toggledOrderType) => {
    if (!name || !toggledOrderType) return;

    dispatch(
      actions.setValue('params', {
        ...params,
        order: toggledOrderType,
        orderBy: name
      })
    );
    dispatch(actions.fetchFingerprints());
  };

  const columns = FINGERPRINT_TABLE_HEAD_CELLS.filter(({ name }) => selectedColumns[name]);

  return (
    <div ref={tableScrollViewRef}>
      <Wrapper>
        <Table>
          <TableHead>
            <TableRow>
              <ToggleHeadCell />
              {columns.map(({ name, label, defaultOrderType }, i) => (
                <TableHeadCell
                  key={i}
                  name={name}
                  label={label}
                  defaultOrderType={defaultOrderType}
                  onClick={handleClickHeadCell}
                  isActive={Boolean(name === params.orderBy)}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fingerprints.length ? (
              fingerprints.map((fingerprint, i) => (
                <FingerprintTableItem
                  key={fingerprint.fp_fpid}
                  fingerprint={fingerprint}
                  selectedColumns={selectedColumns}
                  onClick={(_) => collapseTogglers.toggle(i)}
                  isExpanded={collapseTogglers.state[i]}
                  columns={columns}
                  tableScrollViewRef={tableScrollViewRef}
                />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={columns.length + 1}>
                  <span>검색된 결과가 없습니다.</span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Wrapper>
    </div>
  );
}

export default TableSection;
