import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Wrapper from '@F/materialUI/Wrapper';

export default function FormSection({ title, children }: { title: string; children: React.ReactNode }): JSX.Element {
  return (
    <Wrapper>
      <StyledFormSection>
        <h2>{title}</h2>
        <Grid container direction="row" alignItems="center" spacing={2}>
          {children}
        </Grid>
      </StyledFormSection>
    </Wrapper>
  );
}

const StyledFormSection = styled.div`
  padding: 20px;

  h2 {
    margin-bottom: 20px;
  }

  margin-bottom: 30px;
`;
