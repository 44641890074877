import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CustomizingColumnsDialog from '@F/table/CustomizingColumnsDialog';
import { format } from '@FUNC/math';
import TablePagination from '@F/table/TablePagination';
import { useTogglers } from '@HOOK/useTogglers';
import { actions } from '@/redux/fingerprint/fpManager/state';
import { FINGERPRINT_TABLE_HEAD_CELLS } from './data';

export default function Toolbar({ selectedColumns, setSelectedColumns }) {
  const dispatch = useDispatch();
  const { page, perPage, count } = useSelector((state) => ({
    page: state.fpManager.page,
    perPage: state.fpManager.perPage,
    count: state.fpManager.count,
    fingerprints: state.fpManager.fingerprints,
    params: state.fpManager.params
  }));

  // select custom columns
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

  // pagination
  const togglers = useTogglers();

  useEffect(() => {
    togglers.setOffAll();
    dispatch(actions.fetchFingerprints());
  }, [dispatch, page]);

  const setPage = (newPage) => {
    if (page === newPage) dispatch(actions.fetchFingerprints());
    else dispatch(actions.setPage(newPage));
  };

  // csv download
  const downloadCsv = () => dispatch(actions.fetchFingerprintsCsv());

  return (
    <StyledToolbar>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            key="customizingColumnsButton"
            variant="outlined"
            color="primary"
            onClick={() => setIsCustomModalOpen(true)}
          >
            column 보이기/숨기기
          </Button>
          <CustomizingColumnsDialog
            open={isCustomModalOpen}
            setOpen={setIsCustomModalOpen}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columns={FINGERPRINT_TABLE_HEAD_CELLS}
          />
          <Button variant="outlined" color="primary" onClick={downloadCsv}>
            CSV 다운로드
          </Button>
        </Grid>
        <Grid item>
          <TablePagination count={Math.ceil(count / perPage)} page={page} setPage={setPage} />
        </Grid>
        <Grid item>
          {`Displaying ${(page - 1) * perPage + 1} to ${Math.min(page * perPage, count)} of ${format(count)} items`}
        </Grid>
      </Grid>
    </StyledToolbar>
  );
}

const StyledToolbar = styled.div`
  margin-bottom: 10px;
  padding: 10px 0;
`;
