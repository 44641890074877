import { call, put, debounce, select, takeLeading } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { maxCampaignApi } from '@API/max';
import { snakeObjToCamelObj } from '@FUNC/word';
import type { ManagerApiError } from '@TS/customError';
import type { CampaignDetailResponse } from '@TS/max/campaigns/api';
import type { State, TwoCampaignId, StateType } from './slice';
import { actions, CampaignItem } from './slice';
import { selectParams } from './selector';

type Payload<P> = { payload: P };

function* fetchCampaigns() {
  try {
    yield put(actions.fetchStart({ type: 'campaigns' }));
    const parameters: State['params'] = yield select(selectParams);
    const response: AxiosResponse<CampaignApi.GetCampaign.List.Response> = yield call(
      maxCampaignApi.getCampaigns,
      parameters
    );
    const { total_count: totalCount, result } = response.data;
    const campaigns = result.map((item) => snakeObjToCamelObj(item)) as CampaignItem[];
    yield put(actions.fetchCampaignsSuccess({ totalCount, campaigns }));
  } catch (error) {
    yield sagaErrorHandler(error, 'campaigns');
  }
}

function* getCampaignById({ payload: cid }: Payload<number>) {
  try {
    yield put(actions.clearCampaignDetails());
    yield put(actions.fetchStart({ type: 'campaign' }));
    const response: AxiosResponse<CampaignDetailResponse> = yield call(maxCampaignApi.getCampaign, { cid });
    const camelResult = snakeObjToCamelObj(response.data) as SnakeToCamelObj<CampaignDetailResponse>;
    yield put(actions.fetchCampaignSuccess(camelResult));
  } catch (error) {
    yield sagaErrorHandler(error, 'campaign');
  }
}

function* getTwoCampaigns({ payload: { cidA, cidB } }: Payload<TwoCampaignId>) {
  try {
    yield put(actions.clearCampaignDetails());
    yield put(actions.fetchStart({ type: 'campaign' }));
    const response: AxiosResponse<CampaignDetailResponse>[] = yield call(maxCampaignApi.getTwoCampaign, {
      cidA,
      cidB
    });
    const camelResults = response.map(({ data }) =>
      snakeObjToCamelObj(data)
    ) as SnakeToCamelObj<CampaignDetailResponse>[];
    yield put(actions.fetchCompareCampaignsSuccess(camelResults));
  } catch (error) {
    yield sagaErrorHandler(error, 'campaign');
  }
}

export default function* rootSaga(): Generator {
  yield debounce(500, actions.fetchCampaigns, fetchCampaigns);

  yield takeLeading(actions.fetchCompareCampaigns, getTwoCampaigns);
  yield takeLeading(actions.fetchCampaignById, getCampaignById);
}

function* sagaErrorHandler(error: unknown, type: StateType) {
  if (error instanceof AxiosError) {
    const maxError = error as AxiosError<ManagerApiError>;
    if (!maxError.response) throw new Error('axios에 headers가 없음');
    yield put(actions.fetchFailure({ error: maxError.response.data, type }));
  }
}
