import { GeoGroupIcon, PolygonIcon, CircleIcon } from 'loplat-ui';
import Tooltip from '@F/Tooltip';
import { HTMLAttributes } from 'react';
import { Wrapper } from './index.styles';

type GeofenceModuleType = {
  type: 'single' | 'group';
  shape?: Campaign.Scheme.Trigger.GeofenceScheme.Single['shape_type'];
  count?: number;
} & Campaign.Scheme.Trigger.GeofenceScheme.Common &
  HTMLAttributes<HTMLDivElement>;
const GeofenceModule = ({ type, shape, name, active, count = 1, ...props }: GeofenceModuleType): JSX.Element => (
  <Tooltip title={`${count.toLocaleString()}개 ${active === 0 ? '비활성화' : '활성화'}`}>
    <Wrapper {...props}>
      {type === 'group' ? (
        <GeoGroupIcon size={20} />
      ) : shape === 0 ? (
        <PolygonIcon size={20} />
      ) : (
        <CircleIcon size={20} />
      )}
      {name}
    </Wrapper>
  </Tooltip>
);

export default GeofenceModule;
