import styled from 'styled-components';

export const StyledBulkModal = styled.div`
  margin-bottom: 20px;
  padding-top: 30px;
`;

export const Row = styled.div`
  margin-bottom: 10px;
`;

export const CsvLabel = styled.label`
  color: ${(props: { isError: boolean }) => (props.isError ? 'red' : 'black')};
  font-weight: bolder;
`;
