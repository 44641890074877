import { useEffect, useMemo, useState } from 'react';
import NaverMap from '@F/map/NaverMap';
import PinBlue from '@I/map/pin-blue.png';
import PinRed from '@I/map/pin-red.png';
import { Report } from '@TS/cashplace/report';
import * as S from './styles';
import { kindOptions } from '../../options';

/*

원래 Site 위경도 : report.site.lat
유저 위경도 : report.c_lat
유저가 지정한 위경도 : report.lat

폐점
- nid
- 원래 Site 위경도 🧭 blue
- 유저 위경도 🧭 red
- 유저 코멘트

위치수정
- nid
- 원래 Site 위경도 🧭 blue
- 유저가 지정한 위경도 🧭 red
- POI Status, State, Watching

*/

type Pos = {
  lat: number;
  lng: number;
};
type MapSectionProps = {
  report: Report;
  onChangeReportLatLng: (naverLatLng: naver.maps.LatLng) => void;
};

function MapSection({ report, onChangeReportLatLng }: MapSectionProps): JSX.Element {
  const [bounds, setBounds] = useState({
    getSW(): DOMRect {
      return new DOMRect();
    },
    getNE(): DOMRect {
      return new DOMRect();
    }
  });

  const sitePos: Pos = useMemo(
    () => ({ lat: report.site.lat, lng: report.site.lng }),
    [report.site.lat, report.site.lng]
  );
  const fixedSitePos: Pos = useMemo(() => ({ lat: report.lat, lng: report.lng }), [report.lat, report.lng]);
  const userPos: Pos = useMemo(() => ({ lat: report.c_lat, lng: report.c_lng }), [report.c_lat, report.c_lng]);

  const siteMarker = useMemo(
    () => ({
      ...sitePos,
      image: PinBlue,
      size: [13 * 2, 17 * 2]
    }),
    [sitePos]
  );

  // type === 위치수정 ? 유저가 지정한 위경도 (lat): 유저 위경도 (c_lat)

  const fixedSiteMarker = useMemo(
    () => ({
      ...fixedSitePos,
      image: PinRed,
      size: [13 * 2, 17 * 2]
    }),
    [fixedSitePos]
  );

  const userMarker = useMemo(
    () => ({
      ...userPos,
      image: PinRed,
      size: [13 * 2, 17 * 2]
    }),
    [userPos]
  );

  const [zoom, setZoom] = useState(21);
  const center = useMemo(() => {
    const getCenter = (p1: Pos, p2: Pos): Pos => ({
      lat: (p1.lat + p2.lat) / 2,
      lng: (p1.lng + p2.lng) / 2
    });

    const center =
      report.kind === kindOptions['위치 수정']
        ? getCenter(sitePos, fixedSitePos)
        : userPos.lat
        ? getCenter(userPos, sitePos)
        : sitePos;

    return center;
  }, [sitePos, userPos, fixedSitePos, report.kind]);

  useEffect(() => {
    if (bounds && report && Object.entries(bounds).length !== 0) {
      const minCoords = bounds.getSW();
      const maxCoords = bounds.getNE();
      if (
        (minCoords.x > report.site.lng ||
          maxCoords.x < report.site.lng ||
          minCoords.y > report.site.lat ||
          maxCoords.y < report.site.lat) &&
        zoom > 10
      ) {
        setZoom((prevState) => prevState - 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds, report]);

  return (
    <S.StyledMapSection>
      <NaverMap
        id={report.report_id}
        center={center}
        markers={[siteMarker, userMarker, fixedSiteMarker].filter(Boolean)}
        zoom={zoom}
        triggerEmitBoundary
        onEmitBoundary={setBounds}
        onEmitCoordinates={onChangeReportLatLng}
      />
    </S.StyledMapSection>
  );
}
export default MapSection;
