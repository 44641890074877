import { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import DrawingManagerTool from '@F/map/DrawingManagerTool';
import Loading from '@F/Loading';
import Markers from '@F/map/Markers';
import * as S from './styles';

const defaultCenter = { lat: 37.5666103, lng: 126.9783882 };

function NaverMap({
  id,
  center,
  polygon: rawPolygon,
  onEmitBoundary,
  triggerEmitBoundary,
  setTriggerEmitBoundary,
  hasDrawingManager,
  onEmitPolygon,
  onEmitCoordinates,
  markers,
  zoom,
  children
}) {
  const [map, setMap] = useState();
  const isLoading = useMemo(() => (rawPolygon ? rawPolygon.isLoading : false), [rawPolygon]);
  const mapId = useMemo(() => `NaverMap_${id}`, [id]);

  const loadMap = useCallback(() => {
    const initialMap = new window.naver.maps.Map(mapId, {
      ...mapOptions(defaultCenter, zoom)
    });
    setMap(initialMap);
  }, [mapId, zoom]);

  useEffect(() => {
    let listener;
    if (map && onEmitCoordinates) {
      listener = window.naver.maps.Event.addListener(map, 'click', (e) => {
        onEmitCoordinates(e.coord); // TODO: naver LatLng 객체가 아닌 [lat, lng](또는 {lat, lng}) 형태로 emit 시키기
      });
    }
    return () => {
      window.naver.maps.Event.removeListener(listener);
    };
  }, [map, onEmitCoordinates]);

  useEffect(() => {
    if (map) {
      map.setCenter(center || defaultCenter);
    }
  }, [map, center]);

  useEffect(() => {
    if (map && triggerEmitBoundary) {
      onEmitBoundary(map.getBounds());
      setTriggerEmitBoundary(false);
    }
  }, [map, onEmitBoundary, triggerEmitBoundary, setTriggerEmitBoundary]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (rawPolygon === undefined || rawPolygon.length === 0) {
      return;
    }

    if (rawPolygon.isLoading || !rawPolygon.hasData) {
      return;
    }

    const polygonData = rawPolygon.data.polygon;

    const features = polygonData.flatMap((row) => {
      const isMultiPolygon = row?.type === 'MultiPolygon' || row?.type === 'Polygon';
      if (isMultiPolygon) {
        return [
          {
            type: 'Feature',
            geometry: row
          }
        ];
      }

      if (row.length < 0) {
        return [];
      }

      return row.map((item) => ({
        type: 'Feature',
        geometry: item
      }));
    });

    const geoJson = {
      type: 'FeatureCollection',
      features
    };

    map.data.addGeoJson(geoJson);
    map.data.setStyle(() => {
      const color = 'red';
      return {
        fillColor: color,
        strokeColor: color,
        strokeWeight: 2,
        icon: null,
        clickable: false
      };
    });
  }, [map, rawPolygon]);

  useEffect(() => {
    loadMap();
  }, [loadMap]);

  return (
    <S.StyledNaverMap>
      <S.NaverMap id={mapId} />
      {children}
      {hasDrawingManager && <DrawingManagerTool map={map} onEmitPolygon={onEmitPolygon} />}
      {markers && <Markers map={map} markers={markers} />}
      {isLoading && (
        <S.LoadingWrapper>
          <Loading />
        </S.LoadingWrapper>
      )}
    </S.StyledNaverMap>
  );
}
export default NaverMap;

NaverMap.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  polygon: PropTypes.object,
  onEmitBoundary: PropTypes.func,
  triggerEmitBoundary: PropTypes.bool,
  setTriggerEmitBoundary: PropTypes.func,
  hasDrawingManager: PropTypes.bool,
  onEmitPolygon: PropTypes.func,
  onEmitCoordinates: PropTypes.func,
  markers: PropTypes.arrayOf(PropTypes.any),
  zoom: PropTypes.number,
  children: PropTypes.element
};

NaverMap.defaultProps = {
  center: defaultCenter,
  onEmitBoundary: () => {},
  triggerEmitBoundary: false,
  setTriggerEmitBoundary: () => {},
  hasDrawingManager: false,
  onEmitPolygon: () => {},
  onEmitCoordinates: null,
  polygon: [],
  markers: null,
  zoom: 16,
  children: null
};

const mapOptions = (center, zoom) => ({
  zoom,
  zoomControl: true,
  center: new window.naver.maps.LatLng(center.lat, center.lng),
  tileSpare: 5
});
