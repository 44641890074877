import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from '@material-ui/core';
import { PushPinIcon, CloseIcon, blue500, red200 } from 'loplat-ui';
import { useSideMenu } from '@HOOK/useSideMenu';

export default function MenuListItem({ routeNode, parentRouteNode, onClickMenuLinkItem, disabled = false }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { isFavoriteRouteNode, addFavoriteRouteNode, removeFavoriteRouteNode } = useSideMenu();

  const favoriteRouteNode = {
    ...routeNode,
    permission: routeNode.permission || parentRouteNode.permission
  };

  const isFavorite = isFavoriteRouteNode(favoriteRouteNode);

  return (
    <StyledListItemWrapper>
      <ListItem
        className="list-item"
        button
        disabled={disabled}
        onClick={() => {
          if (routeNode.type === 'link') {
            window.open(routeNode.path);
          } else {
            navigate(routeNode.path);
          }

          if (onClickMenuLinkItem) {
            onClickMenuLinkItem();
          }
        }}
        selected={location.pathname === routeNode.path}
      >
        <ListItemText primary={routeNode.label} />
      </ListItem>
      <IconButton
        disabled={disabled}
        className="pinButton"
        onClick={(e) =>
          isFavorite ? removeFavoriteRouteNode(favoriteRouteNode) : addFavoriteRouteNode(favoriteRouteNode)
        }
      >
        {disabled ? null : (
          <StyledPinIconWrapper>
            <PushPinIcon size={16} fillColor={isFavorite ? red200 : blue500} />
            {isFavorite ? (
              <div className="closeIcon">
                <CloseIcon size={16} fillColor={isFavorite ? red200 : blue500} />
              </div>
            ) : null}
          </StyledPinIconWrapper>
        )}
      </IconButton>
    </StyledListItemWrapper>
  );
}

const StyledListItemWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 240px;

  :hover {
    .pinButton {
      visibility: visible;
    }
  }

  .pinButton {
    visibility: hidden;
    position: absolute;
    right: 10px;
    top: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
  }
`;

const StyledPinIconWrapper = styled.div`
  position: relative;

  .closeIcon {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
`;
