// TODO: foundation 의 Select Component 이용
import { ChangeEvent } from 'react';
import { FormControl, FormControlProps, InputLabel, MenuItem, Select } from '@material-ui/core';

export type SelectFormProps = {
  label: string;
  options?: string[];
  value: string;
  disabled?: boolean;
  onSelect: (e: ChangeEvent<{ name?: string; value?: unknown }>, id: string) => void;
  name: string;
  error?: boolean;
  size?: FormControlProps['size'];
};

function SelectForm({
  label,
  options = [],
  value,
  disabled = false,
  onSelect,
  name,
  error = false,
  size = 'medium'
}: SelectFormProps): JSX.Element {
  return (
    <FormControl variant="outlined" fullWidth disabled={disabled} error={error} size={size}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={(event) => onSelect(event, name)} label={label}>
        {options.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectForm;
