import { useEffect, useState } from 'react';
import { TableCell } from '@material-ui/core';
import { Button } from 'loplat-ui';
import dayjs from 'dayjs';
import * as firebase from 'firebase/app';
import * as S from './styles';
import { FIREBASE_COOK_URL, MODE } from '@/config';
import MapSection from '../MapSection';
import ImageSection from '../ImageSection';
import InfoSection from '../InfoSection';

function DetailTableRow({ inView, colSpan, fingerprint, isExpanded }) {
  const [imageUrls, setImageUrls] = useState([]);

  const loadFingerprintImages = () => {
    const { fp_created_at, fp_collector, fp_fpid } = fingerprint;
    const createdAt = dayjs(fp_created_at).format('YYYY-MM-DD');
    const storageUrls = Array(4)
      .fill(null)
      .map((_, idx) => {
        const name = generateImageRef(createdAt, fp_collector, fp_fpid, 'img_fp');
        return `${name}_${idx}.jpg`;
      });

    const imageUrlsPromises = storageUrls.map(promiseStorageUrl);
    Promise.allSettled(imageUrlsPromises).then(async (results) => {
      const imageUrls = results.filter((result) => result.status === 'fulfilled').map((result) => result.value);

      // NOTE: MC-1608
      if ((results[0].status === 'rejected' && imageUrls.length === 2) || !imageUrls.length) {
        promiseStorageImage(createdAt, fp_collector, fp_fpid, 'img_fp')
          .then((legacy) => imageUrls.unshift(legacy))
          .catch(() => {
            // 또 실패할 경우
            promiseStorageImage(createdAt, fp_collector, fp_fpid, 'img').then((img) => imageUrls.push(img));
          });
      }

      setImageUrls(imageUrls);
    });
  };

  // NOTE: 1년 이상 된 이미지는 버튼을 눌러 수동으로 불러오도록 함 MC-1615
  const isOldFingerprint = dayjs(fingerprint.fp_created_at).isSameOrBefore(dayjs().subtract(1, 'year'));
  const [shouldLoadImage, setShouldLoadImage] = useState(!isOldFingerprint);
  useEffect(() => {
    if (inView && !imageUrls.length) {
      if (shouldLoadImage) {
        loadFingerprintImages();
      }
    }
  }, [inView, shouldLoadImage]);

  return (
    <S.StyledTableRow isExpanded={isExpanded}>
      <TableCell colSpan={colSpan}>
        <S.StyledDetail isExpanded={isExpanded}>
          {inView ? (
            <>
              {isExpanded ? <MapSection fingerprint={fingerprint} isExpanded={isExpanded} /> : null}
              {!shouldLoadImage ? (
                <Button onClick={() => setShouldLoadImage(true)} fullWidth color="primary2">
                  cook 이미지 불러오기
                </Button>
              ) : (
                <ImageSection fingerprint={fingerprint} imageUrls={imageUrls} inView={inView} isExpanded={isExpanded} />
              )}
            </>
          ) : null}
          <InfoSection fingerprint={fingerprint} />
        </S.StyledDetail>
      </TableCell>
    </S.StyledTableRow>
  );
}
export default DetailTableRow;

const promiseStorageImage = (createdAt, collector, fpid, format) => {
  const ref = `${generateImageRef(createdAt, collector, fpid, format)}.jpg`;
  return promiseStorageUrl(ref);
};

const promiseStorageUrl = (ref) =>
  new Promise((resolve, reject) => {
    firebase
      .storage()
      .refFromURL(ref)
      .getDownloadURL()
      .then((imageUrl) => resolve(imageUrl))
      .catch((e) => reject(e));
  });

const generateImageRef = (createdAt, collector, fpid, format) => {
  if (MODE === 'production') {
    return `${FIREBASE_COOK_URL}/loplatcook/images/${createdAt}/${collector}/${format}_${fpid}`;
  }
  return `${FIREBASE_COOK_URL}/test/${format}_${fpid}`;
};
