import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, IconButton, Typography } from '@material-ui/core';
import { AddRounded, RemoveRounded, RefreshRounded } from '@material-ui/icons';
import { ConfigurationForm, SdkTargetApp, XUser } from '@API/manager/sdk/types';
import TextInputForm from '@F/materialUI/TextInputForm';
import { toastWarningMessage } from '@FUNC/toast';
import { MOBILE_OSES, X_MEMBERSHIPS, X_USER_ROLES } from '../constant';
import FormSection from './FormSection';
import { validateTargetApp, validateXUser } from '../validation';

const INITIAL_FORM_VALUES: ConfigurationForm = {
  name: '',
  client_id: '',
  client_secret: '',
  make_refine: false,
  membership: 'BASIC',
  targets: [],
  users: []
};

export default function SdkConfigForm({
  initialValues,
  renderToolbar,
  readOnly = false
}: {
  initialValues?: ConfigurationForm;
  renderToolbar: (formValues: ConfigurationForm, isDirty: boolean) => JSX.Element;
  readOnly?: boolean;
}): JSX.Element {
  const [formValues, setFormValues] = useState<ConfigurationForm>(initialValues || INITIAL_FORM_VALUES);

  const sdkError = useMemo(() => {
    // detail page에서는 동작되지 않음
    if (readOnly) {
      return false;
    }

    // 입력 전
    if (!formValues.client_id) {
      return false;
    }

    // 3자 이상의 영소문자, 숫자로 이루어져 있는지 확인, 영소문자 혹은 숫자로만 구성되어도 됨.
    const clientIdReg = /^[a-z0-9]{3,45}$/;
    if (!clientIdReg.test(formValues.client_id)) {
      return true;
    }
    return false;
  }, [formValues.client_id, readOnly]);

  const [sdkTargetApp, setSdkTargetApp] = useState<SdkTargetApp>({
    app_name: '',
    package: '',
    os: 'android'
  });

  const [xUser, setXUser] = useState<XUser>({
    username: '',
    id: '',
    password: '',
    role: 'manager'
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeSdkConfigFormValues = (e: any) => {
    const { name, value, checked } = e.target;

    setFormValues((prev) => ({ ...prev, [name]: name === 'make_refine' ? checked : value }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeTargetApp = (e: any) => {
    const { name, value } = e.target;

    setSdkTargetApp((prev) => ({ ...prev, [name]: value }));
  };

  const handleClickAddTargetAppButton = () => {
    if (
      formValues.targets.some(
        (_sdkTargetApp) => getSdkTargetAppPrimaryKey(_sdkTargetApp) === getSdkTargetAppPrimaryKey(sdkTargetApp)
      )
    ) {
      toastWarningMessage(`이미 ${sdkTargetApp.os} OS로 등록된 동일 패키지가 있습니다.`);
      return;
    }

    const error = validateTargetApp(sdkTargetApp);
    if (error) {
      toastWarningMessage(error);
      return;
    }

    setFormValues((prev) => ({
      ...prev,
      targets: [sdkTargetApp, ...prev.targets]
    }));

    setSdkTargetApp({
      app_name: '',
      package: '',
      os: 'android'
    });
  };

  const handleClickRemoveTargetAppButton = (sdkTargetAppPrimaryKey: string) => {
    setFormValues((prev) => ({
      ...prev,
      targets: prev.targets.filter((sdkTargetApp) => getSdkTargetAppPrimaryKey(sdkTargetApp) !== sdkTargetAppPrimaryKey)
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeXUser = (e: any) => {
    const { name, value } = e.target;

    setXUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleClickAddXUserButton = () => {
    if (formValues.users.some((user) => user.id === xUser.id)) {
      toastWarningMessage('이미 등록된 유저 id입니다.');
      return;
    }

    const error = validateXUser(xUser);
    if (error) {
      toastWarningMessage(error);
      return;
    }

    setFormValues((prev) => ({
      ...prev,
      users: [xUser, ...prev.users]
    }));

    setXUser({
      username: '',
      id: '',
      password: '',
      role: 'manager'
    });
  };
  const handleClickRemoveXUserButton = (id: string) =>
    setFormValues((prev) => ({
      ...prev,
      users: prev.users.filter((user) => user.id !== id)
    }));

  const getSdkTargetAppPrimaryKey = (sdkTargetApp: SdkTargetApp) => `${sdkTargetApp.package}-${sdkTargetApp.os}`;

  const genRandom16LenString = () => window.btoa(String(Math.random())).substr(0, 16);

  const isDirty = JSON.stringify(initialValues || INITIAL_FORM_VALUES) !== JSON.stringify(formValues);

  return (
    <form>
      <div style={{ marginBottom: '20px' }}>{renderToolbar(formValues, isDirty)}</div>
      <FormSection title="회사 정보">
        <Grid item>
          <TextInputForm
            name="name"
            label="회사"
            value={formValues.name}
            onChange={handleChangeSdkConfigFormValues}
            readOnly={readOnly}
            disabled={readOnly}
          />
        </Grid>
      </FormSection>
      <FormSection title="SDK 정보">
        <Grid item>
          <TextInputForm
            name="client_id"
            label="Client id"
            value={formValues.client_id}
            onChange={handleChangeSdkConfigFormValues}
            readOnly={readOnly}
            disabled={readOnly}
            error={sdkError}
          />
        </Grid>
        <Grid item>
          <TextInputForm
            type={readOnly ? 'password' : 'text'}
            name="client_secret"
            label="Secret"
            value={formValues.client_secret}
            onChange={handleChangeSdkConfigFormValues}
            readOnly={readOnly}
            disabled={readOnly}
          />
        </Grid>
        {readOnly ? null : (
          <Grid item style={{ alignSelf: 'center' }}>
            <IconButton
              onClick={() => {
                setFormValues((prev) => ({
                  ...prev,
                  client_secret: genRandom16LenString()
                }));
              }}
            >
              <RefreshRounded />
            </IconButton>
          </Grid>
        )}
        <Grid item style={{ alignSelf: 'center' }}>
          <Checkbox
            name="make_refine"
            checked={formValues.make_refine}
            onChange={readOnly ? () => null : handleChangeSdkConfigFormValues}
            disabled={readOnly}
          />
          <span>refine 생성</span>
        </Grid>
        {sdkError && (
          <Grid item xs={12}>
            <Typography color="error" variant="caption" style={{ paddingLeft: '8px' }}>
              최소 3자 이상의 영소문자와 숫자를 사용하여 작성해주세요.
            </Typography>
          </Grid>
        )}
      </FormSection>

      <FormSection title="Target Applications">
        {readOnly ? null : (
          <>
            <Grid item xs={2}>
              <TextInputForm
                name="app_name"
                label="App Name"
                value={sdkTargetApp.app_name}
                onChange={handleChangeTargetApp}
              />
            </Grid>
            <Grid item xs={2}>
              <TextInputForm
                name="package"
                label="Package"
                value={sdkTargetApp.package}
                onChange={handleChangeTargetApp}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth variant="outlined" className="type">
                <InputLabel id="notice-type">카테고리</InputLabel>
                <Select name="os" label="OS" value={sdkTargetApp.os} onChange={handleChangeTargetApp}>
                  {MOBILE_OSES.map(({ name, label }) => (
                    <MenuItem key={name} value={name}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClickAddTargetAppButton}>
                <AddRounded />
              </IconButton>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          {formValues.targets.map((sdkTargetApp) => {
            const { app_name, package: app_package, os } = sdkTargetApp;

            return (
              <Grid key={getSdkTargetAppPrimaryKey(sdkTargetApp)} container direction="row" spacing={2}>
                <Grid item container alignItems="center" xs={2}>
                  <AddedListItem item>{app_name}</AddedListItem>
                </Grid>
                <Grid item container alignItems="center" xs={2}>
                  <AddedListItem item>{app_package}</AddedListItem>
                </Grid>
                <Grid item container alignItems="center" xs={2}>
                  <AddedListItem item>{os}</AddedListItem>
                </Grid>
                {readOnly ? null : (
                  <Grid item>
                    <IconButton onClick={() => handleClickRemoveTargetAppButton(`${app_package}-${os}`)}>
                      <RemoveRounded />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Grid>
      </FormSection>
      <FormSection title="X 사용 정보">
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" className="type" disabled={readOnly}>
            <InputLabel id="sdkConfig-membership">Membership</InputLabel>
            <Select
              name="membership"
              label="Membership"
              value={formValues.membership}
              onChange={handleChangeSdkConfigFormValues}
              readOnly={readOnly}
              disabled={readOnly}
            >
              {X_MEMBERSHIPS.map((membership) => (
                <MenuItem key={membership} value={membership}>
                  {membership}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </FormSection>
      <FormSection title="X 사용자">
        {readOnly ? null : (
          <>
            <Grid item xs={2}>
              <TextInputForm name="username" label="사용자 명" value={xUser.username} onChange={handleChangeXUser} />
            </Grid>
            <Grid item xs={2}>
              <TextInputForm name="id" label="id" value={xUser.id} onChange={handleChangeXUser} />
            </Grid>
            <Grid item xs={2}>
              <TextInputForm name="password" label="password" value={xUser.password} onChange={handleChangeXUser} />
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  setXUser((prev) => ({ ...prev, password: genRandom16LenString() }));
                }}
              >
                <RefreshRounded />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth variant="outlined" className="type">
                <InputLabel id="sdkConfig-role">Role</InputLabel>
                <Select name="role" label="Role" value={xUser.role} onChange={handleChangeXUser}>
                  {X_USER_ROLES.map((xUserRole) => (
                    <MenuItem key={xUserRole} value={xUserRole}>
                      {xUserRole}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {readOnly ? null : (
              <Grid item>
                <IconButton onClick={handleClickAddXUserButton}>
                  <AddRounded />
                </IconButton>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          {formValues.users.map(({ id, username, password, role }) => (
            <Grid key={id} container direction="row" spacing={2}>
              <Grid container item xs={2} alignItems="center">
                <AddedListItem item>{username}</AddedListItem>
              </Grid>
              <Grid container item xs={2} alignItems="center">
                <AddedListItem item>{id}</AddedListItem>
              </Grid>
              <Grid container item xs={2} alignItems="center">
                <AddedListItem item>{readOnly ? '***************' : password}</AddedListItem>
              </Grid>
              {/* 아래 Refresh 버튼은 실제 사용하는 Refresh button과 동일한 너비, 높이를 갖기 위해 추가 */}
              <Grid item>
                <IconButton style={{ visibility: 'hidden' }}>
                  <RefreshRounded />
                </IconButton>
              </Grid>
              <Grid container item xs={2} alignItems="center">
                <AddedListItem item>{role}</AddedListItem>
              </Grid>
              {readOnly ? null : (
                <Grid item>
                  <IconButton onClick={() => handleClickRemoveXUserButton(id)}>
                    <RemoveRounded />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </FormSection>
    </form>
  );
}

const AddedListItem = styled(Grid)`
  padding-left: 12px;
`;
