import { kindObj, sizeArr, stateObj } from '@P/polygon/redux/polygonList';
import type { SelectFormProps } from '@F/materialUI/SelectForm';
import type { TextInputFormProps } from '@F/materialUI/TextInputForm';

type ExceptSelectProperties = 'value' | 'onSelect';

export const kindOpts: Omit<SelectFormProps, ExceptSelectProperties> = {
  options: Object.keys(kindObj),
  label: '분류',
  name: 'kind',
  size: 'small'
};

export const queryOpts: TextInputFormProps = {
  label: '검색어',
  name: 'query',
  size: 'small'
};

export const sizeOpts: Omit<SelectFormProps, ExceptSelectProperties> = {
  options: sizeArr,
  label: '페이지 당 목록 수',
  name: 'size',
  size: 'small'
};

export const stateOpts: Omit<SelectFormProps, ExceptSelectProperties> = {
  options: Object.keys(stateObj),
  label: '상태',
  name: 'state',
  size: 'small'
};

export const btnOpts = {
  color: 'primary',
  variant: 'contained',
  size: 'large',
  fullWidth: true,
  style: { height: '40px' }
} as const;

export const idOpts: TextInputFormProps = {
  label: 'ID',
  name: 'ID',
  size: 'small'
};

export const filterOpts = {
  kindOpts,
  queryOpts,
  sizeOpts,
  btnOpts,
  stateOpts,
  idOpts
};
