import styled from 'styled-components';
import { blue100, bluescale400, grayscale800, primary } from 'loplat-ui';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  border: 1px solid ${bluescale400};
  border-radius: 3px;
  background: white;
  padding: 3px 8px;
  font-size: 12px;
  color: ${grayscale800};
  height: min-content;
  svg {
    margin-right: 8px;
  }
  &[role='button'] {
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${blue100};
      border-color: ${primary};
    }
  }
`;
