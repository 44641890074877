import { useEffect, useCallback } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Tooltip } from '@material-ui/core';
import { PolygonIcon } from 'loplat-ui';

type Props = {
  naverMap: naver.maps.Map;
  drawingManager: naver.maps.drawing.DrawingManager;
  isDrawingMode: boolean;
  setIsDrawingMode: Dispatch<SetStateAction<boolean>>;
  editLoading: boolean;
  resetState: (except: 'draw' | 'info' | 'near') => void;
  disabled: boolean;
  exceptionSnapShotEvent: (e: KeyboardEvent, keyList: string[]) => boolean;
};

const DrawingModeButton = ({
  naverMap,
  drawingManager,
  isDrawingMode,
  setIsDrawingMode,
  editLoading,
  resetState,
  disabled,
  exceptionSnapShotEvent
}: Props): JSX.Element => {
  const toggleDrawMode = useCallback(() => {
    if (editLoading || disabled) {
      return;
    }
    resetState('draw');
    const naver_Map = naverMap as naver.maps.Map & {
      setCursor: (pointer: string) => void;
    };

    if (isDrawingMode) {
      naver_Map.setCursor('grab');
      setIsDrawingMode(false);
    } else {
      naver_Map.setCursor('pointer');
      setIsDrawingMode(true);
    }
  }, [disabled, editLoading, isDrawingMode, naverMap, resetState, setIsDrawingMode]);

  const snapShotEvent = useCallback(
    (e) => {
      if (!exceptionSnapShotEvent(e, ['s', 'ㄴ'])) {
        return;
      }
      toggleDrawMode();
    },
    [exceptionSnapShotEvent, toggleDrawMode]
  );

  useEffect(() => {
    window.addEventListener('keydown', snapShotEvent);
    return () => {
      window.removeEventListener('keydown', snapShotEvent);
    };
  }, [snapShotEvent]);

  useEffect(() => {
    const listner = drawingManager.addListener(naver.maps.drawing.DrawingEvents.ADD, () => {
      setIsDrawingMode(false);
    });

    return () => {
      drawingManager.removeListener(listner);
    };
  }, [drawingManager, setIsDrawingMode]);

  return (
    <Tooltip title={isDrawingMode ? '드로잉 모드 해제 (단축키 : s)' : '드로잉 모드 진입 (단축키 : s)'}>
      <span
        role="presentation"
        className={isDrawingMode ? 'drawingManager active' : 'drawingManager'}
        onClick={toggleDrawMode}
      >
        <PolygonIcon />
      </span>
    </Tooltip>
  );
};

export default DrawingModeButton;
