import { AxiosPromise } from 'axios';
import { axiosInstancesManager } from '@API/axios';
import type { PlaceTodoParams } from '@TS/cook/api/placetodos';
import type { PlaceTodo } from '@TS/cook/placetodo';

/**
 *
 * 기존에는 api/cook/site.js에 작성되었던 코드입니다.
 *
 * 백엔드 cook api에서 site, placetodo DB 모델과 관련된 로직이 모두 포함되어있는데요.
 * 프론트엔드에서는 이를 구분할 필요가 있다고 생각해 복합물 미수집 관리를 페이지에서 사용하는 placetodo 모델 관련 로직을 이 파일로 옮겨두었습니다.
 *
 * TODO : api/cook/site에 모든 placetodo 관련 로직을 이 파일로 옮겨야합니다.
 *        api/cook/site에 의존하는 코드들이 있어 우선 보류합니다.
 *
 */

class CookPlaceTodosApi {
  get({ siteId }: { siteId: PlaceTodoParams }): AxiosPromise<PlaceTodo> {
    if (siteId === undefined) {
      throw new Error('siteId is undefined');
    }

    return axiosInstancesManager.manager.get(`/cook/sites/${siteId}`);
  }

  getAll(params: PlaceTodoParams): AxiosPromise<{
    count: number;
    items: PlaceTodo[];
  }> {
    return axiosInstancesManager.manager.get('/cook/sites', {
      params
    });
  }

  patch({ siteId, action }: PlaceTodoParams & Exclude<PlaceTodo, 'siteId'>) {
    return axiosInstancesManager.manager.patch(`/cook/sites/${siteId}`, {
      action
    });
  }
}
export default new CookPlaceTodosApi();
