import { useState } from 'react';
import { Button, styled } from 'loplat-ui';
import { Version } from '.';

type AddVersionProps = {
  addVersion: (version: Version) => void;
};

const AddVersion = ({ addVersion }: AddVersionProps): JSX.Element => {
  const [version, setVersion] = useState<Version>({
    version: '',
    type: 'release',
    comment: ''
  });

  const handleAddVersion = (version: Version) => {
    setVersion({
      version: '',
      type: 'release',
      comment: ''
    });
    addVersion(version);
  };

  return (
    <Wrapper>
      <input
        type="text"
        placeholder="version"
        value={version.version}
        onChange={(e) => setVersion({ ...version, version: e.target.value })}
      />
      <select
        value={version.type}
        onChange={(e) => setVersion({ ...version, type: e.target.value as Version['type'] })}
      >
        <option value="release">release</option>
        <option value="hotfix">hotfix</option>
        <option value="update">update</option>
      </select>
      <input
        id="comment"
        type="text"
        placeholder="comment"
        value={version.comment}
        onChange={(e) => setVersion({ ...version, comment: e.target.value })}
      />
      <Button
        color="primary"
        disabled={!version.version || !version.type || !version.comment}
        onClick={() => handleAddVersion(version)}
      >
        Add Version
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  input {
    padding: 10px;
    border: 1px solid #ccc;

    #comment {
      width: 100%;
    }
  }

  select {
    padding: 10px;
    border: 1px solid #ccc;
  }
`;

export default AddVersion;
