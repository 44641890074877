import { grayscale900, black, grayscale200, grayscale50 } from 'loplat-ui';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
  background-color: white;
  min-width: 800px;
  padding: 1.125rem 1rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Table = styled.table`
  font-size: 0.875rem;
  width: 100%;
  color: ${grayscale900};
  border-collapse: collapse;
  thead h3 {
    font-size: 1.2rem;
    text-align: left;
  }
  th,
  td {
    padding: 0.75rem 1rem;
    border: 1px solid ${grayscale200};
  }
  td {
    color: ${black};
  }

  th {
    font-weight: bold;
    text-align: left;
    background-color: ${grayscale50};
  }
  tbody th {
    width: 130px;
  }

  td.map {
    padding: 0;
    width: 930px;
    height: 680px;
    overflow: hidden;
    > div {
      height: 100%;
    }
  }
`;

export const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  > div.map {
    width: 100%;
    height: 100%;
  }

  > section {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    button {
      border-radius: 0;
      &:nth-child(2) {
        border-top: none;
        border-bottom: none;
      }
    }
  }

  section.pin_tip {
    background-color: white;
    border: 1px solid ${grayscale200};
    border-radius: 4px;
    padding: 10px;
    width: 150px;
    box-sizing: border-box;

    ul {
      list-style: none;
      div {
        font-size: 0.875rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
          color: ${grayscale900};
        }
      }
      li:not(:first-of-type) {
        margin-top: 8px;
      }
    }

    b {
      font-weight: 900;
      color: ${black};
    }
  }
`;
