import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as firebase from 'firebase/app';
import Lightbox from 'react-image-lightbox';
import { Button } from 'loplat-ui';
import Loading from '@F/Loading';
import { GridList, GridListTile } from '@material-ui/core';
import { toastErrorMessage } from '@FUNC/toast';
import { useFetch } from '@HOOK/';
import { cashplaceStudiesApi } from '@API/manager/cashplace';
import { FIREBASE_COOK_URL, FIREBASE_CASHPLACE_URL } from '@/config';
import 'react-image-lightbox/style.css';
import * as S from './styles';

function Images({ detail }) {
  const { callApi, isLoading } = useFetch();
  const [imagesLoading, setImagesLoading] = useState(false);
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const [cashplaceImages, setCashplaceImages] = useState([]);
  const [otherImages, setOtherImages] = useState([]);

  // NOTE: 1년 이상 된 이미지는 버튼을 눌러 수동으로 불러오도록 함 MC-1615
  const isOldFingerprint =
    detail.collector !== 'cashplace' &&
    detail.fingerprint?.some((item) => dayjs(item.created_at).isSameOrBefore(dayjs().subtract(1, 'year')));
  const [shouldLoadImage, setShouldLoadImage] = useState(!isOldFingerprint);

  useEffect(() => {
    if (detail) {
      if (detail.fingerprint && detail.fingerprint.length > 0) {
        const tempLinks = new Set();

        const link = `${FIREBASE_COOK_URL}/loplatcook/images`;

        detail.fingerprint.forEach((item) => {
          if (item.collector === 'cashplace' && item.study_id) {
            getCashPlaceImage(item.study_id);
          } else if (item.collector !== 'cashplace' && shouldLoadImage) {
            const imgLink = `${link}/${dayjs(item.created_at).format('YYYY-MM-DD')}/${item.collector}/img_fp`;

            [1, 2, 3].forEach((i) => {
              tempLinks.add(`${imgLink}_${item.fpid}_${i}.jpg`);
            });
          }
        });
        getImages([...tempLinks]);
      }
    }
  }, [detail, shouldLoadImage]);

  async function getCashPlaceImage(studyId) {
    try {
      setImagesLoading(true);
      const result = await callApi(cashplaceStudiesApi.getStudyImageNames, { studyId });

      if (result) {
        const imagePromises = result.data.image_names.map(async (imgName) => {
          const image = firebase.storage().refFromURL(`${FIREBASE_CASHPLACE_URL}/images/${imgName}`);
          const url = await image.getDownloadURL();
          return url;
        });

        const responseImage = await Promise.allSettled(imagePromises);
        const validImages = responseImage.map((item) => item.value).filter((item) => item !== undefined);

        setCashplaceImages([...validImages]);
      }
    } catch (e) {
      toastErrorMessage(e.message);
    } finally {
      setImagesLoading(false);
    }
  }

  async function getImages(linkList) {
    setImagesLoading(true);

    if (linkList && linkList.length > 0) {
      try {
        const imagePromises = linkList.map(async (img) => {
          const image = firebase.storage().refFromURL(img);
          try {
            const url = await image.getDownloadURL();
            return url;
          } catch {
            return undefined;
          }
        });

        const tempImages = await Promise.allSettled(imagePromises);
        const filteredImages = tempImages.map((item) => item.value).filter((item) => item !== undefined);

        filteredImages.sort((a, b) => {
          const aDate = a.split('%2F')[2];
          const bDate = b.split('%2F')[2];
          return new Date(bDate) - new Date(aDate);
        });

        setOtherImages([...filteredImages]);
      } catch (e) {
        console.error(e.message);
      } finally {
        setImagesLoading(false);
      }
    } else {
      setImagesLoading(false);
    }
  }

  function imageClicked(image) {
    setImageViewerOpen(true);
    setImageIndex(images.findIndex((item) => item === image));
  }

  const images = [...cashplaceImages, ...otherImages];
  // 혹시 모르니 중복 제거
  const uniqueImages = [...new Set(images)];

  return (
    <S.StyledStoreInnerImages>
      {(isLoading || imagesLoading) && <Loading />}
      {shouldLoadImage &&
        (uniqueImages && uniqueImages.length > 0 ? (
          <GridList cellHeight={500} cols={uniqueImages.length > 2 ? 3 : 2}>
            {uniqueImages.slice(0, 3).map((image) => (
              <GridListTile key={image}>
                <img src={image} onClick={() => imageClicked(image)} alt="Image-Viewer" />
              </GridListTile>
            ))}
          </GridList>
        ) : (
          <h1>이미지가 없거나 불러오는 데에 실패했습니다.</h1>
        ))}
      {!shouldLoadImage && (
        <Button onClick={() => setShouldLoadImage(true)} fullWidth color="primary2">
          cook 이미지 불러오기
        </Button>
      )}
      {imageViewerOpen && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 2000 } }}
          mainSrc={uniqueImages[imageIndex]}
          nextSrc={uniqueImages[(imageIndex + 1) % uniqueImages.length]}
          prevSrc={uniqueImages[(imageIndex + uniqueImages.length - 1) % uniqueImages.length]}
          onCloseRequest={() => setImageViewerOpen(false)}
          onMovePrevRequest={() => setImageIndex((imageIndex + uniqueImages.length - 1) % uniqueImages.length)}
          onMoveNextRequest={() => setImageIndex((imageIndex + uniqueImages.length + 1) % uniqueImages.length)}
          imageCaption={uniqueImages[imageIndex]}
          enableZoom
          imageTitle={`${uniqueImages.length}개 중 ${imageIndex + 1}번째 사진`}
        />
      )}
    </S.StyledStoreInnerImages>
  );
}
export default Images;

Images.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired
};
