import { useNavigate } from 'react-router-dom';
import Pagination from '@F/Pagination';

import { Button } from '@material-ui/core';
import CompaniesTable from './components/CompaniesTable';
import CompaniesFilter from './components/CompaniesFilter';

export default function CompaniesPage(): JSX.Element {
  const navigate = useNavigate();
  return (
    <div>
      <CompaniesFilter />

      <Button
        style={{ marginTop: '20px', marginBottom: '20px' }}
        onClick={() => navigate('/x/companies/create')}
        color="primary"
        variant="contained"
        size="large"
      >
        작성
      </Button>

      <CompaniesTable />

      <Pagination />
    </div>
  );
}
