import { Fragment } from 'react';
import styled from 'styled-components';
import { Caption } from 'loplat-ui';
import type { SegmentOptionType } from '@P/max/campaigns/utils/type';
import { blueBackground } from '@P/max/campaigns/utils/styles';
import { SegmentModule } from '@P/max/campaigns/module';
import ConjunctionConditions from './ConjunctionConditions';

const SegmentOption = ({ selection, ...options }: SegmentOptionType): JSX.Element => (
  <Wrapper>
    <Title>
      <SegmentModule type="area" {...options} />
    </Title>

    {Object.keys(selection).length > 0 && <ConjunctionConditions type="and" />}

    {Object.keys(selection).length > 0
      ? (Object.entries(selection) as Entries<Required<Segments.SegmentScheme.Select>>)
          .filter(([, value]) => !!value)
          .map(([key, value]) => (
            <Fragment key={key}>
              <SegmentModule type={key} lv1={value.join(', ')} />
            </Fragment>
          ))
      : null}
    <Caption mt={4} size="xs">
      이 조건의 방문 기록이 있는 사용자를 대상으로 합니다.
    </Caption>
  </Wrapper>
);

export default SegmentOption;

export const Wrapper = styled.div`
  ${blueBackground};
  > div {
    width: 100%;
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
