import { useCallback, useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import { useDispatch } from 'react-redux';
import useInput from '@HOOK/useInput';
import { actions } from '@/redux/cashplace/version/state';
import { VersionProps } from '../../types';
import * as S from './styles';

type DetailProps = {
  id?: number;
  data: VersionProps[];
};

const numberReg = /^[0-9]+$/;

function Detail({ id, data }: DetailProps): JSX.Element {
  const detail = useMemo(() => data?.find((item) => item.version_id === id || null), [data, id]);
  const [versionNumberParsedInteger, setVersionNumberParsedInteger] = useInput(detail?.v ?? '');
  const VersionNumberParsedIntegerInput = (
    <TextInputForm
      name="VersionNumberParsedIntegerInput"
      label="앱 버전(정수형)"
      value={versionNumberParsedInteger}
      onChange={setVersionNumberParsedInteger}
      error={!numberReg.test(versionNumberParsedInteger)}
      required
    />
  );

  const [availableMinimumVersionNumberParsedInteger, setAvailableMinimumVersionNumberParsedInteger] = useInput(
    String(detail?.minimum_v)
  );
  const AvailableMinimumVersionNumberParsedIntegerInput = (
    <TextInputForm
      name="AvailableMinimumVersionNumberParsedInteger"
      label="동작가능 최소버전"
      value={availableMinimumVersionNumberParsedInteger}
      onChange={setAvailableMinimumVersionNumberParsedInteger}
      required
    />
  );

  const [remarks, setRemarks] = useInput(detail?.comment);
  const RemarksInput = <TextInputForm name="RemarksInput" label="비고" value={remarks} onChange={setRemarks} />;

  const dispatch = useDispatch();
  const deleteVersion = useCallback(() => {
    dispatch(actions.deleteVersion(id));
  }, [dispatch]);
  const updateVersion = useCallback(() => {
    dispatch(
      actions.updateVersion({
        versionId: id,
        version: Number(versionNumberParsedInteger),
        minimumVersion: Number(availableMinimumVersionNumberParsedInteger),
        comment: remarks,
        type: detail?.type
      })
    );
  }, [dispatch, id, versionNumberParsedInteger, availableMinimumVersionNumberParsedInteger, remarks]);

  return (
    <S.StyledDetail>
      <Grid container spacing={1}>
        <Grid item xs>
          {VersionNumberParsedIntegerInput}
        </Grid>
        <Grid item xs>
          {AvailableMinimumVersionNumberParsedIntegerInput}
        </Grid>
        <Grid item xs>
          {RemarksInput}
        </Grid>
      </Grid>
      <S.ButtonRow>
        <Button variant="contained" color="secondary" style={{ marginRight: '10px' }} onClick={deleteVersion}>
          DELETE
        </Button>
        <Button variant="contained" color="primary" onClick={updateVersion}>
          UPDATE
        </Button>
      </S.ButtonRow>
    </S.StyledDetail>
  );
}
export default Detail;
