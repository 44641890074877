import { PaginationContextProvider } from '@HOOK/usePagination';
import { SdkConfigsContextProvider } from './useManageCompanies';

export default function ManageCompaniesLayout({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <PaginationContextProvider initialOption={{ page: 1, perPage: 20, totalCount: 0 }}>
      <SdkConfigsContextProvider>{children}</SdkConfigsContextProvider>
    </PaginationContextProvider>
  );
}
