import firebase from 'firebase';

const config = {
  apiKey: process.env.REACT_APP_DMP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_DMP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DMP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_DMP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DMP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DMP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DMP_FIREBASE_APP_ID
};

function DMPFirebaseProject() {
  this.instance; // eslint-disable-line no-unused-expressions

  this.init = async () => {
    if (this.instance) return this.instance;

    this.instance = firebase.initializeApp(config, 'dmp');

    return this.instance;
  };

  this.firestore = () => this.instance.firestore();
}

const dmpFirebaseProject = new DMPFirebaseProject();

export default dmpFirebaseProject;
