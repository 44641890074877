// NOTE: 메뉴별 권한에 관한 자세한 내용은 https://loplat.atlassian.net/wiki/spaces/DEV/pages/1840578667 을 참고.

export const USER_ROLES = {
  admin: 1,
  manager: 2,
  user: 3,
  teamjang: 4,
  customer: 5,
  alba: 6
} as const;
