import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Report } from '@TS/cashplace/report';
import firebase from 'firebase';
import MapSection from '../MapSection';
import InfoSection from '../InfoSection';
import ImageSection from '../ImageSection';
import { kindOptions } from '../../options';
import * as S from './styles';
import { MODE } from '@/config';

type DetailProps = {
  reports: Report[];
  id?: number;
};

export type ReportImage = {
  floor: number;
  url: string;
  createdAt: string;
};

function Detail({ reports, id }: DetailProps): JSX.Element | null {
  const [report, setReport] = useState<Report | null>(() => reports.find((report) => report.report_id === id) ?? null);
  const [reportImages, setReportImages] = useState<(ReportImage | null)[]>([]);

  const changeReportLatLng = (naverLatLng: naver.maps.LatLng) => {
    if (report?.kind === kindOptions['위치 수정']) {
      setReport({ ...report, lat: naverLatLng.lat(), lng: naverLatLng.lng() });
    }
  };

  useEffect(() => {
    if (!report || !report.images_names) return;

    report.images_names.forEach((filepath, i) => {
      // reportId, siteId, userId, floor, index
      const [, , , floor] = filepath.split('_');

      const docRef = firebase
        .storage()
        .refFromURL(`gs://cashplace-2aa7c.appspot.com/reports${MODE === 'staging' ? '-staging' : ''}/${filepath}.jpg`);

      docRef
        .getDownloadURL()
        .then((downloadUrl) =>
          docRef.getMetadata().then((metaData) => {
            setReportImages((prev) => {
              const next = [...prev];
              next[i] = {
                url: downloadUrl,
                createdAt: dayjs(metaData.timeCreated).format('YYYY-MM-DD HH:mm:ss'),
                floor: Number(floor)
              };
              return next;
            });
          })
        )
        .catch(() => {
          setReportImages((prev) => {
            const next = [...prev];
            next[i] = null;
            return next;
          });
        });
    });
  }, [report]);

  if (!report) return null;

  return (
    <S.StyledDetail>
      <MapSection report={report} onChangeReportLatLng={changeReportLatLng} />
      {report.kind === kindOptions['위치 수정'] ? null : report.images_names ? (
        <ImageSection images={reportImages} isLoading={reportImages.length !== report.images_names.length} />
      ) : null}
      <InfoSection report={report} />
    </S.StyledDetail>
  );
}
export default Detail;
