import { Box, TableCell, TableRow } from '@material-ui/core';
import { SEGMENT_TABLE_HEAD_CELLS } from '@/redux/dmp/segmentList';
import AllUserSegment from './AllUserSegment';
import FileSegment from './FileSegment';
import LogBaseSegment from './LogBaseSegment';
import CombinationSegment from './CombinationSegment';
import LogBaseVersion2 from './LogBaseVersion2';

function SegmentDetail(segment: Segments.DMP.Segment) {
  const { target_count, android_count, comment, segment_type, description, min_visit } = segment;
  const version = JSON.parse(comment ?? '[]').version;

  const segmentComponent = {
    all: <AllUserSegment />,
    file: <FileSegment />,
    log_base:
      version === 2 ? (
        <LogBaseVersion2 visit_type={segment.visit_type} comment={segment.comment} />
      ) : (
        <LogBaseSegment comment={segment.comment} visit_type={segment.visit_type} />
      ),
    combination: <CombinationSegment comment={segment.comment} />
  };

  const targetCount = (target_count ?? 0).toLocaleString();
  const androidCount = (android_count ?? 0).toLocaleString();

  return (
    <TableRow>
      <TableCell colSpan={SEGMENT_TABLE_HEAD_CELLS.length}>
        <Box sx={{ flexDirection: 'column' }} display="flex">
          <ul style={{ padding: '1rem', listStyle: 'none' }}>
            <li>세그먼트 대상자 : {targetCount}</li>
            <li>AND 사용자 수 : {androidCount}</li>
            {description && <li>설명 : {description}</li>}
            <li>{min_visit ?? 1}회 이상 방문</li>
          </ul>
          {comment && (
            <Box
              style={{
                padding: '1rem',
                backgroundColor: '#edf0f3'
              }}
            >
              {segmentComponent[segment_type]}
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default SegmentDetail;
