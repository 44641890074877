import { axiosInstancesManager } from '@API/axios';

type pushFcmParams = {
  tokens: string[];
  title: string;
  body: string;
  icon: string;
  delay: {
    delay: number;
    delay_per: number;
  };
  sendAt: string;
};

class CashplaceMessageApi {
  pushFcm({ tokens, title, body, icon, delay, sendAt }: pushFcmParams) {
    return axiosInstancesManager.cashplace.post('/push/fcm', {
      tokens,
      title,
      body,
      icon,
      delay,
      send_at: sendAt
    });
  }
}

export default new CashplaceMessageApi();
