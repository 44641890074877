import React, { useEffect } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

interface Props {
  title: string;
  description: string;
  open: boolean;
  setOpen: (args: boolean) => void;
  // TODO: 컨벤션에 맞지 않지만, 사용하고 있는 곳이 꽤 많아서 언급만 하고 둡니다.
  // confirmButtonDidClicked -> onButtonClicked 로 수정 필요.
  confirmButtonDidClicked: () => void;
  hasCancelButton?: boolean;
  children?: React.ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  confirmButtonDisabled?: boolean;
}

const DialogModal = ({
  title,
  description,
  open,
  setOpen,
  confirmButtonDidClicked,
  hasCancelButton = true,
  children,
  maxWidth = 'sm',
  confirmButtonDisabled = false
}: Props): JSX.Element => {
  useEffect(() => {
    // NOTE: useRef를 안쓴 이유: 모달이 열리기 전에(화면에 그려지기 이전에)이 컴포넌트 코드가 실행 되는데,
    // 버튼이 생성되기 전에 useEffect가 실행되므로 useRef를 쓰면
    // useRef.current = undefined가 뜸
    // 나중에 모달이 열리는 방식을 수정할 예정
    setTimeout(() => {
      const button = document.querySelector('.ConfirmButton');
      if (button) (button as HTMLButtonElement).focus();
    }, 0);
    if (!open && document) {
      (document.activeElement as HTMLElement).blur();
    }
  }, [open]);

  const closeModal = () => {
    setOpen(false);
  };

  const confirm = () => {
    confirmButtonDidClicked();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={closeModal} aria-labelledby="form-dialog-title" fullWidth maxWidth={maxWidth}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {hasCancelButton && (
          <Button onClick={closeModal} color="default">
            취소
          </Button>
        )}
        <Button
          className="ConfirmButton"
          color="primary"
          onClick={confirm}
          onKeyDown={(event) => {
            if (event.key === 'Enter') confirm();
          }}
          disabled={confirmButtonDisabled}
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogModal;
