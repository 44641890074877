import { TogglersContextProvider } from '@HOOK/useTogglers';
import SegmentListTable from './component/SegmentListTable';

function SegmentPage() {
  return (
    <TogglersContextProvider>
      <SegmentListTable />
    </TogglersContextProvider>
  );
}

export default SegmentPage;
