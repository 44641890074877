import { Fragment, useState } from 'react';
import { Body, Caption, grayscale800, primary } from 'loplat-ui';
import { GeofenceModule } from '@P/max/campaigns/module';
import type { SchemedRegionCondition } from '@P/max/campaigns/utils/type';
import Tooltip from '@F/Tooltip';
import { DetailSection } from './styles';
import GeofenceModal from '../GeofenceModal';

export type SelectedGeofence = {
  id: number;
  type: 'single' | 'group';
};
const GeofenceCondition = ({ geofence }: Pick<SchemedRegionCondition, 'geofence'>): JSX.Element => {
  const { count, ...props } = geofence;
  const [selectedGeofence, setSelectedGeofence] = useState<null | SelectedGeofence>(null);
  const isOpen = selectedGeofence !== null;

  return (
    <>
      <dt className="title">
        <Tooltip title="캠페인 실행 위치를 구분하는 가상의 영역. 특정 지점을 중심으로 00km 의 원형 지오펜스 혹은 선택한 폴리곤 지오펜스를 생성할 수 있음">
          <span>지오펜스</span>
        </Tooltip>
      </dt>
      <DetailSection className="body">
        <dl>
          {(Object.entries(props) as Entries<typeof props>)
            .filter(([, value]) => value.length)
            .map(([geofenceType, value]) => (
              <Fragment key={geofenceType}>
                <dt>{GEOFENCE_TITLE[geofenceType]}</dt>
                <dd>
                  {value.map(({ id, ...geo }) => (
                    <GeofenceModule
                      key={id}
                      type={geofenceType}
                      {...geo}
                      role="button"
                      onClick={() => {
                        setSelectedGeofence({ id, type: geofenceType });
                      }}
                    />
                  ))}
                </dd>
              </Fragment>
            ))}
        </dl>
        <div className="caption">
          <Caption size="xs" color={grayscale800}>
            캠페인 대상자가 위의 지오펜스 영역에 방문할 때 캠페인이 동작합니다.
          </Caption>
          <Caption size="xs" color={grayscale800}>
            예상 활성 지오펜스 수 :
            <Body tag="span" color={primary} ml={2} weight="bold">
              {count.toLocaleString()}
            </Body>
          </Caption>
        </div>
      </DetailSection>

      <GeofenceModal isOpen={isOpen} onClose={() => setSelectedGeofence(null)} {...selectedGeofence} />
    </>
  );
};

export default GeofenceCondition;
const GEOFENCE_TITLE = {
  group: '그룹',
  single: '싱글'
};
