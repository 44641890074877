import { axiosInstancesManager } from '@API/axios';

export interface GetPolygonListParams {
  page: number;
  per_page: number;
  kind: string;
  q: string;
  order: string;
  state?: 0 | 1;
}

export type Property = Record<string, number | boolean | string>;
export type Coordinate = [number, number];
export type Polygon = Coordinate[][][];
export type Geometry = {
  type: string;
  coordinates: Polygon;
  mantle_properties?: Property;
  properties?: Property;
};
export type FeatureGeoJSON = naver.maps.GeoJSON & {
  features?: {
    type: string;
    properties?: Property;
    geometry: Geometry;
  }[];
};

export interface GetPolygonListItem {
  job_id: number;
  title: string;
  addr: null | string;
  road_addr: null | string;
  worker: string | null;
  mpolygon_area: {
    type: 'MultiPolygon' | 'Polygon' | 'FeatureCollection';
    coordinates: Polygon;
  } | null;
  p_lng_lat: {
    type: 'Point';
    coordinates: [number, number];
  } | null;
  state: 0 | 1 | -1;
  created: Date | string;
  updated: Date | string | null;
}

interface GetPolygonListData {
  items: GetPolygonListItem[];
  page: number;
  per_page: number;
  total_count: number;
}

interface PostPolygonBody {
  title: string;
  addr: string;
  polygons: Polygon;
  state: 0 | 1;
}
type PutPolygonBody = PostPolygonBody;
interface GetDapiAddressDocItem {
  address: null | {
    address_name: string;
    b_code: string;
    h_code: string;
    main_address_no: string;
    mountain_yn: 'Y' | 'N';
    region_1depth_name: string;
    region_2depth_name: string;
    region_3depth_h_name: string;
    region_3depth_name: string;
    sub_address_no: string;
    x: string;
    y: string;
  };
  address_name: string;
  address_type: string;
  road_address: null | {
    address_name: string;
    building_name: string;
    main_building_no: string;
    region_1depth_name: string;
    region_2depth_name: string;
    region_3depth_name: string;
    road_name: string;
    sub_building_no: string;
    underground_yn: string;
    x: string;
    y: string;
    zone_no: string;
  };
  x: string;
  y: string;
}

export interface GetDapiAddressData {
  documents: GetDapiAddressDocItem[];
  meta: {
    is_end: boolean;
    pageable_count: number;
    total_count: number;
  };
}

interface GetDapiKeywordDocItem {
  address_name: string;
  category_group_code: string;
  category_group_name: string;
  category_name: string;
  distance: string;
  id: string;
  phone: string;
  place_name: string;
  place_url: string;
  road_address_name: string;
  x: string;
  y: string;
}

export interface GetDapiKeywordData {
  documents: GetDapiKeywordDocItem[];
  meta: {
    is_end: boolean;
    pageable_count: number;
    same_name: {
      keyword: string;
      region: string[];
      selected_region: string;
    };
    total_count: number;
  };
}

interface GetInNearPolygonParams {
  min_lng: number;
  min_lat: number;
  max_lng: number;
  max_lat: number;
}

class PolygonApi {
  getPolygonList(params: GetPolygonListParams) {
    const endPoint = '/polygon_jobs';
    return axiosInstancesManager.manager.get<GetPolygonListData>(endPoint, { params });
  }

  getPolygon({ id }: { id: string }) {
    const endPoint = `/polygon_jobs/${id}`;
    return axiosInstancesManager.manager.get<GetPolygonListItem>(endPoint);
  }

  deletePolygon({ id }: { id: number }) {
    const endPoint = `/polygon_jobs/${id}`;
    return axiosInstancesManager.manager.delete(endPoint);
  }

  postPolygon({ body }: { body: PostPolygonBody }) {
    const endPoint = '/polygon_jobs';
    return axiosInstancesManager.manager.post(endPoint, body);
  }

  editPolygon({ id, body }: { id: string; body: PutPolygonBody }) {
    const endPoint = `/polygon_jobs/${id}`;
    return axiosInstancesManager.manager.put(endPoint, { ...body });
  }

  getDapiAddress({ query }: { query: string }) {
    const page = 1;
    const size = 10;
    const endPoint = '/dapi/v2/local/search/address.json';

    return axiosInstancesManager.max.get<GetDapiAddressData>(endPoint, {
      params: {
        page,
        query,
        size
      }
    });
  }

  getDapiKeyword({ query }: { query: string }) {
    const size = 15;
    const endPoint = '/dapi/v2/local/search/keyword.json';
    return axiosInstancesManager.max.get<GetDapiKeywordData>(endPoint, {
      params: {
        query,
        size
      }
    });
  }

  getInNearPolygon(params: GetInNearPolygonParams) {
    const endPoint = '/polygon_jobs/near';
    return axiosInstancesManager.manager.get(endPoint, {
      params
    });
  }
}

export default new PolygonApi();
