import dayjs from 'dayjs';
import type { SchemedTimeCondition, SchemedGroupedTimeSlots } from './type';
import type { CampaignDetail } from '../redux/campaigns/slice';

type SameTimeSlots = {
  [K: string]: Campaign.Scheme.Operation.TimeSlot['week'][];
};

const serializeTime = (time: string): string => `${time.slice(0, -2).padStart(2, '0')}시 ${time.slice(-2)}분`;
const serializeDate = (date: string): string => dayjs(date).format('YYYY-MM-DD(dd)');
const timeSlotsByDays = (timeSlot: Campaign.Scheme.Operation.TimeSlot[]): SchemedGroupedTimeSlots[] => {
  const groupedBySameTime: SameTimeSlots = {};
  timeSlot.forEach(({ start, end, week }) => {
    const key = `${start}_${end}`;
    if (!groupedBySameTime[key]) {
      groupedBySameTime[key] = [];
      groupedBySameTime[key].push(week);
    } else {
      groupedBySameTime[key].push(week);
    }
  });

  return Object.entries(groupedBySameTime).map(([key, week]) => ({
    days: week
      .map((day) =>
        dayjs()
          .day(day + 1)
          .format('dd')
      )
      .join(', '),
    time: {
      start: serializeTime(key.split('_')[0].toString()),
      end: serializeTime(key.split('_')[1].toString())
    }
  }));
};

export const schemeTimeCondition = (operation: CampaignDetail['operation'], location = 0): SchemedTimeCondition => {
  const [startDay, endDay] = [dayjs(operation.start), dayjs(operation.end)];
  const period = endDay.diff(startDay, 'day');

  return {
    period: {
      value: `${serializeDate(operation.start)} ~ ${serializeDate(operation.end)}`,
      count: `${period + 1}일`
    },
    operation: timeSlotsByDays(operation.time_slots),
    location: location === 1 ? '매장 근처' : '매장'
  };
};
