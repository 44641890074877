import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Formik, Form } from 'formik';

import { SdkLogFilterValues, INITIAL_Sdk_LOG_FILTER_VALUES } from '../data';
import { useSdkLogBloc } from '../useSdkLogBloc';
import MaxCompanies from './MaxCompanies';
import Log from './Log';
import Dates from './Dates';

export default function SdkLogFilter(): JSX.Element {
  const { fetchSdkLogs, fetchStatus, downloadPrimitiveLogs, sdkLogs } = useSdkLogBloc();

  const validateFilterValues = (values: SdkLogFilterValues) => {
    const errors: { client_id?: string; log?: { value: string }; dates?: string } = {};

    const { log, client_id, dates } = values;

    if (client_id === '') {
      errors.client_id = '회사명을 입력해주세요.';
    }

    if (log.value === '') {
      errors.log = { value: `${values.log.type} 조회 값을 입력해주세요.` };
    }

    if (dates[0] === '' || dates[1] === '') {
      errors.dates = '기간을 입력해주세요.';
    }

    return errors;
  };

  const submit = (values: SdkLogFilterValues) => {
    const {
      client_id,
      log,
      dates: [startAt, endAt]
    } = values;

    fetchSdkLogs({
      client_id,
      start_date: startAt,
      end_date: endAt,
      adid: log.type === 'adid' ? log.value : undefined,
      echo_code: log.type === 'echo_code' ? log.value : undefined
    });
  };

  const submitCsv = (values: SdkLogFilterValues) => {
    const {
      client_id,
      log,
      dates: [startAt, endAt]
    } = values;

    downloadPrimitiveLogs({
      client_id,
      start_date: startAt,
      end_date: endAt,
      adid: log.type === 'adid' ? log.value : undefined,
      echo_code: log.type === 'echo_code' ? log.value : undefined
    });
  };

  const handleSubmit = (values: SdkLogFilterValues) => submit(values);

  const handleSubmitCsv = (values: SdkLogFilterValues) => submitCsv(values);

  return (
    <Formik initialValues={INITIAL_Sdk_LOG_FILTER_VALUES} onSubmit={handleSubmit} validate={validateFilterValues}>
      {({ values, touched, errors }) => {
        const isValidSdkLogFilterValues =
          !touched.client_id || !touched.log?.value || Boolean(errors.client_id) || Boolean(errors.log);

        return (
          <Form>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <MaxCompanies />
              </Grid>
              <Log />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Dates />
              </Grid>
              <Grid item xs={2}>
                <Button
                  type="submit"
                  style={{ height: '55px' }}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={isValidSdkLogFilterValues || fetchStatus === 'loading'}
                >
                  검색
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => handleSubmitCsv(values)}
                  style={{ height: '55px' }}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={sdkLogs.length === 0}
                >
                  CSV 다운로드
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
