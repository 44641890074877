import { useCallback, useEffect, useMemo } from 'react';
import { createHeadCells } from '@F/table/Table/functions';
import PaginationRow from '@F/table/PaginationRow';
import EnhancedTable from '@F/table/Table';
import { shallowEqual, useDispatch } from 'react-redux';
import { Column } from '@TS/columns';
import { useAppSelector } from '@HOOK/useSelector';
import { actions } from '@/redux/cashplace/version/state';
import { VersionProps } from '../../types';
import Detail from '../Detail';
import * as S from './styles';

/* eslint camelcase: 1 */

const columns: Column[] = [
  { name: 'id', label: 'version ID', isImportant: true },
  { name: 'type', label: 'osType' },
  { name: 'versionNumberParsedInteger', label: '앱 버전(정수형)' },
  { name: 'availableMinimumVersionNumberParsedInteger', label: '동작 가능 최소 버전' },
  { name: 'remarks', label: '비고' },
  { name: 'createdDate', label: '생성일시' },
  { name: 'updatedDate', label: '수정일시' }
];
function TableSection(): JSX.Element {
  const { page, perPage, count, versions, osType, openedVersionIds } = useAppSelector(
    (state) => ({
      page: state.version.page,
      perPage: state.version.perPage,
      count: state.version.count,
      versions: state.version.versions,
      openedVersionIds: state.version.openedVersionIds,
      osType: state.version.osType
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchVersions());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  const filteredVersionsByOs = versions?.filter((item: VersionProps) => osType === 'all' || item.type === osType);

  useEffect(() => {
    dispatch(actions.fetchVersions());
  }, [dispatch, page]);

  const headCells = columns.map((column) => createHeadCells(column.name, column.label));
  const rows = useMemo(
    () =>
      filteredVersionsByOs?.map((item: VersionProps) => ({
        id: item.version_id,
        type: item.type,
        versionNumberParsedInteger: item.v,
        availableMinimumVersionNumberParsedInteger: item.minimum_v,
        remarks: item.comment,
        createdDate: item.created_at,
        updatedData: item.updated_at
      })),
    [versions]
  );

  const setOpenedVersionIds = useCallback(
    (newOpenedVersionIds) => {
      dispatch(actions.setValue('openedVersionIds', newOpenedVersionIds));
    },
    [dispatch]
  );

  return (
    <S.StyledTableSection>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        rows={rows || []}
        headCells={headCells}
        initialOrder="desc"
        initialOrderBy="carbs"
        isCheckable={false}
        isCollapsible
        opened={openedVersionIds}
        setOpened={setOpenedVersionIds}
      >
        <Detail data={versions} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;
