// Options
// id는 API Response에 따른 정수에 대응합니다.
export const sectionOptions = {
  0: '일반 매장',
  1: 'Section'
};
export const storeNameReviewSelectOptions = {
  0: '미검수',
  1: '검수 완료',
  2: '판단 불가',
  3: '오류보고 반영',
  8: '자동 검수 완료'
};
export const subNameReviewSelectOptions = {
  0: { order: 0, value: '미검수' },
  1: { order: 1, value: '네이버 기준' },
  2: { order: 6, value: '다음 기준' },
  3: { order: 3, value: '공식 홈페이지' },
  4: { order: 4, value: '의심' },
  5: { order: 5, value: '수집자가 직접 입력' },
  6: { order: 2, value: '지점명 없음' },
  7: { order: 7, value: '오류보고 반영' },
  8: { order: 8, value: '자동 검수 완료' }
};
export const categoryCodeReviewSelectOptions = {
  0: '미검수',
  1: '검수 완료',
  2: '판단 불가',
  3: '오류보고 반영',
  8: '자동 검수 완료'
};
export const locationReviewSelectOptions = {
  0: '미검수',
  1: '검수 완료',
  2: '판단 불가',
  3: '오류보고 반영',
  8: '자동 검수 완료'
};

export const floorReviewSelectOptions = {
  0: '미검수',
  1: '검수 완료',
  2: '판단 불가',
  3: '오류보고 반영'
};
