import { getPercentWithTwoFraction } from '@FUNC/math';
import type { SchemedCampaignReport } from './type';
import type { CampaignDetail } from '../redux/campaigns/slice';

export const schemeReport = (campaign: CampaignDetail): SchemedCampaignReport => {
  const { cnt } = campaign;
  const count = [...cnt];

  // NOTE: campaign report 데이터가 없을 경우엔 배열길이1로 내려와서 임의로 추가해줌
  if (campaign.message.length === 2 && cnt.length === 1) {
    count.push({ ...count[0], type: 1 });
  }
  if (campaign.outbound?.vendor === 'bizppurio' && cnt.length === 1) {
    count.push({ ...count[0], hit_cnt: 0, uuid_cnt: 0, click_cnt: 0, service: 1 });
  }

  return count.map(({ type, service, click_cnt: clickCnt, hit_cnt: hitCnt }) => ({
    type,
    vendor: campaign.outbound?.vendor === 'bizppurio' ? 'kakao' : 'loplat',
    service,
    hitCnt,
    click: { count: clickCnt, percent: getPercentWithTwoFraction(clickCnt, hitCnt) }
  }));
};
