import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Button, TableRow as TR, TableCell } from '@material-ui/core';
import type { GetPolygonListItem } from '@API/manager/polygon';
import { actions } from '@P/polygon/redux/polygonList';
import ConfirmModal from '@F/modal/ConfirmModal';
import { useFetch } from '@HOOK/index';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { PolygonApi } from '@API/manager';

const TableRow = ({ row }: { row: GetPolygonListItem }): JSX.Element => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { callApi } = useFetch();

  const onPermitFunc = () => {
    callApi(PolygonApi.deletePolygon, { id: row.job_id })
      .then(() => {
        dispatch(actions.deletePolygon(row.job_id));
        toastSuccessMessage('삭제되었습니다.');
      })
      .catch(() => toastErrorMessage())
      .finally(() => setIsOpen(false));
  };

  return (
    <TR>
      <TableCell align="center">{row.job_id}</TableCell>
      <TableCell align="left">
        <Link to={`/equipment/polygon/edit/${row.job_id}`}>{row.title}</Link>
      </TableCell>
      <TableCell align="left">{row.worker}</TableCell>
      <TableCell align="left">{row.addr}</TableCell>
      <TableCell align="center">{row.state === 0 ? '미완료' : '완료'}</TableCell>
      <TableCell align="left">{dayjs(row.created).format('YYYY-MM-DD')}</TableCell>
      <TableCell align="left">{row.updated && dayjs(row.updated).format('YYYY-MM-DD')}</TableCell>
      <TableCell align="center">
        <Button variant="outlined" color="secondary" type="button" onClick={() => setIsOpen(true)}>
          삭제
        </Button>
      </TableCell>
      <ConfirmModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        onPermit={() => onPermitFunc()}
        title="삭제"
        subTitle={`${row.job_id}번 작업을 삭제하시겠습니까?`}
      />
    </TR>
  );
};

export default TableRow;
