import { useMemo, useState } from 'react';
import TextInputForm from '@F/materialUI/TextInputForm';
import { Button } from '@material-ui/core';
import Loading from '@F/Loading';
import DialogModal from '@F/DialogModal';
import { useFetch, useInput } from '@HOOK/';
import { cashplaceMessageApi } from '@API/cashplace';
import * as S from './styles';

function SendMessage(): JSX.Element {
  const [tokens, setTokens] = useState<string>('');
  const TokensInput = <textarea id="TokensInput" value={tokens} onChange={(e): void => setTokens(e.target.value)} />;
  const tokenList = useMemo<string[]>(
    () =>
      tokens
        .split('\n')
        .map((token) => token.trim())
        .filter((token) => token),
    [tokens]
  );

  const [title, setTitle] = useInput('CASHPLACE');
  const TitleInput = <TextInputForm name="TitleInput" label="Title" value={title} onChange={setTitle} />;

  const [body, setBody] = useInput('');
  const BodyInput = <TextInputForm name="BodyInput" label="Body" value={body} onChange={setBody} />;

  const [icon, setIcon] = useInput('logo_white');
  const IconInput = <TextInputForm name="IconInput" label="Icon" value={icon} onChange={setIcon} />;

  const [minute, setMinute] = useInput('');
  const MinuteInput = (
    <TextInputForm name="MinuteInput" label="분 딜레이로" value={minute} onChange={setMinute} type="number" />
  );
  const [count, setCount] = useInput('');
  const CountInput = <TextInputForm name="CountInput" label="개 씩" value={count} onChange={setCount} type="number" />;

  const [time, setTime] = useInput('');
  const TimeInput = <TextInputForm name="TimeInput" label="YYYY-MM-DD HH:mm:ss" value={time} onChange={setTime} />;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { callApi, isLoading } = useFetch();
  const pushMessage = async () => {
    const response = await callApi(cashplaceMessageApi.pushFcm, {
      tokens: tokenList,
      title,
      body,
      icon,
      delay: {
        delay: Number(minute),
        delay_per: Number(count)
      },
      sendAt: time || null
    });
    if (response) return response.data;
    return null;
  };

  return (
    <S.StyledSendMessage>
      <section>
        <p>FCM 토큰</p>
        {TokensInput}
      </section>
      <section>
        <p>제목</p>
        {TitleInput}
      </section>
      <section>
        <p>내용</p>
        {BodyInput}
      </section>
      <section>
        <p>아이콘</p>
        {IconInput}
      </section>
      <section>
        <p>딜레이</p>
        {MinuteInput}
        <p />
        {CountInput}
      </section>
      <section>
        <p>예약</p>
        {TimeInput}
      </section>
      <section>
        <Button variant="outlined" color="primary" onClick={() => setIsModalOpen(true)}>
          {tokenList.length}
          개의 디바이스에 메시지 보내기
        </Button>
      </section>

      {isLoading && <Loading />}
      <DialogModal
        title="FCM 보내기"
        description={`정말로 ${tokenList.length}개의 디바이스에 푸시를 예약하실건가요? (${time})`}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        confirmButtonDidClicked={pushMessage}
      />
    </S.StyledSendMessage>
  );
}
export default SendMessage;
