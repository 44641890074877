/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import { toastSuccessMessage } from '@FUNC/toast';
import { Component, ReactNode } from 'react';

let thrownError: string;
export const setThrownError = (_thrownError: string): void => {
  thrownError = _thrownError;
};

interface Props {
  children: ReactNode;
}
interface State {
  error: Error | null;
}

export default class AppErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    // 다음 렌더링에서 폴백 UI가 보이도록 상태를 업데이트 합니다.
    return { error };
  }

  public render(): ReactNode {
    if (this.state.error === null) return this.props.children;

    const copyCode = () => {
      navigator.clipboard.writeText(thrownError);
      toastSuccessMessage('클립보드로 에러 코드가 복사되었습니다. 매니저 관리자(윤준호)에게 문의해주세요.');
    };

    return (
      <main>
        <h1>Error Report</h1>
        <p>에러 코드 복사 버튼을 이용해 관리자에게 문의해주세요.</p>
        <p>error boundary state : {this.state.error.message}</p>
        <ul className="thrown-error">
          {Object.entries(JSON.parse(thrownError)).map(([key, value]) => (
            <li>{`${key}:${JSON.stringify(value)}`}</li>
          ))}
        </ul>
        <button onClick={copyCode}>에러 코드 복사</button>
      </main>
    );
  }
}
