import { cookUsersApi } from '@API/manager/cook';

export function fetchCookUsers(page, perPage, params) {
  return cookUsersApi.getCookUsers({ page, perPage, ...params }).then((response) => response.data);
}

export function updateCookUser(params) {
  return cookUsersApi.putUser(params).then((response) => response.data);
}

export function deleteUserDevice(userId) {
  return cookUsersApi.deleteUserDevice({ userId });
}
