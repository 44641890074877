import { axiosInstancesManager } from '@API/axios';
import type { AxiosPromise } from 'axios';
import type { LoginParams, AuthResponse } from '@TS/auth';

class AuthApi {
  login({ username, password }: LoginParams): AxiosPromise<AuthResponse> {
    return axiosInstancesManager.manager.post('/auth/login', { username, password });
  }

  refresh(): AxiosPromise<AuthResponse> {
    return axiosInstancesManager.manager.post('/auth/refresh');
  }
}

export default new AuthApi();
