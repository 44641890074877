import moment from 'moment';
import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'STORE_MANAGER';

/** initial state */
const INITIAL_STATE = {
  stores: [],
  openedStoreIds: [],
  count: 0,
  page: 1,
  perPage: 15,
  params: {
    order: 'desc',
    orderBy: 'store_storeid',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  },
  isLoading: false,
  fetchType: 'default', // 'default' | 'FPID' | 'PID'
  // TODO: 앞으로 isLoading도 status에 합쳐서 리팩토링을 진행할 예정
  status: 'default' //  default | success | failure
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  SET_NEW_STORES: `${PREFIX}/SET_NEW_STORES`,
  SET_NEW_STORE: `${PREFIX}/SET_NEW_STORE`,
  FETCH_STORES_CSV: `${PREFIX}/FETCH_STORES_CSV`,
  FETCH_STORES: `${PREFIX}/FETCH_STORES`,
  COMPLETE_STORE: `${PREFIX}/COMPLETE_STORE`,
  CLOSE_STORE: `${PREFIX}/CLOSE_STORE`,
  DISCARD_STORE: `${PREFIX}/DISCARD_STORE`,
  DELETE_STORE: `${PREFIX}/DELETE_STORE`,
  SET_STATUS: `${PREFIX}/SET_STATUS`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  setNewStores: (stores) => ({ type: types.SET_NEW_STORES, stores }),
  setNewStore: (store) => ({ type: types.SET_NEW_STORE, store }),
  fetchStoresCsv: () => ({ type: types.FETCH_STORES_CSV }),
  fetchStores: (fetchType) => ({ type: types.FETCH_STORES, fetchType }),
  completeStore: (storeInfo) => ({ type: types.COMPLETE_STORE, storeInfo }),
  closeStore: (pid) => ({ type: types.CLOSE_STORE, pid }),
  discardStore: (pid) => ({ type: types.DISCARD_STORE, pid }),
  deleteStore: (pid) => ({ type: types.DELETE_STORE, pid }),
  setStatus: (value) => ({ type: types.SET_STATUS, value })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.SET_STATUS]: (draft, action) => {
    draft.status = action.value;
  },
  [types.RESET]: (draft) => {
    draft.stores = [];
    draft.openedStoreIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_NEW_STORES]: (draft, action) => {
    draft.stores = action.stores;
    draft.openedStoreIds = [];
  },
  [types.SET_NEW_STORE]: (draft, action) => {
    const index = draft.stores.findIndex((store) => store.pid === action.store.pid);

    if (index >= 0) {
      draft.stores[index] = action.store;
    }
  },
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  }
});
export default reducer;
