import { useInView } from 'react-intersection-observer';
import dayjs from 'dayjs';
import { TableRow, TableCell } from '@material-ui/core';
import ToggleBodyCell from '@F/table/cells/ToggleBodyCell';
import DetailTableRow from './detailTableRow/DetailTableRow';
import FpImageValidationStatusBadge from './FpImageValidationStatusBadge';

export default function FingerprintTableItem({ isExpanded, fingerprint, columns, onClick, tableScrollViewRef }) {
  const { inView, ref } = useInView({ rootMargin: '4000px', root: tableScrollViewRef.current });

  const { fp_image_validation, fp_created_at, phone } = fingerprint;
  const tableCells = {
    ...fingerprint,
    fp_image_validation: <FpImageValidationStatusBadge status={fp_image_validation} />,
    fp_created_at: dayjs(fp_created_at).format('YYYY-MM-DD HH:mm:ss'),
    phone: phone ?? ''
  };

  return (
    <>
      <TableRow hover onClick={onClick}>
        <ToggleBodyCell onState={isExpanded} />
        {columns.map(({ name }, i) => (
          <TableCell key={i} align="center">
            {tableCells[name]}
          </TableCell>
        ))}
      </TableRow>
      <div ref={ref} />
      <DetailTableRow isExpanded={isExpanded} colSpan={columns.length + 1} fingerprint={fingerprint} inView={inView} />
    </>
  );
}
