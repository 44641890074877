import { useState, useCallback, useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import { Polygon } from '@API/manager/polygon';
import PolygonToDrawingMode from './PolygonToDrawingMode';
import MapControl from './MapControl';
import useDrawingManager from './useDrawingManager';
import { MapWrapper } from '../styles';

type Props = {
  drawingManager: naver.maps.drawing.DrawingManager | null;
  setDrawingManager: Dispatch<SetStateAction<naver.maps.drawing.DrawingManager | null>>;
  marker: naver.maps.Marker;
  setLatitude: Dispatch<SetStateAction<string>>;
  setLongitude: Dispatch<SetStateAction<string>>;
  setAddr: Dispatch<SetStateAction<string>>;
  setRoad: Dispatch<SetStateAction<string>>;
  originGeoJsons?: Polygon;
  isEdit: boolean;
  isLoading?: boolean;
};

const PolygonNaverMap = ({
  drawingManager,
  setDrawingManager,
  marker,
  setLatitude,
  setLongitude,
  setAddr,
  setRoad,
  originGeoJsons,
  isEdit,
  isLoading = false
}: Props): JSX.Element => {
  const mapId = 'polygon_naver_map';
  const [isInitial, setIsInitial] = useState(false);
  const [naverMap, setNaverMap] = useState<naver.maps.Map | null>(null);

  const { drawingOptions } = useDrawingManager();

  const loadMap = useCallback(() => {
    const mapOptions: naver.maps.MapOptions = {
      zoom: 15,
      zoomControl: true,
      zoomControlOptions: {
        style: naver.maps.ZoomControlStyle.LARGE,
        position: naver.maps.Position.TOP_RIGHT
      },
      maxZoom: isEdit ? 19 : 21,
      tileSpare: 5,
      mapTypeId: naver.maps.MapTypeId.NORMAL
      // mapTypeControl: true
    };

    const initialMap = new naver.maps.Map(mapId, mapOptions);
    setNaverMap(initialMap);
  }, [isEdit]);

  const initDrawingManager = useCallback(() => {
    if (!naverMap) {
      return;
    }

    const dm = new naver.maps.drawing.DrawingManager({ ...drawingOptions, map: naverMap });
    setDrawingManager(dm);
  }, [drawingOptions, naverMap, setDrawingManager]);

  const initialMap = useCallback(() => {
    naver.maps.Event.once(naverMap, 'init_stylemap', () => {
      initDrawingManager();
      if (marker && naverMap) {
        marker.setMap(naverMap);
        naverMap.setCenter(marker.getPosition());
      }
      setIsInitial(true);
    });
  }, [naverMap, initDrawingManager, marker]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!naverMap) {
      loadMap();
    } else {
      initialMap();
    }
  }, [initialMap, isInitial, isLoading, loadMap, naverMap]);

  return (
    <>
      <MapWrapper id={mapId}>
        {isInitial && naverMap && drawingManager && (
          <MapControl
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setAddr={setAddr}
            setRoad={setRoad}
            naverMap={naverMap}
            marker={marker}
            drawingManager={drawingManager}
            isEdit={isEdit}
            isLoading={isLoading}
          />
        )}
        {!isLoading && drawingManager && isEdit && naverMap && (
          <PolygonToDrawingMode naverMap={naverMap} drawingManager={drawingManager} originGeoJsons={originGeoJsons} />
        )}
      </MapWrapper>
    </>
  );
};

export default PolygonNaverMap;
