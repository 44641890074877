import { useState, useEffect } from 'react';
import { storesApi } from '@API/manager';
import TextInputForm from '@F/materialUI/TextInputForm';
import DialogModal from '@F/DialogModal';
import Loading from '@F/Loading';
import ResponseAlert from '@F/ResponseAlert';
import { useFetch, useInput } from '@HOOK/';

function UpdateFpDialog(props) {
  const { open, setOpen, currentPid, fpid, updateType, loadFingerPrintsData } = props;
  const { callApi, isLoading, isError, error } = useFetch();
  const [responseAlertOpen, setResponseAlertOpen] = useState(false);

  const [nextUpdateValue, setNextUpdateValue, clearInput] = useInput('');
  const updatefPid = async () => {
    const response = await callApi(storesApi.patchStoreMap, {
      pid: nextUpdateValue,
      cid: 0,
      bid: 0,
      fpid: fpid.fpid
    });
    setResponseAlertOpen(true);
    if (response) {
      loadFingerPrintsData(currentPid);
      setOpen(false);
    }
  };

  const updateFloor = async () => {
    const response = await callApi(storesApi.updateFingerprintsFloor, {
      fpid: fpid.fpid,
      floor: nextUpdateValue
    });
    setResponseAlertOpen(true);
    if (response) {
      loadFingerPrintsData(currentPid);
      setOpen(false);
    }
  };

  const Input = (
    <TextInputForm name="updateInput" label="updateInput" onChange={setNextUpdateValue} value={nextUpdateValue} />
  );

  useEffect(() => {
    clearInput();
  }, [clearInput, updateType]);

  return (
    <>
      {isLoading && <Loading />}
      <ResponseAlert
        error={isError}
        open={responseAlertOpen}
        handleClose={() => setResponseAlertOpen(false)}
        message={isError ? String(error) : '성공하였습니다.'}
      />
      <DialogModal
        title={updateType === 'pid' ? 'fpid 수정' : 'floor 수정'}
        description={
          updateType === 'pid'
            ? '다른 pid와 매핑하시겠습니까? 매핑 하고자하는 pid(숫자)를 입력해주세요.'
            : '다른 floor와 매핑하시겠습니까? 매핑 하고자하는 floor(숫자)를 입력해주세요.'
        }
        open={open}
        setOpen={setOpen}
        confirmButtonDidClicked={updateType === 'pid' ? updatefPid : updateFloor}
        confirmButtonDisabled={nextUpdateValue === ''}
      >
        {Input}
      </DialogModal>
    </>
  );
}
export default UpdateFpDialog;
