import { useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import TableHeadCell from '@F/table/cells/TableHeadCell';

import { Grid, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper } from '@material-ui/core';
import { SdkConfiguration } from '@API/manager/sdk/types';
import { useManageSdkConfigs } from '../useManageCompanies';
import { SDK_CONFIGS_TABLE_HEAD_CELLS } from '../constant';
import TableItem from './CompanyTableItem';

export default function CompaniesTable(): JSX.Element {
  const { pagedSdkConfigs, sortSdkConfigs, getApiStatus, orderBy } = useManageSdkConfigs();
  const [orderName, setOrderName] = useState<keyof SdkConfiguration>(orderBy.name);

  const handleClickHeadCell = (
    _: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    _name?: string,
    toggledOrderType?: OrderType
  ): void => {
    if (!_name || !toggledOrderType) return;

    const name = _name as keyof SdkConfiguration;

    setOrderName(name);
    sortSdkConfigs(name, toggledOrderType);
  };

  return (
    <Wrapper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {SDK_CONFIGS_TABLE_HEAD_CELLS.map(({ name, label, defaultOrderType }, i) => (
                <TableHeadCell
                  key={i}
                  name={name}
                  label={label}
                  defaultOrderType={defaultOrderType}
                  onClick={handleClickHeadCell}
                  isActive={Boolean(name === orderName)}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getApiStatus === 'loading' ? (
              <TableRow>
                <TableCell align="center" colSpan={SDK_CONFIGS_TABLE_HEAD_CELLS.length}>
                  <Grid container justifyContent="center">
                    loading ...
                  </Grid>
                </TableCell>
              </TableRow>
            ) : getApiStatus === 'idle' ? (
              <TableRow>
                <TableCell align="center" colSpan={SDK_CONFIGS_TABLE_HEAD_CELLS.length}>
                  <span>검색해주세요.</span>
                </TableCell>
              </TableRow>
            ) : getApiStatus === 'success' ? (
              pagedSdkConfigs.length ? (
                pagedSdkConfigs.map((sdkConfig) => <TableItem key={sdkConfig.id} sdkConfig={sdkConfig} />)
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <span>검색된 결과가 없습니다.</span>
                  </TableCell>
                </TableRow>
              )
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}
