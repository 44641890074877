import { MobileOS, SdkConfiguration } from '@API/manager/sdk/types';

export const SDK_CONFIGS_INITIAL_ORDER_BY: {
  name: keyof SdkConfiguration;
  type: OrderType;
} = {
  name: 'updated_at',
  type: 'DESC'
};

export const SDK_CONFIGS_TABLE_HEAD_CELLS: {
  name: keyof SdkConfiguration;
  label: string;
  defaultOrderType?: OrderType;
}[] = [
  {
    name: 'id',
    label: 'id'
  },
  {
    name: 'name',
    label: 'company'
  },
  {
    name: 'client_id',
    label: 'client_id'
  },
  {
    name: 'updated_at',
    label: '작성일시',
    defaultOrderType: 'DESC'
  },
  {
    name: 'created_at',
    label: '생성일시',
    defaultOrderType: 'DESC'
  },
  {
    name: 'published_at',
    label: '상태'
  }
];

export const SDK_CONFIG_STATUSES = [
  {
    name: 'ALL',
    label: '전체'
  },
  {
    name: 'WRITING',
    label: '작성 중'
  },
  {
    name: 'COMPLETED',
    label: '작성 완료'
  }
] as const;

export const X_MEMBERSHIPS = [
  'BASIC',
  'PREMIUM',
  'LITE 100K',
  'LITE 200K',
  'LITE 300K',
  'LITE 400K',
  'LITE 1M'
] as const;

export const X_USER_ROLES = ['user', 'manager'] as const;

export const MOBILE_OSES: { name: MobileOS; label: string }[] = [
  { name: 'android', label: 'Android' },
  { name: 'ios', label: 'iOS' }
];
