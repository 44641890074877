import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class CookUsersApi {
  getCookUsers({ username, name, status = 1, kind = 1, page = 1, perPage = 1000, userType, teamId }) {
    return axiosInstancesManager.manager.get('/cook/users/search', {
      params: {
        username,
        name,
        status,
        kind,
        page,
        per_page: perPage,
        user_type: userType,
        team_id: teamId
      }
    });
  }

  postUser({ userName, email, password, kind, type, name, jumin, phone, device, teamId }) {
    return axiosInstancesManager.manager.post('/cook/users', {
      username: userName,
      password,
      kind,
      type,
      name,
      email,
      jumin,
      phone,
      device,
      team_id: teamId
    });
  }

  putUser({
    userId,
    userName,
    password,
    kind,
    type,
    name,
    email,
    jumin,
    phone,
    device,
    teamId,
    status,
    payType,
    dailyMinimum,
    dailySalary,
    rank
  }) {
    return axiosInstancesManager.manager.put(`/cook/users/${userId}`, {
      username: userName,
      password,
      kind,
      type,
      name,
      email,
      jumin,
      phone,
      device,
      team_id: teamId,
      status,
      pay_type: payType,
      daily_min: dailyMinimum,
      daily_sal: dailySalary,
      rank
    });
  }

  getCookTeams(flag) {
    return axiosInstancesManager.manager.get('/cook/users/teams', {
      params: { flag }
    });
  }

  initToken(userName) {
    return axiosInstancesManager.cook.put(`/cook/user/token/reset/${userName}`);
  }

  postVersion({ integerVersion, version, minimumVersion, comment, packageName, downloadLink }) {
    return axiosInstancesManager.manager.post('/cook/versions', {
      v: integerVersion,
      v_str: version,
      minimum_v: minimumVersion,
      comment,
      package_name: packageName,
      download_link: downloadLink
    });
  }

  putVersion({ versionId, integerVersion, version, minimumVersion, comment, packageName, downloadLink, status }) {
    return axiosInstancesManager.manager.put(`/cook/versions/${versionId}`, {
      v: integerVersion,
      v_str: version,
      minimum_v: minimumVersion,
      comment,
      package_name: packageName,
      download_link: downloadLink,
      status
    });
  }

  getCookVersions({ status }) {
    return axiosInstancesManager.manager.get('/cook/versions', { params: { status } });
  }

  deleteVersion(versionId) {
    return axiosInstancesManager.manager.delete(`/cook/versions/${versionId}`);
  }

  getCookDevice() {
    return axiosInstancesManager.manager.get('/cook/users/device');
  }

  manageUserDevice({ userId, approve }) {
    return axiosInstancesManager.manager.post(`/cook/users/${userId}/device/approve`, { approval: approve });
  }

  deleteUserDevice({ userId }) {
    return axiosInstancesManager.manager.delete(`/cook/users/${userId}/device`);
  }
}

export default new CookUsersApi();
