import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { originOptions } from '@P/complex/addComplex/inputs/Inputs/OriginOptions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button, Checkbox, Grid } from '@material-ui/core';
import CategoryModal from '@F/modal/CategoryModal';
import BrandModal from '@F/modal/BrandModal';
import ComplexInput from '@F/input/ComplexInput';
import Chip from '@material-ui/core/Chip';
import { useDispatch } from 'react-redux';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/complex/state';
import * as S from './styles';

function InnerDetail({ detail, newLocation }) {
  const classes = S.useStyles();

  const [name, setName] = useInput(detail.name);
  const NameInput = <TextInputForm name="NameInput" label="장소명" onChange={setName} value={name} required />;

  const [nameOrigin, setNameOrigin] = useInput(
    originOptions.filter((option) => option.key === Number(detail.qa_name))[0].value
  );
  const NameOriginSelect = (
    <SelectForm
      name="storeNameOriginSelect"
      label="장소명 출처"
      options={originOptions.map((option) => option.value)}
      value={nameOrigin}
      onSelect={setNameOrigin}
      disabled={false}
    />
  );

  const [branchName, setBranchName] = useInput(detail.branch_name);
  const BranchNameInput = (
    <TextInputForm name="BranchNameInput" label="지점명" onChange={setBranchName} value={branchName} />
  );

  const [branchNameOrigin, setBranchNameOrigin] = useInput(
    originOptions.filter((option) => option.key === Number(detail.qa_branchname))[0].value
  );
  const BranchNameOriginSelect = (
    <SelectForm
      name="branchNameOriginSelect"
      label="브랜드 이름 출처"
      options={originOptions.map((option) => option.value)}
      value={branchNameOrigin}
      onSelect={setBranchNameOrigin}
      disabled={false}
    />
  );

  const [oldAdress, setOldAdress] = useInput(detail.addr); // 지번 주소
  const OldAdressInput = (
    <TextInputForm name="OldAdressInput" label="지번 주소" onChange={setOldAdress} value={oldAdress} required />
  );

  const [newAdress, setNewAdress] = useInput(detail.addr_road); // 도로명 주소
  const NewAdressInput = (
    <TextInputForm name="BranchNameInput" label="도로명 주소" onChange={setNewAdress} value={newAdress} />
  );

  const [adressOrigin, setAdressOrigin] = useInput(
    originOptions.filter((option) => option.key === Number(detail.qa_addr))[0].value
  );
  const AdressOriginSelect = (
    <SelectForm
      name="adressOriginSelect"
      label="주소 출처"
      options={originOptions.map((option) => option.value)}
      value={adressOrigin}
      onSelect={setAdressOrigin}
    />
  );

  const initialState = useMemo(() => (detail.parent ? { id: detail.parent.cid, ...detail.parent } : null), []);
  const [parentComplex, setParentComplex] = useState(detail.parent ? initialState : null);
  const parentComplexInput = (
    <ComplexInput
      label="부모 복합몰"
      initialState={initialState}
      initialValueType={detail.parent ? 'name' : 'id'}
      onChange={setParentComplex}
    />
  );

  const [category, setCategory] = useState({
    korName: detail.category_kor,
    code: detail.category_code
  });
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const CategoryInput = (
    <TextInputForm
      name="CategoryInput"
      label="카테고리"
      onClick={() => setIsCategoryModalVisible(!isCategoryModalVisible)}
      value={`${category.korName}(${category.code})`}
      required
    />
  );

  const [brands, setBrands] = useState(detail.brands);
  const [isBrandModalVisible, setIsBrandModalVisible] = useState(false);
  const BrandsInput = (
    <TextInputForm
      name="BrandsInput"
      label="브랜드"
      onClick={() => setIsBrandModalVisible(!isBrandModalVisible)}
      value={brands.map((brand) => `${brand.name}(${brand.bid ?? brand.id})`).join(', ')}
    />
  );

  const [isShownInLoplatService, setIsShownInLoplatService] = useState(detail.service === 1); // 로플랫 X/ i 노출
  const IsShownInLoplatServiceOption = (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={isShownInLoplatService}
          onChange={() => setIsShownInLoplatService(!isShownInLoplatService)}
        />
      }
      label="로플랫 X/i 노출"
    />
  );

  const [nid, setNID] = useInput(detail.nid);
  const NIDInput = <TextInputForm name="NIDInput" label="NID" onChange={setNID} value={`${nid}`} />;
  const NIDChip = (
    <Chip
      label="NAVER 확인"
      clickable
      color="primary"
      onClick={() => window.open(`https://map.naver.com/p/entry/place/${nid}`)}
    />
  );

  const dispatch = useDispatch();
  const updateComplex = () => {
    dispatch(
      actions.updateComplex(detail, {
        cid: detail.cid,
        name,
        branchName,
        categoryCode: category.code,
        addr: oldAdress,
        addrRoad: newAdress,
        lat: newLocation.lat,
        lng: newLocation.lng,
        parent: parentComplex ? parentComplex.id : null,
        service: isShownInLoplatService,
        qaName: originOptions.filter((option) => option.value === nameOrigin)[0].key,
        qaBranchName: originOptions.filter((option) => option.value === branchNameOrigin)[0].key,
        qaAddress: originOptions.filter((option) => option.value === adressOrigin)[0].key,
        nid: nid.length > 0 ? Number(nid) : null,
        bids: brands.map((brand) => brand.bid ?? brand.id)
      })
    );
  };

  const CompleteButton = (
    <Button variant="contained" color="primary" className={classes.completeButton} onClick={updateComplex}>
      확인
    </Button>
  );

  return (
    <S.StyledComplexInnerDetail>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {NameInput}
        </Grid>
        <Grid item xs={2}>
          {NameOriginSelect}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {BranchNameInput}
        </Grid>
        <Grid item xs={2}>
          {BranchNameOriginSelect}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {OldAdressInput}
        </Grid>
        <Grid item xs={2}>
          {AdressOriginSelect}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {NewAdressInput}
        </Grid>
        <Grid item xs={2} />
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {parentComplexInput}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {CategoryInput}
          <CategoryModal open={isCategoryModalVisible} setOpen={setIsCategoryModalVisible} setValue={setCategory} />
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {BrandsInput}
          <BrandModal open={isBrandModalVisible} setOpen={setIsBrandModalVisible} setValue={setBrands} />
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {IsShownInLoplatServiceOption}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {NIDInput}
          {NIDChip}
        </Grid>
      </S.Row>

      <S.ButtonRow>{CompleteButton}</S.ButtonRow>
    </S.StyledComplexInnerDetail>
  );
}
export default InnerDetail;

InnerDetail.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired
};
