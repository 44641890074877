import styled from 'styled-components';

export const StyledNaverMap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

export const NaverMap = styled.div`
  width: 100%;
  height: 100%;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100px;
  height: 50px;
`;
