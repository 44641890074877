import { User, UserRoleRank } from '@TS/user';
import { USER_ROLES } from '@/constants/userRole';

export const isPermitted = (user: User, permission: UserRoleRank): boolean => {
  const userRoleRank = USER_ROLES[user.user_claims.role];

  return userRoleRank <= permission;
};

export const userIsCustomer = (user: User): boolean => {
  const userRoleRank = USER_ROLES[user.user_claims.role];
  return userRoleRank === 5;
};
