import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { fetchPolygonByCid } from '@P/complex/complexManager/redux/slice';
import InnerMap from '@P/complex/complexManager/tableSection/detail/InnerMap';
import InnerDetail from '@P/complex/complexManager/tableSection/detail/InnerDetail';
import PinTier2 from '@I/map/pin-blue.png';
import RedPin from '@I/map/pin-red.png';
import * as S from './styles';

function Detail({ id, data }) {
  const dispatch = useDispatch();
  const detail = useMemo(() => data.find((item) => item.cid === id), [data, id]);
  const polygon = useSelector((state) => state.polygonReucer[detail.cid]);

  const fetchPolygon = useCallback(() => {
    dispatch(fetchPolygonByCid({ cid: detail.cid }));
  }, [detail.cid, dispatch]);

  useEffect(() => {
    if (!detail.cid) {
      return;
    }

    if (!polygon) {
      fetchPolygon();
    }
  }, [detail, detail.cid, dispatch, fetchPolygon, polygon]);

  const [newLocation, setNewLocation] = useState({ lat: detail.lat, lng: detail.lng });
  const [markers, setMarkers] = useState([
    {
      type: 'CID',
      lat: newLocation.lat,
      lng: newLocation.lng,
      image: PinTier2,
      size: [13 * 2.5, 17 * 2.5],
      name: detail.cid,
      id: `${detail.cid}-new-location`
    },
    {
      type: 'CID',
      lat: detail.lat,
      lng: detail.lng,
      image: RedPin,
      size: [13 * 2.5, 17 * 2.5],
      name: detail.cid,
      id: `${detail.cid}-location`
    }
  ]);

  const handleEmitCoordinates = (coordinates) => {
    setNewLocation({
      lat: coordinates.y,
      lng: coordinates.x
    });
    setMarkers((prev) =>
      prev.map((marker, i) =>
        i === 0
          ? {
              type: 'CID',
              lat: coordinates.y,
              lng: coordinates.x,
              image: PinTier2,
              size: [13 * 2.5, 17 * 2.5],
              name: detail.cid,
              id: `${detail.cid}-new-location`
            }
          : marker
      )
    );
  };

  return (
    <S.StyledComplexInnerContainer>
      <Grid container spacing={1}>
        <Grid item xs>
          <InnerMap
            detail={detail}
            polygon={polygon ?? null}
            markers={markers}
            onEmitCoordinates={handleEmitCoordinates}
          />
        </Grid>
        <Grid item xs>
          <InnerDetail detail={detail} newLocation={newLocation} />
        </Grid>
      </Grid>
    </S.StyledComplexInnerContainer>
  );
}
export default Detail;

Detail.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired
};
