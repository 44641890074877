import type {
  FetchImageNamesParams,
  FetchImageResultParams,
  FetchStudiesParams,
  UpdateStudyParams
} from '@TS/cashplace/study';
import { axiosInstancesManager } from '@API/axios';

/* eslint class-methods-use-this: 0 */

class CashplaceStudiesApi {
  getStudies({
    perPage,
    page,
    userId,
    siteId,
    studyId,
    status,
    verbosity,
    startDate,
    endDate,
    dateType,
    category,
    placename,
    os,
    deviceIds
  }: FetchStudiesParams) {
    return axiosInstancesManager.manager.get('/cashplace/studies', {
      params: {
        per_page: perPage,
        page,
        user_id: userId,
        site_id: siteId,
        study_id: studyId,
        status,
        verbosity,
        start_date: startDate,
        end_date: endDate,
        date_type: dateType,
        category,
        placename,
        os,
        device_ids: deviceIds
      }
    });
  }

  updateStudy({ userId, images, inspector, result, siteId, studyId }: UpdateStudyParams) {
    return axiosInstancesManager.manager.put(`/cashplace/studies/${studyId}`, {
      user_id: userId,
      images,
      inspector,
      result,
      site_id: siteId
    });
  }

  getStudyImageResults({ studyId }: FetchImageResultParams) {
    return axiosInstancesManager.manager.get(`/cashplace/studies/${studyId}/image-results`);
  }

  getStudyImageNames({ studyId }: FetchImageNamesParams) {
    return axiosInstancesManager.manager.get(`/cashplace/studies/${studyId}/image-names`);
  }

  getCDevices() {
    return axiosInstancesManager.manager.get('/cashplace/studies/devices');
  }

  getStudiesReport() {
    return axiosInstancesManager.manager.get('/cashplace/studies/report');
  }
}

export default new CashplaceStudiesApi();
