import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { spacing } from 'loplat-ui';
import { Grid, Button } from '@material-ui/core';
import SelectForm from '@F/materialUI/SelectForm';
import TextInputForm from '@F/materialUI/TextInputForm';

import { toastWarningMessage } from '@FUNC/toast';
import { kindObj, stateObj, sizeArr } from '@P/polygon/redux/polygonList';
import usePolygonTableFilter from './usePolygonTableFilter';
import { filterOpts } from './formOpts';

type ChangeSelectType = ChangeEvent<{ name?: string; value?: unknown }>;
type ChangeTextType = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

const PolygonTableFilter = ({ setPage }: { setPage: Dispatch<SetStateAction<number>> }): JSX.Element => {
  const { getParams, setParams } = usePolygonTableFilter();
  const params = getParams();
  const [kind, setKind] = useState(params.kind);
  const [query, setQuery] = useState(params.q);
  const [perPage, setPerPage] = useState(params.per_page);
  const [state, setState] = useState(params.state);
  const [id, setId] = useState(params.id);

  const changeHandler = (e: ChangeSelectType | ChangeTextType, id: string) => {
    const { value } = e.target;
    switch (id) {
      case 'kind':
        return setKind(value as keyof typeof kindObj);
      case 'query':
        return setQuery(value as typeof sizeArr[number]);
      case 'size':
        return setPerPage(value as string);
      case 'state':
        return setState(value as keyof typeof stateObj);
      case 'ID':
        if (Number.isNaN(Number(value))) {
          break;
        }
        return setId(value as string);
      default:
        return null;
    }
  };

  const setSliceByParms = () => {
    if (query.trim() && query.length < 2) {
      toastWarningMessage('검색어를 2글자 이상 입력해주십시오.');
      return;
    }
    const newFilter = {
      ...getParams(),
      per_page: perPage,
      state,
      kind,
      id: '',
      q: query,
      page: 1
    };
    setPage(1);
    setParams(newFilter);
  };

  const setSliceByID = () => {
    if (!id.trim()) {
      return;
    }
    const newFilter = {
      ...getParams(),
      id
    };
    setPage(1);
    setParams(newFilter);
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: `${spacing(5)}px` }}>
      <Grid item xs={1}>
        <SelectForm value={kind} onSelect={changeHandler} {...filterOpts.kindOpts} />
      </Grid>
      <Grid item xs={4}>
        <TextInputForm
          value={query}
          onChange={changeHandler}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSliceByParms();
            }
          }}
          {...filterOpts.queryOpts}
          error={Boolean(query.trim()) && query.length < 2}
        />
      </Grid>
      <Grid item xs={2}>
        <SelectForm value={perPage} onSelect={changeHandler} {...filterOpts.sizeOpts} />
      </Grid>
      <Grid item xs={1}>
        <SelectForm value={state} onSelect={changeHandler} {...filterOpts.stateOpts} />
      </Grid>
      <Grid item xs={1}>
        <Button onClick={() => setSliceByParms()} {...filterOpts.btnOpts}>
          조회
        </Button>
      </Grid>
      <Grid item xs={2}>
        <TextInputForm
          value={id}
          onChange={changeHandler}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSliceByID();
            }
          }}
          {...filterOpts.idOpts}
        />
      </Grid>
      <Grid item xs={1}>
        <Button onClick={() => setSliceByID()} {...filterOpts.btnOpts}>
          검색
        </Button>
      </Grid>
    </Grid>
  );
};

export default PolygonTableFilter;
