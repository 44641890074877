import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastSuccessMessage, toastErrorMessage } from '@FUNC/toast';
import { handleErrorWithToast } from '@FUNC/error';
import { downloadCsv } from '@FUNC/download';
import { actions, types } from './state';
import { fetchStoresCsv } from './api';

import * as api from './api';

export function* fetchByTypes({ fetchType }) {
  yield put(actions.setValue('fetchType', fetchType));
  if (fetchType === 'default') yield call(fetchData);
  if (fetchType === 'FPID') yield call(fetchDataByFPID);
  if (fetchType === 'PID') yield call(fetchDataByPID);
}

export function* fetchData() {
  const { page, perPage, params } = yield select((state) => state.storeManager);

  yield put(actions.setLoading(true));
  try {
    const { items, count } = yield call(api.fetchStores, page, perPage, params);
    yield put(actions.setNewStores(items));
    yield put(actions.setValue('count', count));
  } catch (e) {
    handleErrorWithToast(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchCsvData() {
  const { page, perPage, params } = yield select((state) => state.storeManager);

  yield put(actions.setLoading(true));
  try {
    const { data, fileName } = yield call(fetchStoresCsv, page, perPage, params);

    downloadCsv(data, fileName);
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchDataByFPID() {
  const { page, perPage } = yield select((state) => state.storeManager);
  const { fpid } = yield select((state) => state.storeManager.params);
  yield put(actions.setLoading(true));
  try {
    const data = yield call(api.fetchStores, page, perPage, { fpid });
    yield put(actions.setNewStores(data.items));
    yield put(actions.setValue('count', data.count));
    toastSuccessMessage();
  } catch (e) {
    handleErrorWithToast(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchDataByPID() {
  const { pid } = yield select((state) => state.storeManager.params);

  yield put(actions.setLoading(true));
  try {
    const data = yield call(api.fetchStoresByPID, pid);

    yield put(actions.setNewStores([data]));
    yield put(actions.setValue('count', 1));
    toastSuccessMessage();
  } catch (e) {
    handleErrorWithToast(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

function* storeAction(callback, param) {
  yield put(actions.setLoading(true));
  yield put(actions.setStatus('default'));

  try {
    yield call(callback, param);
    yield put(actions.setStatus('success'));
    yield put(actions.setStatus('default'));
    toastSuccessMessage();
  } catch (e) {
    yield put(actions.setStatus('failure'));
    handleErrorWithToast(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* completeStore({ storeInfo }) {
  function* callback(param) {
    yield call(api.completeStore, param);
    const data = yield call(api.fetchStoresByPID, param.pid);
    yield put(actions.setNewStore(data));
  }
  yield call(storeAction, callback, storeInfo);
}

// NOTE: 폐점
export function* closeStore({ pid }) {
  yield call(storeAction, api.closeStore, pid);
}
// NOTE: 휴지통
export function* discardStore({ pid }) {
  yield call(storeAction, api.discardStore, pid);
}
// NOTE: 삭제
export function* deleteStore({ pid }) {
  yield call(storeAction, api.deleteStore, pid);
}

export default function* sagaReducer() {
  // eslint-disable-line func-names
  yield all([
    takeLeading(types.FETCH_STORES, fetchByTypes),
    takeLeading(types.FETCH_STORES_CSV, fetchCsvData),
    takeLeading(types.COMPLETE_STORE, completeStore),
    takeLeading(types.CLOSE_STORE, closeStore),
    takeLeading(types.DISCARD_STORE, discardStore),
    takeLeading(types.DELETE_STORE, deleteStore)
  ]);
}
