import { Children, cloneElement, useState, Fragment, useEffect, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  FormControlLabel,
  Switch,
  Collapse
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EnhancedTableToolbar from '@F/table/TableToolbar';
import EnhancedTableHead from '@F/table/TableHead';
import * as S from './styles';

function descendingComparator(a, b, orderBy) {
  const aValue = (typeof a[orderBy] === 'object' ? a[orderBy]?.value : a[orderBy]) ?? -Infinity;
  const bValue = (typeof b[orderBy] === 'object' ? b[orderBy]?.value : b[orderBy]) ?? -Infinity;

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTable(props) {
  const {
    titleText,
    headCells,
    rows,
    isCollapsible,
    isCheckable,
    isLoading,
    disableAllCheck,
    initialOrder,
    initialOrderBy,
    selected,
    setSelected,
    selectedActions,
    selectMode,
    canControlDense,
    initialDense,
    paginationHeight,
    onClickRow,
    children,
    opened,
    setOpened,
    isSingleSelection,
    onCompleteSelection,
    isApiSort,
    onChangeSortOption,
    onObserved
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (selectMode === 'drag') {
      const onMouseUp = () => setIsDragging(false);
      const onMouseDown = () => setIsDragging(true);

      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('mousedown', onMouseDown);

      return () => {
        window.removeEventListener('mouseup', onMouseUp);
        window.removeEventListener('mousedown', onMouseUp);
      };
    }
  }, []);

  const classes = S.useTableStyles({ paginationHeight });

  const onChangeSortOptionRef = useRef(onChangeSortOption);
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [dense, setDense] = useState(initialDense);
  const [isOpenAll, setIsOpenAll] = useState(false);

  const sortByColumnId = (property) => {
    const isTarget = orderBy === property;

    if (!isTarget) {
      setOrderBy(property);
      setOrder(initialOrder);
      return;
    }

    setOrder((prev) => (prev === 'desc' ? 'asc' : 'desc'));
  };

  const onClickAllCheck = (event) => {
    if (event.target.checked) {
      setSelected(rows);
    } else {
      setSelected([]);
    }
  };

  const onClickAllOpen = () => {
    const newOpened = opened.map((item) => ({ id: item.id, opened: !isOpenAll }));
    setIsOpenAll(!isOpenAll);
    setOpened(newOpened);
  };

  const addRowToSelected = (row) => {
    const rowId = row.id;
    const selectedIndex = selected.findIndex((item) => (item.id || item?.id.value) === rowId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const onClickRowCheck = useCallback(
    (row) => {
      if (selectMode === 'drag') return;

      addRowToSelected(row);
    },
    [selected, setSelected, selectMode]
  );

  const onMouseDownRow = useCallback(
    (e, row) => {
      if (selectMode !== 'drag') return;

      addRowToSelected(row);
    },
    [selected, setSelected, selectMode]
  );

  const onMouseEnterRow = useCallback(
    (e, row) => {
      if (selectMode !== 'drag') return;

      if (!isDragging) return;

      addRowToSelected(row);
      window.getSelection().removeAllRanges();
    },
    [selected, setSelected, selectMode, isDragging]
  );

  const [selectedText, setSelectedText] = useState(null);
  const selectText = () => {
    if (window.getSelection) {
      setSelectedText(window.getSelection().toString());
    }
  };
  const onClickRowOpen = useCallback(
    (event, id) => {
      event.stopPropagation();
      if (selectedText && selectedText.length > 0) return;
      const newOpened = opened.map((item) => (item.id === id ? { id, opened: !item.opened } : item));
      setOpened(newOpened);
    },
    [selectedText, setOpened, opened]
  );

  const selectAndCloseModal = useCallback(
    (row) => {
      if (!isSingleSelection) return;

      if (onCompleteSelection) onCompleteSelection(row);
      else setSelected(row);
    },
    [isSingleSelection, onCompleteSelection, setSelected]
  );

  const selectRow = (e, row) => {
    if (onClickRow) onClickRow(getRowId(row));
    else if (isCollapsible) onClickRowOpen(e, getRowId(row));
    else if (selectMode === 'drag') {
      onMouseDownRow(row);
    } else {
      onClickRowCheck(row);
    }
  };

  const changeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.map((row) => getRowId(row)).indexOf(id) !== -1;
  const isOpened = (id) => opened?.find((item) => item.id === id)?.opened ?? false;

  useEffect(() => {
    const rowIds = rows.map((row) => getRowId(row));
    const openedIds = opened.map((row) => row.id);
    const intersection = rowIds.filter((x) => openedIds.includes(x));

    if (isCollapsible && intersection.length !== rowIds.length) {
      const newOpened = rows.map((row) => ({ id: getRowId(row), opened: false }));
      setOpened(newOpened);
    }
  }, [rows, opened, setOpened, isCollapsible]);

  /*
    onChangeSortOptionRef를 사용하는 이유?

    prop으로 전달받는 onChangeSortOption 함수가 useCallback으로 메모이제이션되지 않은 함수일 경우,
    리렌더링에 의한 무한 루프가 발생해 onChangeSortOption를 참조값으로(ref) 저장하여 사용합니다.
  */
  useEffect(() => {
    if (!isApiSort) return;

    if (onChangeSortOptionRef.current && order && orderBy) {
      onChangeSortOptionRef.current(order, orderBy);
    }
  }, [order, orderBy, isApiSort]);

  useEffect(() => {
    onChangeSortOptionRef.current = onChangeSortOption;
  }, [onChangeSortOption]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {titleText && (
          <EnhancedTableToolbar title={titleText} numSelected={selected.length} selectedActions={selectedActions} />
        )}
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onClickAllCheck={onClickAllCheck}
              disableAllCheck={disableAllCheck}
              onClickAllOpen={onClickAllOpen}
              onRequestSort={sortByColumnId}
              rowCount={rows.length}
              headCells={headCells}
              isCheckable={isCheckable}
              isCollapsible={isCollapsible}
              isOpenAll={isOpenAll}
              isSingleSelection={isSingleSelection}
              key={headCells.map((item) => item.id).join(',')}
            />
            <TableBody>
              {IsRowsEmpty(rows) ? (
                <TableRow>
                  <TableCell colSpan={isCheckable ? headCells.length + 2 : headCells.length + 1}>
                    <h1>{isLoading ? '로딩 중입니다...' : '검색 결과가 없습니다.'}</h1>
                  </TableCell>
                </TableRow>
              ) : (
                (isApiSort ? rows : stableSort(rows, getComparator(order, orderBy))).map((row) => (
                  <TableItem
                    isOpenAll={isOpenAll}
                    isSelected={isSelected(getRowId(row))}
                    isOpened={isOpened(getRowId(row))}
                    row={row}
                    selectMode={selectMode}
                    selectRow={selectRow}
                    onMouseDown={onMouseDownRow}
                    onMouseEnter={onMouseEnterRow}
                    selectAndCloseModal={selectAndCloseModal}
                    isCollapsible={isCollapsible}
                    isCheckable={isCheckable}
                    isSingleSelection={isSingleSelection}
                    selectText={selectText}
                    onClickRowOpen={onClickRowOpen}
                    onClickRowCheck={onClickRowCheck}
                    setSelectedText={setSelectedText}
                    headCells={headCells}
                    onObserved={onObserved}
                  >
                    {children}
                  </TableItem>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {canControlDense && (
        <FormControlLabel control={<Switch checked={dense} onChange={changeDense} />} label="여백없이 보기" />
      )}
    </div>
  );
}
export default EnhancedTable;

function TableItem({
  row,
  children,
  isOpened,
  isSelected,
  selectRow,
  selectAndCloseModal,
  isCollapsible,
  isCheckable,
  isSingleSelection,
  selectMode,
  selectText,
  onMouseDown,
  onMouseEnter,
  onClickRowOpen,
  onClickRowCheck,
  setSelectedText,
  headCells,
  onObserved
}) {
  const { inView, ref } = useInView({ rootMargin: '5000px' });
  const timerRef = useRef(null);

  useEffect(() => {
    if (onObserved) {
      if (inView) {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
          onObserved(getRowId(row));
          timerRef.current = null;
        }, 400);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, onObserved]);

  return (
    <Fragment key={getRowId(row)}>
      <div ref={ref} />
      <TableRow
        hover
        role="checkbox"
        aria-checked={isSelected}
        tabIndex={-1}
        selected={isSelected}
        onClick={(e) => selectRow(e, row)}
        onMouseDown={selectMode === 'drag' ? (e) => onMouseDown(e, row) : null}
        onMouseEnter={selectMode === 'drag' ? (e) => onMouseEnter(e, row) : null}
        onDoubleClick={() => selectAndCloseModal(row)}
      >
        {isCollapsible && (
          <TableCell>
            <IconButton size="small" onClick={(event) => onClickRowOpen(event, getRowId(row))}>
              {isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {isCheckable && !isSingleSelection && (
          <TableCell padding="checkbox">
            <Checkbox checked={isSelected} onClick={() => onClickRowCheck(row)} />
          </TableCell>
        )}
        {Object.keys(row).map((key, idx) => (
          <TableCell
            align={idx === 0 ? 'left' : row[key]?.align || 'left'}
            key={idx}
            onMouseUp={selectText}
            onMouseOut={() => setSelectedText(null)}
          >
            {row[key]?.component ?? row[key]}
          </TableCell>
        ))}
      </TableRow>
      {isOpened && (
        <TableRow>
          <TableCell colSpan={isCheckable ? headCells.length + 2 : headCells.length + 1}>
            <Collapse in={isOpened} timeout="auto" unmountOnExit>
              {typeof children === 'function'
                ? Children.map(children({ inView }), (child) => cloneElement(child, { id: getRowId(row) }))
                : Children.map(children, (child) => cloneElement(child, { id: getRowId(row) }))}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
}

EnhancedTable.propTypes = {
  titleText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    PropTypes.string
  ]),
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCollapsible: PropTypes.bool,
  isCheckable: PropTypes.bool,
  isLoading: PropTypes.bool,
  disableAllCheck: PropTypes.bool,
  initialOrder: PropTypes.oneOf(['desc', 'asc']),
  initialOrderBy: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.any),
  setSelected: PropTypes.func,
  canControlDense: PropTypes.bool,
  initialDense: PropTypes.bool,
  paginationHeight: PropTypes.number,
  onClickRow: PropTypes.func,
  children: PropTypes.element,
  opened: PropTypes.arrayOf(PropTypes.any),
  setOpened: PropTypes.func,
  isSingleSelection: PropTypes.bool,
  onCompleteSelection: PropTypes.func,
  isApiSort: PropTypes.bool,
  onChangeSortOption: PropTypes.func
};

EnhancedTable.defaultProps = {
  titleText: null,
  isCollapsible: false,
  isCheckable: true,
  isLoading: false,
  disableAllCheck: false,
  initialOrder: 'desc',
  initialOrderBy: null,
  children: null,
  selected: [],
  canControlDense: false,
  initialDense: false,
  paginationHeight: null,
  onClickRow: null,
  setSelected: () => {},
  opened: [],
  setOpened: () => {},
  isSingleSelection: false,
  onCompleteSelection: null,
  isApiSort: false,
  onChangeSortOption: () => {}
};

function getRowId(row) {
  return row?.id?.value ?? row?.id;
}

/**
 * @return {boolean}
 */
function IsRowsEmpty(rows) {
  // 스토어매니저 table의 경우, id: { value, component }으로 되어 있어, value값 까지 확인해야한다.
  // 그 외 일반적인 table의 경우, id로만 판단한다.
  if (rows.length === 0) return true;
  if (rows[0].id === undefined || rows[0].id === null) return true;
  if (typeof rows[0].id === 'object' && (rows[0].id.value === undefined || rows[0].id.value === null)) return true;
  return false;
}
