import { useEffect, useState } from 'react';
import { xFirebaseProject } from '@/firebase';
import AddVersion from './AddVersion';
import VersionsView from './VersionsView';

export type VersionList = Version[];
export type Version = {
  comment: string;
  type: 'release' | 'hotfix' | 'patch';
  version: string;
};

const VersionManager = (): JSX.Element => {
  const [versionList, setVersionList] = useState<VersionList>([]);

  const initializeFirebase = async () => {
    await xFirebaseProject.init();
    return xFirebaseProject.firestore();
  };

  useEffect(() => {
    const fetchData = async () => {
      const db = await initializeFirebase();
      const doc = await db.collection('gravity').doc('version').get();

      if (doc.exists) {
        setVersionList(doc.data()?.list || []);
      }
    };

    fetchData();
  }, []);

  // firebase에 버전을 추가하는 함수
  const addVersion = async (version: Version) => {
    const db = await initializeFirebase();

    await db
      .collection('gravity')
      .doc('version')
      .update({
        list: [...versionList, version]
      });

    setVersionList((prevList) => [...prevList, version]);
  };

  const deleteVersion = async (version: Version) => {
    const db = await initializeFirebase();

    await db
      .collection('gravity')
      .doc('version')
      .update({
        list: versionList.filter((v) => v.version !== version.version)
      });

    setVersionList((prevList) => prevList.filter((v) => v.version !== version.version));
  };

  return (
    <div>
      <AddVersion addVersion={addVersion} />
      <VersionsView versionList={versionList} deleteVersion={deleteVersion} />
    </div>
  );
};

export default VersionManager;
