import { BoxParagraph } from './style';
import { areaType, levelOneCodeList } from './const';

type LawDistrict = {
  [key: string]: {
    [subKey: string]: string[];
  };
};

type Props = {
  visit_type: Segments.DMP.Segment['visit_type'];
  comment: Segments.DMP.Segment['comment'];
};

function LogBaseVersion2({ visit_type, comment }: Props) {
  const { selects, commercial, law_district } = JSON.parse(comment ?? '');
  const { brand, category, store, complex } = selects;
  const calculateSelects = {
    brand,
    category,
    store: [...complex, ...store]
  };

  return (
    <div>
      <BoxParagraph>{areaType[visit_type ?? 'all']} 세그먼트</BoxParagraph>
      {law_district &&
        typeof law_district === 'object' &&
        Object.entries(law_district as LawDistrict).flatMap(([key, value]) => {
          const validValue = value && typeof value === 'object';
          if (!validValue) {
            return [];
          }

          if (key === '전국') {
            return (
              <BoxParagraph key={key}>
                <span className="bold">전국</span>
              </BoxParagraph>
            );
          }

          const lv2 = Object.keys(value).flat();
          const lv3 = lv2.flatMap((key) => (key in value ? value[key] : [])).flat();
          const hasOtherCondition = (lv2 && lv2.length > 0) || (lv3 && lv3.length > 0);
          return (
            <BoxParagraph key={key}>
              <span className="bold">{hasOtherCondition ? `${key} 지역 : ` : `${key} 전체`}</span>
              {lv2 && lv2.length > 0 && (
                <>
                  <br />
                  <span className="pl">
                    <span className="bold">시/구 : </span>
                    <span className="area">{lv2.join(', ')}</span>
                  </span>
                </>
              )}
              {lv3 && lv3.length > 0 && (
                <>
                  <br />
                  <span className="pl">
                    <span className="bold">읍/면/동 : </span>
                    <span className="area">{lv3.join(', ')}</span>
                  </span>
                </>
              )}
            </BoxParagraph>
          );
        })}

      {commercial &&
        typeof commercial === 'object' &&
        Object.entries(commercial).flatMap(([key, value]) => {
          const targetLevelOne = levelOneCodeList.find(({ code }) => code === key);
          const validValue = Array.isArray(value) && value.length > 0;
          if (!targetLevelOne || !validValue) {
            return [];
          }
          return (
            <BoxParagraph key={key}>
              <span className="bold">{targetLevelOne.name} 상권 : </span>
              <span className="commercial">{value.join(', ')}</span>
            </BoxParagraph>
          );
        })}
      {selects &&
        Object.entries(calculateSelects).flatMap(([key, value]) => {
          const validValue = Array.isArray(value) && value.length > 0;
          if (!validValue) {
            return [];
          }
          return (
            <BoxParagraph key={key}>
              <span className="bold">{key.toUpperCase()} : </span>
              <span className={key}>
                {key === 'category' || key === 'brand' ? value.map(({ name }) => name).join(', ') : value.join(', ')}
              </span>
            </BoxParagraph>
          );
        })}
    </div>
  );
}

export default LogBaseVersion2;
