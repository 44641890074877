import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper } from '@material-ui/core';

import Wrapper from '@F/materialUI/Wrapper';
import TableHeadCell from '@F/table/cells/TableHeadCell';
import type { SdkLog } from '@P/max/sdkLog/useSdkLogBloc';
import TableBodySkeleton from '@P/max/primitiveLog/PrimitiveLogsTable/TableBodySkeleton';
import { useSdkLogBloc } from '../useSdkLogBloc';
import { Sdk_LOG_TABLE_HEAD_CELLS } from '../data';
import SdkLogTableItem from './SdkLogTableItem';

const MORE = 20;

export default function SdkLogsTable(): JSX.Element {
  const { pagedSdkLogs, sortSdkLogs, fetchStatus } = useSdkLogBloc();
  const [inViewSdkLogs, setInViewSdkLogs] = useState<SdkLog[]>([]);
  const [orderName, setOrderName] = useState('ts_local');
  const { ref, inView } = useInView();

  const handleClickHeadCell = (
    _: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    _name?: string,
    toggledOrderType?: OrderType
  ): void => {
    if (!_name || !toggledOrderType) return;

    const name = _name as keyof SdkLog;
    setOrderName(name);
    sortSdkLogs(name, toggledOrderType);
  };

  const loadMore = () => {
    setInViewSdkLogs((prev) => {
      const next = prev.concat(pagedSdkLogs.slice(prev.length, prev.length + MORE));
      return next;
    });
  };

  useEffect(() => {
    if (pagedSdkLogs.length) {
      setInViewSdkLogs(pagedSdkLogs.slice(0, MORE));
    }
  }, [pagedSdkLogs]);

  useEffect(() => {
    if (inView) {
      if (inViewSdkLogs.length < pagedSdkLogs.length) {
        loadMore();
      }
    }
  }, [inView]);

  return (
    <Wrapper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {Sdk_LOG_TABLE_HEAD_CELLS.map(({ name, label, defaultOrderType }, i) => (
                <TableHeadCell
                  key={i}
                  name={name}
                  label={label}
                  defaultOrderType={defaultOrderType}
                  onClick={handleClickHeadCell}
                  isActive={Boolean(name === orderName)}
                />
              ))}
            </TableRow>
          </TableHead>
          {fetchStatus === 'loading' ? <TableBodySkeleton /> : null}
          <TableBody>
            {fetchStatus === 'idle' ? (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <span>검색해주세요.</span>
                </TableCell>
              </TableRow>
            ) : null}
            {fetchStatus === 'success'
              ? inViewSdkLogs.map((sdkLog, i: number) => <SdkLogTableItem key={i} sdkLog={sdkLog} />)
              : null}
            {fetchStatus === 'failure' ? (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <span>검색된 결과가 없습니다.</span>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
          <div ref={ref} />
        </Table>
      </TableContainer>
    </Wrapper>
  );
}
