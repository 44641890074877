import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const UnAuthorized = styled.div`
  display: flex;
  flex-flow: column;

  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const WideButton = styled(Button)`
  min-width: 400px;
  height: 35px;
  margin-bottom: 10px;
`;
