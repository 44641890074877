import { call, put, takeLatest } from 'redux-saga/effects';
import type { AxiosResponse } from 'axios';
import { maxOperationCompanyApi } from '@API/max';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { operationCompanyResponse } from '@TS/max/operationCompany/api';
import { actions } from './slice';

function* fetchOperationCompany() {
  try {
    yield put(actions.fetchStart());
    const { data }: AxiosResponse<operationCompanyResponse> = yield call(maxOperationCompanyApi.getOperationCompany);
    const operationCompany = data.user.company;
    yield put(actions.fetchSuccess({ operationCompany }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.fetchFailure({ error }));
    }
  }
}

function* setOperationCompany({ payload }: { payload: string }) {
  try {
    yield put(actions.fetchStart());
    const { data }: AxiosResponse<operationCompanyResponse> = yield call(
      maxOperationCompanyApi.setOperationCompany,
      payload
    );
    const operationCompany = data.user.company;
    yield put(actions.fetchSuccess({ operationCompany }));
    if (operationCompany === payload) {
      toastSuccessMessage('회사 변경에 성공하였습니다.');
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.fetchFailure({ error }));
      toastErrorMessage('회사 변경에 실패하였습니다.');
    }
  }
}

export default function* sagaReducer(): Generator {
  yield takeLatest(actions.fetchOperationCompany, fetchOperationCompany);
  yield takeLatest(actions.setOperationCompany, setOperationCompany);
}
