import { axiosInstancesManager } from '@API/axios';
import { AxiosPromise } from 'axios';

export type SegmentRequestParams = {
  page: number;
  size: number;
  state?: number;
  orderby?: string;
  company?: string;
};

export type SegmentResponse = {
  total_count: number;
  result: Segments.DMP.Segment[];
};

class SegmentApi {
  getSegmentList(params: SegmentRequestParams): AxiosPromise<SegmentResponse> {
    return axiosInstancesManager.max.get('/manager/dmp/segments', {
      params
    });
  }
}

export default new SegmentApi();
