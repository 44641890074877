import { TableRow, TableCell } from '@material-ui/core';
import type { SdkLog } from '@P/max/sdkLog/useSdkLogBloc';

export default function SdkLogsTableItem({ sdkLog }: { sdkLog: SdkLog }): JSX.Element {
  const { KST, log_kind, sdk_state, adid, echo_code, os_version, device_name, userAgent } = sdkLog;
  const sdkLogCells = [KST, log_kind, sdk_state, adid, echo_code, os_version, device_name, userAgent];

  return (
    <TableRow>
      {sdkLogCells.map((value, i) => (
        <TableCell key={i} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
}
