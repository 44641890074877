import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { GetCampaignsParameters, CampaignDetailResponse } from '@TS/max/campaigns/api';
import type { TableRowType } from '@TS/max/table/campaignTable';
import type { ManagerApiError } from '@TS/customError';

export type CampaignItem = SnakeToCamelObj<Campaign.CampaignSummary> & {
  methodType: Campaign.Scheme.Message.Method;
  message: Campaign.CampaignDetail['message'];
  isLive: 0 | 1;
};
export type CampaignDetail = SnakeToCamelObj<CampaignDetailResponse>;
export type CompareCampaign = Pick<TableRowType, 'name' | 'status' | 'campaignId'> & {
  duration: string;
  selected: boolean;
};
export type StateType = 'campaigns' | 'campaign';
export type State = {
  status: Record<StateType, ApiStatus>;
  error: ManagerApiError | null;
  params: GetCampaignsParameters;
  totalCount: number;
  orderType: [SnakeToCamel<CampaignApi.OrderBy>, OrderType];
  /** 캠페인 리스트 조회 목록 */
  campaigns: CampaignItem[];
  /** 캠페인 상세 조회 목록 */
  campaignDetails: CampaignDetail[];
  /** 비교할 캠페인 목록 */
  compareCampaigns: CompareCampaign[];
};

const initialState: State = {
  status: { campaign: 'idle', campaigns: 'idle' },
  error: null,
  totalCount: 0,
  params: {
    size: '15',
    status: 'all',
    page: 1
  },
  campaigns: [],
  orderType: ['campaignId', 'DESC'],
  compareCampaigns: [],
  campaignDetails: []
};

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setParams: (state, action: PayloadAction<State['params']>) => {
      state.params = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<State['orderType']>) => {
      const [name, order] = action.payload;
      const newOrder: GetCampaignsParameters['orderby'] = order === 'ASC' ? `^${name}` : name;
      state.params.orderby = newOrder;
      state.orderType = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.params.page = action.payload;
    },

    fetchStart: (state, action: PayloadAction<{ type: StateType }>) => {
      state.status[action.payload.type] = 'loading';
    },
    fetchFailure: (state, action: PayloadAction<{ error: ManagerApiError; type: StateType }>) => {
      state.status[action.payload.type] = 'failure';
      state.error = action.payload.error;
    },
    fetchCampaignsSuccess: (state, action: PayloadAction<Pick<State, 'totalCount' | 'campaigns'>>) => {
      state.status.campaigns = 'success';
      const { campaigns, totalCount } = action.payload;
      state.campaigns = campaigns;
      state.totalCount = totalCount;
    },
    setCompareCampaigns: (state, action: PayloadAction<CompareCampaign[]>) => {
      state.compareCampaigns = action.payload;
    },
    toggleCompareCampaign: (state, action: PayloadAction<CompareCampaign>) => {
      const { campaignId } = action.payload;
      const isExisted = state.compareCampaigns.find((campaign) => campaign.campaignId === campaignId);
      if (isExisted) {
        state.compareCampaigns = state.compareCampaigns.filter((campaign) => campaign.campaignId !== campaignId);
      } else {
        state.compareCampaigns.push(action.payload);
      }
    },
    fetchCampaignSuccess: (state, action: PayloadAction<CampaignDetail>) => {
      state.status.campaign = 'success';
      state.campaignDetails.push(action.payload);
    },
    fetchCompareCampaignsSuccess: (state, action: PayloadAction<CampaignDetail[]>) => {
      state.status.campaign = 'success';
      state.campaignDetails.push(...action.payload);
    },
    clearCampaigns: (state) => {
      state.campaigns = initialState.campaigns;
    },
    clearCampaignDetails: (state) => {
      state.campaignDetails = initialState.campaignDetails;
    },
    // 아래는 saga를 위한 actions
    fetchCampaignById: (state, action: PayloadAction<number>) => {},
    fetchCampaigns: () => {},
    fetchCompareCampaigns: (state, action: PayloadAction<TwoCampaignId>) => {}
  }
});

export const { actions } = slice;
export default slice.reducer;

export type TwoCampaignId = Record<'cidA' | 'cidB', number>;
