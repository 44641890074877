import type { SchemedRegionCondition } from '@P/max/campaigns/utils/type';
import LevelTwoModule from '@P/max/campaigns/module/LevelTwoModule';
import { ZoneWrapper, DetailSection } from './styles';

const DistrictCondition = ({
  lv1,
  lv2
}: Pick<SchemedRegionCondition['condition'], 'lv2' | 'lv1'>): JSX.Element | null => {
  if (lv1 === '') return null;
  return (
    <>
      <dt className="title">행정 구역</dt>
      <DetailSection className="body">
        <ZoneWrapper direction="column">
          <span>{lv1}</span>
          <LevelTwoModule lv2={lv2} />
        </ZoneWrapper>
      </DetailSection>
    </>
  );
};

export default DistrictCondition;
