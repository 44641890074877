import dayjs from 'dayjs';
import type { TableRowType } from '@TS/max/table/campaignTable';
import { campaignStatus } from '@P/max/campaigns/search/List/data';
import { nonNullable } from '@FUNC/nonNullable';
import type { SchemedInformation, Row } from './type';
import type { CampaignDetail } from '../redux/campaigns/slice';

const generateRow = <C>(title: string, content: C): Row<C> => ({ title, content });

export const schemeInformation = (campaign: CampaignDetail): SchemedInformation[] => {
  const clientCodeRow = campaign.clientCode === '' ? null : generateRow('고객 관리 코드', campaign.clientCode);
  return [
    generateRow('회사명', campaign.company),
    generateRow('생성자', campaign.creator),
    generateRow<TableRowType['name']>('캠페인 이름', {
      label: campaign.name,
      state: campaign.isAdids ? (campaign.repeatType === 3 ? 'test' : 'commercial') : null,
      abTest: campaign.message.length === 2,
      vendor: campaign.method === 'ext_request' ? 'kakao' : campaign.method === 'outbound' ? 'braze' : null,
      isLive: campaign.isLive ?? 0
    }),
    clientCodeRow,
    generateRow('캠페인 ID', campaign.campaignId),
    generateRow('생성일', dayjs(campaign.createdAt).format('YYYY-MM-DD')),
    generateRow('상태', campaignStatus[campaign.status])
  ].filter(nonNullable);
};
