import { bluescale50, grayscale200, grayscale800 } from 'loplat-ui';
import styled from 'styled-components';

export const CurrentCIDWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-top: 1rem;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    span {
      &:first-of-type {
        font-size: 0.65rem;
        margin-right: 0.4rem;
      }
      &:last-of-type {
        font-size: 0.875rem;
        font-weight: bold;
      }
    }
  }
`;

export const CurrentSelectedStore = styled.section`
  background-color: ${bluescale50};
  border-radius: 4px;
  border: 1px solid ${grayscale200};
  margin: 1rem 0;
  padding: 12px 8px;
  h4 {
    color: ${grayscale800};
  }
`;
