import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getCookDevice } from './api';

type CookDeviceResponse = {
  isLoading: 'idle' | 'loading' | 'fetched';
  isError: boolean;
  message: string;
  data: {
    count: number;
    items: {
      user_id: number;
      username: string;
      ssaid: string;
      created_at: string;
      updated_at: string;
      approve_by: null | string;
      status: -1 | 0 | 1;
    }[];
  };
};
const initialState: CookDeviceResponse = {
  isLoading: 'idle',
  isError: false,
  message: '',
  data: {
    count: 0,
    items: []
  }
};

export const fetchGetDeviceList = createAsyncThunk('fetchGetDeviceList', async () => {
  try {
    const response = await getCookDevice();
    return response;
  } catch (err) {
    return err;
  }
});

const slice = createSlice({
  name: 'cookDevice',
  initialState,
  reducers: {
    manageUserDevice: (state, action: PayloadAction<number>) => {
      if (state.data) {
        const updatedItems = state.data.items.filter(({ user_id }) => user_id !== action.payload);
        state.data.count = updatedItems.length;
        state.data.items = updatedItems;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetDeviceList.pending, () => ({
        isLoading: 'loading',
        isError: false,
        message: '',
        data: {
          count: 0,
          items: []
        }
      }))
      .addCase(fetchGetDeviceList.fulfilled, (state, action) => {
        const data = action.payload;
        return {
          ...state,
          isLoading: 'fetched',
          data
        };
      })
      .addCase(fetchGetDeviceList.rejected, () => {
        return {
          isLoading: 'fetched',
          isError: true,
          message: 'UnKnown Error',
          data: {
            count: 0,
            items: []
          }
        };
      });
  }
});

export const { actions } = slice;
export default slice.reducer;
