import { useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableContainer, Paper } from '@material-ui/core';
import Pagination from '@F/materialUI/Pagination';

import { fetchPolygonListByParams } from '@P/polygon/redux/polygonList';
import usePolygonTableFilter from '../PolygonTableFilter/usePolygonTableFilter';
import TableHead from './TableHead';
import TableBody from './TableBody';

import type RootStateType from '@/redux/common/store.type';

const PolygonTable = ({ page, setPage }: { page: number; setPage: Dispatch<SetStateAction<number>> }): JSX.Element => {
  const dispatch = useDispatch();
  const { getParams, setParams } = usePolygonTableFilter();
  const { result } = useSelector((state) => (state as RootStateType).polygonListReducer);
  const { total } = result;

  const setPageEvent = (page: number) => {
    setPage(page);
    setParams({
      ...getParams(),
      page
    });
  };

  useEffect(() => {
    if (result.isLoading === 'idle') {
      dispatch(fetchPolygonListByParams(getParams()));
    }
  }, [dispatch, getParams, result.isLoading]);

  return (
    <>
      <Pagination
        page={page}
        perPage={Number(getParams().per_page)}
        totalCount={total > 0 ? total : 1}
        changePage={(e, page) => setPageEvent(page)}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead />
          <TableBody />
        </Table>
      </TableContainer>
      <Pagination
        page={page}
        perPage={Number(getParams().per_page)}
        totalCount={total > 0 ? total : 1}
        changePage={(e, page) => setPageEvent(page)}
      />
    </>
  );
};

export default PolygonTable;
