import { useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import { TogglersContextProvider } from '@HOOK/useTogglers';
import Toolbar from './Toolbar';
import TableSection from './tableSection/TableSection';
import Filter from './Filter';
import { FINGERPRINT_TABLE_HEAD_CELLS } from './data';

function FpManager(): JSX.Element {
  // select custom columns
  const [selectedColumns, setSelectedColumns] = useState<SelectedColumns>(() =>
    FINGERPRINT_TABLE_HEAD_CELLS.reduce((obj, column) => ({ ...obj, [column.name]: true }), {} as SelectedColumns)
  );

  return (
    <TogglersContextProvider>
      <div>
        <Wrapper>
          <Filter />
        </Wrapper>
        <br />
        <Toolbar selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />
        <TableSection selectedColumns={selectedColumns} />
      </div>
    </TogglersContextProvider>
  );
}
export default FpManager;

type ColumnKey =
  | 'fp_image_validation'
  | 'fp_fpid'
  | 'name'
  | 'branch_name'
  | 'fp_floor'
  | 'category_all_kor'
  | 'category_sub'
  | 'category_code'
  | 'fp_collector'
  | 'fp_created_at'
  | 'phone';

type SelectedColumns = Record<ColumnKey, boolean>;
