import { primary, purple, orange, teal, primaryDark } from 'loplat-ui';
import { SchemedCondition } from './type';

export const REPEAT_TYPE: Record<Campaign.CampaignCommon['repeat_type'], Campaign.Scheme.Message.RepeatTypeKorean> = {
  2: '기간 내 1회',
  1: '1일 1회',
  3: '테스트 모드',
  4: '사용자 설정'
};

export const MESSAGE_METHOD: Record<Campaign.Scheme.Message.Method, string> = {
  noti_big: 'TEXT + IMAGE 알림',
  noti: 'TEXT 알림',
  none: '메시지 노출하지 않음',
  outbound: '외부 API와 연동',
  ext_request: 'kakao 친구톡 연동'
};

export const LEVEL_ONE: Record<Campaign.Scheme.Trigger.LevelOne, string> = {
  전국: '전국',
  '전국 상권': '전국 상권',
  서울: '서울특별시',
  강원: '강원도',
  경기: '경기도',
  경남: '경상남도',
  경북: '경상북도',
  광주: '광주광역시',
  대구: '대구광역시',
  대전: '대전광역시',
  부산: '부산광역시',
  세종: '세종특별자치시',
  울산: '울산광역시',
  인천: '인천광역시',
  전남: '전라남도',
  전북: '전라북도',
  제주: '제주특별자치도',
  충남: '충청남도',
  충북: '충청북도'
};

type ConditionModule = Record<
  keyof Omit<SchemedCondition, 'lv1' | 'lv2'> | 'area' | 'user',
  Record<'color' | 'label', string>
>;
export const CHIP_DETAIL: ConditionModule = {
  area: { label: '방문한 지역/상권', color: primary },
  user: { label: '모든 사용자', color: primary },
  store: { label: '매장', color: teal },
  brand: { label: '브랜드', color: purple },
  category: { label: '카테고리', color: primaryDark },
  complex: { label: '복합몰', color: orange }
};
