import { useCallback, useEffect, useMemo } from 'react';
import { PlaceIcon } from 'loplat-ui';
import { Tooltip } from '@material-ui/core';
import { toastWarningMessage } from '@FUNC/toast';
import { makeContent, callReverseGeocode } from './naverMapFunction';

// type Props = {
//   naverMap: naver.maps.Map;
//   drawingManager: naver.maps.drawing.DrawingManager;
//   marker: naver.maps.Marker;
//   setDrawings: Dispatch<SetStateAction<naver.maps.drawing.DrawingOverlay[]>>;
//   backupDraw: (backup: naver.maps.drawing.DrawingOverlay[] | null) => void;
//   clickEventHandler: naver.maps.MapEventListener | null;
//   setClickEventHandler: Dispatch<SetStateAction<naver.maps.MapEventListener | null>>;
//   resetState: (except: 'draw' | 'info' | 'near') => void;
//   naverInfoLoading: boolean;
//   setNaverInfoLoading: Dispatch<SetStateAction<boolean>>;
//   setAddr: Dispatch<SetStateAction<string>>;
//   setRoad: Dispatch<SetStateAction<string>>;
//   exceptionSnapShotEvent: (e: KeyboardEvent, keyList: string[]) => boolean;
// };

// NOTE. naver-map-types에서 click event의 hanlder의 타입이 없음...
const MapInfoControlButton = ({
  naverMap,
  drawingManager,
  marker,
  setDrawings,
  backupDraw,
  clickEventHandler,
  setClickEventHandler,
  resetState,
  naverInfoLoading,
  setNaverInfoLoading,
  setAddr,
  setRoad,
  exceptionSnapShotEvent
}) => {
  const infoWindow = useMemo(
    () =>
      new window.naver.maps.InfoWindow({
        anchorSkew: true,
        content: ''
      }),
    []
  );

  const removeEventListener = useCallback(
    (backup, event) => {
      backupDraw(backup);
      naverMap.setCursor('grab');
      naverMap.removeListener(event);
    },
    [backupDraw, naverMap]
  );

  const addEventListener = useCallback(() => {
    const drawings = drawingManager.getDrawings();
    const drawingEntries = Object.entries(drawings);

    const { keys, overlays } =
      drawingEntries.length > 0
        ? Object.entries(drawings).reduce(
            (acc, [key, value]) => {
              acc.keys.push(key);
              acc.overlays.push(value);
              return acc;
            },
            { keys: [], overlays: [] }
          )
        : { keys: [], overlays: [] };

    setDrawings(overlays);
    keys.forEach((key) => {
      drawingManager.removeDrawing(key);
    });

    naverMap.data.setStyle({ visible: false });

    naverMap.setCursor('pointer');
    const event = naverMap.addListener('click', (e) => mapClickEvent(e.coord, overlays, event));
    setClickEventHandler(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingManager, naverMap, setClickEventHandler, setDrawings]);

  const mapClickEvent = async (coords, backup, event) => {
    setNaverInfoLoading(true);
    infoWindow.close();
    try {
      const { jibun, road } = await callReverseGeocode({ coords });
      setNaverInfoLoading(false);
      const content = makeContent({
        jibun: jibun.address,
        road: road.address,
        cancelCb: () => {
          infoWindow.close();
        },
        confirmCb: () => {
          removeEventListener(backup, event);
          marker.setPosition(coords);
          setAddr(jibun.address);
          setRoad(road.address);
        }
      });

      infoWindow.setContent(content);
      infoWindow.open(naverMap, coords);
    } catch (err) {
      toastWarningMessage('일일 API 사용량을 초과하였습니다.');
      setNaverInfoLoading(false);
    }
  };

  const toggleInfoMode = useCallback(() => {
    if (naverInfoLoading) {
      return;
    }

    resetState('info');
    if (clickEventHandler) {
      removeEventListener(null);
    } else {
      addEventListener();
    }
  }, [addEventListener, clickEventHandler, naverInfoLoading, removeEventListener, resetState]);

  useEffect(() => {
    if (clickEventHandler === null) {
      infoWindow.close();
      setNaverInfoLoading(false);
    }
  }, [clickEventHandler, infoWindow, setNaverInfoLoading]);

  const snapShotEvent = useCallback(
    (e) => {
      if (!exceptionSnapShotEvent(e, ['a', 'ㅁ'])) {
        return;
      }
      toggleInfoMode();
    },
    [exceptionSnapShotEvent, toggleInfoMode]
  );

  useEffect(() => {
    window.addEventListener('keydown', snapShotEvent);
    return () => {
      window.removeEventListener('keydown', snapShotEvent);
    };
  }, [snapShotEvent]);

  return (
    <Tooltip title="주소 검색 (단축키 : a)">
      <span
        role="presentation"
        onClick={() => toggleInfoMode()}
        className={clickEventHandler ? 'select active' : 'select'}
      >
        <PlaceIcon fillColor={clickEventHandler ? '#fff' : '#9DAAB7'} />
      </span>
    </Tooltip>
  );
};

export default MapInfoControlButton;
