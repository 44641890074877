import { ChangeEvent } from 'react';

export type SingleProps = {
  value: string;
  label: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  disabled?: boolean;
  error: boolean;
  helperText: string;
  hasClearButton?: boolean;
  onClear?: () => void;
  min?: string;
  max?: string;
  required?: boolean;
  size?: 'small' | 'medium';
};

type Double<T> = { [K in keyof T as `${string & K}s`]: [NonNullable<T[K]>, NonNullable<T[K]>] };
type RequiredFieldsOnly<T> = { [K in keyof T as T[K] extends Required<T>[K] ? K : never]: T[K] };

export type DatePickersProps = Double<Omit<SingleProps, 'onChange' | 'error' | 'size'>> &
  Pick<SingleProps, 'onChange' | 'error'> & { size?: 'small' | 'medium' };

type Properties = Omit<RequiredFieldsOnly<DatePickersProps>, 'onChange' | 'error' | 'size'>;
export type serializedPropsReturnType = RequiredFieldsOnly<SingleProps>;
export const serializeProps = (idx: 0 | 1, properties: Properties): serializedPropsReturnType =>
  Object.entries(properties).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key.slice(0, -1)]: value[idx]
    }),
    {} as serializedPropsReturnType
  );
