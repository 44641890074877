import type { CampaignDetail } from '../redux/campaigns/slice';
import { REPEAT_TYPE, MESSAGE_METHOD } from './data';

export const schemeMessageCondition = (campaign: CampaignDetail): MessageCondition[] => [
  {
    title: '발송 횟수',
    content: getSendType(campaign)
  },
  {
    title: '알림 방식',
    content: MESSAGE_METHOD[campaign.method],
    get children() {
      if (campaign.method === 'none') return [];
      if (campaign.method === 'outbound') return generateOutboundMessage(campaign);
      if (campaign.method === 'ext_request') return generateKakaoMessage(campaign);
      // 이하 ---- campaign.method === 'noti' || === 'noti_big'
      return generateBasicMessage(campaign);
    }
  }
];

function generateBasicMessage(campaign: CampaignDetail): MessageCondition[] {
  const isAbTest = campaign.message.length === 2;
  const testPushMessages: MessageCondition[] = [
    {
      title: 'A/B 테스트 설정',
      content: isAbTest ? 'ON' : 'OFF'
    }
  ];

  function getPushMessageSettings(type: 0 | 1): MessageCondition[] {
    const parent: MessageCondition = {
      title: type === 0 ? '메시지 A' : '메시지 B',
      content: ''
    };

    const children: MessageCondition[] = [
      { title: '메시지 제목', content: campaign.message[type].title },
      { title: '메시지 내용', content: campaign.message[type].body }
    ];

    if (campaign.method === 'noti_big') {
      children.push({
        title: '이미지 URL',
        content: campaign.message[type].img
      });
    }

    children.push({ title: '딥링크 URL', content: '', children: getDeepLinks(campaign, type) });

    if (!isAbTest) return children;

    parent.children = children;
    return [parent];
  }
  if (isAbTest) {
    testPushMessages.push(...getPushMessageSettings(0));
    testPushMessages.push(...getPushMessageSettings(1));
  } else if (campaign.method === 'ext_request') {
    return [];
  } else {
    testPushMessages[0].children = getPushMessageSettings(0);
  }

  return testPushMessages;
}

function generateOutboundMessage(campaign: CampaignDetail): MessageCondition[] {
  const { outbound } = campaign;
  if (!outbound) return [];
  if (!isBrazeOutbound(outbound)) return [];
  const syncType = outbound.event_name ? 'event' : 'trigger';
  const triggerType = syncType === 'event' ? 'event' : outbound.campaign_id ? 'campaign' : 'canvas';

  const children =
    syncType === 'event'
      ? [
          { title: 'Event name 설정', content: outbound.event_name ?? '' },
          {
            title: 'Braze에 보내는 정보',
            content: getAvailableTriggersFromCreatedCampaign(campaign).join(' ')
          }
        ]
      : triggerType === 'campaign'
      ? [{ title: '연동할 Braze Campaign ID', content: outbound.campaign_id ?? '' }]
      : [{ title: '연동할 Braze Canvas ID', content: outbound.canvas_id ?? '' }];

  const parent: MessageCondition[] = [
    {
      title: 'Braze 연동 방식',
      content: OUTBOUND_SYNC_TYPE[triggerType],
      children
    }
  ];
  return parent;
}

function generateKakaoMessage(campaign: CampaignDetail): MessageCondition[] {
  if (campaign.method !== 'ext_request') return [];
  if (!campaign.outbound) return [];

  const { outbound } = campaign;
  const isBraze = isBrazeOutbound(outbound);
  if (isBraze) return [];

  const basicScheme: MessageConditionBasic = {
    title: 'kakao 채널 ID',
    content: outbound.ft.senderkey,
    children: [
      {
        title: '메시지 타입',
        content: outbound.ft.wide === 'Y' ? '와이드 이미지' : outbound.ft.image ? '이미지' : '텍스트'
      },
      {
        title: '광고성 정보 포함',
        content: outbound.ft.adflag
      },
      {
        title: '메시지 내용',
        content: outbound.ft.message
      }
    ]
  };

  if (outbound.ft.image) {
    const imageScheme: MessageConditionBasic = {
      title: '이미지 업로드 파일',
      content: outbound.ft.image.img_url,
      children: [
        {
          title: '이미지 링크',
          content: outbound.ft.image.img_link
        }
      ]
    };
    basicScheme.children?.push(imageScheme);
  }

  if (outbound.ft.button.length) {
    const buttonScheme: MessageConditionBasic[] = outbound.ft.button.map((btn) => {
      const result = {
        title: '버튼 타입',
        content: btn.type === 'WL' ? '웹링크' : '앱링크',
        children: [{ title: 'kakao.button.name', content: btn.name }]
      };

      if (btn.type === 'WL')
        result.children.push({ title: 'Mobile', content: btn.url_mobile }, { title: 'PC', content: btn.url_pc });

      if (btn.type === 'AL') {
        result.children.push(
          { title: 'Android', content: btn.scheme_android },
          { title: 'IOS', content: btn.scheme_ios }
        );
      }

      return result;
    });

    basicScheme.children?.push(...buttonScheme);
  }

  return [basicScheme];
}

function getSendType(campaign: CampaignDetail) {
  const messageSendType = REPEAT_TYPE[campaign.repeatType];
  if (messageSendType !== '사용자 설정') return messageSendType;

  const { repeatResetMins } = campaign;
  const { day, hour, min } = calculateTimeByMinutes(repeatResetMins);
  return `발송 후 ${day}일 ${hour}시간 ${min === 0 ? '' : `${min}분 `}경과 후 재발송 활성화`;
}

function getDeepLinks(campaign: CampaignDetail, testCase: 0 | 1): DeepLink[] {
  const { targets } = campaign;
  const { uri } = campaign.message[testCase];
  const deeplinkPerTargetApp: { [k: string]: string } = uri ? JSON.parse(uri as string) : {};

  return Object.entries(deeplinkPerTargetApp).map(([key, url]) => {
    const keyType = key.startsWith('A') ? 'android' : 'ios';
    const targetApp = targets.find(
      ({ target, target_type }) => target === key.substring(2) && target_type === keyType
    ) as Campaign.Scheme.TargetApp;

    return {
      title: targetApp.name,
      type: targetApp.target_type,
      content: url
    };
  });
}

function calculateTimeByMinutes(minutes: number): Record<'day' | 'hour' | 'min', number> {
  const ONE_HOUR_IN_MINS = 60;
  const ONE_DAY_IN_HOUR = 24;
  const hours = minutes / ONE_HOUR_IN_MINS;
  const min = minutes % ONE_HOUR_IN_MINS;
  return {
    day: Math.floor(hours / ONE_DAY_IN_HOUR),
    hour: Math.floor(hours) % ONE_DAY_IN_HOUR,
    min
  };
}

export type DeepLink = {
  title: Campaign.Scheme.TargetApp['name'];
  type: Campaign.Scheme.TargetApp['target_type'];
  content: string;
};

export type MessageCondition = {
  title: string;
  content: ReturnType<typeof getSendType> | Campaign.Scheme.Message.Method | string | null;
  children?: MessageCondition[] | DeepLink[];
};
type MessageConditionBasic = {
  title: string;
  content: ReturnType<typeof getSendType> | Campaign.Scheme.Message.Method | string | null;
  children?: MessageConditionBasic[];
};
export function isBrazeOutbound(
  outbound: Campaign.Scheme.Message.Outbound
): outbound is Campaign.Scheme.Message.OutboundBraze {
  return outbound.vendor !== 'bizppurio';
}
const OUTBOUND_SYNC_TYPE = {
  campaign: 'Trigger[API-Triggered Delivery] - Campaign',
  canvas: 'Trigger[API-Triggered Delivery] - Canvas',
  event: 'Add Custom Event'
};
export const getAvailableTriggersFromCreatedCampaign = (campaign: CampaignDetail): TriggerCondition[] => {
  const { areas } = campaign.triggerRaw;
  if (areas.length === 0) return [];

  const available = new Set<TriggerCondition>();
  areas.forEach((trigger) => {
    if ('brand' in trigger && trigger.brand.length > 0) available.add('brand');
    if ('store' in trigger && trigger.store.length > 0) available.add('store');
    if ('complex' in trigger && trigger.complex.length > 0) available.add('store');
    if (trigger.area.length > 0) {
      trigger.area.forEach((region) => {
        if (region.type === 'district') {
          region.lv2 === null
            ? available.add('district_city').add('district_street')
            : available.add('district_city').add('district_state').add('district_street');
        }
        if (region.type === 'geofence') available.add('geofence');
      });
    }
  });
  return [...available];
};
export type TriggerCondition = 'district_state' | 'district_city' | 'district_street' | 'brand' | 'store' | 'geofence';
