import styled from 'styled-components';
import { ReportImage } from './Detail';

export default function ImageSection({
  images,
  isLoading
}: {
  images: (ReportImage | null)[];
  isLoading: boolean;
}): JSX.Element {
  return (
    <StyledImageSection>
      {isLoading
        ? '로딩 중입니다.'
        : images.every((image) => image === null)
        ? '이미지를 불러올 수 없습니다.'
        : images.map((image, i) =>
            image ? (
              <li>
                <div>
                  <span>{image.floor}층&nbsp;</span>
                  <span>{image.createdAt}</span>
                </div>
                <img src={image.url} alt={`inspected ${i}`} />
              </li>
            ) : null
          )}
    </StyledImageSection>
  );
}

const StyledImageSection = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
  width: 1000px;
  margin: 0 -10px;

  li {
    list-style: none;
    width: 33.3333%;
    padding: 0 5px;

    img {
      width: 100%;
    }
  }
`;
