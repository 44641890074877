import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import { Company } from '@TS/max/company/api';
import { toastErrorMessage } from '@FUNC/toast';
import { managerLocalStorage } from '@/utils/localStorageParser';

import { PrimitiveLogFilterValues } from '../data';

export default function MaxCompanies(): JSX.Element {
  const [status, setStatus] = useState<ApiStatus>('idle');
  const [primitiveLogCompanies, setPrimitiveLogCompanies] = useState<Company[]>([]);

  const { values, setValues, touched, setTouched } = useFormikContext<PrimitiveLogFilterValues>();
  const clientId = values.client_id;

  useEffect(() => {
    const cachedPrimitiveLogCompanies = managerLocalStorage.get<Company[]>(
      managerLocalStorage.keys['max/companies?type=2']
    );

    if (cachedPrimitiveLogCompanies) {
      setPrimitiveLogCompanies(cachedPrimitiveLogCompanies);
      setStatus('success');
      return;
    }

    toastErrorMessage('회사 목록을 불러오는데 실패했습니다.');
    setStatus('failure');
  }, []);

  return (
    <Autocomplete
      id="company"
      freeSolo
      autoHighlight
      clearOnBlur
      disabled={status === 'failure'}
      loading={status === 'failure'}
      options={primitiveLogCompanies}
      value={clientId}
      onChange={(e, newValue) => {
        setTouched({ ...touched, client_id: true }, false);
        setValues((prev) => ({ ...prev, client_id: newValue || '' }));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="회사명"
          name="company"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>
          }}
        />
      )}
    />
  );
}
