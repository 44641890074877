import { Typography, Button, DialogTitle, DialogContent, Dialog } from '@material-ui/core';
import styled from 'styled-components';

export default function ConfirmModal({
  isOpen,
  title,
  subTitle = '',
  content = '',
  permitText = '확인',
  onPermit,
  onCancel,
  disabled = false
}) {
  const contents = content
    ? content.split('\n').map((sentence, i) => (
        // eslint-disable-next-line react/jsx-indent
        <Typography key={i} variant="body1" color="textSecondary">
          {sentence}
        </Typography>
      ))
    : null;

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <StyledBox>
        <DialogTitle>
          <div>
            <Typography variant="h5">{title}</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          {subTitle ? <Typography variant="body1">{subTitle}</Typography> : null}
          {contents}
          <StyledButtons>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              취소
            </Button>
            <Button color="primary" variant="contained" onClick={onPermit} disabled={disabled}>
              {permitText}
            </Button>
          </StyledButtons>
        </DialogContent>
      </StyledBox>
    </Dialog>
  );
}

const StyledBox = styled.div`
  padding: 20px 10px;
`;

const StyledButtons = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
