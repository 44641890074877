import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { PolygonApi } from '@API/manager';
import { GetPolygonListItem } from '@API/manager/polygon';
import { AxiosError } from 'axios';
import { toastWarningMessage } from '@FUNC/toast';

export const kindObj = {
  제목: 'title',
  주소: 'addr',
  도로명주소: 'road_addr',
  작업자: 'worker'
} as const;
export const sizeArr = ['15', '50', '100', '200'];
export const stateObj = {
  전체: 2,
  완료: 1,
  미완료: 0
} as const;

export interface PolygonListType {
  result: {
    isLoading: 'idle' | 'loading' | 'fetched';
    isError: boolean;
    message: string;
    total: number;
    data: GetPolygonListItem[];
  };
}

export interface FetchPolygonListParams {
  per_page: (typeof sizeArr)[number];
  state: keyof typeof stateObj;
  order: string;
  page: number;
  kind: keyof typeof kindObj;
  q: string;
  id: string;
}

export const fetchPolygonListByParams = createAsyncThunk(
  'fetchPolygonListByParams',
  async (params: FetchPolygonListParams) => {
    const { page, per_page, q, kind, order, state, id } = params;
    const stateOpts = state !== '전체' ? { state: stateObj[state] } : {};
    if (id) {
      try {
        const response = await PolygonApi.getPolygon({ id });
        return {
          items: [response.data],
          page: 1,
          per_page,
          total_count: 1
        };
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === 404) {
            toastWarningMessage('검색 결과가 없습니다.');
          }
        }
        return {
          items: [],
          page: 1,
          per_page,
          total_count: 1
        };
      }
    }
    const response = await PolygonApi.getPolygonList({
      page,
      q,
      order,
      kind: kindObj[kind],
      per_page: Number(per_page),
      ...stateOpts
    });
    return { ...response.data, items: response.data.items.filter(({ state }) => state !== -1) };
  }
);

const initialState: PolygonListType = {
  result: {
    isLoading: 'idle',
    isError: false,
    message: '',
    total: 0,
    data: []
  }
};

const slice = createSlice({
  name: 'polygonList',
  initialState,
  reducers: {
    createOrEditPolygon: (state) => {
      const currentResult = state.result;
      state.result = {
        ...currentResult,
        isLoading: 'idle'
      };
    },
    deletePolygon: (state, action: PayloadAction<number>) => {
      const currentData = state.result.data;
      const filterdData = currentData.filter((polygon) => polygon.job_id !== action.payload);
      state.result = {
        ...state.result,
        data: filterdData
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPolygonListByParams.pending, (state) => ({
      ...state,
      result: {
        isLoading: 'loading',
        isError: false,
        message: '',
        total: state.result.total ?? 0,
        data: []
      }
    }));
    builder.addCase(fetchPolygonListByParams.fulfilled, (state, action) => {
      const data = action.payload;

      return {
        ...state,
        result: {
          isLoading: 'fetched',
          isError: false,
          message: '',
          total: data.total_count,
          data: data.items
        }
      };
    });
    builder.addCase(fetchPolygonListByParams.rejected, (state, action) => {
      const { message } = action.error;
      return {
        ...state,
        result: {
          isLoading: 'fetched',
          isError: true,
          message: message ?? '',
          total: 0,
          data: []
        }
      };
    });
  }
});

export const { actions } = slice;
export default slice.reducer;

export const polygonListConfig = {
  key: 'polygonList',
  storage,
  whitelist: ['polygonList']
};
