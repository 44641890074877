import { useCallback, useState, useEffect, useMemo } from 'react';
import NaverMap from '@F/map/NaverMap';
import { cookSitesApi } from '@API/manager/cook';
import YellowPin from '@I/map/pin-yellow.png';
import Loading from '@F/Loading';
import { useFetch } from '@HOOK/';
import { toastErrorMessage } from '@FUNC/toast';
import SiteMapToolbar, { PIN_TIERS } from './SiteMapToolbar';
import SiteMapTableWrapper from './SiteMapTableWrapper';
import { getImageBySite, determinePolygonContainsPoint, getComplexFullName } from './functions';
import * as S from './styles';

export const TYPE = {
  리스트: 1,
  마커: 2
};

const INITIAL_PARAMS = {
  type: TYPE['마커'],
  page: 1,
  perPage: 500,
  pinTiers: PIN_TIERS,
  addresses: {},
  orders: ['site_id desc']
};

function SiteMap() {
  const { callApi, isLoading } = useFetch();
  const [params, setParams] = useState(INITIAL_PARAMS);
  const isListType = params.type === TYPE['리스트'];
  const setPage = (newPage) => setParams((prevState) => ({ ...prevState, page: newPage }));

  const [mapCenter, setMapCenter] = useState(null);
  const [mapBoundary, setMapBoundary] = useState(null);
  const [triggerEmitBoundary, setTriggerEmitBoundary] = useState(false);

  const [allSites, setAllSites] = useState([]);
  const [sitesWithinPolygon, setSitesWithinPolygon] = useState(null);
  const [sitesToLiftedUp, setSitesToLiftedUp] = useState([]);
  const [siteCounts, setSiteCounts] = useState();
  const [complexes, setComplexes] = useState([]);

  const sitesForTable = useMemo(() => sitesWithinPolygon ?? allSites, [allSites, sitesWithinPolygon]);

  const LiftUpSites = useCallback(
    (type, id) => {
      if (type === 'site') {
        const targetSite = allSites.filter((site) => site.site_id === id);
        setSitesToLiftedUp(targetSite);
      } else if (type === 'complex') {
        const targetSites = allSites.filter((site) => site.cid === id);
        setSitesToLiftedUp(targetSites);
      }
    },
    [allSites]
  );

  const markers = useMemo(
    () => [
      ...complexes.map((complex) => ({
        lat: complex.lat,
        lng: complex.lng,
        image: YellowPin,
        size: [13 * 2.5, 17 * 2.5],
        id: complex.cid,
        name: getComplexFullName(complex),
        onClickMarker: () => LiftUpSites('complex', complex.cid)
      })),
      ...allSites
        .filter((site) => !site.sites)
        .map((site) => ({
          lat: site.lat,
          lng: site.lng,
          image: getImageBySite(site),
          size: [13 * 1.8, 17 * 1.8],
          id: site.site_id,
          name: site.origin_name,
          onClickMarker: () => LiftUpSites('site', site.site_id)
        }))
    ],
    [allSites, complexes, LiftUpSites]
  );

  const setMapCenterByAddr = useCallback((addr1, addr2, addr3) => {
    window.naver.maps.Service.geocode(
      {
        // TODO: 예외 케이스 리팩토링
        query: `${addr1 === '세종' ? '' : addr1} ${addr2} ${addr3 === '전체' ? '' : addr3}`
      },
      (status, response) => {
        if (status === 200) {
          const data = response.v2.addresses[0];
          if (data) {
            setMapCenter({ lng: parseFloat(data.x), lat: parseFloat(data.y) });
          }
        }
      }
    );
  }, []);

  const calculateBoundary = useCallback((bounds) => {
    if (Object.entries(bounds).length !== 0) {
      const minCoords = bounds.getSW();
      const maxCoords = bounds.getNE();
      setMapBoundary({
        min_x: minCoords.x,
        min_y: minCoords.y,
        max_x: maxCoords.x,
        max_y: maxCoords.y
      });
    }
  }, []);

  const calculateSitesWithinPolygon = useCallback(
    (polygon) => {
      if (polygon) {
        // eslint-disable-next-line no-underscore-dangle
        const polygonPath = polygon.getPath()._array.map((point) => [point.x, point.y]);
        setSitesWithinPolygon(
          allSites.filter((site) => determinePolygonContainsPoint([site.lng, site.lat], polygonPath))
        );
      } else {
        setSitesWithinPolygon(null);
      }
    },
    [allSites]
  );

  const getSites = async ({ page: targetPage, targetParams = params, hideLoading }) => {
    try {
      if (targetParams.pinTiers.length === 0) {
        throw new Error('pin_tier를 선택해주세요.');
      }

      let apiParams = {};

      if (targetParams.type === TYPE['리스트']) {
        if (!targetParams.addresses.addr1 || !targetParams.addresses.addr2) {
          throw new Error('주소를 입력해주세요.');
        }
        apiParams = {
          addresses: targetParams.addresses
        };
      } else if (targetParams.type === TYPE['마커']) {
        apiParams = {
          coords: mapBoundary
        };
      }

      apiParams = {
        ...apiParams,
        type: targetParams.type,
        page: targetPage || targetParams.page,
        perPage: targetParams.perPage,
        pinTiers: targetParams.pinTiers,
        orders: targetParams.orders
      };

      const response = await callApi(cookSitesApi.getBulkSites, apiParams, false, {}, hideLoading);
      const { data } = response;

      setSiteCounts(data.counts);
      setComplexes(data.complex);
      setAllSites(
        data.items.map((site, index) => {
          if (site.sites) {
            // 복합몰의 층을 나타내는 floorSite일 경우
            const complex = data.complex.find((complex) => complex.cid === site.cid);

            return {
              ...site,
              site_id: Number.MAX_SAFE_INTEGER - index,
              lat: complex.lat,
              lng: complex.lng,
              origin_name: `${complex.name} ${complex.branch_name ?? ''}`,
              last_category: complex.category,
              charge: site.sites[0].charge
            };
          }
          return site;
        })
      );
    } catch (error) {
      console.error(error);
      toastErrorMessage(error.message);
    }
  };

  const setPageAndGetSites = (newPage) => {
    if (params.page !== newPage) {
      setPage(newPage);
      getSites({ page: newPage });
    }
  };

  useEffect(() => {
    if (mapBoundary) {
      setPage(1);
      getSites({ page: 1 });
    }
  }, [mapBoundary]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickLoadMarkerButton = (toolbarParams) => {
    setParams((prevState) => ({ ...prevState, ...toolbarParams, type: TYPE['마커'] }));
    setTriggerEmitBoundary(true);
  };

  const onClickLoadListButton = (toolbarParams) => {
    let newParams = {};
    setParams((prevState) => {
      newParams = { ...prevState, ...toolbarParams, page: 1, type: TYPE['리스트'] };
      return newParams;
    });
    getSites({ page: 1, targetParams: newParams });
  };

  const onChangeSortOption = (order, orderBy) => {
    let newParams = {};
    setParams((prevState) => {
      newParams = { ...prevState, page: 1, orders: [`${orderBy} ${order}`] };
      return newParams;
    });
    if (allSites.length > 0) {
      getSites({ page: 1, targetParams: newParams });
    }
  };

  return (
    <S.StyledSiteMap>
      {isLoading && <Loading />}
      <SiteMapToolbar
        onClickLoadListButton={onClickLoadListButton}
        onClickLoadMarkerButton={onClickLoadMarkerButton}
        onSelectAddr3={setMapCenterByAddr}
      />
      <S.FlexContainer>
        <S.FlexItem hidden={isListType}>
          <NaverMap
            id="SiteMap"
            center={mapCenter}
            onEmitBoundary={calculateBoundary}
            triggerEmitBoundary={triggerEmitBoundary}
            setTriggerEmitBoundary={setTriggerEmitBoundary}
            hasDrawingManager
            onEmitPolygon={calculateSitesWithinPolygon}
            markers={markers}
          />
        </S.FlexItem>
        <S.FlexItem>
          <SiteMapTableWrapper
            siteCounts={siteCounts}
            sites={sitesForTable}
            sitesToLiftedUp={sitesToLiftedUp}
            complexes={complexes}
            pages={{
              page: params.page,
              perPage: params.perPage,
              setPage: setPageAndGetSites
            }}
            isListType={isListType}
            onConfirm={() => getSites({ hideLoading: true })}
            onChangeSortOption={onChangeSortOption}
          />
        </S.FlexItem>
      </S.FlexContainer>
    </S.StyledSiteMap>
  );
}
export default SiteMap;

SiteMap.propTypes = {};
