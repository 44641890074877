import styled from 'styled-components';
import { ArrowDownwardOutlined } from '@material-ui/icons';
import { blue600, blue400, spacing } from 'loplat-ui';

export const CustomArrowDownwardOutlined = styled(ArrowDownwardOutlined)`
  width: 18px;
  height: 18px;
  .active & {
    color: ${blue600} !important;
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ControlWrapper = styled.div`
  .addressInput {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 300px;
    background-color: #fff;
    z-index: 50;
    margin-bottom: 20px;
  }

  .icons {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    z-index: 50;
    display: flex;
    gap: ${spacing(2)}px;

    span {
      background-color: #fff;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0.5px;
      cursor: pointer;
      width: 28px;
      height: 28px;

      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        background-color: ${blue400};
      }
    }
  }

  p {
    font-size: 0.8rem;
    color: red;
  }
`;

export const StyledUL = styled.ul<{ inputHeight: number }>`
  position: absolute;
  top: calc(${(props) => props.inputHeight}px + ${spacing(4)}px);
  left: 10px;
  z-index: 50;

  background-color: #fff;
  min-width: 300px;
  width: 300px;
  overflow-y: auto;
  outline: 0px;
  max-height: 300px;
  list-style: none;

  li {
    width: 100%;
    font-size: 0.8rem;
    padding: ${spacing(2)}px;
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

export const PageWrapper = styled.div`
  height: calc(calc(100vh - 65px) - 110px);
`;

export const InputWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: ${spacing(5)}px;

  .inputWrapper {
    display: grid;
    grid-gap: ${spacing(3)}px;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'title latlng'
      'addr road';

    .title {
      grid-area: title;
    }

    .addr {
      grid-area: addr;
    }

    .road {
      grid-area: road;
    }

    .latlng {
      grid-area: latlng;
    }
  }

  .submit {
    display: flex;
    justify-content: flex-end;
    gap: ${spacing(3)}px;
  }
`;
