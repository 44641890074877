import { Modal, Headline, Button } from 'loplat-ui';
import { useEffect, useState } from 'react';
import type { GeofenceApi } from 'loplat-x';
import type { SelectedGeofence } from '@P/max/campaigns/detail/RegionSection/GeofenceCondition';
import { maxCampaignApi } from '@API/max';
import { toastErrorMessage } from '@FUNC/toast';
import axios, { AxiosError } from 'axios';
import { ErrorKey, ManagerApiError } from '@TS/customError';
import { ModalWrapper, Table } from './styles';
import SkeletonTable from './SkeletonTable';
import NaverMap from './NaverMap';
import { isGeofenceGroup } from './type';
import { errorBoundaryMessage } from '@/constants/message';

export type Geofence =
  | GeofenceApi.Get.Single.Response.GeofenceCircle
  | GeofenceApi.Get.Single.Response.GeofencePolygon
  | GeofenceApi.Get.Group.Response.Detail;

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & Partial<SelectedGeofence>;
const GeofenceModal = ({ isOpen, onClose, id, type }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [geofence, setGeofence] = useState<Geofence | null>(null);

  useEffect(() => {
    if (!!id && !!type) {
      (async () => {
        try {
          setIsLoading(true);
          let geofence: Geofence | null = null;
          if (type === 'single') {
            const { data } = await maxCampaignApi.getGeofence({ id });
            geofence = data;
          } else if (type === 'group') {
            const { data } = await maxCampaignApi.getGeofenceGroup({ id });
            geofence = data;
          }
          setGeofence(geofence);
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          onClose();
          if (axios.isAxiosError(e)) {
            const error = e as AxiosError<ManagerApiError>;
            const errorCode = error.response?.data.error as ErrorKey;
            toastErrorMessage(errorBoundaryMessage[errorCode]);
          } else {
            throw e;
          }
        }
      })();
    }

    return () => {
      setIsLoading(false);
      setGeofence(null);
    };
  }, [id, onClose, type]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalWrapper>
        {isLoading ? (
          <SkeletonTable />
        ) : geofence === null ? (
          <SkeletonTable />
        ) : (
          <GeofenceContent geofence={geofence} />
        )}
        <Button onClick={onClose} mt={4} color="primary2">
          닫기
        </Button>
      </ModalWrapper>
    </Modal>
  );
};

export default GeofenceModal;

type ContentProps = {
  geofence: Geofence;
};
const GeofenceContent = ({ geofence }: ContentProps) => (
  <Table>
    <thead>
      <tr>
        <th colSpan={4}>
          <Headline tag="h3">{geofence.name}</Headline>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">지오펜스 설명</th>
        <td colSpan={3}>{geofence.description === '' ? '-' : geofence.description}</td>
      </tr>
      <tr>
        <th>Client Code</th>
        <td>{geofence.client_code === '' ? '-' : geofence.client_code}</td>
        <th>ID</th>
        <td>{isGeofenceGroup(geofence) ? geofence.gfgid : geofence.gfid}</td>
      </tr>
      <tr>
        <th>생성자</th>
        <td>{geofence.creator}</td>
        <th>생성일</th>
        <td>{geofence.created_at}</td>
      </tr>
      <tr>
        <td colSpan={4} className="map">
          <NaverMap geofence={geofence} />
        </td>
      </tr>
    </tbody>
  </Table>
);
