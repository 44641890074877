import { blue600, grayscale400 } from 'loplat-ui';

// see: https://navermaps.github.io/maps.js.ncp/docs/tutorial-3-geocoder-geocoding.example.html
function hasArea(area) {
  return !!(area && area.name && area.name !== '');
}

function hasData(data) {
  return !!(data && data !== '');
}

function hasAddition(addition) {
  return !!(addition && addition.value);
}

function checkLastString(word, lastString) {
  // eslint-disable-next-line prefer-template
  return new RegExp(lastString + '$').test(word);
}

export function makeAddress(item) {
  if (!item) {
    return;
  }

  // eslint-disable-next-line prefer-const
  let { name, region, land } = item;
  const isRoadAddress = name === 'roadaddr';

  let sido = '';
  let sigugun = '';
  let dongmyun = '';
  let ri = '';
  let rest = '';

  if (hasArea(region.area1)) {
    sido = region.area1.name;
  }

  if (hasArea(region.area2)) {
    sigugun = region.area2.name;
  }

  if (hasArea(region.area3)) {
    dongmyun = region.area3.name;
  }

  if (hasArea(region.area4)) {
    ri = region.area4.name;
  }

  if (land) {
    if (hasData(land.number1)) {
      if (hasData(land.type) && land.type === '2') {
        rest += '산';
      }

      rest += land.number1;

      if (hasData(land.number2)) {
        // eslint-disable-next-line prefer-template
        rest += '-' + land.number2;
      }
    }

    if (isRoadAddress === true) {
      if (checkLastString(dongmyun, '면')) {
        ri = land.name;
      } else {
        dongmyun = land.name;
        ri = '';
      }

      if (hasAddition(land.addition0)) {
        // eslint-disable-next-line prefer-template
        rest += ' ' + land.addition0.value;
      }
    }
  }

  // eslint-disable-next-line consistent-return
  return [sido, sigugun, dongmyun, ri, rest].filter((v) => Boolean(v)).join(' ');
}

const makeWrapper = () => {
  const wrapper = document.createElement('div');
  wrapper.style.padding = '10px';
  wrapper.style.minWidth = '200px';
  return wrapper;
};

const makeHeading = (text) => {
  const heading = document.createElement('h4');
  heading.innerText = text;
  return heading;
};

const makeButtonWrapper = () => {
  const buttonWrapper = document.createElement('div');
  buttonWrapper.style.width = '100%';
  buttonWrapper.style.display = 'flex';
  buttonWrapper.style.gap = '4px';
  buttonWrapper.style.marginTop = '8px';
  buttonWrapper.style.justifyContent = 'flex-end';
  return buttonWrapper;
};

const makeParagraph = (text) => {
  const paragraph = document.createElement('p');
  paragraph.style.marginBottom = '4px';
  paragraph.style.fontSize = '0.75rem';
  paragraph.innerText = text;
  return paragraph;
};

const makeButton = (text, callback) => {
  const button = document.createElement('button');
  button.style.padding = '4px';
  button.style.backgroundColor = text === '등록' ? blue600 : 'inherit';
  button.style.border = text === '등록' ? `1px solid ${blue600}` : `1px solid ${grayscale400}`;
  button.style.borderRadius = '5px';
  button.style.color = text === '등록' ? 'white' : grayscale400;
  button.style.fontSize = '0.75rem';
  button.style.cursor = 'pointer';
  button.innerText = text;
  button.addEventListener('click', callback);
  return button;
};

export function makeLoadingContent() {
  const wrapper = makeWrapper();
  const heading = makeHeading('로딩중입니다...');
  wrapper.appendChild(heading);
  return wrapper;
}

export function makeContent({ jibun, road, cancelCb, confirmCb }) {
  const wrapper = makeWrapper();
  const heading = makeHeading('검색 주소');
  heading.style.marginBottom = '8px';
  wrapper.appendChild(heading);

  const jibunParagraph = makeParagraph(`1. [지번주소] ${jibun}`);
  wrapper.appendChild(jibunParagraph);
  const roadParagraph = makeParagraph(`2. [도로명주소] ${road}`);
  wrapper.appendChild(roadParagraph);

  const buttonWrapper = makeButtonWrapper();
  const cancelButton = makeButton('닫기', cancelCb);
  const confirmButton = makeButton('등록', confirmCb);
  buttonWrapper.appendChild(cancelButton);
  buttonWrapper.appendChild(confirmButton);

  wrapper.appendChild(buttonWrapper);
  return wrapper;
}

export function randomColorArray(length) {
  const colors = [];

  function getRandomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  for (let i = 0; i < length; ) {
    const randomColor = getRandomColor();

    if (colors.indexOf(randomColor) === -1 && randomColor !== '#FF0000') {
      colors.push(randomColor);
      i += 1;
    }
  }

  return colors;
}

export async function drawGeoJson(geoJson, map) {
  await map.data.addGeoJson(geoJson);
  map.data.setStyle((feature) => {
    const color = feature.getProperty('color') || 'blue';

    return {
      strokeColor: color,
      fillColor: color,
      fillOpacity: 0.5,
      strokeOpacity: 0.5,
      strokeWeight: 0.5,
      icon: null
    };
  });
}

export function setEventAtGeoJsonData({ naverMap, draw }) {
  const bound = draw.getBounds();
  const { x: maxX, y: maxY } = bound.getMax();
  const { x: minX, y: minY } = bound.getMin();

  const position = new window.naver.maps.Point((maxX + minX) / 2, (maxY + minY) / 2);
  const content = document.createElement('div');
  content.style.padding = '10px';
  content.innerText = draw.getProperty('title');

  const infoWindonw = new window.naver.maps.InfoWindow({
    position,
    content,
    anchorSkew: false,
    disableAnchor: false,
    anchorSize: {
      width: 0,
      height: 0
    }
  });

  draw.addListener('mouseover', () => {
    infoWindonw.open(naverMap);
    naverMap.data.overrideStyle(draw, {
      fillOpacity: 0.8,
      strokeOpacity: 0.8,
      strokeWeight: 1.5
    });
  });

  draw.addListener('mouseout', () => {
    infoWindonw.close();
    naverMap.data.overrideStyle(draw, {
      fillOpacity: 0.5,
      strokeOpacity: 0.5,
      strokeWeight: 0.5
    });
  });
}

export function callReverseGeocode({ coords }) {
  return new Promise((resolve, reject) => {
    try {
      window.naver.maps.Service.reverseGeocode(
        {
          coords,
          orders: [window.naver.maps.Service.OrderType.ADDR, window.naver.maps.Service.OrderType.ROAD_ADDR].join(',')
        },
        (status, response) => {
          if (status === window.naver.maps.Service.Status.ERROR) {
            reject();
          }
          const items = response.v2.results;
          const address = items.map((item) => ({ name: item.name, address: makeAddress(item) }));

          const jibun = address.find((item) => item.name === 'addr') ?? { address: '' };
          const road = address.find((item) => item.name === 'roadaddr') ?? { address: '' };
          resolve({ jibun, road });
        }
      );
    } catch (err) {
      reject();
    }
  });
}

export const toggleMapControl = (map, flag) => {
  map.setOptions({
    draggable: flag === 'on',
    zoomControl: flag === 'on',
    scrollWheel: flag === 'on',
    disableDoubleClickZoom: flag === 'off'
  });
};

export const getCurrentMapBounds = (map) => {
  const getBounds = map.getBounds();
  return {
    min_lng: getBounds.minX(),
    min_lat: getBounds.minY(),
    max_lng: getBounds.maxX(),
    max_lat: getBounds.maxY()
  };
};
