import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchCookUsers, updateCookUser, deleteUserDevice } from './api';

export function* fetchData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.cookUser.page,
    perPage: state.cookUser.perPage,
    params: state.cookUser.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchCookUsers, page, perPage, params);
    yield put(actions.setNewUsers(data.items));
    yield put(actions.setValue('count', data.count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateUser(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(updateCookUser, action.params);
    const newAttributes = {
      team_id: action.params.teamId,
      username: action.params.userName,
      daily_min: action.params.dailyMinimum,
      daily_sal: action.params.dailySalary,
      pay_type: action.params.payType
    };
    yield put(actions.setNewUser({ ...action.user, ...action.params, ...newAttributes }));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* deleteDevice(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(deleteUserDevice, action.user.user_id);
    yield put(actions.setNewUser({ ...action.user, ssaid: null }));
    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_USERS, fetchData),
    takeLeading(types.UPDATE_USER, updateUser),
    takeLeading(types.DELETE_DEVICE, deleteDevice)
  ]);
}
