import { useEffect, useState } from 'react';
import { DMPFirebaseProject } from '@/firebase';
import AddVersion from './AddVersion';
import VersionsView from './VersionsView';

export type VersionList = Version[];
export type Version = {
  comment: string;
  published_at: string;
  type: 'release' | 'hotfix' | 'update';
  version: string;
  fileName?: string;
};

const VersionManager = (): JSX.Element => {
  const [versionList, setVersionList] = useState<VersionList>([]);

  const initializeFirebase = async () => {
    await DMPFirebaseProject.init();
    return DMPFirebaseProject.firestore();
  };

  useEffect(() => {
    let versionList: VersionList = [];
    const fetchData = async () => {
      const db = await initializeFirebase();

      await db
        .collection('version')
        .get()
        .then((snapshot) => {
          snapshot.docs.map((doc) => {
            const data = doc.data();
            const version = {
              ...data,
              fileName: doc.id
            } as Version;
            versionList.push(version);
          });
        });

      // version 순서대로 정렬
      versionList.sort((a, b) => {
        const aVersion = a.version.split('.').map(Number);
        const bVersion = b.version.split('.').map(Number);

        for (let i = 0; i < aVersion.length; i++) {
          if (aVersion[i] > bVersion[i]) {
            return 1;
          } else if (aVersion[i] < bVersion[i]) {
            return -1;
          }
        }

        return 0;
      });

      setVersionList(versionList);
    };

    fetchData();
  }, []);

  // firebase에 버전을 추가하는 함수
  const addVersion = async (version: Version) => {
    const db = await initializeFirebase();

    await db.collection('version').doc(version.version).set(version);

    setVersionList((prevList) => [...prevList, version]);
  };

  const deleteVersion = async (version: Version) => {
    const db = await initializeFirebase();

    await db.collection('version').doc(version.fileName).delete();

    setVersionList((prevList) => prevList.filter((v) => v.version !== version.version));
  };

  return (
    <div>
      <AddVersion addVersion={addVersion} />
      <VersionsView versionList={versionList} deleteVersion={deleteVersion} />
    </div>
  );
};

export default VersionManager;
