import { MouseEventHandler, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Grid, TableRow, TableCell } from '@material-ui/core';

import NaverMap from '@F/map/NaverMap';
import PinRed from '@I/map/pin-red.png';
import ToggleBodyCell from '@F/table/cells/ToggleBodyCell';
import { ItemClass, TooltipItemClass } from '@FUNC/item';
import Tooltip from '@F/Tooltip';
import type { PrimitiveLog, ScannedWifi } from '@TS/max/primitiveLog/primitiveLog';

// import ScannedWifiTable from './primitiveLogTableItem/ScannedWifiTable';

export default function PrimitiveLogsTableItem({
  index,
  primitiveLog,
  isExpanded,
  onClick
}: {
  index: number;
  primitiveLog: PrimitiveLog;
  isExpanded: boolean;
  onClick: MouseEventHandler;
}): JSX.Element {
  const primitiveLogCells = useMemo(() => {
    const { ts_local, adid, echo_code, campaign_id, client_id } = primitiveLog;

    return [ts_local, adid, echo_code, campaign_id, client_id].map((value, i) => (
      <TableCell key={i} align="center">
        {value}
      </TableCell>
    ));
  }, [primitiveLog]);

  return (
    <>
      <TableRow onClick={onClick}>
        <ToggleBodyCell onState={isExpanded} />
        {primitiveLogCells}
      </TableRow>
      {isExpanded ? <PrimitiveLogDetail index={index} primitiveLog={primitiveLog} /> : null}
    </>
  );
}

function PrimitiveLogDetail({ index, primitiveLog }: { index: number; primitiveLog: PrimitiveLog }) {
  const {
    lat,
    lng,
    addr_lv0, // 국가
    addr_lv1, // 시도
    addr_lv2, // 시군구 코드
    addr_lv3, // 행정동 코드
    vi_floor,
    fpid,
    placename,
    cat,
    device,
    os_ver,
    sdk_ver,
    anid,
    campain_candidates,
    scanned_wifi,
    area_ids,
    hit,
    complex_ids
  } = primitiveLog;

  const hasAddress = lat && lng;
  const addressInfo: (ItemClass<number | string | null> | TooltipItemClass<number | string | null>)[] = [
    new ItemClass('lat', '위도', lat),
    new ItemClass('lng', '경도', lng),
    new ItemClass('addr_lv0', '국가', addr_lv0),
    new ItemClass('addr_lv1', '시도', addr_lv1),
    new ItemClass('addr_lv2', '시군구', addr_lv2),
    new ItemClass('addr_lv3', '행정동', addr_lv3),
    new TooltipItemClass('vi_floor', 'vi 층수', vi_floor, 'vi 기준 store 층'),
    new TooltipItemClass(
      'fpid',
      'fpid',
      fpid,
      'vi 기준 인식된 fpid 또는 bfpid. 2019-09-17까지는 fpid가 아니라 pid가 들어가고 있었음'
    ),
    new TooltipItemClass(
      'placename',
      '장소',
      `${placename ?? '정보 없음'} (${hit === 0 ? 'Unknown' : hit === 1 ? 'In' : 'Near'})`,
      'vi 기준 인식된 pid, GPS 기반 인식 기록은 2020-05-19부터'
    ),
    new TooltipItemClass('cat', '카테고리', cat, 'vi 기준 인식된 pid의 category')
  ];

  const deviceInfo: (ItemClass<number | string | null> | TooltipItemClass<number | string | null>)[] = [
    new TooltipItemClass(
      'anid',
      'anid',
      anid,
      '로플랫 규격의 Anonymous ID. adid가 없을 경우에만 기록. (2021-04-14 부터)'
    ),
    new ItemClass('device', '기기명', device),
    new ItemClass('os_ver', 'OS 버전', os_ver),
    new ItemClass('sdk_ver', 'SDK 버전', sdk_ver)
  ];

  const marker = {
    lng: Number(lng),
    lat: Number(lat),
    image: PinRed,
    size: [13 * 2, 17 * 2]
  };

  const [showNaverMap, setShowNaverMap] = useState(false);

  const naverMapInfo = useMemo(
    () =>
      hasAddress ? (
        <StyledNaverMapWrapper scanned_wifi={scanned_wifi}>
          <NaverMap
            id={`${lat}/${lng}/${index}`}
            center={{ lat: Number(lat), lng: Number(lng) }}
            zoom={10}
            markers={[marker]}
          />
        </StyledNaverMapWrapper>
      ) : null,
    [showNaverMap]
  );

  const detailInfo = useMemo(
    () => (
      <Grid container>
        <Grid item xs={6}>
          <li>
            <h3>위치</h3>
            <ul>
              {addressInfo.map((item, i) => {
                const content = (
                  <li key={i}>
                    {`${item.label} : `}
                    {item.value || <span style={{ color: '#aaa' }}>정보 없음</span>}
                  </li>
                );
                if (item instanceof TooltipItemClass) {
                  return (
                    <Tooltip title={item.description} key={i}>
                      {content}
                    </Tooltip>
                  );
                }
                return content;
              })}
              <li>
                <b>복합물 목록</b>
                <ul style={{ paddingLeft: '8px' }}>
                  {complex_ids?.map((complex_id, i) => <li key={i}>{complex_id}</li>) ?? '정보 없음'}
                </ul>
              </li>
              <li>
                <b> 상권 목록</b>
                <ul style={{ paddingLeft: '8px' }}>
                  {area_ids.map((area_id, i) => (
                    <li key={i}>{area_id}</li>
                  ))}
                </ul>
              </li>
            </ul>
          </li>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item>
              <li>
                <h3>인식기기</h3>
                <ul>
                  {deviceInfo.map((item, i) => {
                    const content = (
                      <li key={i}>
                        {`${item.label} : `}
                        {item.value || <span style={{ color: '#aaa' }}>정보 없음</span>}
                      </li>
                    );

                    if (item instanceof TooltipItemClass) {
                      return (
                        <Tooltip title={item.description} key={i}>
                          {content}
                        </Tooltip>
                      );
                    }

                    return content;
                  })}
                </ul>
              </li>
            </Grid>
            <Grid item>
              <li>
                <h3>매칭 가능 캠페인 목록</h3>
                <StyledHorizontalScrollableList>
                  {campain_candidates.map((campaign_candidate, i) => (
                    <li key={i}>{campaign_candidate}</li>
                  ))}
                </StyledHorizontalScrollableList>
              </li>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    []
  );

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <StyledPrimitiveLogsTableItem scanned_wifi={scanned_wifi}>
          <Grid container>
            <Grid item xs={4}>
              {naverMapInfo}
            </Grid>
            <Grid item xs={8}>
              <StyledDetailInfoSection>{detailInfo}</StyledDetailInfoSection>
              {/* {scanned_wifi.length ? <ScannedWifiTable scanned_wifi={scanned_wifi} /> : null} */}
            </Grid>
          </Grid>
        </StyledPrimitiveLogsTableItem>
      </TableCell>
    </TableRow>
  );
}

//   height: ${({ scanned_wifi }) => (scanned_wifi.length ? '800' : '400')}px;
const StyledPrimitiveLogsTableItem = styled.div<{ scanned_wifi: ScannedWifi[] }>`
  margin-bottom: 20px;
  height: 400px;
  overflow-y: scroll;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  * {
    border-collapse: none;
  }
`;

const StyledDetailInfoSection = styled.ul`
  height: 300px;
`;

//   height: ${({ scanned_wifi }) => (scanned_wifi.length ? '800' : '400')}px;
const StyledNaverMapWrapper = styled.div<{ scanned_wifi: ScannedWifi[] }>`
  height: 400px;
  max-width: 600px;
  margin-right: 30px;
`;
// TODO: 스캔 와이파이 추가  height: ${({ scanned_wifi }) => (scanned_wifi.length ? '800' : '400')}px;

const StyledHorizontalScrollableList = styled.ul`
  display: flex;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;

  li {
    margin-bottom: 10px;
    margin-right: 15px;
  }
`;
