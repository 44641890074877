import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { Grid, Button, colors } from '@material-ui/core';
import { ConfigurationForm } from '@API/manager/sdk/types';
import ConfirmModal from '@F/modal/ConfirmModal';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import SdkConfigForm from './components/SdkConfigForm';
import { useManageSdkConfigs } from './useManageCompanies';
import { validateConfigurationFormValues } from './validation';

let saveController: AbortController | undefined;

export default function CompanyCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { createSdkConfig } = useManageSdkConfigs();
  const [tempSaveApiStatus, setTempSaveApiStatus] = useState<ApiStatus>('idle');
  const [saveApiStatus, setSaveApiStatus] = useState<ApiStatus>('idle');

  const handleTempSave = async (formValues: ConfigurationForm) => {
    setTempSaveApiStatus('loading');
    createSdkConfig({ ...formValues, publish: false }).then((created) => {
      if (created) {
        toastSuccessMessage('고객사 정보를 성공적으로 임시 저장하였습니다.');
        navigate(`/x/companies/edit/${created.id}`);
        setTempSaveApiStatus('success');
      } else {
        setTempSaveApiStatus('failure');
      }
    });
  };

  const handleSave = async (formValues: ConfigurationForm) => {
    if (saveController) {
      saveController.abort();
    }

    const errorMessage = validateConfigurationFormValues(formValues);

    if (errorMessage) {
      toastErrorMessage(errorMessage);
      return;
    }

    saveController = new AbortController();

    setSaveApiStatus('loading');
    createSdkConfig({ ...formValues, publish: true }, saveController?.signal).then((created) => {
      if (created) {
        toastSuccessMessage('고객사 정보를 성공적으로 등록하였습니다.');
        navigate(`/x/companies/${created.id}`);
      } else {
        setSaveApiStatus('failure');
        saveController = undefined;
      }
    });
  };

  const [openPublishModal, setOpenPublishModal] = useState(false);

  useEffect(() => {
    document.querySelector('main')?.scrollTo({
      top: 0
    });
  }, []);

  return (
    <SdkConfigForm
      renderToolbar={(formValues, isDirty) => (
        <Grid container justifyContent="flex-end" direction="row" spacing={1}>
          <Grid item>
            <Button onClick={() => navigate('/x/companies')} color="primary" variant="contained">
              목록으로
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleTempSave(formValues)}
              color="primary"
              variant="contained"
              disabled={!isDirty || tempSaveApiStatus === 'loading'}
            >
              {tempSaveApiStatus === 'loading' && !openPublishModal ? (
                <div style={{ height: '24.5px' }}>
                  <div style={{ marginTop: '-8px' }}>
                    <ReactLoading type="cylon" color={colors.indigo[600]} height={18} width={40} />
                  </div>
                </div>
              ) : (
                '임시저장'
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setOpenPublishModal(true)} color="primary" variant="contained" disabled={!isDirty}>
              작성완료
            </Button>
            <ConfirmModal
              isOpen={openPublishModal}
              title={
                saveApiStatus === 'loading' ? '고객사 정보를 등록하는 중입니다...' : '고객사 정보를 등록하시겠습니까?'
              }
              subTitle={
                saveApiStatus === 'loading'
                  ? '잠시만 기다려주세요.'
                  : saveApiStatus === 'failure'
                  ? '요청에 실패했습니다. 다시 시도해주세요.'
                  : '고객사 정보 등록 시 관리 탭을 통해 해당 정보를 삭제/수정할 수 없습니다.'
              }
              permitText="등록"
              onPermit={() => handleSave(formValues)}
              onCancel={() => {
                saveController?.abort();
                setOpenPublishModal(false);
              }}
              disabled={!isDirty || saveApiStatus === 'loading'}
            />
          </Grid>
        </Grid>
      )}
    />
  );
}
