import styled from 'styled-components';
import { FormControl, Menu, Paper, TextField } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

export const StyledFormControl = styled(FormControl)`
  height: 100%;
`;

export const StyledPaper = styled(Paper)`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  &.dim {
    background-color: inherit;
    cursor: pointer;
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    caret-color: transparent;
    padding-right: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledArrowDorpDown = styled(ArrowDropDown)<{ isOpen: boolean }>`
  position: absolute;
  top: 50%;
  right: 7px;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg) translateY(50%);' : 'translateY(-50%);')};
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  z-index: 2;
`;

export const StyledMenu = styled(Menu)<{ width: number }>`
  .MuiList-root {
    min-width: ${(props) => props.width}px;
  }
`;
