import styled from 'styled-components';
import { primary } from 'loplat-ui';
import type { TableRowType } from '@TS/max/table/campaignTable';
import { ReactComponent as ADIcon } from '@ICON/campaign/ad_campaign.svg';
import { ReactComponent as TestIcon } from '@ICON/campaign/test_campaign.svg';
import { ReactComponent as LiveIcon } from '@ICON/campaign/live.svg';

type Props = TableRowType['name'];
const CampaignName = ({ label, state, vendor, abTest, isLive }: Props): JSX.Element => (
  <NameWrapper>
    <span>{label}</span>
    {state === 'test' && (
      <span className="caption">
        <TestIcon width={14} height={14} />
        테스트
      </span>
    )}
    {isLive === 1 && <LiveIcon width={16} height={16} />}
    {state === 'commercial' && <ADIcon width={46} height={16} />}
    {vendor ? <span className="caption">{vendor}</span> : null}
    {abTest ? <span className="caption">AB test</span> : null}
  </NameWrapper>
);

export default CampaignName;

const NameWrapper = styled.div`
  word-break: break-word;
  display: flex;
  align-items: center;

  span.caption {
    vertical-align: top;
    font-size: 0.75rem;
    color: ${primary};
    margin-left: 0.5rem;
  }

  svg {
    margin: 0 2px 0 4px;
  }

  img {
    margin: 0 2px 0 4px;
    vertical-align: inherit;
  }
`;
