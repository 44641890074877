/* eslint-disable no-param-reassign */
import { MAP_STYLES } from './data';
import type { DrawCircle, DrawMarker, DrawPolygon, SetTooltipLayer, HandleHoverStyle } from './type';

export const formatMeterToKiloMeter = (area: number): number => area / 10 ** 6;

export function getUnionBoundaries(bounds: naver.maps.Bounds[]): naver.maps.Bounds {
  return bounds.reduce((prev, curr) => prev.union(curr), bounds[0]);
}

export const drawCircle: DrawCircle = ({ dist, lat, lng, map }) =>
  new naver.maps.Circle({
    map,
    center: new naver.maps.LatLng(lat, lng),
    radius: dist,
    ...MAP_STYLES.circle
  });

export const drawPolygon: DrawPolygon = ({ paths, map }) =>
  new naver.maps.Polygon({ map, paths, ...MAP_STYLES.polygon });

export const drawMarker: DrawMarker = ({ map, center }) =>
  new naver.maps.Marker({
    ...MAP_STYLES.marker,
    map,
    position: center
  });

export const moveToCenter = (map: naver.maps.Map, bounds: naver.maps.Bounds): void => {
  map.fitBounds(bounds, { top: 200, right: 200, bottom: 200, left: 200 });
};

export const setTooltipLayerOnGeofence: SetTooltipLayer = (geofence, map, marker) => {
  const content = `
    <section class='pin_tip'>
      <ul>
        <li>
          <div>
            <b>면적</b>
            <span>
              ${(+formatMeterToKiloMeter(geofence.getAreaSize()).toFixed(2)).toLocaleString()}
              <span>km<sup>2</sup></span>
            </span>
          </div>
        </li>
      </ul>
    </section>
  `;
  const infoWindow = new naver.maps.InfoWindow({ content, ...MAP_STYLES.infoWindow });

  naver.maps.Event.addListener(geofence, 'click', () => {
    if (infoWindow.getMap()) infoWindow.close();
    else infoWindow.open(map, marker);
  });
  naver.maps.Event.addListener(map, 'click', () => {
    infoWindow.close();
  });
};

export const setHoverOffStyle: HandleHoverStyle = (geofence, type) => {
  geofence.setStyles('strokeColor', MAP_STYLES[type].strokeColor);
  geofence.setStyles('fillColor', MAP_STYLES[type].fillColor);
};
export const setHoverOnStyle: HandleHoverStyle = (geofence, type) => {
  geofence.setStyles('strokeColor', MAP_STYLES.hover[type].strokeColor);
  geofence.setStyles('fillColor', MAP_STYLES.hover[type].fillColor);
};
export const setHoverStyles: HandleHoverStyle = (geofence, type) => {
  naver.maps.Event.addListener(geofence, 'mouseover', () => {
    setHoverOnStyle(geofence, type);
  });
  naver.maps.Event.addListener(geofence, 'mouseout', () => {
    setHoverOffStyle(geofence, type);
  });
};

export const setVisibleToolTip = (isVisible: boolean, tipNode: HTMLElement): void => {
  tipNode.style.display = isVisible ? 'block' : 'none';
};
