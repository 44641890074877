import { GetPromotionsResponse, Promotion } from '@TS/max/Promotion/api';
import { Table } from '@material-ui/core';
import { primary } from 'loplat-ui';
import { useState } from 'react';
import styled from 'styled-components';
import { usePromotionContext } from '../usePromotionContext';
import TableHead from './Table/TableHead';
import UpdateTableRow from './Table/UpdateTableRow';
import TableRow from './Table/TableRow';

type PromotionListProps = {
  promotions: GetPromotionsResponse['items'];
};

const PromotionList = ({ promotions }: PromotionListProps): JSX.Element => {
  const [updatingPromotionItem, setUpdatingPromotionItem] = useState<Promotion | null>(null);
  const [updateImg, setUpdateImg] = useState<File | null>(null);
  const { fetchPromotions, togglePublishPromotion, deletePromotion, UpdatePromotion, uploadBlobImageAndCallback } =
    usePromotionContext();

  const updateImgURL = (imgFile: File) => URL.createObjectURL(imgFile);

  const imgUploadAndUpdate = async (imgFile: File | string, updatePromotion: Promotion) => {
    // 이미지 변경 없을 때
    if (typeof imgFile === 'string') {
      UpdatePromotion({ ...updatePromotion, img: imgFile }).then((result) => {
        if (result) {
          fetchPromotions();
        }
      });
      return;
    }

    const imgUrl = await uploadBlobImageAndCallback(imgFile);
    if (imgUrl) {
      UpdatePromotion({ ...updatePromotion, img: imgUrl }).then((result) => {
        if (result) {
          fetchPromotions();
        }
      });
    }
  };

  return (
    <Wrapper>
      <Table>
        <TableHead />
        <tbody>
          {promotions.map((promotion: Promotion) => (
            <tr key={promotion.id}>
              {updatingPromotionItem?.id === promotion.id ? (
                <UpdateTableRow
                  promotion={promotion}
                  setUpdatingPromotionItem={setUpdatingPromotionItem}
                  updatingPromotionItem={updatingPromotionItem}
                  imgUploadAndUpdate={imgUploadAndUpdate}
                  updateImg={updateImg}
                  setUpdateImg={setUpdateImg}
                  updateImgURL={updateImgURL}
                />
              ) : (
                <TableRow
                  promotion={promotion}
                  setUpdatingPromotionItem={setUpdatingPromotionItem}
                  togglePublishPromotion={togglePublishPromotion}
                  deletePromotion={deletePromotion}
                />
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px;

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    width: 100%;

    .Img {
      min-width: 68px;
      min-height: 68px;
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 12px;
    }
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 10px;
    min-height: 100px;

    label {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      border: 1px solid #ccc;
    }

    #promotion-Img {
      display: none;
    }

    input {
      font-size: 1rem;

      width: 100%;
      height: 100%;

      border: none;
      border-bottom: 2px solid #ccc;
      outline: none;

      &:focus {
        border-bottom: 1px solid ${primary};
      }
    }
  }

  th {
    background-color: #eee;
  }
`;

export default PromotionList;
