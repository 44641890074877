import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toJS } from 'mobx';
import dayjs from 'dayjs';
import apiWrapper from '@API/wrapper';
import { toastErrorMessage } from '@FUNC/toast';
import { categoriesApi, sdkClientsApi } from '@API/manager';
import { cookUsersApi } from '@API/manager/cook';

import { GlobalStyle } from '@S/index';
import Toast from '@F/materialUI/Toast';
import useAuth from '@HOOK/useAuth';

import { actions } from '@/redux/cashplace/inspect/state';
import { valuesStore } from '@/mobX/values';
import { managerLocalStorage } from '@/utils/localStorageParser';

import Router from './routes';
import { cashplaceFirebaseProject, managerFirebaseProject } from './firebase';

import 'dayjs/locale/ko';

dayjs.locale('ko');

function App() {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const checkAndRefreshCategories = useCallback(async () => {
    // NOTE: 권한과 메뉴에 따라 볼 수 있는 카테고리 영역이 다릅니다. MC-1067
    const cachedCategories = toJS(await valuesStore.getValues('categories'));

    const emptyCachedCategoryEntries = Object.entries(cachedCategories).filter(([, category]) => category.length === 0);

    if (emptyCachedCategoryEntries.length === 0) return;

    try {
      const categories = await Promise.all(
        emptyCachedCategoryEntries.map(([type]) =>
          apiWrapper(categoriesApi.getCategories, type).then((refreshedCategory) => ({
            type,
            data: refreshedCategory
          }))
        )
      );

      const newCachedCategories = categories.reduce((acc, category) => {
        // eslint-disable-next-line no-param-reassign
        acc[category.type] = [...acc[category.type], ...category.data];
        return acc;
      }, cachedCategories);

      valuesStore.setValues('categories', newCachedCategories);
    } catch (e) {
      console.error(e);
      toastErrorMessage('카테고리를 로드하는 데 오류가 발생했습니다. 관리자에게 문의해주세요.');
    }
  }, []);

  const checkAndRefreshCollectors = useCallback(async () => {
    if (toJS(await valuesStore.getValues('collectors'))?.length) return;

    const newCollectors = (await apiWrapper(cookUsersApi.getCookUsers, {}))?.items;
    if (newCollectors?.length) {
      valuesStore.setValues('collectors', newCollectors);
    }
  }, []);

  const checkSdkClientIds = useCallback(async () => {
    if (toJS(await valuesStore.getValues('sdkClientIds'))?.length) return;

    const newCollectors = (await apiWrapper(sdkClientsApi.getSdkClientIds, {}))?.items;
    if (newCollectors?.length) {
      valuesStore.setValues('sdkClientIds', newCollectors);
      managerLocalStorage.set(managerLocalStorage.keys['max/companies?type=2'], newCollectors);
    }
  }, []);

  useEffect(() => {
    if (user) {
      checkAndRefreshCategories();
      if (user.user_claims.role !== 'customer') {
        checkAndRefreshCollectors();
        checkSdkClientIds();
      }
    }
  }, [checkAndRefreshCategories, checkAndRefreshCollectors, checkSdkClientIds, user]);

  useEffect(() => {
    // firebase init and auth
    const firebaseProjects = [cashplaceFirebaseProject, managerFirebaseProject];
    const initAndAuthProjectPromiser = (project) =>
      new Promise((res, rej) => {
        try {
          project.init().then(() => project.auth().then((instance) => res(instance)));
        } catch (e) {
          rej(e);
        }
      });

    const initAndAuthProjectPromises = firebaseProjects.map(initAndAuthProjectPromiser);

    Promise.all(initAndAuthProjectPromises);
  }, []);

  useEffect(() => {
    dispatch(actions.fetchCDevices());
  }, [dispatch]);

  return (
    <>
      <GlobalStyle />
      <Toast />
      <Router />
    </>
  );
}

export default App;
