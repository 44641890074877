import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import NaverMap from '@F/map/NaverMap';
import PinTier2 from '@I/map/pin-blue.png';
import RedPin from '@I/map/pin-red.png';
import * as S from './styles';

function Map(props) {
  const { detail, newLocation, setNewLocation } = props;
  const center = useMemo(() => ({ lat: detail.lat, lng: detail.lng }), [detail]);

  const [markers, setMarkers] = useState([
    {
      type: 'PID',
      lat: newLocation.lat,
      lng: newLocation.lng,
      image: PinTier2,
      size: [13 * 2.5, 17 * 2.5],
      id: detail.pid,
      name: detail.name
    }
  ]);
  useEffect(() => {
    setMarkers((prevState) =>
      prevState.concat(
        detail.fingerprint.map((fp) => ({
          type: 'FPID',
          lat: fp.lat,
          lng: fp.lng,
          image: RedPin,
          size: [13 * 2.5, 17 * 2.5],
          id: fp.fpid,
          name: 'fpid'
        }))
      )
    );
  }, [detail]);
  useEffect(() => {
    setMarkers((prevState) =>
      prevState.map((marker) =>
        marker.type === 'PID'
          ? {
              ...marker,
              lat: newLocation.lat,
              lng: newLocation.lng
            }
          : marker
      )
    );
  }, [newLocation]);

  const updatePin = useCallback(
    (coordinates) => {
      setNewLocation({
        lat: coordinates.y,
        lng: coordinates.x
      });
    },
    [setNewLocation]
  );

  const PolygonProps = useMemo(
    () => ({
      isLoading: false,
      hasData:
        (typeof detail.polygon !== 'object' && Boolean(detail.polygon)) ||
        (detail.polygon.length > 0 && !detail.polygon.some((geoJSON) => geoJSON === null)),
      data: { polygon: [detail.polygon] }
    }),
    [detail.polygon]
  );

  return (
    <S.MapWrapper>
      <NaverMap
        id={detail.pid}
        center={center}
        polygon={PolygonProps}
        markers={markers.reverse()}
        onEmitCoordinates={updatePin}
        zoom={20}
      />
    </S.MapWrapper>
  );
}
export default Map;

Map.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any),
  newLocation: PropTypes.objectOf(PropTypes.any),
  setNewLocation: PropTypes.func
};

Map.defaultProps = {
  detail: null,
  newLocation: null,
  setNewLocation: () => {}
};
