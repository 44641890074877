import type { AxiosPromise, AxiosResponse } from 'axios';
import type { GetCampaignsParameters, CampaignDetailResponse } from '@TS/max/campaigns/api';
import type { State } from '@P/max/campaigns/redux/campaigns/slice';
import { camelToSnake } from '@FUNC/word';
import { skipEmptyStringValueFromObj } from '@FUNC/filterObject';
import { axiosInstancesManager } from '@API/axios';
import { GeofenceApi } from 'loplat-x';

const CAMPAIGN_STATUS: Campaign.Scheme.Status.Parameter[] = ['complete', 'test', 'active', 'inactive'];
const stringifyStatus = (rule: (key: Campaign.Scheme.Status.Parameter) => boolean): string =>
  CAMPAIGN_STATUS.filter(rule).join(',');

const defineStatus = (status: Campaign.Scheme.Status.NamedStatus): string => {
  if (status === 'all') return CAMPAIGN_STATUS.join(',');
  if (status === 'proceeding') return stringifyStatus((item) => item === 'test' || item === 'active');
  return stringifyStatus((key) => status === key);
};

class CampaignApi {
  getCampaigns(parameter: GetCampaignsParameters): AxiosPromise<CampaignApi.GetCampaign.List.Response> {
    const notEmptyParams = skipEmptyStringValueFromObj<State['params']>(parameter);
    const stringifiedStatus = defineStatus(parameter.status);
    const params = {
      ...notEmptyParams,
      status: stringifiedStatus,
      orderby: notEmptyParams.orderby ? camelToSnake(notEmptyParams.orderby) : null
    };

    return axiosInstancesManager.max.get('/manager/campaign', { params });
  }

  getCampaign({ cid }: { cid: number }): AxiosPromise<CampaignDetailResponse> {
    return axiosInstancesManager.max.get(`/manager/campaign/${cid}`);
  }

  getTwoCampaign({ cidA, cidB }: Record<'cidA' | 'cidB', number>): Promise<AxiosResponse<CampaignDetailResponse>[]> {
    return Promise.all([cidA, cidB].map((cid) => axiosInstancesManager.max.get(`/manager/campaign/${cid}`)));
  }

  getGeofence({
    id
  }: {
    id: number;
  }): AxiosPromise<GeofenceApi.Get.Single.Response.GeofenceCircle | GeofenceApi.Get.Single.Response.GeofencePolygon> {
    return axiosInstancesManager.max.get(`/manager/geofence/${id}`);
  }
  getGeofenceGroup({ id }: { id: number }): AxiosPromise<GeofenceApi.Get.Group.Response.Detail> {
    return axiosInstancesManager.max.get(`/manager/geofence/group/${id}`);
  }
}

export default new CampaignApi();
