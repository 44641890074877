import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CreatePromotionParams } from '@TS/max/Promotion/api';
import { CircleLoading } from 'loplat-ui';
import PreviewCarousel from './components/PreviewCarousel';
import PromotionList from './components/PromotionList';
import UploadInputs from './components/UploadInputs';
import { usePromotionContext } from './usePromotionContext';

const PromotionComponent = (): JSX.Element => {
  const { fetchPromotions, fetchApiStatus, deleteApiStatus, createApiStatus, UpdateApiStatus, promotions } =
    usePromotionContext();

  const [promotion, setPromotion] = useState<CreatePromotionParams>({
    img: '',
    url: '',
    content: ''
  });
  const [imgFile, setImgFile] = useState<File | null>(null);

  const isURLvalid = (url: string) => {
    const regex = new RegExp(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/);
    return regex.test(url);
  };

  const isLoading =
    fetchApiStatus === 'loading' ||
    deleteApiStatus === 'loading' ||
    createApiStatus === 'loading' ||
    UpdateApiStatus === 'loading';

  useEffect(
    () => {
      fetchPromotions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (isLoading) {
    return (
      <Wrapper>
        <CircleLoading />
      </Wrapper>
    );
  }

  const filteredPromotions = promotions.filter((promotion) => promotion.status !== 'DELETED');

  return (
    <Wrapper>
      <PreviewCarousel imgFile={imgFile} promotion={promotion} />
      <UploadInputs imgFile={imgFile} setImgFile={setImgFile} setPromotion={setPromotion} promotion={promotion} />
      <div className="promotion-validation">
        {promotion.url && !isURLvalid(promotion.url) && (
          <div>
            <p>https://를 포함한 유효한 URL을 입력해주세요.</p>
            <p>ex) https://x.loplat.com, https://dmp.loplat.com</p>
          </div>
        )}
      </div>
      {filteredPromotions.length > 0 && <PromotionList promotions={filteredPromotions} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .promotion-validation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    color: red;
  }
`;

export default PromotionComponent;
