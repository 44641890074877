import { useNavigate } from 'react-router-dom';
import useAuth from '@HOOK/useAuth';
import * as S from './styles';

function Unauthorized() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <S.UnAuthorized>
      <h1>이 페이지에 접근할 권한이 없습니다.</h1>
      <lottie-player
        autoplay
        speed="2.0"
        mode="normal"
        src="https://assets1.lottiefiles.com/private_files/lf30_sbsvs4ki.json"
        style={{ height: '500px', width: '500px', margin: '0 auto' }}
      />
      <S.WideButton
        onClick={() => {
          navigate('/');
        }}
        color="primary"
        variant="contained"
      >
        홈 화면으로 이동
      </S.WideButton>
      <S.WideButton
        onClick={() => {
          logout();
          navigate('/login');
        }}
        color="secondary"
        variant="contained"
      >
        다른 계정으로 로그인 시도
      </S.WideButton>
    </S.UnAuthorized>
  );
}
export default Unauthorized;

Unauthorized.propTypes = {};
