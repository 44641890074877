import styled from '@emotion/styled';
import Pagination, { PaginationContextProvider } from '@F/Pagination';
import { TogglersContextProvider } from '@HOOK/useTogglers';
import AlertBox from '../primitiveLog/AlertBox';
import SdkLogsFilter from '../sdkLog/SdkLogsFilter';
import SdkLogsTable from '../sdkLog/SdkLogsTable';
import { SdkLogBlocProvider } from './useSdkLogBloc';

function XSdkLog(): JSX.Element {
  return (
    <>
      <AlertBox alertMessage="오늘 날짜를 기준으로 최대 7일 전까지 조회 가능합니다." />
      <Box>
        <SdkLogsFilter />
        <Pagination />
        <SdkLogsTable />
        <Pagination />
      </Box>
    </>
  );
}

export default function Wrapped(): JSX.Element {
  return (
    <TogglersContextProvider>
      <PaginationContextProvider initialOption={{ page: 1, perPage: 1000, totalCount: 0 }}>
        <SdkLogBlocProvider>
          <XSdkLog />
        </SdkLogBlocProvider>
      </PaginationContextProvider>
    </TogglersContextProvider>
  );
}

const Box = styled.div`
  padding: 20px;
  box-shadow: 0 0 0 1px #eee;
  background-color: #fff;
  border-radius: 4px;
`;
