import { useCallback, useEffect } from 'react';

// NOTE.
// @types/navermaps lib이 addDrawing method에서 에러를 발생시키기에 부득이하게 JSX로 분리

function PolygonToDrawingMode({ naverMap, drawingManager, originGeoJsons }) {
  const getBoundary = useCallback(
    (flated) => {
      const { minX, maxX, minY, maxY } = flated.reduce(
        (acc, cur) => {
          const { currentMinX, currentMaxX, currentMinY, currentMaxY } = cur.reduce(
            (cur_acc, cur_cur) => ({
              currentMinX: Math.min(cur_acc.currentMinX, cur_cur[0]),
              currentMaxX: Math.max(cur_acc.currentMaxX, cur_cur[0]),
              currentMinY: Math.min(cur_acc.currentMinY, cur_cur[1]),
              currentMaxY: Math.max(cur_acc.currentMaxY, cur_cur[1])
            }),
            {
              currentMinX: Infinity,
              currentMaxX: -Infinity,
              currentMinY: Infinity,
              currentMaxY: -Infinity
            }
          );
          return {
            minX: Math.min(acc.minX, currentMinX),
            maxX: Math.max(acc.maxX, currentMaxX),
            minY: Math.min(acc.minY, currentMinY),
            maxY: Math.max(acc.maxY, currentMaxY)
          };
        },
        {
          minX: Infinity,
          maxX: -Infinity,
          minY: Infinity,
          maxY: -Infinity
        }
      );
      const gapX = (maxX - minX) / 2;
      const gapY = (maxY - minY) / 2;
      const latLngCounds = new window.naver.maps.LatLngBounds([minX - gapX, minY - gapY], [maxX + gapX, maxY + gapY]);
      naverMap.fitBounds(latLngCounds, {});
      naverMap.setOptions({ maxZoom: 21 });
    },
    [naverMap]
  );

  useEffect(() => {
    if (!drawingManager || !naverMap) {
      return;
    }
    if (originGeoJsons) {
      originGeoJsons.forEach((polygon) => {
        const newPolygon = new window.naver.maps.Polygon({
          paths: polygon,
          fillColor: 'red',
          fillOpacity: 0.5,
          strokeColor: 'red'
        });
        drawingManager.addDrawing(newPolygon, window.naver.maps.drawing.DrawingMode.POLYGON);
      });
      const flated = originGeoJsons.flatMap((v) => v);
      getBoundary(flated);
    } else {
      naverMap.setOptions({ maxZoom: 21 });
    }
  }, [drawingManager, getBoundary, naverMap, originGeoJsons]);

  return null;
}

export default PolygonToDrawingMode;
