import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { red300 } from 'loplat-ui';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > span {
    padding: 0 0.5rem;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -1.5rem;
    padding: 0;
  }

  > div {
    width: 100%;
  }

  position: relative;

  p[role='alert'] {
    position: absolute;
    bottom: -1.6rem;
    font-size: 0.75rem;
    color: ${red300};
  }
`;

export const DateInput = styled(TextField)`
  .MuiOutlinedInput-inputMarginDense {
    padding: 5px;
  }

  input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
`;
