import { AxiosError } from 'axios';
import sdkConfigurationsApi from '@API/manager/sdk/configurations';
import {
  SdkConfigurationsGet,
  SdkConfigurationCreate,
  SdkConfigurationPatch,
  SdkConfiguration
} from '@API/manager/sdk/types';
import { tableSort } from '@FUNC/order';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { usePagination } from '@HOOK/usePagination';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { SDK_CONFIGS_INITIAL_ORDER_BY } from './constant';

type isSuccess = boolean;

type ManageSDKConfigsContextType = {
  orderBy: {
    name: keyof SdkConfiguration;
    type: OrderType;
  };
  sdkConfigs: SdkConfiguration[];
  pagedSdkConfigs: SdkConfiguration[];
  getApiStatus: ApiStatus;
  deleteApiStatus: ApiStatus;
  createSdkConfig: (params: SdkConfigurationCreate, signal?: AbortSignal) => Promise<SdkConfiguration | null>;
  fetchSdkConfigSummaries: (params: SdkConfigurationsGet) => Promise<isSuccess>;
  getSdkConfig: (id: SdkConfiguration['id']) => Promise<SdkConfiguration | null>;
  updateSdkConfig: (
    id: SdkConfiguration['id'],
    data: SdkConfigurationPatch,
    signal?: AbortSignal
  ) => Promise<SdkConfiguration | null>;
  deleteSdkConfig: (id: SdkConfiguration['id']) => Promise<isSuccess>;
  sortSdkConfigs: (name: keyof SdkConfiguration, type: OrderType) => void;
};

const ManageSDKConfigsContext = createContext<ManageSDKConfigsContextType | undefined>(undefined);

export function SdkConfigsContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [sdkConfigs, setSdkConfigs] = useState<SdkConfiguration[]>([]);
  const [pagedSdkConfigs, setPagedSdkConfigs] = useState<SdkConfiguration[]>([]);
  const [getApiStatus, setGetApiStatus] = useState<ApiStatus>('idle');
  const [deleteApiStatus, setDeleteApiStatus] = useState<ApiStatus>('idle');
  const [orderBy, setOrderBy] = useState(SDK_CONFIGS_INITIAL_ORDER_BY);

  const pagination = usePagination();

  // create
  const createSdkConfig: ManageSDKConfigsContextType['createSdkConfig'] = useCallback(
    async (sdkConfigCreate, signal) => {
      try {
        const { data: created } = await sdkConfigurationsApi.post(sdkConfigCreate, signal);
        setSdkConfigs((prev) => tableSort<SdkConfiguration>([...prev, created], orderBy.name, orderBy.type));

        return created;
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.name === 'CanceledError') return null;
          if (e.response) {
            toastErrorMessage(e.response.data.message);
            return null;
          }
        }

        const error = e as Error;
        toastErrorMessage(error.message);
        return null;
      }
    },
    [orderBy]
  );

  // sdkConfigSummaries
  const fetchSdkConfigSummaries: ManageSDKConfigsContextType['fetchSdkConfigSummaries'] = useCallback(
    async (params) => {
      try {
        setGetApiStatus('loading');
        const {
          data: { count, items }
        } = await sdkConfigurationsApi.getAll(params);
        setGetApiStatus('success');

        setSdkConfigs(tableSort<SdkConfiguration>(items, orderBy.name, orderBy.type));

        pagination.setTotalCount(count);
        pagination.setPage(1);
        return true;
      } catch (e) {
        setGetApiStatus('failure');

        if (e instanceof AxiosError && e.response) {
          toastErrorMessage(e.response.data.message);
          return false;
        }

        const error = e as Error;
        toastErrorMessage(error.message);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getSdkConfig: ManageSDKConfigsContextType['getSdkConfig'] = useCallback(
    async (id) => {
      try {
        const cachedSdkConfig = sdkConfigs.find((sdkConfig) => sdkConfig.id === id);
        if (cachedSdkConfig) return cachedSdkConfig;

        setGetApiStatus('loading');
        const { data } = await sdkConfigurationsApi.getById(id);
        setGetApiStatus('success');

        return data;
      } catch (e) {
        setGetApiStatus('failure');

        if (e instanceof AxiosError && e.response) {
          toastErrorMessage(e.response.data.message);
          return null;
        }

        const error = e as Error;
        toastErrorMessage(error.message);
        return null;
      }
    },
    [sdkConfigs]
  );

  // edit sdkConfig
  const updateSdkConfig: ManageSDKConfigsContextType['updateSdkConfig'] = useCallback(async (id, data, signal) => {
    try {
      const { data: updated } = await sdkConfigurationsApi.patch(id, data, signal);
      setSdkConfigs((prev) =>
        tableSort<SdkConfiguration>(
          prev.map((sdkConfig) => (sdkConfig.id === id ? updated : sdkConfig)),
          orderBy.name,
          orderBy.type
        )
      );
      toastSuccessMessage('고객사 정보를 성공적으로 등록하였습니다.');

      return updated;
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.name === 'CanceledError') {
          return null;
        }

        if (e.response) {
          toastErrorMessage(e.response.data.message);
          return null;
        }
      }

      const error = e as Error;

      toastErrorMessage(error.message);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // detail
  const deleteSdkConfig: ManageSDKConfigsContextType['deleteSdkConfig'] = useCallback(async (id) => {
    try {
      setDeleteApiStatus('loading');
      await sdkConfigurationsApi.delete(id);
      setDeleteApiStatus('success');
      toastSuccessMessage('고객사 정보를 성공적으로 삭제하였습니다.');

      setSdkConfigs((prev) => prev.filter((sdkConfig) => sdkConfig.id !== id));

      return true;
    } catch (e) {
      setDeleteApiStatus('failure');

      if (e instanceof AxiosError && e.response) {
        toastErrorMessage(e.response.data.message);
        return false;
      }

      const error = e as Error;
      toastErrorMessage(error.message);
      return false;
    }
  }, []);

  const sortSdkConfigs: ManageSDKConfigsContextType['sortSdkConfigs'] = useCallback((name, orderType) => {
    setOrderBy({
      name,
      type: orderType
    });
    pagination.setPage(1);

    setSdkConfigs((prev) => tableSort<SdkConfiguration>([...prev], name, orderType));
  }, []);

  useEffect(() => {
    const pagedNotices = pagination.pageSlice(sdkConfigs);
    setPagedSdkConfigs(pagedNotices);
    pagination.setTotalCount(sdkConfigs.length);
  }, [pagination, sdkConfigs]);

  return (
    <ManageSDKConfigsContext.Provider
      value={{
        orderBy,
        sdkConfigs,
        pagedSdkConfigs,
        getApiStatus,
        deleteApiStatus,
        createSdkConfig,
        fetchSdkConfigSummaries,
        getSdkConfig,
        updateSdkConfig,
        deleteSdkConfig,
        sortSdkConfigs
      }}
    >
      {children}
    </ManageSDKConfigsContext.Provider>
  );
}

export function useManageSdkConfigs(): ManageSDKConfigsContextType {
  const context = useContext(ManageSDKConfigsContext);

  if (!context) {
    throw new Error('useManageSdkConfig must be used in SdkConfigsContextProvider');
  }

  return context;
}
