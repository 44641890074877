import { useEffect, useCallback } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Tooltip } from '@material-ui/core';
import { NearMeIcon } from 'loplat-ui';

type Props = {
  naverMap: naver.maps.Map;
  showInNear: boolean;
  setShowInNear: Dispatch<SetStateAction<boolean>>;
  resetState: (except: 'draw' | 'info' | 'near') => void;
  getInNearPolygonLogic: () => void;
  isLoading: boolean;
  disabled: boolean;
  exceptionSnapShotEvent: (e: KeyboardEvent, keyList: string[]) => boolean;
};

const NearPolygonButton = ({
  naverMap,
  showInNear,
  setShowInNear,
  resetState,
  getInNearPolygonLogic,
  isLoading,
  disabled,
  exceptionSnapShotEvent
}: Props): JSX.Element => {
  const toggleShow = useCallback(async () => {
    if (isLoading || disabled) {
      return;
    }
    resetState('near');
    if (showInNear) {
      naverMap.data.setStyle({ visible: false });
      setShowInNear(false);
    } else {
      await getInNearPolygonLogic();
      naverMap.data.setStyle({ visible: true });
    }
  }, [disabled, getInNearPolygonLogic, isLoading, naverMap.data, resetState, setShowInNear, showInNear]);

  const snapShotEvent = useCallback(
    (e) => {
      if (!exceptionSnapShotEvent(e, ['d', 'ㅇ'])) {
        return;
      }
      toggleShow();
    },
    [exceptionSnapShotEvent, toggleShow]
  );

  useEffect(() => {
    getInNearPolygonLogic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', snapShotEvent);
    return () => {
      window.removeEventListener('keydown', snapShotEvent);
    };
  }, [snapShotEvent]);

  return (
    <>
      <Tooltip title={showInNear ? '불러온 폴리곤 숨기기 (단축키 : d)' : '근처 폴리곤 불러오기 (단축키 : d)'}>
        <span role="presentation" className={showInNear ? 'inNear active' : 'inNear'} onClick={() => toggleShow()}>
          <NearMeIcon fillColor={showInNear ? '#fff' : '#9DAAB7'} />
        </span>
      </Tooltip>
    </>
  );
};

export default NearPolygonButton;
