import { TableHead, TableRow, TableCell, TableBody, Link } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { cashplaceStudiesApi } from '@API/manager/cashplace';
import { toastErrorMessage } from '@FUNC/toast';
import { styled } from 'loplat-ui';
import Loading from '@F/Loading';

type ReportType = {
  date: string;
  users: number;
  android: {
    studies: number;
    approved_studies: number;
    users: number;
    approved_users: number;
  };
  ios: {
    studies: number;
    approved_studies: number;
    users: number;
    approved_users: number;
  };
};

const AppUsage = (): JSX.Element => {
  const [studiesReport, setStudiesReport] = useState([]);
  const [apiStatus, setApiStatus] = useState<ApiStatus>('idle');
  const sortedStudiesReport = studiesReport.sort((a: ReportType, b: ReportType) =>
    a.date > b.date ? -1 : a.date < b.date ? 1 : 0
  );
  const firebaseUrl =
    'https://console.firebase.google.com/project/cashplace-2aa7c/analytics/app/android:com.loplat.cashplace/overview/reports~2Fdashboard%3Fparams%3D_u..comparisons%253D%255B%257B%2522savedComparisonId%2522%253A%2522d8%2522%252C%2522name%2522%253A%2522%25EB%25AA%25A8%25EB%2593%25A0%2520%25EC%2582%25AC%25EC%259A%25A9%25EC%259E%2590%2522%252C%2522isEnabled%2522%253Atrue%252C%2522filters%2522%253A%255B%257B%2522isCaseSensitive%2522%253Atrue%252C%2522expression%2522%253A%25220%2522%252C%2522fieldName%2522%253A%2522audience%2522%257D%255D%257D%252C%257B%2522name%2522%253A%2522%25ED%2594%258C%25EB%259E%25AB%25ED%258F%25BC%2520%25EB%258B%25A4%25EC%259D%258C%25EA%25B3%25BC%2520%25EC%25A0%2595%25ED%2599%2595%25ED%2595%2598%25EA%25B2%258C%2520%25EC%259D%25BC%25EC%25B9%2598%2520Android%2522%252C%2522isEnabled%2522%253Atrue%252C%2522filters%2522%253A%255B%257B%2522fieldName%2522%253A%2522platform%2522%252C%2522expressionList%2522%253A%255B%2522Android%2522%255D%252C%2522isCaseSensitive%2522%253Afalse%257D%255D%257D%252C%257B%2522name%2522%253A%2522%25ED%2594%258C%25EB%259E%25AB%25ED%258F%25BC%2520%25EB%258B%25A4%25EC%259D%258C%25EA%25B3%25BC%2520%25EC%25A0%2595%25ED%2599%2595%25ED%2595%2598%25EA%25B2%258C%2520%25EC%259D%25BC%25EC%25B9%2598%2520iOS%2522%252C%2522isEnabled%2522%253Atrue%252C%2522filters%2522%253A%255B%257B%2522fieldName%2522%253A%2522platform%2522%252C%2522expressionList%2522%253A%255B%2522iOS%2522%255D%252C%2522isCaseSensitive%2522%253Afalse%257D%255D%257D%255D%2526_u..built_comparisons_enabled%253Dtrue%2526_u.dateOption%253Dlast12Months%2526_u.comparisonOption%253Ddisabled&r%3Dfirebase-overview&fpn%3D902911106519';

  useEffect(() => {
    getStudiesReport();
  }, []);

  const tableTopHeader = ['날짜', '가입자 수', '안드로이드', 'iOS'];
  const secondTableTopHeader = [
    '수집',
    '수집(승인)',
    '수집인원',
    '수집인원(승인)',
    '수집',
    '수집(승인)',
    '수집인원',
    '수집인원(승인)'
  ];

  const getStudiesReport = () => {
    setApiStatus('loading');
    cashplaceStudiesApi
      .getStudiesReport()
      .then((res) => {
        setStudiesReport(res.data.items);
        setApiStatus('idle');
      })
      .catch(() => {
        toastErrorMessage(
          '회사 정보를 불러오는 요청에 실패했습니다. 요청을 다시 시도해주세요. \n문제가 지속될 경우 관리자(윤준호)에게 문의 부탁드립니다.'
        );
        setApiStatus('idle');
      });
  };

  if (apiStatus === 'loading') {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <LinkContainer href={firebaseUrl} target="_blank" rel="noreferrer">
        MAU, DAU 확인
      </LinkContainer>
      <CustomTable>
        <TableHead>
          <TableRow>
            {tableTopHeader.map((header, index) => (
              <TableCell
                key={index}
                align="center"
                colSpan={index === 0 || index === 1 ? 1 : 4}
                rowSpan={index === 0 || index === 1 ? 2 : 1}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {secondTableTopHeader.map((header, index) => (
              <TableCell align="center" key={index}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedStudiesReport.map((item: ReportType, index) => (
            <TableRow key={index}>
              <TableCell align="center">{item.date}</TableCell>
              <TableCell align="center">{item.users}</TableCell>
              <TableCell align="center">{item.android.studies}</TableCell>
              <TableCell align="center">{item.android.approved_studies}</TableCell>
              <TableCell align="center">{item.android.users}</TableCell>
              <TableCell align="center">{item.android.approved_users}</TableCell>
              <TableCell align="center">{item.ios.studies}</TableCell>
              <TableCell align="center">{item.ios.approved_studies}</TableCell>
              <TableCell align="center">{item.ios.users}</TableCell>
              <TableCell align="center">{item.ios.approved_users}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </CustomTable>
    </>
  );
};

const LinkContainer = styled(Link)`
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  font-size: 24px;
`;

const CustomTable = styled.table`
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  thead {
    width: 100%;
    background-color: #f5f5f5;

    th {
      font-size: 16px;
      font-weight: 700;
      color: #333333;
      padding: 10px 0;
      border: 1px solid #e0e0e0;
    }
  }

  tbody {
    width: 100%;
    background-color: #ffffff;

    td {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }
  }
`;

export default AppUsage;
