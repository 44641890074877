import { Promotion } from '@TS/max/Promotion/api';
import { Button } from '@material-ui/core';
import { Switch } from 'loplat-ui';

type TableRowProps = {
  promotion: Promotion;
  setUpdatingPromotionItem: React.Dispatch<React.SetStateAction<Promotion | null>>;
  togglePublishPromotion: (promotion: Promotion) => Promise<boolean>;
  deletePromotion: (promotion: Promotion) => void;
};

const TableRow = ({
  promotion,
  setUpdatingPromotionItem,
  togglePublishPromotion,
  deletePromotion
}: TableRowProps): JSX.Element => (
  <>
    <td className="Img">
      <img src={promotion.img} alt="promotion" width={68} height={68} />
    </td>
    <td className="td-content">{promotion.content}</td>
    <td className="td-url">{promotion.url}</td>
    <td>
      <Button variant="contained" color="secondary" type="button" onClick={() => deletePromotion(promotion)}>
        삭제
      </Button>
    </td>
    <td>
      <Button variant="contained" color="primary" type="button" onClick={() => setUpdatingPromotionItem(promotion)}>
        수정
      </Button>
    </td>
    <td>
      <Switch
        onChange={() => togglePublishPromotion(promotion)}
        name="promotion-status"
        checked={promotion.status === 'PUBLISH'}
      />
    </td>
  </>
);

export default TableRow;
