import { AxiosPromise } from 'axios';
import { axiosInstancesManager } from '@API/axios';
import {
  SdkConfiguration,
  SdkConfigurationCreate,
  SdkConfigurationPatch,
  SdkConfigurationsGet,
  SdkConfigurationsGetResponse
} from './types';

class SDKConfigurationsApi {
  url: string;

  constructor() {
    this.url = '/sdk/configurations';
  }

  getAll(params: SdkConfigurationsGet): AxiosPromise<SdkConfigurationsGetResponse> {
    return axiosInstancesManager.manager.get(this.url, { params });
  }
  getById(id: SdkConfiguration['id']): AxiosPromise<SdkConfiguration> {
    return axiosInstancesManager.manager.get(`${this.url}/${id}`);
  }
  post(data: SdkConfigurationCreate, signal?: AbortSignal): AxiosPromise<SdkConfiguration> {
    return axiosInstancesManager.manager.post(this.url, data, { signal });
  }
  patch(id: SdkConfiguration['id'], data: SdkConfigurationPatch, signal?: AbortSignal): AxiosPromise<SdkConfiguration> {
    return axiosInstancesManager.manager.patch(`${this.url}/${id}`, data, { signal });
  }
  delete(id: SdkConfiguration['id']): AxiosPromise<void> {
    return axiosInstancesManager.manager.delete(`${this.url}/${id}`);
  }
}

const sdkConfigurationsApi = new SDKConfigurationsApi();

export default sdkConfigurationsApi;
