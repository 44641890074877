/* eslint-disable vars-on-top */
import React, { useCallback, useEffect, useState, createContext, useContext } from 'react';
import { AxiosError } from 'axios';
import { useTogglers } from '@HOOK/useTogglers';
import { usePagination } from '@HOOK/usePagination';
import { tableSort } from '@FUNC/order';
import { toastErrorMessage } from '@FUNC/toast';
import { PrimitiveLogParams } from '@TS/max/primitiveLog/api';
import { PrimitiveLog } from '@TS/max/primitiveLog/primitiveLog';
import { logApi } from '@API/manager';
import { downloadCsv, fileNameFromContentDisposition } from '@FUNC/download';

import { PRIMITIVE_LOG_TABLE_INITIAL_ORDER_BY } from './data';

type SortPrimitiveLogs = (name: keyof PrimitiveLog, orderType: OrderType) => void;
type FetchPrimitiveLogs = (params: PrimitiveLogParams) => void;
type DownloadPrimitiveLogs = (params: PrimitiveLogParams) => void;

type PrimitiveLogBlocContextType = {
  primitiveLogs: PrimitiveLog[];
  pagedPrimitiveLogs: PrimitiveLog[];
  fetchPrimitiveLogs: FetchPrimitiveLogs;
  downloadPrimitiveLogs: DownloadPrimitiveLogs;
  sortPrimitiveLogs: SortPrimitiveLogs;
  fetchStatus: ApiStatus;
  downloadStatus: ApiStatus;
};

const PrimitiveLogBlocContext = createContext<PrimitiveLogBlocContextType | undefined>(undefined);

export function PrimitiveLogBlocProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [fetchStatus, setFetchStatus] = useState<ApiStatus>('idle');
  const [downloadStatus, setDownloadStatus] = useState<ApiStatus>('idle');
  const [primitiveLogs, setPrimitiveLogs] = useState<PrimitiveLog[]>([]);
  const [pagedPrimitiveLogs, setPagedPrimitiveLogs] = useState<PrimitiveLog[]>([]);
  const collapseTogglers = useTogglers();
  const pagination = usePagination();

  const fetchPrimitiveLogs: FetchPrimitiveLogs = useCallback(
    async (params) => {
      try {
        setFetchStatus('loading');

        const { data } = await logApi.getLogQuery(params);
        const { name, orderType } = PRIMITIVE_LOG_TABLE_INITIAL_ORDER_BY;
        setPrimitiveLogs(tableSort<PrimitiveLog>(data, name, orderType));
        pagination.setTotalCount(data.length);
        pagination.setPage(1);
        setFetchStatus('success');
      } catch (e) {
        setFetchStatus('idle');
        toastErrorMessage(
          e instanceof AxiosError
            ? '요청하신 조건에 해당되는 인식 로그 데이터가 없습니다. 다른 조건으로 검색을 시도해주세요.'
            : '인식 로그 정보 조회에 실패했습니다. 관리자(윤준호)에게 문의 바랍니다.'
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.setTotalCount]
  );

  const downloadPrimitiveLogs: DownloadPrimitiveLogs = useCallback(async (params) => {
    try {
      setDownloadStatus('loading');
      const { data, headers } = await logApi.getLogQueryCsv(params);
      const filename = fileNameFromContentDisposition(headers['content-disposition']);
      downloadCsv(data, filename);
      setDownloadStatus('success');
    } catch (e) {
      setDownloadStatus('idle');
      toastErrorMessage(
        e instanceof AxiosError
          ? '요청하신 조건에 해당되는 인식 로그 데이터가 없습니다. 다른 조건으로 검색을 시도해주세요.'
          : '인식 로그 정보 조회에 실패했습니다. 관리자(윤준호)에게 문의 바랍니다.'
      );
    }
  }, []);

  const sortPrimitiveLogs: SortPrimitiveLogs = useCallback(
    (name, orderType) => setPrimitiveLogs((prev) => tableSort<PrimitiveLog>([...prev], name, orderType)),
    []
  );

  useEffect(() => {
    const pagedPrimitiveLogs = pagination.pageSlice(primitiveLogs);
    setPagedPrimitiveLogs(pagedPrimitiveLogs);
    collapseTogglers.resetStateWithData(pagedPrimitiveLogs);
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primitiveLogs, pagination, collapseTogglers.resetStateWithData]);

  return (
    <PrimitiveLogBlocContext.Provider
      value={{
        sortPrimitiveLogs,
        fetchPrimitiveLogs,
        downloadPrimitiveLogs,
        pagedPrimitiveLogs,
        primitiveLogs,
        fetchStatus,
        downloadStatus
      }}
    >
      {children}
    </PrimitiveLogBlocContext.Provider>
  );
}

export function usePrimitiveLogBloc(): PrimitiveLogBlocContextType {
  const context = useContext(PrimitiveLogBlocContext);

  if (!context) {
    throw new Error('usePrimitiveLogBloc must be used in PrimitiveLogBlocProvider');
  }

  return context;
}
