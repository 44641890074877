import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import DialogModal from '@F/DialogModal';
import type { Contact, Image } from '@TS/cashplace/contact';
import type { Study } from '@TS/cashplace/study';
import { Report } from '@TS/cashplace/report';
import { cashplaceReportsApi, cashplaceStudiesApi } from '@API/manager/cashplace';
import useAuth from '@HOOK/useAuth';
import { ReportImage } from '@P/cashplace/Report/tableSection/Detail';
import { kindOptions } from '@P/cashplace/Report/options';
import ReportImageSection from '../../../Report/tableSection/ImageSection';
import { actions } from '@/redux/cashplace/contact/state';
import { actions as reportActions } from '@/redux/cashplace/report/state';
import * as Inspect from '@/redux/cashplace/inspect/state';
import { FIREBASE_CASHPLACE_URL, MODE } from '@/config';

import * as S from './styles';

type DetailProps = {
  data: Contact[];
  id?: number;
};

function Detail({ data, id }: DetailProps): JSX.Element {
  const contact = useMemo(() => data.find((element) => element.contact_id === id), [id, data]);

  const [study, setStudy] = useState<Study | null>(null);
  const [report, setReport] = useState<Report | null>(null);
  const [reportImages, setReportImages] = useState<(ReportImage | null)[]>([]);
  const [images, setImages] = useState<string[] | null>(null);
  const [imageInfos, setImageInfos] = useState<Image[] | null>(null);

  const [feedback, setFeedback] = useState<string>(contact?.reply || '');
  const FeedbackInput = (
    <textarea id="FeedbackInput" value={feedback} onChange={(e): void => setFeedback(e.target.value)} />
  );
  const dispatch = useDispatch();
  const reply = () => {
    if (feedback) {
      dispatch(actions.updateContact(contact, { reply: feedback }));
    }
  };

  useEffect(() => {
    if (!id) return;
    if (!contact) return;
    if (contact?.study_id) {
      (async () => {
        const response = await cashplaceStudiesApi.getStudies({
          page: 1,
          perPage: 1,
          studyId: String(contact.study_id)
        });
        setStudy(response.data.result[0]);
      })();
    }
    if (contact?.report_id) {
      cashplaceReportsApi.getReport(contact?.report_id).then((response) => {
        const {
          data: {
            result: [report]
          }
        } = response;

        setReport(report);
      });
    }
  }, [id, contact]);

  useEffect(() => {
    if (!study) return;

    const names = study.image_names;
    setImageInfos(study.image_results);

    // NOTE: load images from firebase.
    const imageLength = names.length;
    const newImages = Array(imageLength).fill(null);
    let count = 0;
    names.forEach((image: string, index: number) => {
      const url = firebase
        .storage()
        .refFromURL(`${FIREBASE_CASHPLACE_URL}/${MODE === 'staging' ? 'images-staging' : 'images'}/${image}`);
      url
        .getDownloadURL()
        .then((responseUrl) => {
          newImages.splice(index, 1, responseUrl);
          if (count === imageLength - 1) {
            setImages(newImages);
          }
        })
        .finally(() => {
          count += 1;
        });
    });
  }, [study]);

  useEffect(() => {
    if (!report) return;
    if (report.kind === kindOptions['위치 수정']) return;

    report.images_names.forEach((filepath, i) => {
      // reportId, siteId, userId, floor, index
      const [, , , floor] = filepath.split('_');

      const docRef = firebase
        .storage()
        .refFromURL(`gs://cashplace-2aa7c.appspot.com/reports${MODE === 'staging' ? '-staging' : ''}/${filepath}.jpg`);

      docRef
        .getDownloadURL()
        .then((downloadUrl) =>
          docRef.getMetadata().then((metaData) => {
            setReportImages((prev) => {
              const next = [...prev];
              next[i] = {
                url: downloadUrl,
                createdAt: dayjs(metaData.timeCreated).format('YYYY-MM-DD HH:mm:ss'),
                floor: Number(floor)
              };

              return next;
            });
          })
        )
        .catch(() => {
          setReportImages((prev) => {
            const next = [...prev];
            next[i] = null;
            return next;
          });
        });
    });
  }, [report]);

  function changeInout(seq: number, value: string) {
    if (value !== 'in' && value !== 'out' && value !== 'unknown') return;
    setImageInfos(
      (prevState) =>
        prevState &&
        prevState.map((item: Image, index: number) => {
          if (index === seq) {
            return {
              ...item,
              inout: value
            };
          }
          return item;
        })
    );
  }

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRewardedModalOpen, setIsRewardedModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const { user } = useAuth();

  const approve = (type: 'stackInDB' | 'justReward') => {
    if (!user) return;

    if (study) {
      dispatch(
        Inspect.actions.updateInspect({
          studyId: study.study_log.study_id,
          siteId: study.study_log.site_id,
          userId: study.study_log.user_id,
          images: imageInfos,
          result: type === 'stackInDB' ? 1 : 3,
          inspector: user?.identity
        })
      );
    }

    if (report) {
      let latLngParams = {};
      if (report.kind === kindOptions['위치 수정']) {
        latLngParams = { lat: report.lat, lng: report.lng };
      }
      dispatch(reportActions.updateReport(report, { result: 1, user_id: report.user_id, ...latLngParams }));
    }

    setIsApproveModalOpen(false);
    dispatch(actions.fetchContacts());
  };

  const refuseInquiry = () => {
    if (!user) return;

    if (study) {
      dispatch(
        Inspect.actions.updateInspect({
          studyId: study.study_log.study_id,
          siteId: study.study_log.site_id,
          userId: study.study_log.user_id,
          images: imageInfos,
          result: -1,
          inspector: typeof user.identity === 'string' && user.identity
        })
      );
    }

    if (report) {
      dispatch(reportActions.updateReport(report, { result: -1, user_id: report.user_id }));
    }

    setIsRejectModalOpen(false);
    dispatch(actions.fetchContacts());
  };

  return (
    <S.StyledDetail>
      {FeedbackInput}
      <DialogModal
        title="정말 승인하시겠습니까?"
        description="한번 선택하면 돌이킬 수 없습니다."
        open={isApproveModalOpen}
        setOpen={setIsApproveModalOpen}
        confirmButtonDidClicked={() => approve('stackInDB')}
      />
      <DialogModal
        title="정말 보상만 하고 DB에 저장하지 않으시겠습니까?"
        description="한번 선택하면 돌이킬 수 없습니다."
        open={isRewardedModalOpen}
        setOpen={setIsRewardedModalOpen}
        confirmButtonDidClicked={() => approve('justReward')}
      />
      <DialogModal
        title="정말 거부하시겠습니까?"
        description="한번 선택하면 돌이킬 수 없습니다."
        open={isRejectModalOpen}
        setOpen={setIsRejectModalOpen}
        confirmButtonDidClicked={() => refuseInquiry()}
      />
      <S.Row>
        {report ? (
          <S.Row>
            <S.Cell>
              <p>장소명</p>
              <p>{report?.site.name}</p>
            </S.Cell>
            <S.Cell>
              <p>주소</p>
              <p>{report?.site.addr}</p>
            </S.Cell>
            <S.Cell>
              <p>리워드</p>
              <p>{report.credit ?? 0}원</p>
            </S.Cell>
          </S.Row>
        ) : null}
        {study ? (
          <S.Row>
            <S.Cell>
              <p>장소명</p>
              <p>{study?.site.name}</p>
            </S.Cell>
            <S.Cell>
              <p>주소</p>
              <p>{study?.site.addr}</p>
            </S.Cell>
            <S.Cell>
              <p>리워드</p>
              <p>{study?.slot.credit}원</p>
            </S.Cell>
          </S.Row>
        ) : null}
        <S.Row>
          {/* {study ? <Button variant="contained" color="default">이미지 열기</Button> : null} */}
          <Button variant="contained" color="primary" className="FeedbackButton" onClick={reply}>
            답변 달기
          </Button>
        </S.Row>
      </S.Row>
      {report && report.kind === 0 ? (
        <ReportImageSection isLoading={reportImages.length !== report.images_names.length} images={reportImages} />
      ) : null}
      {study && images === null && <S.RedText>로딩 중입니다. 시간이 지연될 시 해당 Row를 다시 펼쳐주세요.</S.RedText>}
      {study && images !== null && images.length !== 0 && (
        <S.ImageSection>
          {images.filter(Boolean).map((image, index) => (
            <S.ImageWrapper key={image + index}>
              {imageInfos && imageInfos[index] && (
                <p>{imageInfos[index].floor ? `${imageInfos[index].floor} 층` : ''}</p>
              )}
              <img key={image} src={image} alt="로딩중" />
            </S.ImageWrapper>
          ))}
        </S.ImageSection>
      )}
      <div style={{ width: '100%', maxWidth: '500px' }}>
        <S.Row>
          {study || report ? (
            <>
              <Grid item xs>
                <Button variant="contained" color="primary" onClick={() => setIsApproveModalOpen(true)}>
                  승인
                </Button>
              </Grid>
              <Grid item xs>
                <Button variant="contained" color="secondary" onClick={() => setIsRejectModalOpen(true)}>
                  거부
                </Button>
              </Grid>
              <Grid item xs>
                <Tooltip
                  title={
                    <>
                      <p
                        style={{
                          fontSize: '16px',
                          wordBreak: 'keep-all'
                        }}
                      >
                        Reward를 지급하고 DB에 저장하지 않습니다. 그리고 핀을 제거합니다.
                      </p>
                    </>
                  }
                >
                  <Button variant="contained" color="inherit" onClick={() => setIsRewardedModalOpen(true)}>
                    보상 후 핀 제거
                  </Button>
                </Tooltip>
              </Grid>
            </>
          ) : null}
        </S.Row>
      </div>
      {study && images !== null && images.length === 0 && <S.RedText>이미지가 존재하지 않습니다.</S.RedText>}
    </S.StyledDetail>
  );
}
export default Detail;
