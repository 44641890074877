export const resultOptions = {
  전체: null,
  미검수: 0,
  올바름: 1,
  틀림: -1,
  취소: 3
} as const;

export const kindOptions = {
  전체: null,
  폐점: 0,
  '위치 수정': 1
} as const;
