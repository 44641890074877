import { useState } from 'react';
import { Button } from 'loplat-ui';
import { Box } from '@material-ui/core';
import SelectForm from '@F/materialUI/SelectForm';
import CompanyInput from '@P/max/campaigns/search/Filter/CompanyInput';

import type { Dispatch, SetStateAction } from 'react';

function SearchCompany({
  size,
  setSize,
  setCompany
}: {
  size: number;
  setSize: Dispatch<SetStateAction<number>>;
  setCompany: Dispatch<SetStateAction<string>>;
}) {
  const [select, setSelect] = useState('');
  const [selectSize, setSelectSize] = useState(size);

  return (
    <Box display="flex" sx={{ mb: 2, justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
      <Box display="flex" sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
        <CompanyInput company={select} setCompany={setSelect} />
        <div style={{ width: '200px', marginLeft: '1rem' }}>
          <SelectForm
            label="페이지 당 세그먼트 개수"
            options={['10', '20', '30', '50', '100']}
            value={String(selectSize)}
            onSelect={(e) => setSelectSize(Number(e.target.value))}
            name="size"
          />
        </div>
      </Box>
      <Button
        onClick={() => {
          setCompany(select);
          setSize(selectSize);
        }}
      >
        검색
      </Button>
    </Box>
  );
}

export default SearchCompany;
