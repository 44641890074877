import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'loplat-ui';
import PolygonTable from './PolygonTable';
import PolygonTableFilter from './PolygonTableFilter';
import usePolygonTableFilter from './PolygonTableFilter/usePolygonTableFilter';

const PolygonListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { getParams } = usePolygonTableFilter();
  const [page, setPage] = useState(getParams().page);

  return (
    <div>
      <PolygonTableFilter setPage={setPage} />
      <Button variant="primary1" size="xs" mb={4} onClick={() => navigate('/equipment/polygon/add')}>
        생성
      </Button>
      <PolygonTable page={page} setPage={setPage} />
    </div>
  );
};

export default PolygonListPage;
