import { useState } from 'react';

import { Button, styled } from 'loplat-ui';
import { Version, VersionList } from '.';

type VersionsViewProps = {
  versionList: VersionList;
  deleteVersion: (version: Version) => void;
};

const VersionsView = ({ versionList, deleteVersion }: VersionsViewProps): JSX.Element => {
  const ascVersionList = [...versionList].reverse();
  const [visibleVersionCount, setVisibleVersionCount] = useState(10);

  return (
    <>
      <CustomTable>
        <thead>
          <tr>
            <th>Version</th>
            <th>Type</th>
            <th>Comment</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {ascVersionList.map((version) => (
            <tr key={`${version.version}-${version.comment}`}>
              <td>{version.version}</td>
              <td>{version.type}</td>
              <td>{version.comment}</td>
              <td>
                <Button color="secondary" onClick={() => deleteVersion(version)}>
                  삭제
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTable>
      <Button
        fullWidth
        color="primary"
        onClick={() => setVisibleVersionCount(visibleVersionCount + 10)}
        disabled={visibleVersionCount >= versionList.length}
      >
        더보기
      </Button>
    </>
  );
};

// 보기 좋게 테이블 스타일링
const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #f5f5f5;

    th {
      padding: 0.75rem;
      font-weight: bold;
      border-bottom: 2px solid #ddd;
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: #f9f9f9;
      }

      td {
        padding: 0.75rem;
        border-bottom: 1px solid #ddd;
      }
    }
  }

  th,
  td {
    text-align: center;
  }
`;

export default VersionsView;
