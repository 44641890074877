import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

export type State = {
  status: ApiStatus;
  error: null | Error;
  operationCompany: string;
};

const initialState: State = {
  status: 'idle',
  error: null,
  operationCompany: ''
};

const slice = createSlice({
  name: 'operationCompany',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.status = 'loading';
    },
    fetchFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.status = 'failure';
      state.error = action.payload.error;
    },
    fetchSuccess: (state, action: PayloadAction<{ operationCompany: string }>) => {
      state.status = 'success';
      state.operationCompany = action.payload.operationCompany;
    },
    fetchOperationCompany: () => {},
    setOperationCompany: (state, action: PayloadAction<string>) => {}
  }
});

export const { actions } = slice;
export default slice.reducer;

export const operationCompanyPersistConfig = {
  key: 'operationCompany',
  storage,
  whitelist: ['operationCompany']
};
