const TableHead = (): JSX.Element => (
  <thead>
    <tr>
      <th>이미지</th>
      <th>내용</th>
      <th>링크</th>
      <th>삭제</th>
      <th>수정</th>
      <th>공개</th>
    </tr>
  </thead>
);

export default TableHead;
