import { ConfigurationForm, SdkTargetApp, XUser } from '@API/manager/sdk/types';

export const validateConfigurationFormValues = (formValues: ConfigurationForm): string | null => {
  const { client_id, client_secret, name } = formValues;

  if (name.trim() === '') {
    return '회사명을 입력해주세요.';
  }

  if (client_secret.trim().length !== 16) {
    return 'client_secret은 공백 제외 16자리로 입력 가능합니다.';
  }

  const trimClientId = client_id.trim();

  if (trimClientId === '') {
    return 'client_id를 입력해주세요.';
  }

  const clientIdReg = /^[a-z0-9]{3,45}$/;
  if (!clientIdReg.test(trimClientId)) {
    return 'client_id는 최소3자 이상의 영소문자, 숫자의 조합으로 작성해주세요';
  }

  return null;
};

export const validateTargetApp = (targetApp: SdkTargetApp): string | null => {
  const { app_name } = targetApp;

  if (targetApp.package.trim() === '') {
    return 'target app의 패키지명을 입력해주세요.';
  }

  if (app_name.trim() === '') {
    return 'target app의 app_name을 입력해주세요.';
  }

  return null;
};

export const validateXUser = (xUser: XUser): string | null => {
  const { username, id, password } = xUser;

  if (username.trim() === '') {
    return '사용자명을 입력해주세요.';
  }
  if (id.trim() === '') {
    return 'id를 입력해주세요.';
  }
  if (password.trim().length !== 16) {
    return 'password는 공백 없이 16자리로 입력할 수 있습니다.';
  }
  return null;
};
