import { BoxParagraph, HorizontalLineBreak } from './style';

type Props = {
  comment: Segments.DMP.Segment['comment'];
};

function CombinationSegment({ comment }: Props) {
  const combineInfo = JSON.parse(comment ?? '');
  const { segment1, segment2, relation } = combineInfo;

  return (
    <div>
      <BoxParagraph>조합 세그먼트 : {relation}</BoxParagraph>
      <HorizontalLineBreak />
      <BoxParagraph>
        세그먼트 ID : <span className="bold">{segment1?.id}</span>
      </BoxParagraph>
      <BoxParagraph>
        세그먼트 이름 : <span className="bold">{segment1?.name}</span>
      </BoxParagraph>
      <HorizontalLineBreak />
      <BoxParagraph>
        세그먼트 ID : <span className="bold">{segment2?.id}</span>
      </BoxParagraph>
      <BoxParagraph>
        세그먼트 이름 : <span className="bold">{segment2?.name}</span>
      </BoxParagraph>
    </div>
  );
}

export default CombinationSegment;
