/* eslint-disable react/no-danger */
import styled from '@emotion/styled';
import Badge from '@F/Badge';
import dayjs from 'dayjs';

import { Notice } from '@TS/max/manageNotice';
import { black, grayscale800 } from 'loplat-ui';
import { NOTICE_STATUS_COLOR, NOTICE_STATUS_TEXT, NOTICE_TYPE_COLOR, NOTICE_TYPE_TEXT } from '../constant';

export default function NoticeContentViewer({ notice }: { notice: Notice }): JSX.Element {
  const { created_at, published_at, content, title, type, status } = notice;

  return (
    <StyledNoticeContentViewer>
      <div className="badges">
        <Badge text={NOTICE_TYPE_TEXT[type]} color={NOTICE_TYPE_COLOR[type]} />
        <Badge
          text={`${NOTICE_STATUS_TEXT[status]}${status === 'PENDING' ? '' : '됨'}`}
          color={NOTICE_STATUS_COLOR[status]}
        />
      </div>
      <div className="title-date">
        <h1>{title}</h1>
        <div className="date">
          <span>생성일 : {dayjs(created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
          {published_at ? <span>발행일 : {dayjs(published_at).format('YYYY-MM-DD HH:mm:ss')}</span> : null}
        </div>
      </div>
      <span dangerouslySetInnerHTML={{ __html: content || '' }} />
    </StyledNoticeContentViewer>
  );
}

const StyledNoticeContentViewer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap');

  background-color: white;
  padding: 24px;
  box-shadow: 0 0 10px #eee;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  span {
    margin-top: 50px;
    font-family: 'Noto Sans KR', Helvetica, Arial, sans-serif !important;

    * {
      line-height: normal;
    }

    h2 {
      font-size: 20px;
      color: #303133;
    }

    ul,
    ol {
      color: #303133;
      font-size: 13px;
      padding-left: 40px;
      margin: 13px 0;
    }

    p {
      color: rgb(48, 49, 51);
      font-size: 13px;
      line-height: normal;
      margin: 0;
    }
  }

  img {
    width: 100%;
    max-width: 1100px !important;
  }

  table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 12px 0 14px;
    color: ${black};
    width: auto;
    border-collapse: collapse;
    box-sizing: border-box;

    p {
      line-height: 160%;
      color: inherit;
      margin: 0;
      font-size: 0.875rem;
    }

    th {
      background-color: ${grayscale800};
      font-weight: 300;
      color: #fff;
      padding-top: 6px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 14px;
      min-height: 43px;
      p {
        font-weight: 500;
      }
    }

    td {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 14px;
      min-height: 43px;
    }
  }

  .badges {
    justify-content: flex-end;
    display: flex;
    *:not(:first-of-type) {
      margin-left: 10px;
    }
    margin-bottom: 10px;
  }

  .title-date {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 20px;
    }

    .date {
      *:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }

  img {
    max-width: 100%;
  }
`;
