import { useEffect } from 'react';
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Refresh } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import type { Company } from '@TS/max/company/api';
import Tooltip from '@F/Tooltip';
import { selectCompanies, selectStatus } from '../../redux/companies/selector';
import { actions } from '../../redux/companies/slice';
import * as S from './styles';

type Props = {
  company: Company;
  setCompany: React.Dispatch<React.SetStateAction<string>>;
};

const CompanyInput = ({ company, setCompany }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const fetchCompaniesStatus = useSelector(selectStatus);
  const maxCompanies = useSelector(selectCompanies);

  useEffect(() => {
    if (!maxCompanies.length) {
      dispatch(actions.fetchCompanies());
    }
  }, [dispatch, maxCompanies.length]);

  return (
    <S.GridItem>
      <Grid container>
        <S.GridItem xs={12}>
          <Autocomplete
            size="small"
            id="company"
            freeSolo
            autoHighlight
            clearOnBlur
            disabled={fetchCompaniesStatus === 'loading' || fetchCompaniesStatus === 'failure'}
            loading={fetchCompaniesStatus === 'loading'}
            options={maxCompanies}
            value={company}
            onChange={(event, newValue) => setCompany(newValue ?? '')}
            renderInput={(params) => (
              <TextField
                {...params}
                label="회사명"
                name="company"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {fetchCompaniesStatus === 'loading' && <CircularProgress size={20} thickness={4} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </S.GridItem>

        <Button
          size="small"
          endIcon={<Refresh color="inherit" />}
          onClick={() => dispatch(actions.fetchCompanies())}
          disabled={fetchCompaniesStatus === 'loading'}
        >
          <Tooltip title="X를 구독 중인 회사 목록을 새로 가져옵니다.">
            <span>다시 불러오기</span>
          </Tooltip>
        </Button>
      </Grid>
    </S.GridItem>
  );
};

export default CompanyInput;
