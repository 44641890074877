import { ManagePromotionProvider } from './usePromotionContext';
import PromotionComponent from './PromotionComponent';

const PromotionPage = (): JSX.Element => (
  <ManagePromotionProvider>
    <PromotionComponent />
  </ManagePromotionProvider>
);

export default PromotionPage;
