import { useMemo, useRef, useState } from 'react';
import type { UseGeofenceMap } from './type';
import {
  formatMeterToKiloMeter,
  getUnionBoundaries,
  drawCircle,
  drawPolygon,
  drawMarker,
  setVisibleToolTip,
  moveToCenter,
  setHoverOnStyle,
  setHoverOffStyle,
  setHoverStyles
} from './utils';
import { MAP_STYLES, MAX_DISPLAY } from './data';

const useGeofenceMap: UseGeofenceMap = (id) => {
  const mapId = useMemo(() => `Geofence__NaverMap__${id}`, [id]);
  const centerBoundsRef = useRef<naver.maps.Bounds | null>(null);
  const [zoom, setZoom] = useState(0);
  const [areaSizeInKM, setAreaSizeInKM] = useState<number | null>(null);
  const tipRef = useRef<HTMLDivElement>(null);

  const syncZoomLevel = (map: naver.maps.Map) => {
    naver.maps.Event.addListener(map, 'zoom_changed', (zoomLevel) => {
      setZoom(zoomLevel);
    });
  };

  /** 지오펜스의 직사각형 좌표 경계 */
  const memoGeofenceBounds = (geofence: naver.maps.Circle | naver.maps.Polygon): naver.maps.Bounds => {
    const bound = geofence.getBounds();
    centerBoundsRef.current = bound;
    return bound;
  };

  const initGeofenceCircle = ({ dist, lat, lng }: Geofence.GeofenceApi.Get.Single.Response.GeofenceCircle) => {
    const center = new naver.maps.LatLng(lat, lng);
    const map = new naver.maps.Map(mapId, {
      center,
      ...MAP_STYLES.map
    });
    const circleSketch = drawCircle({ dist, lat, lng, map });

    setHoverStyles(circleSketch, 'circle');
    memoGeofenceBounds(circleSketch);
    setAreaSizeInKM(formatMeterToKiloMeter(circleSketch.getAreaSize()));
    syncZoomLevel(map);
    const bounds = circleSketch.getBounds();
    naver.maps.Event.addListener(circleSketch, 'click', () => moveToCenter(map, bounds));

    const marker = drawMarker({ map, center });
    naver.maps.Event.addListener(marker, 'click', () => moveToCenter(map, bounds));
    naver.maps.Event.addListener(marker, 'mouseover', () => setHoverOnStyle(circleSketch, 'circle'));
    naver.maps.Event.addListener(marker, 'mouseout', () => setHoverOffStyle(circleSketch, 'circle'));
    return map;
  };

  const initGeofencePolygon = ({ polygon }: Geofence.GeofenceApi.Get.Single.Response.GeofencePolygon) => {
    const map = new naver.maps.Map(mapId, {
      ...MAP_STYLES.map
    });
    const paths = [polygon.map(([lng, lat]) => new naver.maps.LatLng(lat, lng))];
    const polygonSketch = drawPolygon({ paths, map });

    setHoverStyles(polygonSketch, 'polygon');
    memoGeofenceBounds(polygonSketch);
    setAreaSizeInKM(formatMeterToKiloMeter(polygonSketch.getAreaSize()));
    syncZoomLevel(map);
    const bounds = polygonSketch.getBounds();
    naver.maps.Event.addListener(polygonSketch, 'click', () => moveToCenter(map, bounds));
    return map;
  };

  // TODO
  // 현재는 데이터 중 20개만 불러오기 때문에 부정확함. (연산처리비용때문에 20개로 정함) MC-1549
  // 따라서 갯수가 n개 이상일때 center, zoom을 임의로 정하고 [보이는 지도영역의 마커만 표시](https://navermaps.github.io/maps.js/docs/tutorial-marker-viewport.example.html)하도록 수정
  const initGeofenceGroup = ({ circles, polygons }: Geofence.GeofenceApi.Get.Group.Response.Detail) => {
    const map = new naver.maps.Map(mapId, {
      ...MAP_STYLES.map
    });

    const boundaries: naver.maps.Bounds[] = [];
    if (circles.length) {
      for (let i = 0; i < circles.length; i++) {
        if (i > MAX_DISPLAY) break;
        const { distance: dist, lat, lng } = circles[i];
        const circleSketch = drawCircle({ lat, lng, dist, map });
        const marker = drawMarker({ map, center: new naver.maps.LatLng(lat, lng) });
        const bounds = circleSketch.getBounds();
        boundaries.push(bounds);
        naver.maps.Event.addListener(marker, 'click', () => moveToCenter(map, bounds));
        naver.maps.Event.addListener(circleSketch, 'click', () => moveToCenter(map, bounds));
        setHoverStyles(circleSketch, 'circle');
        naver.maps.Event.addListener(marker, 'mouseover', () => setHoverOnStyle(circleSketch, 'circle'));
        naver.maps.Event.addListener(marker, 'mouseout', () => setHoverOffStyle(circleSketch, 'circle'));
      }
    }

    if (polygons.length) {
      for (let i = 0; i < polygons.length; i++) {
        if (i > MAX_DISPLAY) break;
        const { polygon } = polygons[i];
        const paths = [polygon.map(([lng, lat]) => new naver.maps.LatLng(lat, lng))]; // eslint-disable-line no-loop-func
        const polygonSketch = drawPolygon({ map, paths });

        const bounds = polygonSketch.getBounds();
        boundaries.push(bounds);
        const { x, y } = bounds.getCenter();
        const marker = drawMarker({ map, center: new naver.maps.LatLng(y, x) });
        naver.maps.Event.addListener(marker, 'click', () => moveToCenter(map, bounds));
        naver.maps.Event.addListener(polygonSketch, 'click', () => moveToCenter(map, bounds));
        setHoverStyles(polygonSketch, 'polygon');
        naver.maps.Event.addListener(marker, 'mouseover', () => setHoverOnStyle(polygonSketch, 'polygon'));
        naver.maps.Event.addListener(marker, 'mouseout', () => setHoverOffStyle(polygonSketch, 'polygon'));
      }
    }

    const unionBoundaries = getUnionBoundaries(boundaries);
    centerBoundsRef.current = unionBoundaries;

    syncZoomLevel(map);
    setAreaSizeInKM(null);

    naver.maps.Event.addListener(map, 'mousedown', () => {
      if (tipRef.current) setVisibleToolTip(false, tipRef.current);
    });
    return map;
  };

  return {
    mapId,
    tipRef,
    zoom,
    setZoom,
    areaSize: areaSizeInKM,
    centerBoundsRef,
    initGeofenceCircle,
    initGeofencePolygon,
    initGeofenceGroup
  };
};

export default useGeofenceMap;
