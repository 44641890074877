type StatusKoreanKey = Record<Campaign.Scheme.Status.NamedStatusKorean, Campaign.Scheme.Status.NamedStatus>;
type StatusEnglishKey = Record<Campaign.Scheme.Status.NamedStatus, Campaign.Scheme.Status.NamedStatusKorean>;
export const CAMPAIGN_STATUS: StatusKoreanKey = {
  전체: 'all',
  진행: 'proceeding',
  대기: 'inactive',
  종료: 'complete'
};

export const CAMPAIGN_STATUS_KOREAN: StatusEnglishKey = (
  Object.entries(CAMPAIGN_STATUS) as Entries<StatusKoreanKey>
).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {} as StatusEnglishKey);
