import { ManagerApiError } from '@TS/customError';

export default {
  homepage: { requestFail: '요청 실패 : 관리자(윤준호)에게 문의바랍니다.' },
  max: { requestFail: '요청 실패 : 관리자(윤준호)에게 문의바랍니다.' },
  manager: { requestFail: '요청 실패 : 관리자(윤준호)에게 문의바랍니다.' },
  cook: { requestFail: '요청 실패 : 관리자(윤준호)에게 문의바랍니다.' },
  cashplace: { requestFail: '요청 실패 : 관리자(윤준호)에게 문의바랍니다.' }
};

export const errorBoundaryMessage: Record<ManagerApiError['error'], string> = {
  EntityNotFound: '자료를 찾을 수 없습니다. 찾으시는 자료의 id를 확인해주세요.',
  AccessDeny: '다시 로그인 해주세요.'
};
