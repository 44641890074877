/* eslint-disable vars-on-top */
/* eslint-disable no-console */
import React, { useCallback, useState, createContext, useContext, useEffect } from 'react';
import { toastErrorMessage } from '@FUNC/toast';
import { PromotionApi } from '@API/max/index';
import { CreatePromotionParams, Promotion, UpdatePromotionParams } from '@TS/max/Promotion/api';
import moment from 'moment';

import firebase from 'firebase';
import { managerFirebaseProject } from '@/firebase';

type isSuccess = boolean;

type FetchPromotions = () => Promise<isSuccess>;
type TogglePublishPromotion = (Promotion: UpdatePromotionParams) => Promise<isSuccess>;
type DeletePromotion = (Promotion: Promotion) => Promise<isSuccess>;
type UpdatePromotion = (Promotion: UpdatePromotionParams) => Promise<isSuccess>;
type CreatePromotion = (Promotion: CreatePromotionParams) => Promise<isSuccess>;

type PromotionContextType = {
  promotions: Promotion[];
  togglePublishApiStatuses: ApiStatus;
  togglePublishPromotion: TogglePublishPromotion;
  fetchApiStatus: ApiStatus;
  fetchPromotions: FetchPromotions;
  UpdateApiStatus: ApiStatus;
  UpdatePromotion: UpdatePromotion;
  deleteApiStatus: ApiStatus;
  deletePromotion: DeletePromotion;
  createApiStatus: ApiStatus;
  createPromotion: CreatePromotion;
  uploadBlobImageAndCallback: (blobFile: File) => Promise<string>;
};

const promotionContext = createContext<PromotionContextType | undefined>(undefined);

export function ManagePromotionProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [fetchApiStatus, setFetchStatus] = useState<ApiStatus>('idle');
  const [togglePublishApiStatuses, setTogglePublishApiStatuses] = useState<ApiStatus>('idle');
  const [deleteApiStatus, setDeleteApiStatus] = useState<ApiStatus>('idle');
  const [createApiStatus, setCreateApiStatus] = useState<ApiStatus>('idle');
  const [UpdateApiStatus, setUpdateApiStatus] = useState<ApiStatus>('idle');

  useEffect(() => {
    fetchPromotions();
  }, []);

  const uploadBlobImageAndCallback = useCallback((blobFile: File) => {
    const filePath = (file: File) => {
      const today = moment();
      const folderName = `banner/${today.format('YYYY_MM_DD')}`;
      const fileName = `${today.format('hh_mm_ss')} - ${file.name.split(' ').join('_')}`;

      return `${folderName}/${fileName}`;
    };

    const uploadPath = filePath(blobFile);
    const uploadTask = managerFirebaseProject.storage().ref(uploadPath).put(blobFile);

    const onStartUpload = (snapshot: any) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

      console.log(`Upload is ${progress}% done`);

      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
        default:
          break;
      }
    };

    const onErrorUpload = (error: Error) => {
      toastErrorMessage(error.message);
    };

    // eslint-disable-next-line arrow-body-style
    const onSuccessUpload = () => {
      const downloadURL = uploadTask.snapshot.ref.getDownloadURL();
      return downloadURL;
    };

    uploadTask.on('state_changed', onStartUpload, onErrorUpload, onSuccessUpload);

    return onSuccessUpload();
  }, []);

  const fetchPromotions: FetchPromotions = useCallback(
    async () => {
      try {
        setFetchStatus('loading');

        const { data } = await PromotionApi.getPromotions();

        setPromotions(data.items);

        setFetchStatus('success');
        return true;
      } catch (e) {
        const error = e as Error;
        setFetchStatus('idle');
        toastErrorMessage(error.message);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const togglePublishPromotion: TogglePublishPromotion = useCallback(async (Promotion) => {
    const { id, img, url, content, status } = Promotion;
    const updatedPromotion = {
      id,
      img,
      url,
      content,
      status: status === 'PUBLISH' ? 'PENDING' : 'PUBLISH'
    } as UpdatePromotionParams;

    try {
      setTogglePublishApiStatuses('loading');
      await PromotionApi.updatePromotion(updatedPromotion);
      setTogglePublishApiStatuses('idle');
      setPromotions((prev) =>
        prev.map((promotion) => {
          if (promotion.id === id) {
            return {
              ...promotion,
              status: status === 'PUBLISH' ? 'PENDING' : 'PUBLISH'
            };
          }
          return promotion;
        })
      );
      return true;
    } catch (e) {
      const error = e as Error;
      setTogglePublishApiStatuses('idle');
      toastErrorMessage(error.message);
      return false;
    }
  }, []);

  const deletePromotion: DeletePromotion = useCallback(async (Promotion) => {
    const { id } = Promotion;

    try {
      setDeleteApiStatus('loading');
      await PromotionApi.deletePromotion(id);
      setDeleteApiStatus('idle');
      setPromotions((prev) => prev.filter((Promotion) => Promotion.id !== id));
      return true;
    } catch (e) {
      setDeleteApiStatus('idle');
      const error = e as Error;
      toastErrorMessage(error.message);
      return false;
    }
  }, []);

  const UpdatePromotion: UpdatePromotion = useCallback(async (Promotion) => {
    const { id, img, content, url, status } = Promotion;
    const updatedPromotion = {
      id,
      img,
      content,
      url,
      status
    };

    try {
      setUpdateApiStatus('loading');
      await PromotionApi.updatePromotion(updatedPromotion);
      setUpdateApiStatus('idle');
      return true;
    } catch (e) {
      const error = e as Error;
      setUpdateApiStatus('idle');
      toastErrorMessage(error.message);
      return false;
    }
  }, []);

  const createPromotion: CreatePromotion = useCallback(async (promotion) => {
    try {
      setCreateApiStatus('loading');
      const { data: created } = await PromotionApi.createPromotion(promotion);
      setCreateApiStatus('idle');
      setPromotions((prev) => [created, ...prev]);
      return true;
    } catch (e) {
      const error = e as Error;
      setCreateApiStatus('idle');
      toastErrorMessage(error.message);
      return false;
    }
  }, []);

  return (
    <promotionContext.Provider
      value={{
        fetchPromotions,
        togglePublishPromotion,
        deletePromotion,
        createPromotion,
        UpdatePromotion,
        fetchApiStatus,
        deleteApiStatus,
        togglePublishApiStatuses,
        createApiStatus,
        UpdateApiStatus,
        promotions,
        uploadBlobImageAndCallback
      }}
    >
      {children}
    </promotionContext.Provider>
  );
}

export function usePromotionContext(): PromotionContextType {
  const context = useContext(promotionContext);

  if (!context) {
    throw new Error('useManagePromotion must be used in ManagePromotionProvider');
  }

  return context;
}
