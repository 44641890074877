import { useCallback, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import DialogModal from '@F/DialogModal';
import { useDispatch } from 'react-redux';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import { Report } from '@TS/cashplace/report';
import { copyMessageToClipboard } from '@HOOK/useCopy';
import { kindOptions, resultOptions } from '../../options';
import { actions } from '@/redux/cashplace/report/state';
import Tooltip from '@/foundations/Tooltip';
import * as S from './styles';

type InfoSectionProps = {
  report: Report;
};

/*

원래 Site 위경도 : report.site.lat
유저 위경도 : report.c_lat
유저가 지정한 위경도 : report.lat

폐점
- nid
- 원래 Site 위경도 🧭 blue
- 유저 위경도 🧭 red
- 유저 코멘트

위치수정
- nid
- 원래 Site 위경도 🧭 blue
- 유저가 지정한 위경도 🧭 red
- POI Status, State, Watching

*/

type Mode = 'FIX_POS' | 'CLOSE_STORE';

/*
  -1 : 틀림
  1 : 올바름
  3 : 취소
  0 : 미검수
  */
type ReportResult = 'PENDING' | 'CORRECT' | 'CANCELED' | 'WRONG';

class Pos {
  lat: number;
  lng: number;

  constructor(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }

  toString = (): string => (this.lat ? `${String(this.lat)}, ${String(this.lng)}` : '정보 없음');
}

function InfoSection({ report }: InfoSectionProps): JSX.Element {
  const sitePos: Pos = new Pos(report.site.lat, report.site.lng);
  const fixedSitePos: Pos = new Pos(report.lat, report.lng);
  const userPos: Pos = new Pos(report.c_lat, report.c_lng);
  const mode: Mode = report.kind === kindOptions['위치 수정'] ? 'FIX_POS' : 'CLOSE_STORE';

  const NameInput = <TextInputForm name="NameInput" label="Name" value={report.site.name} disabled />;
  const AddressInput = <TextInputForm name="AddressInput" label="Address" value={report.site.addr} disabled />;
  const FloorInput = <TextInputForm name="FloorInput" label="Floor" value={String(report.site.floor ?? '')} disabled />;
  const RefinedCategoryInput = (
    <TextInputForm name="RefinedCategoryInput" label="RefinedCategory" value={report.site.refined_category} disabled />
  );
  const CategoryInput = <TextInputForm name="CategoryInput" label="Category" value={report.site.category} disabled />;
  const PhoneInput = <TextInputForm name="PhoneInput" label="Phone" value={report.site.phone ?? ''} disabled />;
  const ClosedReportCountInput = (
    <TextInputForm
      name="ClosedReportCountInput"
      label="ClosedReportCount"
      value={String(report.site.closed_report_count)}
      disabled
    />
  );
  const CommentInput = <TextInputForm name="CommentInput" value={report.comment ?? ''} disabled />;
  const PoiStatusInput = (
    <TextInputForm name="PoiStatusInput" label="PoiStatus" value={String(report.site.poi_status)} disabled />
  );
  const StateInput = <TextInputForm name="StateInput" label="State" value={String(report.site.state)} disabled />;
  const WatchingInput = (
    <TextInputForm name="WatchingInput" label="Watching" value={String(report.site.watching)} disabled />
  );

  const OriginPosition = <TextInputForm name="sitePos" label="sitePos" value={sitePos.toString()} disabled />;
  const FixedSitePosition = (
    <TextInputForm name="fixedSitePos" label="fixedSitePos" value={fixedSitePos.toString()} disabled />
  );
  const UserPosition = <TextInputForm name="userPos" label="userPos" value={userPos.toString()} disabled />;

  const [result, setResult] = useState<Report['result']>(report.result);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClickButton = useCallback((newResult: Report['result']) => {
    setResult(newResult);
    setIsModalOpen(true);
  }, []);

  const dispatch = useDispatch();
  const updateReportResult = () => {
    const latLngParams = report.kind === kindOptions['위치 수정'] && result === resultOptions['올바름'] ? userPos : {};

    dispatch(actions.updateReport(report, { result, user_id: report.user_id, ...latLngParams }));
  };

  const openNaverMapByLocation = ({ lat, lng }: { lat: number; lng: number }) =>
    window.open(`https://map.naver.com/?lat=${lat}&lng=${lng}`);
  const openNaverMapByAddress = () => window.open(`https://map.naver.com/v5/search/${report.site.addr}`);
  const openNaverMapByNid = () =>
    window.open(`https://store.naver.com/restaurants/detail?id=${report.site.nid}&panel=fold`, 'naver-map');

  const reportStatus: ReportResult =
    result === 0 ? 'PENDING' : result === 1 ? 'CORRECT' : result === 3 ? 'CANCELED' : 'WRONG';

  return (
    <S.StyledInfoSection>
      <Grid container spacing={1}>
        <Grid item xs>
          <p>이름</p>
          {NameInput}
        </Grid>
        <Grid item xs>
          <p>전화번호</p>
          {PhoneInput}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <div>
            <span>주소</span>
            <Button
              color="primary"
              variant="outlined"
              style={{ padding: '0px 10px', margin: '10px' }}
              onClick={openNaverMapByAddress}
            >
              네이버로 확인
            </Button>
          </div>
          {AddressInput}
        </Grid>
        <Grid item xs>
          <p style={{ marginTop: '13px', marginBottom: '13px' }}>층수</p>
          {FloorInput}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <p>카테고리</p>
          {RefinedCategoryInput}
        </Grid>
        <Grid item xs>
          <p>카테고리</p>
          {CategoryInput}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <p>Closed Report Count</p>
          {ClosedReportCountInput}
        </Grid>
        {mode === 'FIX_POS' ? (
          <>
            <Grid item xs>
              <p>POI Status</p>
              {PoiStatusInput}
            </Grid>
            <Grid item xs>
              <p>State</p>
              {StateInput}
            </Grid>
            <Grid item xs>
              <p>Watching</p>
              {WatchingInput}
            </Grid>
          </>
        ) : (
          <Grid item xs={7}>
            <p>유저가 입력한 내용</p>
            {CommentInput}
          </Grid>
        )}
      </Grid>
      <p style={{ marginTop: '20px' }}>네이버 지도로 위치 확인</p>
      <Grid xs={12} container spacing={3}>
        <Grid item xs={4}>
          <Button fullWidth color="primary" variant="outlined" onClick={openNaverMapByNid} disabled={!report.site.nid}>
            {!report.site.nid ? 'nid 없음' : 'nid'}
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            style={{ marginBottom: '10px' }}
            onClick={() => openNaverMapByLocation(sitePos)}
          >
            원래 site 위경도
          </Button>
          {OriginPosition}
          <p style={{ color: 'blue', display: 'flex', alignItems: 'center' }}>
            위경도 복사(Lat, Lng)
            <FileCopyTwoToneIcon
              style={{ cursor: 'pointer' }}
              onClick={() => copyMessageToClipboard(sitePos.toString())}
            />
          </p>
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            style={{ marginBottom: '10px' }}
            disabled={mode === 'FIX_POS' ? false : !report.c_lat}
            onClick={() => openNaverMapByLocation(report.kind === kindOptions['위치 수정'] ? fixedSitePos : userPos)}
          >
            {mode === 'FIX_POS'
              ? '유저가 지정한 위경도'
              : report.c_lat
              ? '유저의 위치 위경도'
              : '유저의 위치 위경도 없음'}
          </Button>
          {mode === 'FIX_POS' ? FixedSitePosition : UserPosition}
          <p style={{ color: 'red', display: 'flex', alignItems: 'center' }}>
            위경도 복사(Lat, Lng)
            <FileCopyTwoToneIcon
              style={{ cursor: 'pointer' }}
              onClick={() => copyMessageToClipboard((mode === 'FIX_POS' ? fixedSitePos : userPos).toString())}
            />
          </p>
        </Grid>
      </Grid>
      <Tooltip
        title={
          reportStatus === 'CANCELED'
            ? '취소 상태일 경우 변경 불가능합니다.'
            : reportStatus === 'WRONG'
            ? ''
            : reportStatus === 'CORRECT'
            ? '올바름 상태일 경우 변경 불가능합니다.'
            : ''
        }
      >
        <S.ButtonRow>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleClickButton(-1)}
            disabled={reportStatus !== 'PENDING'}
          >
            틀림
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClickButton(1)}
            disabled={reportStatus === 'CANCELED' || reportStatus === 'CORRECT'}
          >
            올바름
          </Button>
        </S.ButtonRow>
      </Tooltip>

      <DialogModal
        title="리포트 검수"
        description={`해당 리포트를 ${result === resultOptions['올바름'] ? '올바름' : '틀림'} 처리합니다.`}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        confirmButtonDidClicked={updateReportResult}
      />
    </S.StyledInfoSection>
  );
}
export default InfoSection;
