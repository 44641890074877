import PropTypes from 'prop-types';
import NaverMap from '@F/map/NaverMap';
import * as S from './styles';

function InnerMap({ detail, polygon, markers, onEmitCoordinates }) {
  return (
    <S.MapWrapper>
      <NaverMap
        id={detail.cid}
        center={{ lat: detail.lat, lng: detail.lng }}
        polygon={polygon}
        markers={markers}
        zoom={20}
        onEmitCoordinates={onEmitCoordinates}
      />
    </S.MapWrapper>
  );
}
export default InnerMap;

InnerMap.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired
};
