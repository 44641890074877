import { useState, useCallback } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';

import { ControlWrapper } from '@P/polygon/styles';
import { useFetch } from '@HOOK/index';
import Loading from '@F/Loading';

import { PolygonApi } from '@API/manager';
import { GetPolygonListItem } from '@API/manager/polygon';

import AddressInput from './AddressInput';
import DrawingModeButton from './DrawingModeButton';
import NearPolygonButton from './NearPolygonButton';
import MapInfoControlButton from './MapInfoControlButton';
import MarkerControl from './MarkerControl';

import {
  drawGeoJson,
  toggleMapControl,
  getCurrentMapBounds,
  randomColorArray,
  setEventAtGeoJsonData
} from './naverMapFunction';
import useDrawingTool from './useDrawingTool';
import MapTypeButton from './MapTypeButton';

type Props = {
  drawingManager: naver.maps.drawing.DrawingManager;
  naverMap: naver.maps.Map;
  marker: naver.maps.Marker;
  setLatitude: Dispatch<SetStateAction<string>>;
  setLongitude: Dispatch<SetStateAction<string>>;
  setAddr: Dispatch<SetStateAction<string>>;
  setRoad: Dispatch<SetStateAction<string>>;
  isEdit: boolean;
  isLoading: boolean;
};

type Bound = {
  min_lng: number;
  min_lat: number;
  max_lng: number;
  max_lat: number;
};

// SEE : https://navermaps.github.io/maps.js.ncp/docs/tutorial-Custom-Control.html
// NOTE. custom control을 맵에 등록하는 방법으로 구현할 수 있으나, 로직 분리를 위해 하위 컴포넌트로 분리
function MapConrtrol({
  drawingManager,
  naverMap,
  marker,
  setLatitude,
  setLongitude,
  setAddr,
  setRoad,
  isEdit,
  isLoading: editLoading
}: Props): JSX.Element {
  const { isLoading, callApi } = useFetch();
  const { id } = useParams<{ id: string }>();

  const [drawings, setDrawings] = useState<naver.maps.drawing.DrawingOverlay[]>([]);
  const [showInNear, setShowInNear] = useState(true);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [clickEventHandler, setClickEventHandler] = useState<naver.maps.MapEventListener | null>(null);
  const [naverInfoLoading, setNaverInfoLoading] = useState(false);
  const [drawed, setDrawed] = useState<number[]>([]);
  const [cachedBounce, setCachedBounce] = useState<Bound | null>(null);
  useDrawingTool({ naverMap, drawingManager, isDrawingMode, setIsDrawingMode });

  const backupDraw = (backup: naver.maps.drawing.DrawingOverlay[] | null) => {
    const backupTarget = backup || drawings;
    if (backupTarget.length < 0) {
      return;
    }
    backupTarget.forEach((drawing) => {
      drawingManager.addDrawing(drawing, naver.maps.drawing.DrawingMode.POLYGON);
    });
    setDrawings([]);
    if (clickEventHandler) {
      naverMap.removeListener(clickEventHandler);
    }
    setClickEventHandler(null);
  };

  const resetState = (except: 'info' | 'draw' | 'near') => {
    if (except === 'info') {
      // drawingManager.setOptions({ drawingMode: 0 });
      setIsDrawingMode(false);
      setShowInNear(false);
    }

    if (except === 'draw') {
      backupDraw(drawings);
      if (clickEventHandler) {
        naverMap.removeListener(clickEventHandler);
        setClickEventHandler(null);
      }
    }

    if (except === 'near') {
      backupDraw(drawings);
      if (clickEventHandler) {
        naverMap.removeListener(clickEventHandler);
        setClickEventHandler(null);
      }
    }
  };

  const queryCacheCheck = (bound: Bound) => {
    if (!cachedBounce) {
      return false;
    }
    const { min_lng, min_lat, max_lng, max_lat } = bound;
    const {
      min_lng: cached_min_lng,
      min_lat: cached_min_lat,
      max_lng: cached_max_lng,
      max_lat: cached_max_lat
    } = cachedBounce;

    return (
      min_lng === cached_min_lng &&
      min_lat === cached_min_lat &&
      max_lng === cached_max_lng &&
      max_lat === cached_max_lat
    );
  };

  const filterArg = useCallback(
    (item: GetPolygonListItem) =>
      isEdit ? item.job_id !== Number(id) && !drawed.includes(item.job_id) : !drawed.includes(item.job_id),
    [drawed, id, isEdit]
  );

  const getInNearPolygon = async (params: Bound) => {
    try {
      toggleMapControl(naverMap, 'off');
      const response = await callApi(PolygonApi.getInNearPolygon, params);
      setCachedBounce(params);

      const data = response.data as GetPolygonListItem[];
      const filteredData = data.filter(filterArg);

      if (filteredData.length > 0) {
        const colorArray = randomColorArray(filteredData.length);

        const geoJson = {
          type: 'FeatureCollection',
          features: filteredData.map((item, index) => ({
            type: 'Feature',
            geometry: item.mpolygon_area,
            properties: {
              title: item.title,
              color: colorArray[index]
            }
          }))
        };

        setDrawed((prev) => [...prev, ...filteredData.map((item) => item.job_id)]);
        await drawGeoJson(geoJson, naverMap);
        const drawedData = naverMap.data.getAllFeature();
        drawedData.forEach((draw) => {
          setEventAtGeoJsonData({ naverMap, draw });
        });
      }
    } finally {
      toggleMapControl(naverMap, 'on');
    }
  };

  const getInNearPolygonLogic = async () => {
    const bound = getCurrentMapBounds(naverMap);
    const isCached = queryCacheCheck(bound);
    if (!isCached) {
      await getInNearPolygon(bound);
    }
    setShowInNear(true);
  };

  const moveMap = async (x: number, y: number) => {
    const point = new naver.maps.Point(x, y);
    naverMap.setCenter(point);
    marker.setPosition(point);
    getInNearPolygonLogic();
  };

  const exceptionSnapShotEvent = (e: KeyboardEvent, keyList: string[]) => {
    const { key, target } = e;
    if (target instanceof HTMLInputElement) {
      return false;
    }
    const currentKey = key.toLowerCase();
    return keyList.includes(currentKey);
  };

  return (
    <>
      {isLoading && <Loading />}
      <ControlWrapper>
        <AddressInput
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setAddr={setAddr}
          setRoad={setRoad}
          moveMap={moveMap}
        />

        <div className="icons">
          <MapInfoControlButton
            naverMap={naverMap}
            drawingManager={drawingManager}
            marker={marker}
            setDrawings={setDrawings}
            backupDraw={backupDraw}
            clickEventHandler={clickEventHandler}
            setClickEventHandler={setClickEventHandler}
            resetState={resetState}
            naverInfoLoading={naverInfoLoading}
            setNaverInfoLoading={setNaverInfoLoading}
            setAddr={setAddr}
            setRoad={setRoad}
            exceptionSnapShotEvent={exceptionSnapShotEvent}
          />
          <DrawingModeButton
            naverMap={naverMap}
            resetState={resetState}
            drawingManager={drawingManager}
            isDrawingMode={isDrawingMode}
            setIsDrawingMode={setIsDrawingMode}
            editLoading={editLoading}
            disabled={naverInfoLoading}
            exceptionSnapShotEvent={exceptionSnapShotEvent}
          />
          <NearPolygonButton
            naverMap={naverMap}
            showInNear={showInNear}
            setShowInNear={setShowInNear}
            resetState={resetState}
            getInNearPolygonLogic={getInNearPolygonLogic}
            isLoading={isLoading}
            disabled={naverInfoLoading}
            exceptionSnapShotEvent={exceptionSnapShotEvent}
          />
          <MapTypeButton naverMap={naverMap} exceptionSnapShotEvent={exceptionSnapShotEvent} />
        </div>

        <MarkerControl
          naverMap={naverMap}
          marker={marker}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setAddr={setAddr}
          setRoad={setRoad}
        />
      </ControlWrapper>
    </>
  );
}

export default MapConrtrol;
