import styled from 'styled-components';

export const MapWrapper = styled.div`
  width: 100%;
  height: 90%;
  max-height: 776px;
  display: flex;
  position: relative;
  min-height: 400px;
`;
