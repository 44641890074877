import { cashplaceContactsApi } from '@API/manager/cashplace';
import { Contact, PutContactParams } from '@TS/cashplace/contact';

type FetchContactsParams = {
  userId: number;
  state: number | null;
  content?: string;
};

export function fetchContacts(page: number, perPage: number, params: FetchContactsParams): Promise<Contact[]> {
  return cashplaceContactsApi.getContacts({ page, perPage, ...params }).then((response) => response.data);
}

export function updateContact(contactId: number, params: PutContactParams): Promise<Contact> {
  return cashplaceContactsApi.putContact(contactId, params).then((response) => response.data);
}
