import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from '@HOOK/index';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import TextInputForm, { TextInputFormProps } from '@F/materialUI/TextInputForm';

import type { Dispatch, SetStateAction } from 'react';
import type { AxiosResponse } from 'axios';

import { actions } from '@P/polygon/redux/polygonList';
import Loading from '@F/Loading';
import { toastWarningMessage } from '@FUNC/toast';
import { PolygonApi } from '@API/manager';
import useDrawingManager from './polygonNaverMap/useDrawingManager';
import { InputWrapper } from './styles';

type Props = {
  drawingManager: naver.maps.drawing.DrawingManager | null;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  addr: string;
  road: string;
  latitude: string;
  longitude: string;
  isEdit: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
};

const InputForm = ({
  drawingManager,
  title,
  setTitle,
  addr,
  road,
  latitude,
  longitude,
  isEdit,
  isLoading = false,
  children
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { getDrawData } = useDrawingManager();
  const { isLoading: fetchLoading, callApi } = useFetch();

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
    const { value } = event.target;
    switch (name) {
      case 'title':
        return setTitle(value);
      default:
        return null;
    }
  };

  const inputProps: TextInputFormProps[] = [
    {
      className: 'title',
      label: '제목',
      name: 'title',
      size: 'small',
      value: title,
      onChange: onChangeHandler,
      disabled: isLoading,
      error: Boolean(title) && title.trim().length < 2
    },
    {
      className: 'addr',
      label: '지번',
      name: 'addr',
      size: 'small',
      value: addr ?? '',
      disabled: true,
      onCopy: true
    },
    {
      className: 'road',
      label: '도로명',
      name: 'road',
      size: 'small',
      value: road ?? '',
      disabled: true,
      onCopy: true
    },
    {
      className: 'latlng',
      label: '위/경도',
      name: 'latlng',
      size: 'small',
      value: `${latitude}, ${longitude}`,
      disabled: true,
      onCopy: true
    }
  ];

  const submitFunc = async () => {
    if (fetchLoading) {
      return;
    }

    if (!id && isEdit) {
      return;
    }

    if (!title.trim()) {
      toastWarningMessage('제목을 입력해주세요.');
      return;
    }

    if (title.trim().length < 2) {
      toastWarningMessage('제목을 2자 이상 입력해주세요.');
      return;
    }

    const polygons = getDrawData(drawingManager);

    if (isEdit && polygons.length < 1) {
      toastWarningMessage('한 개 이상의 폴리곤을 그려주세요.');
      return;
    }

    const body = {
      title,
      addr,
      polygons,
      road_addr: road,
      lng: longitude,
      lat: latitude,
      state: title && latitude && longitude && polygons.length > 0 ? 1 : 0
    };

    const response: AxiosResponse = await callApi(
      isEdit ? PolygonApi.editPolygon : PolygonApi.postPolygon,
      isEdit ? { id, body } : { body },
      true
    );

    if (response.status) {
      dispatch(actions.createOrEditPolygon());
      navigate('/equipment/polygon/list');
    }
  };

  return (
    <>
      <InputWrapper>
        <div className="inputWrapper">
          {inputProps.map((prop) => (
            <TextInputForm key={prop.name} {...prop} />
          ))}
        </div>
        {children}
        <div className="submit">
          <Button variant="contained" color="inherit" onClick={() => navigate('/equipment/polygon/list')}>
            목록
          </Button>
          <Button variant="contained" color="primary" onClick={() => submitFunc()}>
            {isEdit ? '제출' : '등록'}
          </Button>
        </div>
      </InputWrapper>
      {fetchLoading && <Loading />}
    </>
  );
};

export default InputForm;
