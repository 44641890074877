import styled from 'styled-components';

export const BoxParagraph = styled.p`
  margin: 0;
  word-break: keep-all;
  &.bold {
    font-weight: bold;
  }
  &.pl {
    padding-left: 1rem;
  }
  &.area {
    color: #1678ff;
  }
  &.commercial {
    color: #67c23a;
  }
  &.brand {
    color: #d999ff;
  }
  &.store {
    color: #ffa25c;
  }
  &.complex {
    color: #67c23a;
  }
  &.category {
    color: #98a8ff;
  }

  span {
    &.bold {
      font-weight: bold;
    }
    &.pl {
      padding-left: 1rem;
    }
    &.area {
      color: #1678ff;
    }
    &.commercial {
      color: #67c23a;
    }
    &.brand {
      color: #d999ff;
    }
    &.store {
      color: #ffa25c;
    }
    &.complex {
      color: #67c23a;
    }
    &.category {
      color: #98a8ff;
    }
  }
`;

export const HorizontalLineBreak = styled.hr`
  margin: 0.5rem 0;
`;
