import styled from '@emotion/styled';

import { IconButton } from '@material-ui/core';
import { ChevronLeft, MenuOpen } from '@material-ui/icons';
import { isPermitted, userIsCustomer } from '@FUNC/permission';
import useAuth from '@HOOK/useAuth';
import { useSideMenu } from '@HOOK/useSideMenu';

import { PRIVATE_ROUTES } from '@/routes';
import { MODE } from '@/config';

import MenuList from './MenuList';
import MenuListItem from './MenuList/MenuListItem';

type Props = {
  showSideMenu: boolean;
  setShowSideMenu: React.Dispatch<React.SetStateAction<boolean>>;
  onClickMenuLinkItem: () => void;
};

export function SideMenu({ showSideMenu, setShowSideMenu, onClickMenuLinkItem }: Props): JSX.Element | null {
  const { user } = useAuth();
  const { favoriteRouteNodes } = useSideMenu();

  if (!user) {
    return null;
  }

  const permissionMenuItems = PRIVATE_ROUTES.filter(
    (routeNode) =>
      !routeNode.children &&
      !routeNode.isNotShow &&
      (routeNode.permission ? isPermitted(user, routeNode.permission) : true)
  ).map((routeNode, i) => (
    <MenuListItem key={i} parentRouteNode={null} routeNode={routeNode} onClickMenuLinkItem={onClickMenuLinkItem} />
  ));

  const favoriteMenu = favoriteRouteNodes.length ? (
    <MenuList
      parentRouteNode={{
        label: '즐겨찾기',
        children: favoriteRouteNodes
      }}
      onClickMenuLinkItem={onClickMenuLinkItem}
    />
  ) : null;

  const permissionMenus = PRIVATE_ROUTES.filter((routeNode) => {
    if (routeNode.label === '도구') {
      return (
        routeNode.children &&
        (routeNode.permission
          ? isPermitted(user, routeNode.permission)
          : routeNode.children.some((childRouteNode) =>
              childRouteNode.permission ? isPermitted(user, childRouteNode.permission) : true
            )) &&
        !routeNode.isNotShow &&
        !userIsCustomer(user)
      );
    }
    return (
      routeNode.children &&
      (routeNode.permission
        ? isPermitted(user, routeNode.permission)
        : routeNode.children.some((childRouteNode) =>
            childRouteNode.permission ? isPermitted(user, childRouteNode.permission) : true
          )) &&
      !routeNode.isNotShow
    );
  })
    .map((routeNode) => ({
      ...routeNode,
      children: routeNode.permission
        ? routeNode.children?.filter((childRouteNode) => !childRouteNode.isNotShow)
        : routeNode.children!.filter((childRouteNode) =>
            !childRouteNode.isNotShow && childRouteNode.permission ? isPermitted(user, childRouteNode.permission) : true
          )
    }))
    .map((parentRouteNode, i) => (
      <MenuList key={i} parentRouteNode={parentRouteNode} onClickMenuLinkItem={onClickMenuLinkItem} />
    ));

  return (
    <StyledSideMenu showSideMenu={showSideMenu}>
      <div className="close">
        <StyledMenuIconButton color="inherit" onClick={() => setShowSideMenu((prev) => !prev)}>
          {showSideMenu ? <MenuOpen /> : <ChevronLeft />}
        </StyledMenuIconButton>
      </div>
      {favoriteMenu}
      {permissionMenuItems}
      {permissionMenus}
    </StyledSideMenu>
  );
}
export default SideMenu;

export const StyledSideMenu = styled.div<{ showSideMenu: boolean }>`
  height: 100%;
  overflow: scroll;

  .close {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
    display: flex;
    border-bottom: 1px solid #eee;
    justify-content: flex-end;
    align-items: center;
    transition: background-color 0.2s;
    height: 66px;
    ${({ showSideMenu }) =>
      showSideMenu
        ? `
            color:#fff;
            background-color: ${MODE === 'staging' ? '#FC4C94' : '#3f50b5'};
            `
        : `
        color:#222;
        background-color: #fff;
    `}
  }
`;

// header - menu icon button
const StyledMenuIconButton = styled(IconButton)`
  width: 60px;
`;
