import { Navigate, Outlet, Route, useLocation, BrowserRouter, Routes } from 'react-router-dom';

import React, { Suspense } from 'react';
import Loading from '@P/Loading';

import { UserRoleRank } from '@TS/user';
import { isPermitted } from '@FUNC/permission';

import UnauthorizedPage from '@P/Unauthorized';
import LoginPage from '@P/login';

import DashboardPage from '@P/dashboard/index';
import SiteMap from '@P/poi/SiteMap';
import SiteMapManager from '@P/poi/SiteMapManager';
import UncollectedComplexPlaceTodoManager from '@P/poi/UnCollectedComplexPlaceTodosManager';
import FpManager from '@P/fingerprint/fpManager';
import ManualMapping from '@P/fingerprint/manualMapping';
import CookUser from '@P/cook/cookUser';
import CookVersion from '@P/cook/cookVersion';
import CookDevice from '@P/cook/cookDevice';
import StoreManager from '@P/store/StoreManager';
import StoreErrorReport from '@P/store/ErrorReport';
import StoreCombine from '@P/store/StoreCombine';
import SiteDashboard from '@P/cashplace/SiteDashboard';
import UserDashboard from '@P/cashplace/UserDashboard';
import AppUsage from '@P/cashplace/AppUsage';
import Inspect from '@P/cashplace/Inspect';
import Report from '@P/cashplace/Report';
import Reward from '@P/cashplace/Reward';
import Contact from '@P/cashplace/Contact';
import Users from '@P/cashplace/Users';
import Version from '@P/cashplace/Version';
import Event from '@P/cashplace/Event';
import Message from '@P/cashplace/Message';
import ComplexManager from '@P/complex/complexManager';
import AddComplex from '@P/complex/addComplex';
import ClosingManager from '@P/ClosingManager';
import UserManager from '@P/admin/UserManager';
import Whitelist from '@P/admin/Whitelist';
import ManageRecruits from '@P/homepage/manageRecruits';
import ManageArticles from '@P/homepage/manageArticles';
import RecruitPositionForm from '@P/homepage/manageRecruits/RecruitForm';
import PreviewHomepageRecruitInfoView from '@P/homepage/manageRecruits/PreviewHomepageRecruitView';
import MaxCampaigns from '@P/max/campaigns/Campaigns';
import MaxCampaignsCompare from '@P/max/campaigns/Compare';
import MaxCampaignsDetail from '@P/max/campaigns/Detail';
import XPrimitiveLogPage from '@P/max/primitiveLog';
import XsdkLogPage from '@P/max/sdkLog';
import VersionManager from '@P/max/versionManager';
import ManageNoticeLayout from '@P/max/manageNotice/ManageNoticeLayout';
import NoticesPage from '@P/max/manageNotice/NoticesPage';
import NoticeCreatePage from '@P/max/manageNotice/NoticeCreatePage';
import NoticeEditPage from '@P/max/manageNotice/NoticeEditPage';
import NoticeDetailPage from '@P/max/manageNotice/NoticeDetailPage';
import ManageCompaniesLayout from '@P/max/manageCompanies/ManageCompaniesLayout';
import CompaniesPage from '@P/max/manageCompanies/CompaniesPage';
import CompanyCreatePage from '@P/max/manageCompanies/CompanyCreatePage';
import CompanyEditPage from '@P/max/manageCompanies/CompanyEditPage';
import CompanyDetailPage from '@P/max/manageCompanies/CompanyDetailPage';
import OperationPage from '@P/max/operation';
import useAuth from '@HOOK/useAuth';
import PromotionPage from '@P/max/dashboard/PromotionPage';
import PolygonAddPage from '@P/polygon/add';
import PolygonEditPage from '@P/polygon/edit';
import PolygonListPage from '@P/polygon/list';
import DmpSegmentListPage from '@P/dmp/SegmentPage';

import ErrorBoundary from '@/Templates/ErrorBoundary';
import Layout from './Templates/Layout';
import { USER_ROLES } from './constants/userRole';
import DmpVersion from '@P/max/dmpVersion';

export type RouteNode = {
  label: string;
  path: string;
  element?: React.ReactElement;
  permission?: UserRoleRank;
  children?: RouteNode[];
  type?: 'link';
  isNotShow?: boolean;
};

// private
export const PRIVATE_ROUTES: RouteNode[] = [
  { label: '대시보드', path: '/', element: <DashboardPage />, permission: USER_ROLES.alba },
  {
    label: '수집',
    path: '/poi',
    permission: USER_ROLES.teamjang,
    children: [
      { label: '수집 관리', path: '/poi/sitemap', element: <SiteMap /> },
      { label: '수집 할당', path: '/poi/sitemap-manager', element: <SiteMapManager /> },
      {
        label: '미수집 관리 (복합몰)',
        path: '/poi/complex-placetodo-manager',
        element: <UncollectedComplexPlaceTodoManager />
      }
    ]
  },
  {
    label: '스토어',
    path: '/store',
    children: [
      { label: '매니저', path: '/store/manager', element: <StoreManager />, permission: USER_ROLES.customer },
      { label: '병합', path: '/store/combine', element: <StoreCombine />, permission: USER_ROLES.user },
      { label: '에러 리포트', path: '/store/error-report', element: <StoreErrorReport />, permission: USER_ROLES.user }
    ]
  },
  // 캐시 플레이스
  {
    label: '캐시플레이스',
    path: '/cashplace',
    permission: USER_ROLES.user,
    children: [
      { label: '수집 대시보드', path: '/cashplace/site-dashboard', element: <SiteDashboard /> },
      { label: '유저 대시보드', path: '/cashplace/user-dashboard', element: <UserDashboard /> },
      { label: 'App 사용현황', path: '/cashplace/AppUsage', element: <AppUsage /> },
      { label: '검수', path: '/cashplace/inspect', element: <Inspect /> },
      { label: '유저 관리', path: '/cashplace/users', element: <Users /> },
      { label: '리포트', path: '/cashplace/report', element: <Report /> },
      { label: '문의', path: '/cashplace/contact', element: <Contact /> },
      { label: '출금', path: '/cashplace/reward', element: <Reward /> },
      { label: '버전 관리', path: '/cashplace/version', element: <Version /> },
      { label: '공지/이벤트', path: '/cashplace/event', element: <Event /> },
      { label: '푸시메시지', path: '/cashplace/message', element: <Message /> }
    ]
  },
  {
    label: '복합몰',
    path: '/complex',
    permission: USER_ROLES.user,
    children: [
      {
        label: '매니저',
        path: '/complex/manager',
        element: <ComplexManager />,
        children: [{ label: '복합몰 조회', path: '/complex/manager/:id', element: <ComplexManager /> }]
      },
      { label: '생성', path: '/complex/add', element: <AddComplex /> }
    ]
  },
  {
    label: 'FP',
    path: '/fingerprint',
    children: [
      {
        label: 'FP 관리',
        path: '/fingerprint/collect-manager',
        element: <FpManager />,
        permission: USER_ROLES.teamjang
      },
      {
        label: 'Auto FP 관리',
        path: '/fingerprint/manual-mapping',
        element: <ManualMapping />,
        permission: USER_ROLES.user
      }
    ]
  },
  {
    label: '쿡',
    path: '/cook',
    permission: USER_ROLES.user,
    children: [
      { label: '유저 관리', path: '/cook/user', element: <CookUser /> },
      { label: '버전 관리', path: '/cook/version', element: <CookVersion /> }
      // { label: '기기 관리', path: '/cook/device', element: <CookDevice />, permission: USER_ROLES.manager }
    ]
  },
  {
    label: '폐점',
    path: '/closing',
    permission: USER_ROLES.teamjang,
    children: [{ label: '매니저', path: '/closing/manager', element: <ClosingManager /> }]
  },
  {
    label: '홈페이지',
    path: '/homepage',
    permission: USER_ROLES.user,
    children: [
      { label: '기사 관리', path: '/homepage/manage-articles', element: <ManageArticles /> },
      {
        label: '채용정보 관리',
        path: '/homepage/manage-recruits',
        children: [
          { label: '채용정보 조회', path: '/homepage/manage-recruits', element: <ManageRecruits /> },
          { label: '채용정보 추가', path: '/homepage/manage-recruits/create', element: <RecruitPositionForm /> },
          { label: '채용정보 수정', path: '/homepage/manage-recruits/edit', element: <RecruitPositionForm /> },
          {
            label: '채용정보 미리보기',
            path: '/homepage/manage-recruits/preview',
            element: <PreviewHomepageRecruitInfoView />
          }
        ]
      }
    ]
  },
  {
    label: '관리자',
    path: '/admin',
    permission: USER_ROLES.admin,
    children: [
      { label: '유저 관리', path: '/admin/user-manager', element: <UserManager /> },
      { label: '화이트리스트', path: '/admin/whitelist', element: <Whitelist /> }
    ]
  },
  // max (loplat-x) MC-1321
  {
    label: 'X',
    path: '/x',
    children: [
      {
        label: '캠페인',
        path: '/x/campaigns',
        permission: USER_ROLES.user,
        element: (
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        ),
        children: [
          {
            label: '캠페인 조회',
            path: '/x/campaigns',
            element: <MaxCampaigns />
          },
          {
            label: '캠페인 비교',
            path: '/x/campaigns/compare',
            element: <MaxCampaignsCompare />
          },
          {
            label: '캠페인 상세 조회',
            path: '/x/campaigns/:id',
            element: <MaxCampaignsDetail />
          }
        ]
      },
      {
        permission: USER_ROLES.user,
        label: '인식 로그',
        path: '/x/refined_log',
        element: <XPrimitiveLogPage />
      },
      {
        permission: USER_ROLES.user,
        label: 'SDK 이벤트 로그',
        path: '/x/sdk_event_log',
        element: <XsdkLogPage />
      },
      {
        label: '공지사항',
        path: '/x/notices',
        permission: USER_ROLES.user,
        element: (
          <ManageNoticeLayout>
            <Outlet />
          </ManageNoticeLayout>
        ),
        children: [
          {
            label: 'X 공지사항',
            path: '/x/notices',
            element: <NoticesPage />
          },
          {
            label: '공지사항 추가',
            path: '/x/notices/create',
            element: <NoticeCreatePage />
          },
          {
            label: '공지사항 수정',
            path: '/x/notices/edit/:id',
            element: <NoticeEditPage />
          },
          {
            label: '공지사항 미리보기',
            path: '/x/notices/:id',
            element: <NoticeDetailPage />
          }
        ]
      },
      {
        permission: USER_ROLES.user,
        label: '고객사 조회 (google docs)',
        path: 'https://docs.google.com/spreadsheets/d/14xEnqQQKtWsJZdtKBFW6HqAudeNGMzVATvjpmjyPI-w/edit?usp=sharing',
        type: 'link'
      },
      {
        label: 'SDK 설정 - 고객사 조회',
        path: '/x/companies',
        permission: USER_ROLES.manager,
        element: (
          <ManageCompaniesLayout>
            <Outlet />
          </ManageCompaniesLayout>
        ),
        children: [
          {
            label: '고객사 조회 - X SDK 설정',
            path: '/x/companies',
            element: <CompaniesPage />
          },
          {
            label: '고객사 정보 작성 - X SDK 설정',
            path: '/x/companies/create',
            element: <CompanyCreatePage />
          },
          {
            label: '고객사 정보 수정 - X SDK 설정',
            path: '/x/companies/edit/:id',
            element: <CompanyEditPage />
          },
          {
            label: '고객사 정보 상세 - X SDK 설정',
            path: '/x/companies/:id',
            element: <CompanyDetailPage />
          }
        ]
      },
      {
        permission: USER_ROLES.manager,
        label: 'Operation 계정 설정',
        path: '/x/operation',
        element: (
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <OperationPage />
            </Suspense>
          </ErrorBoundary>
        )
      },
      {
        permission: USER_ROLES.user,
        label: '대시보드 홍보 배너',
        path: '/x/promotion',
        element: (
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <PromotionPage />
            </Suspense>
          </ErrorBoundary>
        )
      },
      {
        permission: USER_ROLES.manager,
        label: '버전 관리',
        path: '/x/version',
        element: (
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <VersionManager />
            </Suspense>
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    permission: USER_ROLES.user,
    label: 'DMP',
    path: '/dmp',
    element: (
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    ),
    children: [
      { label: '세그먼트', path: '/dmp/segment', permission: USER_ROLES.user, element: <DmpSegmentListPage /> },
      {
        permission: USER_ROLES.manager,
        label: 'dmp 버전 관리',
        path: '/dmp/dmpVersion',
        element: (
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <DmpVersion />
            </Suspense>
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    permission: USER_ROLES.alba,
    label: '도구',
    path: '/equipment',
    element: (
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    ),
    children: [
      {
        label: '폴리곤 목록',
        path: '/equipment/polygon/list',
        permission: USER_ROLES.alba,
        element: <PolygonListPage />
      },
      {
        label: '폴리곤 생성',
        path: '/equipment/polygon/add',
        isNotShow: true,
        permission: USER_ROLES.alba,
        element: <PolygonAddPage />
      },
      {
        label: '폴리곤 수정',
        path: '/equipment/polygon/edit/:id',
        isNotShow: true,
        permission: USER_ROLES.alba,
        element: <PolygonEditPage />
      }
    ]
  }
];

export const findRouteNodeByPathname = (pathname: string) => {
  const stack = [...PRIVATE_ROUTES];

  while (stack.length) {
    const routeNode = stack.pop() as RouteNode;

    const paths = pathname.split('/');
    let routeNodePath = routeNode.path;

    if (!Number.isNaN(+paths[paths.length - 1])) {
      routeNodePath = routeNodePath.replace(':id', paths[paths.length - 1]);
    }

    if (routeNodePath === pathname) return routeNode;

    if (routeNode.children) {
      stack.push(...routeNode.children);
    }
  }
};

const routeToReactElement = (routeNode: RouteNode) => {
  const { path, element, permission, children } = routeNode;

  const permissionElement = permission ? (
    <Protected permission={permission}>{element || <Outlet />}</Protected>
  ) : (
    element
  );

  if (!children) {
    return <Route path={path} element={permissionElement} />;
  }

  return (
    <Route path={path} element={permissionElement}>
      {children.map((routeNode, i) => (
        <React.Fragment key={i}>{routeToReactElement(routeNode)}</React.Fragment>
      ))}
    </Route>
  );
};

function Protected({ permission, children }: { permission: any; children: React.ReactElement }) {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (permission === undefined || isPermitted(user, permission)) {
    return children;
  }

  return <Navigate to="/no-permission" state={{ from: location.pathname }} replace />;
}

export default function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/no-permission" element={<UnauthorizedPage />} />
        <Route element={<Layout />}>
          {PRIVATE_ROUTES.map((routeNode, i) => (
            <React.Fragment key={i}>{routeToReactElement(routeNode)}</React.Fragment>
          ))}
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}
