import { useState, useRef } from 'react';
import PolygonNaverMap from './polygonNaverMap';
import InputForm from './InputForm';
import useDrawingManager from './polygonNaverMap/useDrawingManager';
import { PageWrapper } from './styles';

const PolygonAddPage = (): JSX.Element => {
  const { drawingManagerState } = useDrawingManager();
  const [drawingManager, setDrawingManager] = drawingManagerState;
  const [title, setTitle] = useState('');
  const [addr, setAddr] = useState('');
  const [road, setRoad] = useState('');
  const [latitude, setLatitude] = useState('37.5000776');
  const [longitude, setLongitude] = useState('127.0385419');
  const marker = useRef<naver.maps.Marker>(
    new naver.maps.Marker({
      position: new naver.maps.Point(Number(longitude), Number(latitude)),
      clickable: true,
      draggable: true
    })
  );

  return (
    <PageWrapper>
      <InputForm
        drawingManager={drawingManager}
        title={title}
        setTitle={setTitle}
        addr={addr}
        road={road}
        latitude={latitude}
        longitude={longitude}
        isEdit={false}
      >
        <PolygonNaverMap
          drawingManager={drawingManager}
          setDrawingManager={setDrawingManager}
          marker={marker.current}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setAddr={setAddr}
          setRoad={setRoad}
          isEdit={false}
        />
      </InputForm>
    </PageWrapper>
  );
};

export default PolygonAddPage;
