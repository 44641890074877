import { Fragment } from 'react';
import { Body, Caption, primary } from 'loplat-ui';
import styled from 'styled-components';
import Badge from '@F/Badge';
import { listMargin } from '@P/max/campaigns/utils/styles';
import Tooltip from '@F/Tooltip';
import { AdModule, SegmentModule } from '@P/max/campaigns/module';
import SegmentOption, { Wrapper } from './CustomSegment';
import ConjunctionConditions from './ConjunctionConditions';
import type { Props } from './index';

const Segment = ({ segment }: Pick<Props, 'segment'>): JSX.Element => {
  const { name, estimatedUsers, description, is_live, period } = segment;

  return (
    <SegmentWrapper>
      <div>
        <Body tag="span" mr={4} weight="bold">
          {name}
        </Body>
        <Body weight="extraBold" tag="span" color={primary}>
          총 {estimatedUsers.toLocaleString()}명
        </Body>
      </div>
      {description !== '' && (
        <Caption mb={4} size="xs">
          {description}
        </Caption>
      )}

      {'duration' in segment && name !== '모든 사용자' && (
        <>
          {is_live && 'end' in segment ? (
            <Badge color="#314050" text={`[라이브 세그먼트] ${segment.end} 기준 최근 ${period}일`} />
          ) : (
            <Tooltip
              title={`캠페인 실행 시점 기준으로 ${segment.period}일 동안 방문 기록이 있는 고객을 대상으로 캠페인을 진행합니다.`}
              placement="top"
            >
              <Badge color="#314050" text={`${segment.duration} 기준`} />
            </Tooltip>
          )}
        </>
      )}

      <SegmentDetailElement segment={segment} />
    </SegmentWrapper>
  );
};

export default Segment;

const SegmentDetailElement = ({ segment }: Pick<Props, 'segment'>): JSX.Element => {
  const detailsByType = (): React.ReactNode => {
    if (segment.type === 'commercial') {
      const adids = segment.adids ?? [];
      return [...adids]
        .sort((a, b) => (a.toUpperCase() === b ? 1 : -1))
        .map((name, idx) => <AdModule name={name} key={`${name}_${idx}`} />);
    }

    if (segment.type === 'custom') {
      return (
        <OptionList>
          {segment.options.map((option, idx) => (
            <Fragment key={`${option}_${idx}`}>
              {idx > 0 && <ConjunctionConditions type="or" />}
              <li className="base">
                <SegmentOption {...option} />
              </li>
            </Fragment>
          ))}
        </OptionList>
      );
    }
    return <SimpleDetailWrapper type={segment.type} />;
  };

  return <section>{detailsByType()}</section>;
};

const SimpleDetailWrapper = ({ type }: { type: 'allUser' | 'file' }): JSX.Element => (
  <Wrapper>
    {type === 'allUser' && <SegmentModule type="user" />}
    {type === 'file' && (
      <Body mx={0} my={0} weight="extraBold">
        사용자가 업로드한 캠페인 대상자 입니다.
      </Body>
    )}
  </Wrapper>
);

const SegmentWrapper = styled.div`
  width: 100%;

  > div {
    ${listMargin(8)}
  }

  section {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

const OptionList = styled.ul`
  width: 100%;
  li {
    width: 100%;
    p,
    span {
      margin: 0;
    }
    & ~ div {
      margin: 8px 16px;
    }
  }
`;
