import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import type { Settings } from 'react-slick';
import styled from 'styled-components';
import { blue500, bluescale600, bluescale700, grayscale200, spacing } from 'loplat-ui';
import { CreatePromotionParams } from '@TS/max/Promotion/api';

type PreviewCarouselProps = {
  promotion: CreatePromotionParams;
  imgFile: File | null;
};

const PreviewCarousel = ({ imgFile, promotion }: PreviewCarouselProps): JSX.Element => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    appendDots: (dots: React.ReactNode) => (
      <AppendDots>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </AppendDots>
    )
  };

  const imgURL = imgFile ? URL.createObjectURL(imgFile) : '/favicon-32x32.png';

  return (
    <StyledWrapper>
      <Slider {...settings}>
        <AdCard>
          <div className="wrapper">
            <div>
              <img src={imgURL} alt="" width={68} height={68} />
            </div>
            <div className="content">
              <p>
                {promotion.content || '로플랫의 데이터를 통해 매장 방문 이력을 기반으로 오프라인 경쟁력을 강화하세요.'}
              </p>
            </div>
            <a
              target="_black"
              rel="noopener"
              className="link"
              href={promotion.url || 'https://www.loplat.com/loplat-i'}
            >
              자세히보기&#32;&gt;
            </a>
          </div>
        </AdCard>
      </Slider>
    </StyledWrapper>
  );
};

export const AdCard = styled.div`
  padding: 26px 24px;
  height: 100%;
  background-color: #eef0f3;

  .wrapper {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 68px 1fr 78px;
    grid-template-rows: 68px;
    grid-gap: 12px;

    .content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: #4d5566;

        word-break: keep-all;
      }
    }

    a {
      text-decoration: none;

      display: flex;
      align-self: center;

      font-size: 0.875rem;
      font-weight: 500;
      line-height: 21px;
      text-align: right;
      color: ${bluescale700};
    }
  }
`;

export const AppendDots = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  ul {
    li {
      width: 10px;
      button {
        &::before {
          color: ${bluescale600};
        }
      }
      &.slick-active {
        button {
          &::before {
            color: ${blue500};
          }
        }
      }
    }
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
  border-radius: ${spacing(3)}px;
  border: 1px solid ${grayscale200};
  width: 528px;

  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
      }
    }
  }
  .slick-slide {
    > div {
      height: 100%;
    }
  }
`;

export default PreviewCarousel;
